<template>
  <div class="barra-progresso-abbonamento">
    {#if $abbonamento != null}
      {#each PASSI as passo, indice}
        <div class="passo"
            class:corrente={PASSI[$abbonamento.passo_corrente] == passo && !$abbonamento.completato}
            class:fatto={indice < $abbonamento.passo_raggiunto || $abbonamento.completato}>
          <Collegamento
              nome_rotta={`/nuovo_abbonamento/${PASSI[indice]}`}
              disabilitato={indice > $abbonamento.passo_raggiunto || $abbonamento.completato}>
            <span class="cerchio-icona">
              {#if indice < $abbonamento.passo_raggiunto || $abbonamento.completato}
                <Icona tipo="fatto"/>
              {:else}
                {indice + 1}
              {/if}
            </span>
            {t.nomi_passi[passo][$l]}
          </Collegamento>
        </div>
      {/each}
    {/if}
  </div>
</template>

<style>
  :global(div.barra-progresso-abbonamento) {
    display:          flex;
    flex-direction:   column;
    overflow:         hidden;
    flex-grow:        1;
  }
  :global(div.barra-progresso-abbonamento div.passo button) {
    color:            var(--colore-testo-leggero);
    padding:          14px;
    overflow:         hidden;
    text-overflow:    ellipsis;
    justify-content:  flex-start;
  }
  :global(div.barra-progresso-abbonamento div.passo div.collegamento-barra-laterale > a) {
    color:            var(--colore-primario);
    padding:          0 14px;
    overflow:         hidden;
    text-overflow:    ellipsis;
    justify-content:  flex-start;
  }
  :global(div.barra-progresso-abbonamento div.passo.fatto div.collegamento-barra-laterale > a) {
    color:            var(--colore-testo-leggero) !important
  }
  :global(div.barra-progresso-abbonamento div.passo div.collegamento-barra-laterale > a > span.cerchio-icona) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border: 1px solid var(--colore-disabilitato);
    border-radius: 50%;
    margin-right: 15px;
  }
  :global(div.barra-progresso-abbonamento div.passo.corrente div.collegamento-barra-laterale > a > span.cerchio-icona) {
    border-color: var(--colore-primario);
    background-color: var(--colore-primario);
    color: var(--colore-bianco)
  }
  :global(div.barra-progresso-abbonamento div.passo.fatto div.collegamento-barra-laterale > a > span.cerchio-icona) {
    border-color: var(--colore-testo-leggero);
    background-color: var(--colore-testo-leggero);
    color: var(--colore-bianco)
  }
  :global(div.barra-progresso-abbonamento div.passo button[disabled]) {
    background-color: var(--colore-bianco);
    color:            var(--colore-disabilitato);
    cursor:           not-allowed;
  }
  :global(div.barra-progresso-abbonamento div.passi div.passo.corrente button) {
    color:            var(--colore-primario);
  }
</style>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import Collegamento from "@/base/componenti/barra_laterale/Collegamento.svelte"
  import { abbonamento } from "@/portale/sorgenti/abbonamento"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  // Passi di un nuovo abbonamento.
  const PASSI = [
    "struttura",
    "gestionale",
    "piano",
    "fatturazione",
    "pagamento" ]

  const t = avvia_localizzazione(dizionario_progresso)
</script>

<script context="module">
  export const dizionario_progresso = {
    torna_a_gestione_abbonamenti: {
      it: `Torna a Gestione Account`,
      en: `Back to Account Management`,
      de: `Zurück zur Kontoverwaltung`        
    },
    nomi_passi: {
      struttura: {
        it: `La Mia Struttura`,
        en: `My Property`,
        de: `Mein Betrieb`
      },
      gestionale: {
        it: `Il Mio Gestionale`,
        en: `My ERP`,
        de: `Mein Property Management System (PMS)`
      },
      contratto: {
        it: `Contratto`,
        en: `Contract`,
        de: `Vertrag`
      },
      piano: {
        it: `Piano Abbonamento`,
        en: `Plan`,
        de: `Abonnement Plan`
      },
      fatturazione: {
        it: `Fatturazione`,
        en: `Billing`,
        de: `Abrechnung`
      },
      pagamento: {
        it: `Pagamento`,
        en: `Checkout`,
        de: `Auschecken`
      }
    }
  }
</script>
