<template>
  <Rotta schermo_intero={false}>
    {#if titolo != ""}
      <Titolo titolo={titolo}/>
    {/if}

    <MessaggioDominio/>
    
    <div class="contenitore-progresso-abbonamento">
      <div class="contenuto-progresso-abbonamento">
        {#if $location == "/nuovo_abbonamento/pagamento/completato"}
          <slot/>
        {:else}
          <ContenitoreBase senza_margini_padding={true}>
            <slot/>
          </ContenitoreBase>
        {/if}
      </div>      
    </div>
  </Rotta>
</template>

<style>
  :global(div.contenitore-progresso-abbonamento) {
    overflow-x:       auto;
  }
  :global(div.contenuto-progresso-abbonamento) {
    display:          flex;
    flex-grow:        1;
  }
  :global(div.contenuto-progresso-abbonamento div.passo-progresso-abbonamento) {
    width:          45%;
    min-width:      400px;
    max-height:     95%;
    min-height:     calc(100vh - 126px);
    margin:         0 auto;
    padding-top:    30px;
    overflow-y:     auto;
    overflow-x:     hidden;
  }
  :global(div.contenuto-progresso-abbonamento div.passo-progresso-abbonamento.schermo-intero) {
    width:          100%;
    padding:        15px;
    margin-top:     0;
  }
  :global(div.contenuto-progresso-abbonamento div.passo-progresso-abbonamento div.blocco-inserimento-campi) {
    display:          flex;
    justify-content:  space-between;
  }
  :global(div.contenuto-progresso-abbonamento div.passo-progresso-abbonamento .successo) {
    color:    var(--colore-verde);
  }
  :global(div.contenuto-progresso-abbonamento div.passo-progresso-abbonamento .errore) {
    color:    var(--colore-rosso);
  }
  :global(div.contenuto-progresso-abbonamento div.riprendi-abbonamento > div.azioni) {
    display:          flex;
    justify-content:  space-between;
  }
  :global(div.contenuto-progresso-abbonamento div.passo-progresso-abbonamento div.azioni) {
    flex-direction:   row-reverse;
    justify-content:  space-between;
  }
  :global(div.contenuto-progresso-abbonamento div.passo-progresso-abbonamento form) {
    width:          100%;
  }
  :global(div.contenuto-progresso-abbonamento div.passo-progresso-abbonamento form > label) {
    display:        inline-block;
    color:          var(--colore-testo-leggero);
    font-weight:    500;
    line-height:    20.17px;
    font-size:      14px;
    margin-bottom:  7px;
    margin-left:    1.75px;
    cursor:         default;
  }
</style>

<script>
  import Rotta from "@/base/componenti/Rotta.svelte"
  import Titolo from "@/base/componenti/Titolo.svelte"
  import MessaggioDominio from "@/portale/componenti/abbonamento/MessaggioDominio.svelte"
  import ContenitoreBase from "@/base/componenti/ContenitoreBase.svelte"
  import { dominio } from "@/portale/sorgenti/dominio"
  import { abbonamento } from "@/portale/sorgenti/abbonamento"
  import { parametri_abbonamento_personalizzato } from "@/portale/sorgenti/abbonamento"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { onDestroy } from "svelte"
  import { location } from "svelte-spa-router"

  export let titolo = ""

  if ($abbonamento == null) riparti_da_zero_con_abbonamento_nuovo()

  // Riparte da zero con l'abbonamento.
  function riparti_da_zero_con_abbonamento_nuovo(nuovo_dominio) {
    if ($location == "/nuovo_abbonamento/pagamento/completato" || $location == "/nuovo_abbonamento/pagamento/annulato") {
      return
    }

    if ($abbonamento != null && $abbonamento.flusso_personalizzato == nuovo_dominio) return
    let abbonamento_predefinito = parametri_abbonamento_personalizzato($l, $dominio)
    abbonamento.sovrascrivi(abbonamento_predefinito)

    // Aggiunge i valori iniziali da Trentino Marketing.
    let parametri_trentino_marketing = deposito_locale.prendi("parametri_trentino_marketing") || {}
    if (parametri_trentino_marketing.nomeStruttura != null && $abbonamento.struttura.nome == null)       $abbonamento.struttura.nome      = parametri_trentino_marketing.nomeStruttura
    if (parametri_trentino_marketing.capStruttura != null && $abbonamento.struttura.cap == null)        $abbonamento.struttura.cap       = parametri_trentino_marketing.capStruttura
    if (parametri_trentino_marketing.cittàStruttura != null && $abbonamento.struttura.città == null)      $abbonamento.struttura.città     = parametri_trentino_marketing.cittàStruttura
    if (parametri_trentino_marketing.provinciaStruttura != null && $abbonamento.struttura.provincia == null)  $abbonamento.struttura.provincia = parametri_trentino_marketing.provinciaStruttura
    if (parametri_trentino_marketing.indirizzoStruttura != null && $abbonamento.struttura.indirizzo == null)  $abbonamento.struttura.indirizzo = parametri_trentino_marketing.indirizzoStruttura
    if (parametri_trentino_marketing.telefonoStruttura != null && $abbonamento.struttura.telefono == null)   $abbonamento.struttura.telefono  = parametri_trentino_marketing.telefonoStruttura
    abbonamento.sovrascrivi($abbonamento)
  }

  // Al cambio di dominio, riparte con l'abbonamento.
  const scollega = dominio.su_cambio(riparti_da_zero_con_abbonamento_nuovo)
  onDestroy(scollega)
</script>