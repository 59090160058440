<template>
  <RottaNuovoAbbonamento>
    <div class="passo-progresso-abbonamento schermo-intero completato">
      {#if in_caricamento}
        <Caricatore/>
      {:else if $abbonamento.flusso_personalizzato == "veneto"}
        <CompletatoVeneto/>
      {:else}
        <div class="contenitore-messaggio">
          <div class="messaggio-pagamento">
            <h1><span>{t.registrazione[$l]}</span> {t.fine_titolo[$l]}</h1>
            <p>{t.abbonamento_completato[$l]}</p>
            <Bottone
                variante="primario"
                on:click={vai_al_cruscotto}>
              {t.vai_al_cruscotto[$l]}
            </Bottone>
            <p class="copyright">
              &copy; {Date.today.year} HBenchmark s.r.l. - P.IVA03998320240
              <a href="{collegamenti_politiche.riserbo[$l]}" target="_blank">
                Privacy Policy
              </a>
              -
              <a href="{collegamenti_politiche.biscottini[$l]}" target="_blank">
                Cookie Policy
              </a>
            </p>
          </div>
          <div class="contenitore-immagine-spunta">
            <div class="immagine-spunta">
              <img alt="" src={window.risolviImg(spunta)}/>
            </div>
          </div>
        </div>
      {/if}

      {#if $abbonamento.flusso_personalizzato != "veneto"}
        <div class="sfondo">
          <img alt="" src={window.risolviImg(sfondo)}/>
        </div>
      {/if}
    </div>
  </RottaNuovoAbbonamento>
</template>

<style>
  :global(div.passo-progresso-abbonamento.completato.schermo-intero){
    height:           calc(100vh - 61px);
    position:         relative;
    display:          flex;
    justify-content:  center;
  }
  :global(div.passo-progresso-abbonamento.completato div.contenitore-messaggio){
    display:          flex;
    width:            85%;
    height:           fit-content;
    justify-content:  space-between;
    margin-top:       10%;
    z-index:          1;
  }
  :global(div.passo-progresso-abbonamento.completato div.contenitore-messaggio > *){
    width:            50%;
  }
  :global(div.passo-progresso-abbonamento.completato div.contenitore-messaggio div.messaggio-pagamento h1){
    font-size:    35px;
    line-height:  45px;
  }
  :global(div.passo-progresso-abbonamento.completato div.contenitore-messaggio div.messaggio-pagamento h1 span){
    color: var(--colore-primario);
  }
  :global(div.passo-progresso-abbonamento.completato div.contenitore-messaggio div.messaggio-pagamento button){
    margin: 30px 0;
  }
  :global(div.passo-progresso-abbonamento.completato div.contenitore-messaggio div.messaggio-pagamento p.copyright){
    font-size: 12px;
  }
  :global(div.passo-progresso-abbonamento.completato div.contenitore-messaggio div.contenitore-immagine-spunta){
    display:          flex;
    justify-content:  flex-end;
  }
  :global(div.passo-progresso-abbonamento.completato div.contenitore-messaggio div.contenitore-immagine-spunta div.immagine-spunta){
    height: 65%;
  }
  :global(div.passo-progresso-abbonamento.completato div.contenitore-messaggio div.contenitore-immagine-spunta div.immagine-spunta img){
    height: 100%;
  }
  :global(div.passo-progresso-abbonamento.completato div.sfondo){
    position:         fixed;
    width:            calc(100vw - 257px);
    bottom:           0;
  }
  :global(div.passo-progresso-abbonamento.completato div.sfondo img){
    width: 100%;
    position: absolute;
    bottom: 0;
  }
</style>

<script>
  import RottaNuovoAbbonamento from "@/portale/componenti/abbonamento/RottaNuovoAbbonamento.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import CompletatoVeneto from "@/portale/rotte/abbonamenti/pagamento/completato_per_dominio/Veneto.svelte"
  import sfondo from "@/base/immagini/pagamento-completato.svg"
  import spunta from "@/base/immagini/spunta-pagamento-completato.svg"
  import { abbonamento } from "@/portale/sorgenti/abbonamento"
  import { utente_corrente } from "@/base/sorgenti/svuby"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { onDestroy } from "svelte"
  import { onMount } from "svelte"

  const t = avvia_localizzazione(dizionario_completato)
  const collegamenti_politiche = {
    riserbo: {
      it: `https://www.iubenda.com/privacy-policy/81333460`,
      en: `https://www.iubenda.com/privacy-policy/40732172`,
      de: `https://www.iubenda.com/privacy-policy/74782468`
    },
    biscottini: {
      it: `https://www.iubenda.com/privacy-policy/81333460/cookie-policy`,
      en: `https://www.iubenda.com/privacy-policy/40732172/cookie-policy`,
      de: `https://www.iubenda.com/privacy-policy/74782468/cookie-policy`
    }
  }

  let gettone = biscottini.prendi("gettone")
  let in_caricamento = false

  // Torna a gestione abbonamenti.
  async function vai_al_cruscotto() {
    abbonamento.sovrascrivi(null)

    if (gettone == null || $utente_corrente == null) {
      utente_corrente.slogga()
      in_caricamento = false
      return
    }
    
    let risposta = await retro.chiama("GET", retro.estremi.anagrafe.me)
    if (risposta.stato == 401) navigatore.verso("/accesso")
    let utente_autenticato = risposta.contenuto.utente
    
    utente_corrente.cambia_in(utente_autenticato)
    location.reload()
  }

  onMount(() => {
    if ($abbonamento == null) return navigatore.verso("/")
    $abbonamento.completato = true
  })
  onDestroy(async () => {
    abbonamento.sovrascrivi(null)

    if (gettone == null || $utente_corrente == null) {
      utente_corrente.slogga()
      in_caricamento = false
      return
    }
    
    let risposta = await retro.chiama("GET", retro.estremi.anagrafe.me)
    let utente_autenticato = risposta.contenuto.utente
    
    utente_corrente.cambia_in(utente_autenticato)
  })
</script>

<script context="module">
  export const dizionario_completato = {
    registrazione: {
      it: `Registrazione`,
      en: `Registration`,
      de: `Registrierung`
    },
    fine_titolo: {
      it: `avvenuta con successo`,
      en: `was successful`,
      de: `war erfolgreich`
    },
    pagamento_annullato: {
      it: `Il pagamento non è andato a buon fine`,
      en: `The payment was canceled`,
      de: `Die Zahlung wurde storniert`
    },
    pagamento_completato: {
      it: `Abbiamo ricevuto il tuo pagamento con successo!`,
      en: `We received your payment successfully!`,
      de: `Wir haben Ihre Zahlung erfolgreich erhalten!`
    },
    creazione_in_corso: {
      it: `Creazione abbonamento in corso`,
      en: `Subscription is being created`,
      de: `Abonnement wird erstellt`
    },
    abbonamento_completato: {
      it: `Ora potrai accedere alla tua dashboard`,
      en: `You will now be able to access your dashboard`,
      de: `Sie können jetzt auf Ihr Dashboard zugreifen`
    },
    vai_al_cruscotto: {
      it: `Vai alla Dashboard`,
      en: `Go to the Dashboard`,
      de: `Gehe zum Dashboard`
    }
  }
</script>