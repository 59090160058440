<template>
  <div class="strutture-contribuenti">
    {#each statistica.gruppi as gruppo, indice_gruppo}
      <div class="gruppo-strutture">
        <p>
          <b>
            {gruppo.nome}
            {#if gruppo.categorie != null && gruppo.categorie.length > 0}
              - {gruppo.categorie.join(" - ")}
            {/if}
          </b>
        </p>
        {#each statistica.cornice_temporale.periodi as periodo, indice_periodo}
          {#each serie_per_gruppo_e_periodo[indice_gruppo][indice_periodo] as serie, indice}
            {#if indice == 0}
              <div class="dettaglio-strutture">
                <div class="dettagli-principali">
                  <span class="nome-periodo">
                    {#if periodo.osservato}
                      {periodi[periodo.nome][$l]}
                      {periodo.data_osservazione.to_date.format("%e %B %Y")}:
                    {:else}
                      {periodi[periodo.nome][$l]}:
                    {/if}
                  </span>
  
                  &nbsp;<b>{serie.strutture_contribuenti.length}</b>
                  &nbsp;{t.strutture[$l]}
  
                  <Bottone
                      piccolo={true}
                      variante="primario-invertito"
                      on:click={evento => apri_modale_dettaglio_strutture(serie.strutture_contribuenti)}>
                    <Icona tipo="ricerca"/>
                  </Bottone>
                </div>

                <div class="dettagli-secondari">
                  {t.di_cui[$l]}
                  {#each Object.keys(inventaria_strutture_contribuenti(serie.strutture_contribuenti)) as categoria}
                    {#if categoria == "unità_totali"}
                      <br/>
                      {t.per_un_totale_di[$l]}
                      <strong>{inventaria_strutture_contribuenti(serie.strutture_contribuenti)[categoria]}</strong>
                      {#if $sessione_corrente.tipo == "hotel"}
                        {t.camere_disponibili[$l]}.
                      {:else}
                        {t.unità_disponibili[$l]}.
                      {/if}
                    {:else}
                      &nbsp;{inventaria_strutture_contribuenti(serie.strutture_contribuenti)[categoria]} {t.in[$l]} <strong>{categoria}</strong>,
                    {/if}
                  {/each}
                </div>
              </div>
            {/if}
          {/each}
        {/each}
      </div>
    {/each}

    <Modale
        espansa={true}
        scorribile={true}
        richiudibile={true}
        titolo={t.titolo_strutture[$l]}
        bind:aperta={dettaglio_strutture_aperto}>
      <div class="modale-contenuto">
        <Tabella
            {colonne}
            {valore_cella}
            righe={strutture}
            dizionario={t}
            let:riga={riga}
            let:colonna={colonna}
            let:valore={valore}>
          {valore_cella(riga, colonna, valore)}
        </Tabella>
      </div>
    </Modale>
  </div>
</template>

<style>
  :global(div.strutture-contribuenti) {
    display:        flex;
  }
  :global(div.strutture-contribuenti div.gruppo-strutture) {
    margin-right: 20px;
  }
  :global(div.strutture-contribuenti div.dettaglio-strutture) {
    margin-bottom: 20px;
  }
  :global(div.strutture-contribuenti div.dettaglio-strutture div.dettagli-principali) {
    display:        flex;
    align-items:    center;
  }
  :global(div.strutture-contribuenti div.dettaglio-strutture div.dettagli-principali span.nome-periodo) {
    font-weight:    500;
  }
  :global(div.strutture-contribuenti div.dettaglio-strutture div.dettagli-principali button) {
    padding-top:    0;
    padding-bottom: 0;
  }
  :global(div.strutture-contribuenti div.dettaglio-strutture div.dettagli-secondari.attivo) {
    display: block;
  }
</style>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Modale from "@/base/componenti/Modale.svelte"
  import Tabella from "@/base/componenti/Tabella.svelte"
  import { categorie } from "@/base/sorgenti/hbenchmark/categorie"
  import { periodi } from "@/base/sorgenti/hbenchmark/periodi"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { sessione_corrente } from "@/portale/sorgenti/sessione"

  const t = avvia_localizzazione(dizionario_strutture_contribuenti)
  const colonne = [ "nome", "categoria", "disponibilità" ]

  export let statistica = {}
  export let serie = []

  let serie_per_gruppo_e_periodo = {}
  let dettaglio_strutture_aperto = false
  let strutture = []

  ////
  // Raggruppa le serie per gruppo e periodo.
  function raggruppa_serie_per_gruppo_e_periodo() {
    serie_per_gruppo_e_periodo = {}

    statistica.gruppi.forEach((gruppo, indice_gruppo) => {
      serie_per_gruppo_e_periodo[indice_gruppo] = {}
      statistica.cornice_temporale.periodi.forEach((periodo, indice_periodo) => {
        serie_per_gruppo_e_periodo[indice_gruppo][indice_periodo] = []
      })
    })

    serie.forEach(serie => {
      let puntatore = serie_per_gruppo_e_periodo[serie.indice_gruppo][serie.indice_periodo]
      serie_per_gruppo_e_periodo[serie.indice_gruppo][serie.indice_periodo] = [
        ...puntatore, serie ]
    })
  }

  ////
  // Determina il valore di una cella.
  function valore_cella(riga, colonna, valore, indice_riga, indice_colonna) {
    switch (colonna) {
      case "disponibilità":
        return riga.unità_disponibili || "-"
      default:
        return valore || "-"
    }
  }

  ////
  // Apre il dettaglio strutture contribuenti.
  function apri_modale_dettaglio_strutture(strutture_contribuenti) {
    dettaglio_strutture_aperto = true
    strutture = strutture_contribuenti
  }

  ////
  // Fa l'inventario delle strutture contribuenti alla statistica
  function inventaria_strutture_contribuenti(strutture) {
    let inventario = categorie.reduce((inventario, categoria) => ({...inventario, [categoria]: 0}), {})
    let unità_totali = 0

    strutture.forEach(struttura => {
      if (categorie.includes(struttura.categoria)) inventario[struttura.categoria]++
      if (struttura.unità_disponibili != null) unità_totali += struttura.unità_disponibili
    })

    Object.keys(inventario).forEach(categoria => {
      if (inventario[categoria] == 0) delete inventario[categoria]
    })

    return inventario = { ...inventario,  unità_totali }
  }

  // Al cambio di serie, raggruppa.
  $: if (serie != null) raggruppa_serie_per_gruppo_e_periodo()
</script>

<script context="module">
  export const dizionario_strutture_contribuenti = {
    strutture: {
      it: `strutture`,
      en: `properties`,
      de: `Betriebe`
    },
    titolo_strutture: {
      it: `Strutture`,
      en: `Properties`,
      de: `Betriebe`
    },
    di_cui: {
      it: `di cui`,
      en: `of which`,
      de: `wovon`
    },
    in: {
      it: `in`,
      en: `in`,
      de: `in`
    },
    per_un_totale_di: {
      it: `per un totale di`,
      en: `for a total of`,
      de: `Für insgesamt`
    },
    camere_disponibili: {
      it: `camere disponibili`,
      en: `available Rooms`,
      de: `Verfügbare Zimmer`
    },
    unità_disponibili: {
      it: `unità disponibili`,
      en: `available units`,
      de: `Verfügbare Einheiten`
    },
    colonne: {
      nome: {
        it: `Nome`,
        en: `Name`,
        de: `Vorname`
      },
      categoria: {
        it: `Categoria`,
        en: `Category`,
        de: `Kategorie`
      },
      disponibilità: {
        it: `Disponibilità`,
        en: `Availability`,
        de: `Verfügbarkeit`
      }
    }
  }
</script>
