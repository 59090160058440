<template>
  <GestioneStruttura>
    {#if in_caricamento}
      <Caricatore/>
    {:else}
      <div class="margini-positivi">
        <TabellaProdotti
            {prodotti}
            {struttura}
            {segmenti}
            {attributi}
            {in_salvataggio}
            {messaggio_visualizzato}
            tipologia={struttura.tipo}
            on:salva={salva_modifiche}/>
      </div>
    {/if}
  </GestioneStruttura>
</template>

<script>
  import GestioneStruttura from "@/portale/componenti/GestioneStruttura.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import TabellaProdotti from "@/base/componenti/tabelle/Prodotti.svelte"
  import { onDestroy } from "svelte"
  import { sessione_corrente } from "@/portale/sorgenti/sessione"

  let in_caricamento = true
  let struttura = {}
  let prodotti = []
  let segmenti = {}
  let attributi = {}
  let in_salvataggio = false
  let messaggio_visualizzato = false

  ////
  // Richiede le prodotti.
  async function richiedi_codifiche(nuova_sessione) {
    in_caricamento = true
    if (nuova_sessione.tipo != "campeggio") navigatore.verso("/gestione_strutture/struttura")

    let risposta = await retro.chiama(
      "GET",
      retro.estremi.portale.codifiche, {
        id_struttura: $sessione_corrente._id,
        solo_prodotti: true })

    prodotti = risposta.contenuto.codifiche
    struttura = risposta.contenuto.struttura
    segmenti = risposta.contenuto.segmenti
    attributi = risposta.contenuto.attributi

    in_caricamento = false
  }

  ////
  // Salva le modifiche.
  async function salva_modifiche(evento) {
    in_salvataggio = true

    let parametri = {
      id_struttura: $sessione_corrente._id,
      solo_prodotti: true,
      codifiche: Object.values(evento.detail.prodotti_da_aggiornare) }

    let risposta = await retro.chiama("PATCH",
      retro.estremi.portale.codifiche,
      parametri)

    prodotti = risposta.contenuto.codifiche
    segmenti = risposta.contenuto.segmenti
    attributi = risposta.contenuto.attributi
    struttura = risposta.contenuto.struttura

    in_salvataggio = false
    messaggio_visualizzato = true
  }

  // Al cambio sessione, richiede le prodotti.
  const scollega = sessione_corrente.su_cambio(richiedi_codifiche)
  // Quando distrutto, scollega i cambi.
  onDestroy(scollega)
</script>
