<template>
  <RottaNuovoAbbonamento titolo={t.titolo[$l]}>
    <div class="passo-progresso-abbonamento struttura">
      <Modulo 
          gestore={gestore}
          validatore={validatore}
          bind:errori={errori}>
        <TitoloSecondario>{t.titolo_secondario[$l]}</TitoloSecondario>
        <Inserimento
            etichetta={t.nome[$l]}
            errore={errori.nome}
            segnaposto={t.segnaposti.nome[$l]}
            bind:valore={$abbonamento.struttura.nome}/>
        <label for="">
          {t.tipo[$l]}
        </label>
        <Combo 
            nome="tipo_struttura"
            valore="hotel"
            etichetta="Hotel"
            selezionato={$abbonamento.struttura.tipo == "hotel"}
            on:change={evento => $abbonamento.struttura.tipo = evento.target.value}/>
        <Combo 
            nome="tipo_struttura"
            valore="campeggio"
            etichetta="Camping"
            selezionato={$abbonamento.struttura.tipo == "campeggio" || $abbonamento.struttura.tipo == "camping"}
            on:change={evento => $abbonamento.struttura.tipo = evento.target.value}/>
        <div class="blocco-inserimento-campi">
          <Selezione 
              icona="espandi"
              etichetta={t.categoria[$l]}
              attributi={{ etichetta: "Category" }}
              opzioni={OPZIONI_CATEGORIA}
              bind:valore={$abbonamento.struttura.categoria}/>
          {#if $abbonamento.struttura.tipo == "hotel"}
            <Inserimento 
                tipo="numero"
                errore={errori.unità_disponibili}
                attributi={{ minimo: 1, massimo: 999 }}
                etichetta={t.unità_disponibili[$l]}
                segnaposto={`${t.segnaposti.esempio[$l]} 24`}
                bind:valore={$abbonamento.struttura.unità_disponibili}/>
            <Inserimento 
                tipo="numero"
                errore={errori.letti_disponibili}
                attributi={{ minimo: 1, massimo: 999 }}
                etichetta={t.letti_disponibili[$l]}
                segnaposto={`${t.segnaposti.esempio[$l]} 90`}
                bind:valore={$abbonamento.struttura.letti_disponibili}/>
          {/if}
        </div>
        <Inserimento 
            etichetta={t.indirizzo[$l]}
            attributi={{ etichetta: "Address" }}
            errore={errori.indirizzo}
            bind:valore={$abbonamento.struttura.indirizzo}/>
        <div class="blocco-inserimento-campi">
          <Inserimento 
              etichetta={t.città[$l]}
              attributi={{ etichetta: "City" }}
              errore={errori.città}
              bind:valore={$abbonamento.struttura.città}/>
          <Inserimento 
              etichetta={t.provincia[$l]}
              attributi={{ etichetta: "Province" }}
              errore={errori.provincia}
              bind:valore={$abbonamento.struttura.provincia}/>
          <Inserimento 
              etichetta={t.cap[$l]}
              attributi={{ etichetta: "ZIP" }}
              errore={errori.cap}
              bind:valore={$abbonamento.struttura.cap}/>
        </div>
        <Inserimento 
            etichetta={t.telefono[$l]}
            attributi={{ etichetta: "Phone" }}
            errore={errori.telefono}
            bind:valore={$abbonamento.struttura.telefono}/>
        <div class="azioni">
          <Bottone 
              tipo="invio"
              variante="primario">
            {t.continua[$l]}
          </Bottone>
        </div>
      </Modulo>
    </div>
  </RottaNuovoAbbonamento>
</template>

<style>
  :global(div.contenuto-progresso-abbonamento div.passo-progresso-abbonamento.struttura
  div.blocco-inserimento-campi:nth-child(6) div.inserimento),
  :global(div.contenuto-progresso-abbonamento div.passo-progresso-abbonamento.struttura
  div.blocco-inserimento-campi:nth-child(6) div.selezione) {
    width: 30%;
  }
  :global(div.contenuto-progresso-abbonamento div.passo-progresso-abbonamento.struttura
  div.blocco-inserimento-campi:nth-child(8) div.inserimento:first-child) {
    width: 40%;
  }
  :global(div.contenuto-progresso-abbonamento div.passo-progresso-abbonamento.struttura
  div.blocco-inserimento-campi:nth-child(8) div.inserimento) {
    width: 25%;
  }
</style>

<script>
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Modulo from "@/base/componenti/Modulo.svelte"
  import Combo from "@/base/componenti/Combo.svelte"
  import Selezione from "@/base/componenti/Selezione.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import TitoloSecondario from "@/base/componenti/TitoloSecondario.svelte"
  import RottaNuovoAbbonamento from "@/portale/componenti/abbonamento/RottaNuovoAbbonamento.svelte"
  import { abbonamento } from "@/portale/sorgenti/abbonamento"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  const t = avvia_localizzazione(dizionario_struttura)
  const OPZIONI_CATEGORIA = [
    { valore: "No-Star",    testo: "No-Star" },
    { valore: "2*",         testo: "2*" },
    { valore: "3*",         testo: "3*" },
    { valore: "3*S",        testo: "3* S" },
    { valore: "4*",         testo: "4*" },
    { valore: "4*S",        testo: "4* S" },
    { valore: "5*",         testo: "5*" },
    { valore: "5*S",        testo: "5* S" } ]

  let errori = {
    nome:               null,
    categoria:          null,
    unità_disponibili:  null,
    letti_disponibili:  null,
    città:              null,
    indirizzo:          null,
    provincia:          null,
    cap:                null,
    telefono:           null }
  
  $: if ($abbonamento != null && $abbonamento.struttura.tipo == "campeggio") {
    delete $abbonamento.struttura.unità_disponibili
    delete $abbonamento.struttura.letti_disponibili
  }

  // Valida i dati struttura.
  function validatore() {
    if (!$abbonamento.struttura.nome)               errori.nome               = t.validazioni.richiesto[$l]
    if (!$abbonamento.struttura.categoria)          errori.categoria          = t.validazioni.richiesto[$l]
    if (!$abbonamento.struttura.città)              errori.città              = t.validazioni.richiesto[$l]
    if (!$abbonamento.struttura.indirizzo)          errori.indirizzo          = t.validazioni.richiesto[$l]
    if (!$abbonamento.struttura.provincia)          errori.provincia          = t.validazioni.richiesto[$l]
    if (!$abbonamento.struttura.cap)                errori.cap                = t.validazioni.richiesto[$l]
    if (!$abbonamento.struttura.telefono)           errori.telefono           = t.validazioni.richiesto[$l]
    if (!$abbonamento.struttura.unità_disponibili && $abbonamento.struttura.tipo == "hotel") {
      errori.unità_disponibili  = t.validazioni.richiesto[$l]
    }
    if (!$abbonamento.struttura.letti_disponibili && $abbonamento.struttura.tipo == "hotel") {
      errori.letti_disponibili  = t.validazioni.richiesto[$l]
    }
  }

  // Gestisce il passaggio al prossimo passo.
  function gestore() {
    $abbonamento.passo_corrente = 1
    if ($abbonamento.struttura.tipo == "campeggio" || $abbonamento.struttura.tipo == "camping") {
      delete $abbonamento.struttura.letti_disponibili
      delete $abbonamento.struttura.unità_disponibili
    }
    if ($abbonamento.passo_raggiunto < 1) $abbonamento.passo_raggiunto = 1
    abbonamento.sovrascrivi($abbonamento)
    navigatore.verso("/nuovo_abbonamento/gestionale")
  }
</script>

<script context="module">
  export const dizionario_struttura = {
    titolo: {
      it: `La mia struttura`,
      en: `My Property`,
      de: `Mein Betrieb`
    },
    titolo_secondario: {
      it: `Inserisci i dati della tua struttura`,
      en: `Enter your facility details`,
      de: `Geben Sie Ihre Unternehmensdetails ein`
    },
    nome: {
      it: `Nome Struttura *`,
      en: `Property Name *`,
      de: `Name des Betriebes*`
    },
    tipo: {
      it: `Tipo Struttura`,
      en: `Property Type`,
      de: `Art des Betriebes`
    },
    categoria: {
      it: `Categoria *`,
      en: `Category *`,
      de: `Kategorie *`
    },
    unità_disponibili: {
      it: `Camere Disponibili *`,
      en: `Available Rooms *`,
      de: `Verfügbare Zimmer *`
    },
    letti_disponibili: {
      it: `Letti Disponibili *`,
      en: `Available Beds *`,
      de: `Verfügbare Betten *`
    },
    città: {
      it: `Città *`,
      en: `City *`,
      de: `Stadt *`
    },
    indirizzo: {
      it: `Indirizzo *`,
      en: `Address *`,
      de: `Adresse *`
    },
    provincia: {
      it: `Provincia *`,
      en: `Province *`,
      de: `Region *`
    },
    cap: {
      it: `CAP *`,
      en: `Zip Code *`,
      de: `Postleitzahl (PLZ) *`
    },
    telefono: {
      it: `Telefono *`,
      en: `Phone *`,
      de: `Telefonnummer *`
    },
    segnaposti: {
      nome: {
        it: `Inserisci il nome della tua struttura`,
        en: `Enter the name of your property`,
        de: `Geben Sie den Namen Ihres Betriebes ein`
      },
      esempio: {
        it: `es.`,
        en: `ex.`,
        de: `z.B..`
      },
      città: {
        it: `es. Milano`,
        en: `ex. Milan`,
        de: `z.B. Mailand`
      },
      indirizzo: {
        it: `es. Via G. Battista`,
        en: `ex. G. Battista St`,
        de: `z.B. G. Battista St`
      },
      provincia: {
        it: `es. Milano (MI)`,
        en: `ex. Milano (MI)`,
        de: `z.B. Milano (MI)`
      },
      telefono: {
        it: `es. XXX - XXXXXXX`,
        en: `ex. XXX - XXXXXXX`,
        de: `z.B. XXX - XXXXXXX`
      },
    }
  }
</script>