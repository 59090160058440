<template>
  <div class="modulo-attributi-codifica">
    {#each nomi_attributi as nome_attributo, indice}
      {#if attributi_visibili.include(nome_attributo)}
        <div class="attributi">
          <Selezione
              ricercabile={true}
              errore={errori_attributi[nome_attributo]}
              etichetta={t[nome_attributo][$l]}
              opzioni={opzioni_attributi[nome_attributo]}
              bind:valore={prodotto[nome_attributo]}
              let:opzione={opzione}
              let:opzione_selezionata={opzione_selezionata}
              on:change={propaga_aggiornamento}>
            <div slot="valore">
              {#if t[opzione_selezionata.valore] != null}
                {t[opzione_selezionata.valore][$l]}
              {:else}
                -
              {/if}
            </div>
            <div slot="opzione">
              {t[opzione.valore][$l]}
            </div>
          </Selezione>
        </div>
      {/if}
    {/each}

    <div class="azioni">
      <Bottone
          variante="primario"
          on:click={propaga_conferma}>
        {t.conferma[$l]}
      </Bottone>
    </div>
  </div>
</template>

<style>
  :global(div.modulo-attributi-codifica) {
    margin-top:       14px;
  }
  :global(div.modulo-attributi-codifica div.azioni) {
    display:          flex;
    justify-content:  flex-end;
    margin-top:       14px;
  }
</style>

<script>
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Selezione from "@/base/componenti/Selezione.svelte"
  import { createEventDispatcher } from "svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { attributo_per_prodotto } from "@/base/sorgenti/hbenchmark/attributo_per_prodotto"

  export let prodotto = {}
  export let attributi = {}

  const t = avvia_localizzazione()
  const propaga = createEventDispatcher()

  let opzioni_attributi = {}
  let errori_attributi = {}

  // Nomi delle attributi.
  $: nomi_attributi = Object.keys(attributi)

  // Opzioni ed errori degli attributi.
  $: nomi_attributi.each(nome_attributo => {
    let attributi_specificati = attributi[nome_attributo].filter(valore => valore != "non_specificato")

    errori_attributi[nome_attributo] = null
    opzioni_attributi[nome_attributo] = attributi_specificati.map(valore_attributo => {
      return { valore: valore_attributo }
    })
  })

  // Proprietà visibili in base al codice prodotto.
  $: attributi_visibili = prodotto.codice_interno != null ? attributo_per_prodotto[prodotto.codice_interno] : []

  // Quando cambia il codice interno, reimposta le attributi non visibili.
  $: if (prodotto.codice_interno != null) nomi_attributi.each(nome_attributo => {
    if (attributo_per_prodotto[prodotto.codice_interno].include(nome_attributo)) return
    prodotto[nome_attributo] = "non_specificato"
  })

  ////
  // Propaga aggiornamento.
  function propaga_aggiornamento() {
    propaga("change", { valore: prodotto.codice_interno })
  }

  ////
  // Propaga conferma.
  function propaga_conferma() {
    attributi_visibili.each(nome_attributo => errori_attributi[nome_attributo] = null)

    attributi_visibili.each(nome_attributo => {
      if (prodotto[nome_attributo] == null || prodotto[nome_attributo] == "non_specificato")
        errori_attributi[nome_attributo] = t.validazioni.richiesto[$l]
    })

    if (Object.values(errori_attributi).some(valore => valore != null)) return
    propaga("submit", { prodotto })
  }
</script>
