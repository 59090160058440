<template>
  <div class="tabella-codifiche">
    <div class="filtri">
      <div class="inserimenti">
        <Selezione
            disabilitata={in_salvataggio || salvataggio_riuscito}
            opzioni={opzioni_segmento}
            let:opzione={opzione}
            let:opzione_selezionata={opzione_selezionata}
            bind:valore={filtri.segmento}
            etichetta={t.filtri.segmento[$l]}>
          <div slot="valore">
            {#if t[opzione_selezionata.valore] != null}
              {t[opzione_selezionata.valore][$l]}
            {:else}
              -
            {/if}
          </div>
          <div slot="opzione">
            {#if t[opzione.valore] != null}
              {t[opzione.valore][$l]}
            {:else}
              {opzione.testo}
            {/if}
          </div>
        </Selezione>
        <Inserimento
            cancellabile={true}
            disabilitato={in_salvataggio || salvataggio_riuscito}
            bind:valore={filtri.codice}
            etichetta={t.filtri.codice[$l]}
            segnaposto={t.filtri.segnaposto_codice[$l]}/>
      </div>
      {#if Object.keys(codifiche_da_aggiornare).length > 0}
        <Bottone
            variante="primario-invertito"
            disabilitato={in_salvataggio || salvataggio_riuscito}
            on:click={salva_modifiche}>
          {t.salva[$l]}
        </Bottone>
      {/if}
    </div>

    {#if in_salvataggio}
      <div class="ricalcolo-in-corso">
        <Caricatore/>
        <p>{t.salvataggio_in_corso[$l]}</p>
      </div>
    {:else if salvataggio_riuscito}
      <div class="ricalcolo-in-corso">
        <p>{t.salvataggio_riuscito[$l]}</p>
        <Bottone
            variante="primario"
            on:click={evento => propaga("change")}>
          {t.continua[$l]}
        </Bottone>
      </div>
    {:else}
      <Tabella
          {colonne}
          {valore_cella}
          righe={codifiche_filtrate}
          dizionario={t}
          cliccabile={true}
          disabilitata={in_salvataggio || salvataggio_riuscito}
          ultima_colonna_fissa={true}
          let:riga={riga}
          let:colonna={colonna}
          let:valore={valore}
          on:click={evento => apri_modale_modifica(evento.detail.riga)}>
        {#if colonna == "stato"}
          {#if riga.codice_interno == "non_specificato" && riga.segmento != "nazionalità"}
            <Fanalino stato="da_controllare"/>
          {:else if riga.modificata}
            <Fanalino stato="in_corso"/>
          {:else}
            <Fanalino stato="completato"/>
          {/if}
        {:else if colonna == "segmento"}
          {#if valore != null && t[valore] != null}
            {t[valore][$l]}
          {:else}
            -
          {/if}
        {:else if colonna == "codice_interno"}
          {#if valore != null && t[valore] != null}
            {t[valore][$l]}
          {/if}
        {:else if colonna == "modifica"}
          <Bottone
              variante="primario-invertito"
              on:click={evento => apri_modale_modifica(riga)}>
            <Icona tipo="modifica"/>
          </Bottone>
        {:else}
          {valore_cella(riga, colonna, valore)}
        {/if}
      </Tabella>
    {/if}

    <Messaggio visualizzato={messaggio_visualizzato}>
      {t.salvataggio_riuscito[$l]}
    </Messaggio>
    <Modale
        scorribile={false}
        richiudibile={true}
        mostra_eccedenze={true}
        titolo={t.modale.titolo[$l]}
        bind:aperta={modale_modifica_aperta}
        on:chiusa={evento => indice_in_modifica = null}>
      {#if indice_in_modifica != null}
        <div class="modale-contenuto">
          <p>
            {t.modale.domanda_codifica.prima_parte[$l]}
            <b>{t[codifiche[indice_in_modifica].segmento][$l]}</b>
            {t.modale.domanda_codifica.seconda_parte[$l]}
            <b>{codifiche[indice_in_modifica].codice_esterno}</b>
            {t.modale.domanda_codifica.terza_parte[$l]}
          </p>

          <Selezione
              ricercabile={true}
              opzioni={opzioni_codice_interno}
              valore={codifiche[indice_in_modifica].codice_interno}
              let:opzione={opzione}
              let:opzione_selezionata={opzione_selezionata}
              on:change={modifica_codifica}>
            <div slot="valore">
              {#if t[opzione_selezionata.valore] != null}
                {t[opzione_selezionata.valore][$l]}
              {:else}
                -
              {/if}
            </div>
            <div slot="opzione">
              {t[opzione.valore][$l]}
            </div>
          </Selezione>
        </div>
      {/if}
    </Modale>
  </div>
</template>

<style>
  :global(div.tabella-codifiche) {
    flex-grow:        1;
    display:          flex;
    flex-direction:   column;
  }
  :global(div.tabella-codifiche div.filtri) {
    display:          flex;
    align-items:      flex-end;
    justify-content:  space-between;
    margin-bottom:    14px;
  }
  :global(div.tabella-codifiche div.filtri > div.inserimenti) {
    display:          flex;
    align-items:      flex-end;
    flex-grow:        1;
    margin-right:     14px;
  }
  :global(div.tabella-codifiche div.filtri > div.inserimenti div.selezione) {
    margin-right:     14px;
  }
  :global(div.tabella-codifiche div.filtri > div.inserimenti div.selezione),
  :global(div.tabella-codifiche div.filtri > div.inserimenti div.inserimento) {
    flex-grow:        1;
    max-width:        350px;
    margin-bottom:    0;
  }
  :global(div.tabella-codifiche div.ricalcolo-in-corso) {
    display:          flex;
    flex-direction:   column;
    align-items:      center;
  }
</style>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Tabella from "@/base/componenti/Tabella.svelte"
  import Fanalino from "@/base/componenti/Fanalino.svelte"
  import Modale from "@/base/componenti/Modale.svelte"
  import Messaggio from "@/base/componenti/Messaggio.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Selezione from "@/base/componenti/Selezione.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import { createEventDispatcher } from "svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  export let codifiche = []
  export let segmenti = {}
  export let in_salvataggio = false
  export let salvataggio_riuscito = false

  const t = avvia_localizzazione(dizionario_codifiche)
  const propaga = createEventDispatcher()

  let indice_in_modifica = null
  let codifiche_da_aggiornare = {}
  let modale_modifica_aperta = false
  let messaggio_visualizzato = false
  let filtri = { segmento: null, codice: null }
  let colonne = [ "stato", "segmento", "codice_esterno", "codice_interno", "ultima_modifica", "modifica" ]

  // Codifiche con indice.
  $: codifiche_con_indice = codifiche.map((codifica, indice) => {
    return { ...codifica, indice }
  })

  // Codifica in modifica.
  $: codifica_in_modifica = indice_in_modifica != null ? codifiche[indice_in_modifica] : null

  // Opzioni codice interno.
  $: opzioni_codice_interno = codifica_in_modifica != null ?
    segmenti[codifica_in_modifica.segmento].map(sotto_segmento => {
      let testo = t[sotto_segmento][$l]
      return { valore: sotto_segmento, testo }
    }) : []

  // Filtro su segmento.
  $: opzioni_segmento = [
    { testo: t.tutti[$l], valore: null },
    ...codifiche.map(codifica => codifica.segmento).uniq.map(segmento => {
      return { valore: segmento }
    }) ]
  // Filtro su codice.
  $: espressione_ricerca_codice = new RegExp(`.*${filtri.codice}.*`, "i")

  // Righe filtrate.
  $: codifiche_filtrate = codifiche_con_indice.filter(codifica => {
    let includi = true
    if (filtri.segmento != null) includi = codifica.segmento == filtri.segmento
    if (filtri.codice != null && filtri.codice != "") includi = (codifica.codice_esterno.match(espressione_ricerca_codice) != null)
    return includi
  })

  ////
  // Apre la modale codifica.
  function apri_modale_modifica(codifica) {
    indice_in_modifica = codifica.indice
    modale_modifica_aperta = true
  }

  ////
  // Modifica una codifica.
  function modifica_codifica(evento) {
    let codice_interno = evento.detail.valore
    let codifica_modificata = codifiche[indice_in_modifica]

    codifica_modificata.codice_interno = codice_interno
    codifiche_da_aggiornare[codifica_modificata._id] = codifica_modificata
    codifiche[indice_in_modifica] = { ...codifica_modificata, modificata: true }

    modale_modifica_aperta = false
    indice_in_modifica = null
  }

  ////
  // Salva le modifiche.
  function salva_modifiche() {
    propaga("salva", {codifiche_da_aggiornare})
  }

  ////
  // Determina il valore di una cella.
  function valore_cella(riga, colonna, valore, indice_riga, indice_colonna) {
    switch (colonna) {
      case "segmento":
      case "codice_interno":
        if (valore == null) return "-"
        return t[valore][$l]
      case "ultima_modifica":
        if (riga.aggiornata_il == null) return "-"
        if (riga.nome_aggiornatore == null) return "-"
        return [
          riga.aggiornata_il.to_date.format("%d %B %Y"),
          " da ",
          riga.nome_aggiornatore ].join(" ")
      default:
        return valore || "-"
    }
  }

  // Rimuove il messaggio di salvataggio.
  $: if (codifiche != null) salvataggio_riuscito = false
</script>

<script context="module">
  export const dizionario_codifiche = {
    ricalcolo_in_corso: {
      it: `Ricalcolo dati in corso: l'operazione potrebbe richiedere qualche minuto.`,
      en: `Data recalc in progress: it might take some minutes.`,
      de: `Daten werden neu berechnet: Es kann einige Minuten dauern.`
    },
    salvataggio_in_corso: {
      it: `Salvataggio codifiche in corso..`,
      en: `Transcodings save in progress..`,
      de: `Kodierungen werden gespeichert..`
    },
    salvataggio_riuscito: {
      it: `Codifiche salvate con successo. I dati saranno aggiornati fra qualche minuto.`,
      en: `Transcodings updated successfully. The data will be updated within a few minutes.`,
      de: `Kodierungen erfolgreich aktualisiert. Die Daten werden innerhalb weniger Minuten aktualisiert.`
    },
    modale: {
      titolo: {
        it: `Modifica`,
        en: `Edit`,
        de: `Bearbeiten`
      },
      domanda_codifica: {
        prima_parte: {
          it: `Che tipo di `,
          en: `What kind of`,
          de: `Welche Art von`
        },
        seconda_parte: {
          it: `identifica il codice `,
          en: `does the code `,
          de: `identifiziert den Code `
        },
        terza_parte: {
          it: `?`,
          en: `identify?`,
          de: `?`
        }
      }
    },
    filtri: {
      segmento: {
        it: `Filtra Segmento`,
        en: `Filter Segment`,
        de: `Segment filtern`
      },
      codice: {
        it: `Filtra Codice`,
        en: `Filter Code`,
        de: `Code filtern`
      },
      segnaposto_codice: {
        it: `Ricerca per codice`,
        en: `Search by code`,
        de: `Suche nach Code`
      }
    },
    colonne: {
      stato: {
        it: `Stato`,
        en: `Status`,
        de: `Status`
      },
      segmento: {
        it: `Segmento`,
        en: `Segment`,
        de: `Segment`
      },
      codice_esterno: {
        it: `Cod. PMS`,
        en: `PMS Code`,
        de: `PMS-Code`
      },
      codice_interno: {
        it: `Cod. HBenchmark`,
        en: `HBenchmark Code`,
        de: `HBenchmark-Code`
      },
      ultima_modifica: {
        it: `Ultima Modifica`,
        en: `Last Edit`,
        de: `Letzte Bearbeitung`
      },
      modifica: {
        it: `Modifica`,
        en: `Edit`,
        de: `Bearbeiten`
      }
    }
  }
</script>
