<template>
  <Messaggio>
    <div class="loghi-contenitore firenze" slot="loghi">
      <img src={window.risolviImg(logo)} alt="HBenchmark Logo White" height="52">
      <div class="loghi-sponsor">
        <div class="contenitore-immagine">
          <img src={window.risolviImg(logo_firenze_alberghi)} alt="Firenze Alberghi">
        </div>
        <div class="contenitore-immagine">
          <img src={window.risolviImg(logo_confindustria)} alt="Confindustria">
        </div>
        <div class="contenitore-immagine">
          <img src={window.risolviImg(logo_banca_chianti)} alt="Banca Chianti">
        </div>
        <div class="contenitore-immagine">
          <img src={window.risolviImg(logo_banco_fiorentino)} alt="Banco Fiorentino">
        </div>
        <div class="contenitore-immagine">
          <img src={window.risolviImg(logo_confesercenti_firenze)} alt="Confesercenti Firenze">
        </div>
        <div class="contenitore-immagine">
          <img src={window.risolviImg(logo_assohotel)} alt="Assohotel">
        </div>
      </div>
    </div>
  </Messaggio>
</template>

<style>
  :global(div#accesso div.loghi-contenitore.firenze > img:first-child){
    margin-bottom: 10px;
  }
  :global(div#accesso div.loghi-contenitore div.loghi-sponsor){
    display:          flex;
    flex-wrap:        wrap;
    justify-content:  space-between;
    align-items:      center;
  }
  :global(div#accesso div.loghi-contenitore div.loghi-sponsor div.contenitore-immagine){
    width:  calc((100% / 3) - 5px);
  }
  :global(div#accesso div.loghi-contenitore div.loghi-sponsor img){
    width: 100%;
  }
</style>

<script>
  import Messaggio from "@/base/componenti/accesso/Messaggio.svelte"
  import logo from "@/base/immagini/logo-completo.svg"
  import logo_confindustria from "@/base/immagini/loghi/logo-confindustria-firenze-colori.png"
  import logo_banca_chianti from "@/base/immagini/loghi/logo-chiantibanca-colori.png"
  import logo_banco_fiorentino from "@/base/immagini/loghi/logo-banco-fiorentino-colori.jpeg"
  import logo_firenze_alberghi from "@/base/immagini/loghi/logo-consorzio-firenze-albergo-colori.png"
  import logo_confesercenti_firenze from "@/base/immagini/loghi/logo-confesercenti-firenze-colori.png"
  import logo_assohotel from "@/base/immagini/loghi/logo-assohotel-colori.jpeg"
</script>
