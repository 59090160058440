<template>
  {#if !pronto && grafico == null}
    <Caricatore/>
  {:else}
    <div class="info-esporta-tabella">
      <span><strong>{t.totale_righe[$l]}:</strong> {grafico.serie[0].punti.length}</span>
      
      <Bottone
          piccolo={true}
          variante="primario-invertito"
          on:click={esporta_in_csv}>
        {t.esporta[$l]}
      </Bottone>
    </div>
    <div
        class="grafico-tabella"
        class:ombra-ultima-riga={ombra_ultima_riga}
        on:scroll={inserisci_elimina_ombre}
        bind:this={elemento_tabella}>

      <table>
        <thead>
          <tr>
            <th>
              {#if grafico.statistica.granularità != "totale"}
                {t.data[$l]}
              {/if}
            </th>

            {#each grafico.serie as serie}
              <th>
                {#if grafico.indicatore.dettagliato}
                  <Indicatore
                      indicatore={grafico.indicatore}
                      piano={true}
                      visualizza_segmentazione={false}
                      visualizza_descrizione={false}/>
                    {#if t[serie.segmento] == null}
                      n.d.
                    {:else if grafico.indicatore.dettagliato_per == "canale" && serie.segmento == "altri"}
                      {t.altri_canali[$l]}
                    {:else}
                      {t[serie.segmento][$l]}
                    {/if}
                    <br/>
                    {grafico.statistica.gruppi[serie.indice_gruppo].nome}
                    {#if grafico.statistica.gruppi[serie.indice_gruppo].categorie != null &&
                      grafico.statistica.gruppi[serie.indice_gruppo].categorie.length > 0}
                      - {grafico.statistica.gruppi[serie.indice_gruppo].categorie.join(" - ")}
                    {/if}
                    <br/>
                    {t[grafico.statistica.cornice_temporale.periodi[serie.indice_periodo].nome][$l]}
                {:else}
                  <Indicatore
                      indicatore={grafico.indicatore}
                      piano={true}
                      visualizza_descrizione={false}/>
                    {grafico.statistica.gruppi[serie.indice_gruppo].nome}
                    {#if grafico.statistica.gruppi[serie.indice_gruppo].categorie != null &&
                      grafico.statistica.gruppi[serie.indice_gruppo].categorie.length > 0}
                      - {grafico.statistica.gruppi[serie.indice_gruppo].categorie.join(" - ")}
                    {/if}
                    <br/>
                    {t[grafico.statistica.cornice_temporale.periodi[serie.indice_periodo].nome][$l]}
                {/if}
              </th>
            {/each}
          </tr>
        </thead>
        <tbody>
          {#if grafico.serie[0].punti.length > 0}
            {#each grafico.serie[0].punti as _,i}
              <tr in:fade={{ duration: 500, delay: (i * 10) }}>
                {#if grafico.statistica.granularità == "totale"}
                  <td>{t.totale[$l]}</td>
                {:else}
                  <td>{new Date(grafico.serie[0].punti[i][0]).format("%d %B %Y")}</td>
                {/if}
                {#each grafico.serie as serie}
                  <td>
                    <Numero
                        numero={serie.punti[i][1]}
                        unità={serie.unità}
                        valuta={serie.valuta}
                        mostra_zero={true}
                        riduci={true}
                        decimali={grafico.indicatore.decimali}/>
                  </td>
                {/each}
              </tr>
            {/each}
            <!-- <tr>
              <td>{t.totale[$l]}</td>
              {#each grafico.serie as serie}
                <td>
                  <Numero
                      numero={serie.totale}
                      unità={serie.unità}
                      valuta={serie.valuta}
                      mostra_zero={true}
                      riduci={true}
                      decimali={grafico.indicatore.decimali}/>
                </td>
              {/each}
            </tr> -->
          {:else}
            <tr class="riga-senza-hover">
              <td class="nessun-dato" colspan="999">
                <em>{t.nessun_dato[$l]}</em>
              </td>
            </tr>
          {/if}
        </tbody>
      </table>
    </div>
  {/if}
</template>

<style>
  :global(div.info-esporta-tabella) {
    display:          flex;
    justify-content:  space-between;
  }
  :global(div.grafico-tabella) {
    max-height:     500px;
    overflow:       auto;
    border-bottom:  1px solid var(--colore-bordo);
  }
  
  /* Generali. */
  :global(div.grafico-tabella table) {
    width:            100%;
    border-spacing:   0;
    border-color:     transparent;
    border-collapse:  collapse;
    position:         relative;
    white-space:      nowrap;
  }
  :global(div.grafico-tabella td.nessun-dato) {
    text-align:       center;
  }
  /* Tabella. */
  :global(div.grafico-tabella table tbody tr) {
    background-color: var(--colore-bianco);
    border-bottom:    1px solid var(--colore-bordo);
  }
  :global(div.grafico-tabella table tbody tr:hover) {
    background-color: var(--colore-sorvolo);
  }
  :global(div.grafico-tabella table tbody tr td) {
    font-size:        14px;
    padding:          14px 16px;
    border-top:       none;
    background-color: inherit;
    line-height:      24px;
  }
  :global(div.grafico-tabella table thead) {
    background-color: var(--colore-header-tabella);
  }
  :global(div.grafico-tabella table thead th) {
    background-color: var(--colore-header-tabella);
    text-transform:   uppercase;
    text-align:       left;
    padding:          11.5px 16px;
    color:            var(--colore-testo-leggero);
    font-size:        14px;
    line-height:      17px;
  }
  /* Intestazione Fissa */
  :global(div.grafico-tabella table thead th) {
    position:     sticky;
    top:          -2px;
    /* z-index:      5; */ /* commentato per problema con popup Intervallo */
  }
  /* Prima colonna Fissa */
  :global(div.grafico-tabella table tbody td:first-child) {
    position:     sticky;
    left:         0;
    z-index:      0;
  }
  :global(div.grafico-tabella table thead th:first-child) {
    position:     sticky;
    left:         0;
    z-index:      1;
  }
  /* Ultima Riga Fissa */
  /* :global(div.grafico-tabella table tbody tr:last-child) {
    position:     sticky;
    bottom:       -2px;
    z-index:      5;
    border-bottom: none;
  }
  :global(div.grafico-tabella.ombra-ultima-riga table tbody tr:last-child) {
    box-shadow:   -4px 0 15px 2px rgba(0, 0, 0, 0.3);
  } */
</style>

<script>
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Indicatore from "@/base/componenti/statistiche/Indicatore.svelte"
  import Numero from "@/base/componenti/Numero.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { periodi } from "@/base/sorgenti/hbenchmark/periodi"
  import { granularità_predefinite } from "@/base/sorgenti/hbenchmark/periodi"
  import { dizionario_indicatore } from "@/base/componenti/statistiche/Indicatore.svelte"
  import { fade } from "svelte/transition"

  export let grafico = {}
  export let pronto  = false

  const t = avvia_localizzazione(dizionario_tabella)

  let elemento_tabella = {}
  let ombra_ultima_riga = false

  // $:if (elemento_tabella.scrollHeight - Math.abs(elemento_tabella.scrollTop) === elemento_tabella.clientHeight) {
  //   ombra_ultima_riga = false
  // } else {
  //   ombra_ultima_riga = true
  // }

  ////
  // Controlla se la tabella è stata scrollata e se inserire le ombre
  function inserisci_elimina_ombre() {
    return
    if (elemento_tabella.scrollHeight - Math.abs(elemento_tabella.scrollTop) === elemento_tabella.clientHeight) ombra_ultima_riga = false
    else ombra_ultima_riga = true
  }

  ////
  // Esporta in CSV.
  function esporta_in_csv() {
    let contenuto_csv = []
    let colonne_esportate = [`${t.data[$l]}(${t[grafico.statistica.granularità][$l]})`]
    let colonne_serie = []

    colonne_serie = grafico.serie.map(serie => {
      let stringa_indicatore = ""
      let stringa_segmento = ""
      let stringa_gruppo = grafico.statistica.gruppi[serie.indice_gruppo].nome
      let stringa_periodo = t[grafico.statistica.cornice_temporale.periodi[serie.indice_periodo].nome][$l]

      stringa_indicatore = `${t.codici[grafico.indicatore.tipo][grafico.indicatore.codice][$l]}`

      if (grafico.indicatore.filtri.length > 0) {
        let stringa_segmentazioni = Object.keys(grafico.indicatore.filtri).map(nome_filtro => {
          return `${t[nome_filtro][$l]}: ${grafico.indicatore.filtri[nome_filtro].join(" ,")}`
        })
        stringa_segmentazioni = stringa_segmentazioni.join(", ")
        stringa_indicatore = `${stringa_indicatore} (${stringa_segmentazioni})`
      }
      
      if (grafico.indicatore.dettagliato) stringa_segmento = `${t[serie.segmento][$l]}`

      return `${stringa_indicatore} - ${stringa_segmento} - ${stringa_gruppo} - ${stringa_periodo}`
    })
    
    colonne_esportate = [ ...colonne_esportate, ...colonne_serie]
    contenuto_csv.push(colonne_esportate.join(";"))

    grafico.serie[0].punti.each((punto, index) => {
      let stringa_valori = grafico.serie.map(serie => `${serie.punti[index][1]}`)
      let data = new Date(grafico.serie[0].punti[index][0]).format("%d %B %Y")
      let valori = stringa_valori.join(";").replace("null", "0").replace(".", ",")
      let riga_esportata = `${data};${valori}`

      contenuto_csv.push(riga_esportata)
    })

    contenuto_csv = contenuto_csv.join("\r\n")

    scarica_esportazione(contenuto_csv, "export.csv", "text/csv;encoding:utf-8");
  }

  ////
  // Crea collegamento per scaricamento.
  function scarica_esportazione(contenuto, nome_archivio, tipo) {
    let a = document.createElement("a")
    tipo = tipo || "application/octet-stream"

    if (navigator.msSaveBlob) { // IE10
      navigator.msSaveBlob(new Blob([contenuto], { type: tipo }), nome_archivio)
    } else if (URL && "download" in a) { //html5 a[download]
      a.href = URL.createObjectURL(new Blob([contenuto], { type: tipo }))
      a.setAttribute("download", nome_archivio)
      document.body.append(a)
      a.click()
      a.remove()
    } else {
      window.open("data:application/octet-stream," + encodeURIComponent(contenuto)) // solo con data-url
    }
  }
</script>

<script context="module">
  export const dizionario_tabella = {
    ...periodi,
    ...granularità_predefinite,
    ...dizionario_indicatore,
    data: {
      it: `Data`,
      en: `Data`,
      de: `Daten`
    },
    totale_righe: {
      it: `Totale righe`,
      en: `Total rows`,
      de: `Gesamte Zeilen`
    }
  }
</script>