<template>
  <GestioneStruttura>
    {#if in_caricamento}
      <Caricatore/>
    {:else}
      <div class="margini-positivi">
        <TabellaTrasmissioni
          {trasmissioni}
          {struttura}
          {indirizzo}
          on:invio={gestisci_risposta_invio_archivio}/>
      </div>
    {/if}

    <Messaggio
        di_errore={messaggio_archivio_errore}
        visualizzato={messaggio_archivio_visualizzato}>
      {#if messaggio_archivio != null}
        {t.messaggio_archivio[messaggio_archivio][$l]}
      {/if}
    </Messaggio>
  </GestioneStruttura>
</template>

<script>
  import GestioneStruttura from "@/portale/componenti/GestioneStruttura.svelte"
  import Messaggio from "@/base/componenti/Messaggio.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import TabellaTrasmissioni from "@/base/componenti/tabelle/Trasmissioni.svelte"
  import { onDestroy } from "svelte"
  import { sessione_corrente } from "@/portale/sorgenti/sessione"
  import { localizzazione as l } from "@/base/sorgenti/svuby"

  const t = {
    messaggio_archivio: {
      riuscito: {
        it: `File inviati con successo.`,
        en: `Files successfully sent.`,
        de: `Dateien erfolgreich gesendet.`
      },
      fallito: {
        it: `Si è verificato un errore nell'invio dei file. Riprovare o contattare il supporto.`,
        en: `An error occurred while sending the files. Retry or contact support.`,
        de: `Beim Senden der Dateien ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.`
      }
    }
  }
  const indirizzo = retro.estremi.portale.trasmissioni

  let in_caricamento = true
  let struttura = {}
  let trasmissioni = []

  let messaggio_archivio = null
  let messaggio_archivio_errore = false
  let messaggio_archivio_visualizzato = false

  ////
  // Richiede i trasmissioni.
  async function richiedi_trasmissioni() {
    in_caricamento = true

    let risposta = await retro.chiama(
      "GET",
      retro.estremi.portale.trasmissioni,
      { id_struttura: $sessione_corrente._id })

    trasmissioni = risposta.contenuto.trasmissioni
    struttura = $sessione_corrente

    in_caricamento = false
  }

  ////
  // Gestisce la risposta dell'invio archivio.
  function gestisci_risposta_invio_archivio(evento) {
    messaggio_archivio_visualizzato = false

    let risposta = evento.detail.risposta

    if (risposta.riuscita) {
      messaggio_archivio = "riuscito"
      messaggio_archivio_errore = false
    } else {
      messaggio_archivio = "fallito"
      messaggio_archivio_errore = true
    }

    messaggio_archivio_visualizzato = true
    richiedi_trasmissioni()
  }

  // Al cambio sessione, richiede le trasmissioni.
  const scollega = sessione_corrente.su_cambio(richiedi_trasmissioni)
  // Quando distrutto, scollega i cambi.
  onDestroy(scollega)
</script>
