<template>
  {#if sottoscrivente}
    <BarraMessaggio>
      Contratto sottoscritto da <b>{sottoscrivente.nome}</b> il giorno <b>{sottoscrivente.sottoscritto_il.format("%d %B %Y")}</b>
    </BarraMessaggio>
  {/if}

  <p class="c0"><span class="c3">Dear Customer, welcome to the HBenchmark platform.</span></p>
  <p class="c0"><span class="c3">HBenchmark srl, whose registered office is situated in Italy, Altavilla Vicentina, via
      Retrone no. 16, (VI) &ndash; VAT no. and tax code: 03998320240 - Tel no. 0444276202 Mail </span><span
      class="c9"><a class="c6" href="mailto:info@hbenchmark.it">info@hbenchmark.it</a></span><span
      class="c3">&nbsp;Legal mail </span><span class="c9"><a class="c6"
        href="mailto:hbenchmark@legalmail.it">hbenchmark@legalmail.it</a></span><span class="c4">,</span><span
      class="c3">&nbsp;together with other companies in the Develon Group, has created and developed this platform, run
      by its own software dedicated to professional operators in the hospitality sector, such as hotel and campsite
      managers.</span></p>
  <p class="c0"><span class="c3">The HBenchmark platform, as you will have seen by clicking on the
      <a href="https://preventivi.hbenchmark.it/{tipo_struttura == "hotel" ? "brochure-hotel_EN.pdf" : "flyer-camping_EN.pdf"}" target="_blank">Brochure HB</a>
      key provided, [if you have not already done so, we suggest that you follow the link before continuing]
      is a web - based collaboration platform that normalises and organises aggregated information from which its
      algorithms extrapolate and compare specific economic performance indicators (KPIs or Key Performance Indicators)
      for the hospitality industry, to help users optimise the list, back up dynamic price strategies, give the
      company&rsquo;s rating credibility and tackle any form of investment with awareness.</span></p>
  <p class="c0"><span class="c3">This Subscription contract (hereinafter referred to as the &ldquo;</span><span
      class="c1">Contract</span><span class="c3">&rdquo;), together with the </span><span class="c9"><a class="c6"
        href="https://www.iubenda.com/privacy-policy/7839661">Privacy
        Policy</a></span><span class="c3">&nbsp;and </span><span class="c9"><a class="c6"
        href="https://preventivi.hbenchmark.it/{tipo_struttura == "hotel" ? "brochure-hotel_EN.pdf" : "flyer-camping_EN.pdf"}" target="_blank">HBenchmark
        Brochure</a></span><span class="c9">,</span><span class="c3">&nbsp;which form an integral part thereof, contain
      the terms and conditions regulating your access to the Platform and your use of the services requested and,
      together with the subscription and payment form, constitute a legally binding agreement that regulates your
      dealings with HBenchmark (if you are a sole trader) or those of the legal entity that you represent. </span></p>
  <p class="c0"><span class="c3">The Contract shall be taken to have been concluded and to be legally binding on
      HBenchmark and yourself as soon as you have successfully completed the subscription procedure and you have paid
      the fee.&nbsp;</span></p>
  <p class="c0"><span class="c1">Art. 1. Definitions</span><span class="c3">&nbsp;</span></p>
  <p class="c0"><span class="c3">The following capitalised terms shall, throughout this Contract, have the following
      meanings:</span></p>
  <p class="c0"><span class="c1">Activity Data</span><span class="c3">: means data relating to your business activities,
      including the company name, VAT number, registered office address, email, certified email, telephone number, duly
      authorised representative, etc. that you will be required to include in the subscription form; </span></p>
  <p class="c0"><span class="c1">Booking Information</span><span class="c3">: means information relating to bookings
      placed in the Subscriber&rsquo;s database programme, which are transferred to HB, such as the following: the name
      of the structure (your Hotel or your activity), booking (without personal data), period of stay, booking date,
      date of arrival, number of rooms booked, number of adult guests, number of children guests, room type, description
      of board/stay, booking amount (Room + Board), booking status, date of cancellation (if booking is cancelled),
      nationality of subject booking, booking channel code (direct, Internet, TO, etc.) and booking market sector code.
      This information is and will be received by us exclusively in anonymous form, that is, without being able, in any
      way, to associate them with data identifying persons. </span></p>
  <p class="c0"><span class="c1">Aggregated Information</span><span class="c3">: means the Booking Information relating
      to more than one Subscriber, normalised and aggregated in anonymous form. </span></p>
  <p class="c0"><span class="c1">Personal KPIs</span><span class="c3">: means the economic performance indicators,
      statistics and reports that the HBenchmark algorithms elaborate/extract from the Booking Information relating to
      each individual Subscriber, as described in further detail in the </span><span class="c9"><a class="c6"
        href="https://preventivi.hbenchmark.it/{tipo_struttura == "hotel" ? "brochure-hotel_EN.pdf" : "flyer-camping_EN.pdf"}" target="_blank">Brochure</a></span><span
      class="c3">. </span></p>
  <p class="c0"><span class="c1">Aggregated KPIs</span><span class="c3">: means the economic performance indicators,
      statistics, comparisons and reports that the HBenchmark algorithms elaborate/extract from the </span><span
      class="c3">Aggregated Data/Information </span><sup><a href="#cmnt1" id="cmnt_ref1">[a]</a></sup><span
      class="c3">as described in further detail in the Brochure (https://preventivi.hbenchmark.it/{tipo_struttura == "hotel" ? "brochure-hotel_EN.pdf" : "flyer-camping_EN.pdf"}). Certain Aggregated KPIs may be made accessible to
      Policy Makers with whom HB works. </span></p>
  <p class="c0"><span class="c1">HB:</span><span class="c3">&nbsp;means the hotel benchmark Platform described in
      further detail in Annex B) </span></p>
  <p class="c0"><span class="c1">Subscription</span><span class="c3">: means the on-line or off-line procedure described
      in Annex B with which the Customer can access HB for the period of time corresponding to the fee paid. </span></p>
  <p class="c0"><span class="c1">Subscriber: </span><span class="c3">means the tour operator, whether a natural person
      or legal entity, who or which completed the Subscription procedure and who or which has access to HB, throughout
      the Subscription period. </span></p>
  <p class="c0"><span class="c1">Connection APIs: </span><span class="c3">also referred to as API REST (Representational
      State Transfer) are a set of interfaces and procedures made available by HB to allow Databases (PMS) to
      interrogate and collaborate with the Platform via an encrypted channel protected by a password. </span></p>
  <p class="c0"><span class="c1">Database: </span><span class="c3">also referred to as PMS, is the system with which the
      hotel manages room planning, check-in and check-out operations, the prices of rooms and services associated with
      the booking and invoicing. </span></p>
  <p class="c0"><span class="c1">Brochure: </span><span class="c3">means the brochure describing the services offered by
      HB which is available via the following </span><span class="c9"><a class="c6"
        href="https://preventivi.hbenchmark.it/{tipo_struttura == "hotel" ? "brochure-hotel_EN.pdf" : "flyer-camping_EN.pdf"}" target="_blank">Link</a></span><span
      class="c3">&nbsp;</span></p>
  <p class="c0"><span class="c1">Competitive set:</span><span class="c3">&nbsp;means the computer tool that allows you
      to compare the KPIs in your structure with those aggregated in a group of other structures, up to a number of at
      least 5, which you can select yourself from the list of Subscribers. The fact that, like any other user, you can
      select structures forming part of the group by name does not mean that anyone can obtain or extract performance
      data relating to one or each of the members from the group KPIs. The laws of statistics dictate that if a group is
      made up of at least 4 structures, the performances of an individual structure cannot be extrapolated. As a
      prudential measure, we have raised this minimum number to 5. </span></p>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c1">Art. 2. Subscription and Access methods</span><span class="c3">&nbsp;</span></p>
  <p class="c0"><span class="c3">To gain access to the Platform and the Personal and Aggregated KPIs, you must subscribe
      to HB adopting the procedure which can be followed by clicking on the link </span><span class="c9"><a class="c6"
        href="http://portal.hbenchmark.it" target="_blank">http://portal.hbenchmark.it</a></span><span
      class="c3">. Remember that the procedure cannot be started until you have read, scrolled and accepted the
      contractual documents. We invite you to read clauses no. 4 (Processing of Booking Information and confidentiality
      obligations) and no. 6 (privacy and consent) with particular attention. </span></p>
  <p class="c0"><span class="c3">2.1. At this stage, you will be asked to supply certain data of a personal nature and
      relating to your business activities, that we need in order to include you in the Subscribers list. It is
      essential if we are to proceed with the supply of our services that the data we ask you to provide is correct,
      truthful and complete.</span></p>
  <p class="c0"><span class="c3">Please take note that should we discover, even after controls carried out in
      retrospect, that Activity Data you have inserted are untrue or inaccurate, we may choose to suspend your access to
      the HB Platform without prior notice.</span></p>
  <p class="c0"><span class="c3">2.2. At a certain point, you will be asked to open a window where you will find a list
      of the management programmes with which HBenchmark set up a Connection API and to indicate which of the Databases
      you use.</span></p>
  <p class="c0"><span class="c3">Once you have selected your Database, concluding the subscription processinstruct
      your database to connect up to the API HBenchmark, adopting a guided procedure, in order to transfer: (i) current
      Booking Information on a daily basis and (ii) when accessed for the first time, Booking Information relating to
      the last two years, if applicable.</span></p>
  <p class="c0"><span class="c3">By doing this, you guarantee that you have legitimately collected the Booking
      Information and you send it to us.</span></p>
  <p class="c0"><span class="c3">2.3. Further methods</span></p>
  <p class="c0"><span class="c3">If the Database that you use does not appear amongst those listed, please let us know
      immediately, so that we can adopt different connection methods, also with the back-up of a wizard; take note that
      if a connection cannot be established with your Database, we will not be able to provide our services and you will
      be unable to subscribe to HB.</span></p>
  <p class="c0"><span class="c3">You will also be asked to insert a Username and you will be given a password; you must
      treat your account and password as confidential and keep access to your computer and your devices under control,
      and you accept, to the extent envisaged by legislation, that you will be held liable for all the activities
      carried out with your account and your password. You undertake to take all the steps necessary to ensure that your
      password remains secure and private and you undertake to inform us immediately if you have reason to believe that
      a third party is familiar with your password, or if your password is, or presumably is about to be, used in an
      unauthorised manner.</span></p>
  <p class="c0"><span class="c3">The Subscription procedure is backed up by a wizard and you can be contacted at any
      time via chat or by telephone by our assistance centre.</span></p>
  <p class="c0"><span class="c3">Remember that once you have completed the Subscription procedure, a certain period of
      time will inevitably elapse for technical reasons before the data will be loaded onto our systems. Our customer
      care service will be ready to help at any time.</span></p>
  <p class="c0 c7"><span class="c3"></span></p>
  <p class="c0"><span class="c1">Art. 3 &ndash; Services offered</span></p>
  <p class="c0"><span class="c3">The services offered by HBenchmark are the Personal and Aggregated KPIs and the
      Competitive Sets described in further detail in the </span><span class="c9"><a class="c6"
        href="https://preventivi.hbenchmark.it/{tipo_struttura == "hotel" ? "brochure-hotel_EN.pdf" : "flyer-camping_EN.pdf"}" target="_blank">Brochure</a></span><span
      class="c3">&nbsp;to which you are given access throughout the Subscription period. </span></p>
  <p class="c0"><span class="c3">HBenchmark reserves the right to offer you further services, giving adequate
      notice.</span></p>
  <p class="c0 c7"><span class="c3"></span></p>
  <p class="c0"><span class="c1">Art. 4</span><span class="c3">&nbsp;</span><span class="c1">-Processing of Booking
      Information and confidentiality obligations of HB</span><span class="c3">&nbsp;</span></p>
  <p class="c0"><span class="c3">4.1. The Booking Information that we normalise, analyse and process continually in
      order to obtain the Personal KPIs and then, after aggregation, to obtain the Aggregated KPIs, do not permit the
      subject to which they refer to be identified, and are therefore anonymous and not subject to data protection
      legislation.</span></p>
  <p class="c0"><span class="c3">4.2. This business information may, on the other hand, be safeguarded by the provisions
      of the Industrial property code, as amended by Italian Legislative Decree no. 63/2018.</span></p>
  <p class="c0"><span class="c3">By subscribing to HB you also give your consent and authorisation in the manner
      envisaged in art. 99 of the Industrial property code to the following, that:</span></p>
  <ul class="c2 lst-kix_list_2-0 start">
    <li class="c10 li-bullet-0"><span class="c3">your Booking Information be sent &ndash; or obtained &ndash; by your
        database to HB;</span></li>
    <li class="c0 c17 li-bullet-0"><span class="c3">your Booking Information be processed by HB in order to extrapolate
        the Personal KPIs;</span></li>
    <li class="c0 c17 li-bullet-0"><span class="c3">your Booking Information be aggregated in anonymous form with those
        of other Subscribers in order to construct clusters of resorts, zones, area, types, regions and nations, from
        which the HB algorithms will then process and extrapolate the Aggregated KPIs visible on HB.</span></li>
    <li class="c17 c18 li-bullet-0"><span class="c3">your Booking Information be processed by HB in order to extrapolate
        Aggregated KPIs in a Competitive set.</span></li>
  </ul>
  <p class="c0"><span class="c1">Remember that without these authorisations and consents, you cannot benefit from the
      service</span><span class="c3">. </span></p>
  <p class="c0"><span class="c3">4.3. We undertake and warrant:</span></p>
  <ul class="c2 lst-kix_list_3-0 start">
    <li class="c12 li-bullet-0"><span class="c3">not to make your Booking Information accessible to anyone other than
        yourself and to store such information in computer systems protected against unlawful or unauthorised access and
        your Personal KPIs. Only you can instruct us if you wish your Booking Information or your Personal KPIs to be
        made visible or to be transferred to third parties.</span></li>
  </ul>
  <ul class="c2 lst-kix_list_4-0 start">
    <li class="c12 li-bullet-0"><span class="c3">Once the Subscription period comes to an end on any grounds, including
        the termination of the Contract, Booking Information will automatically cease to be obtained and your business
        will no longer be visible or accessible on HB; the Booking Information you sent us during the Subscription
        period remaining intermingled with the historical Aggregated Information cannot be traced back to you,
        recognised, identified or extracted.</span></li>
  </ul>
  <p class="c0"><span class="c3">&nbsp;</span></p>
  <p class="c0"><span class="c1">Art. 5. &ndash; Obligation on the part of the Subscriber not to diffuse, disclose or
      sell the Aggregated KPIs </span></p>
  <p class="c0"><span class="c3">You may use the Aggregated KPIs and Competitive Set processed by HBenchmark solely
      within your organisation and with your working partners or associates, exclusively for the purpose of analysing
      the market and improving performances and strategies characterising your own business or of programming projects
      or investments. By signing this Contract, you undertake not to disclose or make them accessible to third parties
      and/or to the public, and not to transfer or assign them for direct or indirect commercial purposes. Should such
      situations arise, we will be entitled to suspend the services without any prior notice, to terminate the Contract
      and to demand compensation for any damage incurred.&nbsp;</span></p>
  <p class="c0"><span class="c1">Art. 6. - Privacy and consent</span><span class="c3">. </span></p>
  <p class="c0"><span class="c1">6.1.</span><span class="c3">&nbsp;During the Subscription procedure, you provide us
      with information necessary for the registration and supply of the Services, that may include sensitive information
      and data such as your name, your date of birth and/or tax code, your email address or telephone number, the entity
      issuing your credit card, certain numbers and codes. </span></p>
  <p class="c0"><span class="c3">The name, address and category of your business will be included in the Subscribers
      list found on HB and visible to other Subscribers.&nbsp;</span></p>
  <p class="c0"><span class="c3">We will process your personal data with the utmost attention and caution and will only
      use them to supply you with the Services, to ask for your opinion and to up-date you on the latest initiatives and
      new services offered by our company; some of your data may be processed by companies in our Develon Group that
      have products and services that we believe may interest you, complying at all times with our </span><span
      class="c9"><a class="c6"
        href="https://www.iubenda.com/privacy-policy/7839661">Privacy
        Policy</a></span><span class="c3">. </span></p>
  <p class="c0"><span class="c3">Your credit card data, on the other hand, will not pass through our system, but will be
      processed and stored from the very start exclusively by the payment circuits listed in the Subscription
      form.&nbsp;</span></p>
  <p class="c0"><span class="c1">6.2. You should also note the following</span><span class="c3">: </span></p>
  <ol class="c2 lst-kix_list_9-1 start" start="1">
    <li class="c14 li-bullet-0"><span class="c1">a) Transactions involving third parties:</span><span
        class="c3">&nbsp;we may make services, applications, skills or products supplied by third parties available to
        you. If you accept these services, we will be obliged to share data with these third parties. </span></li>
    <li class="c5 li-bullet-0"><span class="c1">b) External suppliers of services: </span><span class="c3">we use other
        businesses and individuals to conduct activities on our behalf. For example, to send out paper-based post and
        emails, to remove repetitive information from customer lists, to process payments. </span></li>
  </ol>
  <p class="c0"><span class="c3">These external suppliers only have access to personal data that are necessary for the
      activities they have been instructed to conduct, but they cannot process data brought to their knowledge for other
      purposes. Furthermore, these third parties are obliged to process data brought to their knowledge in keeping with
      this privacy statement and with date protection legislation applicable. We also use suppliers of technical
      services who may manage the technical infrastructure necessary to provide the Service, in particular suppliers who
      host, store, manage and maintain the HB application, its contents and the data we process.&nbsp;</span></p>
  <ol class="c2 lst-kix_list_10-0 start" start="1">
    <li class="c14 li-bullet-0"><span class="c1">c) Transfers of businesses: </span><span class="c3">seeing that our
        business is in continuous development, we may sell or acquire other businesses or services. With this type of
        transaction, customers&rsquo; data generally form part of the assets of the business being transferred, but are
        still subject to</span><span class="c1">&nbsp;</span><span class="c3">the terms of any privacy statement
        previously applied (unless, of course, the customer chooses otherwise). Should HBenchmark S.r.l. or,
        substantially, its assets, be acquired by third parties, customers&rsquo; personal data will naturally be
        included amongst the assets transferred. </span></li>
    <li class="c5 li-bullet-0"><span class="c1">d) Safeguarding HBenchmark: </span><span class="c3">we disclose data
        relating to the account and other personal data whenever such a disclosure is, in our opinion, necessary to
        satisfy legal obligations, to ensure that our operating Terms and Conditions and other agreed terms are complied
        with and applied, or to safeguard the rights, assets and security of HBenchmark srl, and those of our users or
        other subjects. </span></li>
  </ol>
  <p class="c0"><span class="c1">6.3. Consent</span></p>
  <p class="c0"><span class="c3">By completing the Subscription procedure, you are asked to give your consent to the use
      of your data in the manner described above, subject to the protective guarantees provided for in our Privacy
      Policy and current legislation. Without this consent, we will be unable to provide the service requested.</span>
  </p>
  <p class="c0"><span class="c3">&nbsp;</span></p>
  <p class="c0"><span class="c1">Art. 7 - Conclusion, Duration and Renewal</span></p>
  <p class="c0"><span class="c3">The Contract shall be concluded and legally binding on both HBenchmark and yourself as
      soon as you have successfully completed the Subscription procedure and you have paid the Fee. Unless specifically
      agreed to the contrary, the Subscription to HB shall be valid for a period of one year from the date of payment of
      the Fee and shall be automatically renewed unless notice of termination is given, also by email, at least one day
      prior to the expiry date. If you do not choose to terminate the contract, you will be taken to have accepted and
      given your authorisation for the Fee to be automatically charged for a further annual term.</span></p>
  <p class="c0"><span class="c3">&nbsp;</span></p>
  <p class="c0"><span class="c1">Art. 8 - Fee </span></p>
  <p class="c0"><span class="c3">8.1. The annual Subscription Fee will be that published on the website on the date of
      the Subscription, plus VAT at the legal rate, and must be paid, subject to discounts and any special conditions
      publicised and offered on our website. HB reserves the right to vary the Fee, giving notification of the change on
      the website.</span></p>
  <p class="c0"><span class="c3">8.2. Separate terms and conditions may be applied to users who received a prepaid or
      discounted offer from a third party, entity or association, accompanied by a Code, to be communicated together
      with the Code, and they shall be taken to have been accepted by the Subscriber when he or she accepts the
      Offer.</span></p>
  <p class="c0"><span class="c3">8.3. If, for technical reasons, the Subscription procedure cannot be completed, we will
      immediately refund any fee that you may have already paid.</span></p>
  <p class="c0"><span class="c3">&nbsp;</span></p>
  <p class="c0"><span class="c1">Art. 9- Limited liability</span></p>
  <p class="c0"><span class="c3">HB also provides prediction analyses, put together with the use of algorithms.</span>
  </p>
  <p class="c0"><span class="c3">Even though these algorithms have been and are constantly monitored, this does not and
      cannot eliminate the natural uncertainty of predictions with reference to both factors that have not been taken
      into account and unforeseen factors. HBenchmark does not guarantee, to any degree, the accuracy of its predictive
      analyses and shall not be held liable for the unforeseen consequences of any decision or choice made on the basis
      of such analyses.</span></p>
  <p class="c0"><span class="c3">&nbsp;</span></p>
  <p class="c0"><span class="c1">Art. 10 &ndash; Amendments to this Contract </span></p>
  <p class="c0"><span class="c3">HBenchmark reserves the right to amend this Subscription Contract, but such variations
      shall only be valid if you have first accepted them either by email or directly on the Platform. If the
      Subscription is renewed after amendments have been notified, they shall be automatically treated as having been
      accepted.</span></p>
  <p class="c0"><span class="c3">&nbsp;</span></p>
  <p class="c0"><span class="c1">Art. 11 &ndash; Expiry or termination and continued application of confidentiality
      covenant </span></p>
  <p class="c0"><span class="c3">In the event of the expiry or termination of this Contract, without prejudice to any
      remedy envisaged by the law, the provisions contained in art. 5 shall remain in force for a period of 5
      years.</span></p>
  <p class="c0 c7"><span class="c3"></span></p>
  <p class="c0"><span class="c1">Art. 12 &ndash; Applicable law </span></p>
  <p class="c0"><span class="c3">This Subscription Contract, made up of Subscription form, Terms and Conditions, Privacy
      Policy and Brochure, is subject to Italian law. In the event that a dispute arises that cannot be settled on an
      amicable basis, before bringing a legal action before the competent court authorities, a mediation attempt must
      first be made following the procedure laid down in Italian Legislative Decree no. 28/2010 as amended and
      supplemented.</span></p>
  <p class="c0"><span class="c3">&nbsp;</span></p>
  <p class="c0"><span class="c1">Art. 13 &ndash; Contacts</span><span class="c3">&nbsp;</span></p>
  <p class="c0"><span class="c3">For any communications, information or query, the Subscriber can contact HBenchmark at
      the following address/number:</span></p>
  <ul class="c2 lst-kix_list_7-0 start">
    <li class="c10 li-bullet-0"><span class="c16"><a class="c6"
          href="mailto:supporto@hbenchmark.it">supporto@hbenchmark.it</a></span><span class="c3">&nbsp;</span></li>
    <li class="c0 c17 li-bullet-0" id="h.gjdgxs"><span class="c3">+39 (0)444 276202</span></li>
  </ul>
</template>

<style>
  .c9 {
    color: #0000ff;
    text-decoration: underline;
  }

  .c10 {
    margin-left: 36px;
    padding-top: 14px;
    padding-left: 0px;
    padding-bottom: 0px;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c14 {
    margin-left: 35.5px;
    padding-top: 14px;
    padding-left: 0px;
    padding-bottom: 0px;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c5 {
    margin-left: 35.5px;
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 14px;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c12 {
    margin-left: 36px;
    padding-top: 14px;
    padding-left: 0px;
    padding-bottom: 14px;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c4 {
    text-decoration: none;
    vertical-align: baseline;;
    font-style: normal
  }

  .c1 {
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;;
    font-style: normal
  }

  .c3 {
    text-decoration: none;
    vertical-align: baseline;;
    font-style: normal
  }

  .c18 {
    padding-top: 0px;
    padding-bottom: 14px;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c0 {
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c16 {
    -webkit-text-decoration-skip: none;
    color: #0000ff;
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }

  .c2 {
    padding: 0;
    margin: 0
  }

  .c17 {
    margin-left: 36px;
    padding-left: 0px
  }

  .c6 {
    color: inherit;
    text-decoration: inherit
  }

  .c7 {
    height: 12px
  }
</style>

<script>
  import BarraMessaggio from "@/base/componenti/BarraMessaggio.svelte"

  export let sottoscrivente = null
  export let tipo_struttura = "hotel"
</script>