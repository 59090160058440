<template>
  <div
      class="indicatore"
      class:piano={piano}
       id={id_componente}
       on:click>
    {#if visualizza_nome}
      <div class="nome">
        {t.codici[indicatore.tipo][indicatore.codice][$l]}
      </div>
    {/if}

    {#if visualizza_segmentazione}
      <div class="segmentazione">
        {#if indicatore.filtri != null}
          {#each Object.keys(indicatore.filtri) as filtro, indice}
            {#if filtro == "attributi"}
              {#each Object.keys(indicatore.filtri.attributi) as attributo}
                { t.attributi[$l]}:
                {#each indicatore.filtri.attributi[attributo] as valore, indice}
                  {#if indice > 0}
                    ,
                  {/if}
                  {t[valore][$l]}
                {/each}
                <br/>
              {/each}
            {:else if filtro == "canale"}
              {t[filtro][$l]}:
              {#each filtri_canale as valore, indice}
                {#if indice > 0}
                  ,
                {/if}
                {#if valore == "altri"}
                  {t.altri_canali[$l]}
                {:else}
                  {t[valore][$l]}
                {/if}
              {/each}
              <br/>
            {:else if filtro == "nazionalità" && indicatore.filtri[filtro].length == 1}
              {t[filtro][$l]}:
              <div class="nazionalità-singola">
                {t[indicatore.filtri[filtro][0]][$l]}
                <div class="bandiera">
                  <img src={bandiera_nazione(indicatore.filtri[filtro][0])} alt={indicatore.filtri[filtro][0]}/>
                </div>
              </div>
            {:else}
              {t[filtro][$l]}:
              {#each indicatore.filtri[filtro] as valore, indice}
                {#if indice > 0}
                  ,
                {/if}
                {t[valore][$l]}
              {/each}
              <br/>
            {/if}
          {/each}
        {/if}

        {#if indicatore.dettagliato_per != null}
          {t.dettagliato_per[$l]}
          {t[indicatore.dettagliato_per][$l]}
          {#if indicatore.percentualizzato}
            (%)
          {/if}
        {/if}
      </div>
    {/if}

    {#if visualizza_descrizione}
      <div class="descrizione">
        {@html t.descrizioni[indicatore.tipo][indicatore.codice][$l]}
      </div>
    {/if}

    {#if serie.length == 1}
      <div class="totale">
        <div class="valore">
          <Numero
              numero={serie.first.totale}
              unità={serie.first.unità}
              valuta={serie.first.valuta}
              mostra_zero={true}
              riduci={true}
              decimali={indicatore.decimali}/>
        </div>
      </div>
    {:else if mostra_comparazioni}
      <div class="comparazioni">
        <Comparazione
            serie={serie_da_comparare}
            {serie_comparata}
            {statistica}
            mostra_dettagli={true}/>
      </div>
    {/if}
  </div>
</template>

<style>
    :global(div.indicatore) {
        padding: 14px;
        position: relative;
        cursor: pointer;
    }
    :global(div.indicatore.piano) {
      padding:  0;
      cursor:   default;
    }

    /* Nome. */
    :global(div.indicatore > div.nome),
    :global(div.indicatore > div.segmentazione) {
        text-transform: uppercase;
    }

    /* Descrizione. */
    :global(div.indicatore > div.descrizione) {
        width: 350px;
        position: absolute;
        top: 100%;
        left: 0;
        opacity: 0;
        padding: 14px;
        font-weight: normal;
        background-color: var(--colore-bianco);
        color: var(--colore-testo);
        border: 1px solid var(--colore-bordo);
        box-shadow: 0 5px 10px 0 var(--colore-ombreggiatura);
        transition: opacity .3s ease-in-out;
        z-index: -1;
    }

    :global(div.indicatore:hover > div.descrizione) {
        opacity: 1;
        z-index: 10;
    }

    :global(div.indicatore > div.descrizione > div.nome) {
        font-weight: bold;
        margin-bottom: 7px;
    }

    /* Quando usato in statistiche. */
    :global(div.statistica div.indicatore:not(.piano)) {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 14px 21px;
        color: var(--colore-testo-leggero);
        border-right: 1px solid var(--colore-bordo);
        border-bottom: 1px solid var(--colore-bordo);
    }

    :global(div.statistica div.indicatore:not(.piano):hover) {
        background-color: var(--colore-sorvolo);
    }

    :global(div.statistica div.etichetta.corrente > div.indicatore) {
        font-weight: bold;
        color: var(--colore-primario);
        border-bottom: 2px solid var(--colore-primario);
    }

    /* Totale e Comparazione. */
    :global(div.statistica div.indicatore div.totale),
    :global(div.statistica div.indicatore div.comparazioni) {
        margin-top: auto;
    }

    :global(div.statistica div.indicatore div.totale div.valore),
    :global(div.statistica div.indicatore div.comparazione div.valore) {
        font-size: 28px;
        margin-top: 14px;
        width: max-content;
    }

    :global(div.statistica div.indicatore div.comparazione div.valore i.icona) {
        margin-right: 14px;
    }

    :global(div.indicatore div.nazionalità-singola) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    :global(div.indicatore div.nazionalità-singola div.bandiera) {
        height: 16px;
        flex-grow: 1;
        display: flex;
        justify-content: center;
    }

    :global(div.indicatore div.nazionalità-singola div.bandiera img) {
        height: 100%;
    }
</style>

<script>
  import Numero from "@/base/componenti/Numero.svelte"
  import Comparazione from "@/base/componenti/statistiche/indicatori/Comparazione.svelte"
  import {localizzazione as l} from "@/base/sorgenti/svuby"
  import {avvia_localizzazione} from "@/base/sorgenti/svuby"
  import {bandiera_nazione} from "@/base/sorgenti/svuby"
  import {createPopper} from '@popperjs/core';
  import uuid from "uuid/dist/v4";
  import {onMount} from "svelte";

  export let indicatore = null
  export let statistica = {}
  export let serie = []
  export let visualizza_nome = true
  export let visualizza_descrizione = true
  export let visualizza_segmentazione = true
  export let piano = false

  const t = avvia_localizzazione(dizionario_indicatore)
  const id_componente = uuid();
  const AGENZIE_OTA = [ "agenzie_booking", "agenzie_expedia", "altre_agenzie_online" ]

  let più_di_un_gruppo = false
  let più_di_un_periodo = false
  let mostra_comparazioni = false
  let filtri_canale = []

  $: if (Object.keys(statistica).length > 0) {
    più_di_un_gruppo = statistica.gruppi.length > 1
    più_di_un_periodo = statistica.cornice_temporale.periodi.length > 1
    mostra_comparazioni = indicatore.dettagliato_per == null && (più_di_un_gruppo || più_di_un_periodo)
  }
  $: serie_da_comparare   = mostra_comparazioni ? trova_serie_da_comparare() : []
  $: serie_comparata      = mostra_comparazioni ? serie.find(
    serie => serie.indice_gruppo == 0 && serie.indice_periodo == 0) : null
  $: if (indicatore.filtri.canale != null) {
    if (AGENZIE_OTA.every(agenzia => indicatore.filtri.canale.includes(agenzia))){
      filtri_canale = indicatore.filtri.canale.filter(canale => !AGENZIE_OTA.includes(canale))
      filtri_canale = [ ...filtri_canale, "totale_agenzie_online" ]
    } else {
      filtri_canale = indicatore.filtri.canale
    }
  }

  ////
  // Trova la serie da comparare.
  function trova_serie_da_comparare() {
    let periodi = statistica.cornice_temporale.periodi

    if (statistica.cornice_temporale.data_osservazione != null){
      if (statistica.cornice_temporale.compara_anno_precedente || statistica.cornice_temporale.compara_due_anni_precedenti || statistica.cornice_temporale.compara_duemiladiciannove)
        return serie.find(serie => (serie.indice_gruppo >= 1 && periodi[serie.indice_periodo].osservato) || (serie.indice_periodo >= 1 && periodi[serie.indice_periodo].osservato))
      else return serie.find(serie => serie.indice_gruppo == 1 || serie.indice_periodo == 1)
    }else {
      return serie.find(serie => serie.indice_gruppo == 1 || serie.indice_periodo == 1)
    }
  }

  onMount(async (a, bs) => {
    const bottone = document.getElementById(id_componente);
    const descrizione = bottone.querySelector(".descrizione");

    if (descrizione) {
      /**
       * Utilizzo popper.js per posizionare correttamente il tooltip
       * in modo che non vada a creare scrollbar o risultati spiacevoli
       */
      createPopper(bottone, descrizione, {
        placement: 'bottom',
      });
    }
  });
</script>

<!-- Dizionario esportabile del componente -->
<script context="module">
  export const dizionario_indicatore = {
    codici: {
      hotel: {
        occupazione: {
          it: `Occupazione`,
          en: `Occupancy`,
          de: `Belegung`
        },
        adr: {
          it: `ADR`,
          en: `ADR`,
          de: `ADR`
        },
        revpar: {
          it: `REVPAR`,
          en: `REVPAR`,
          de: `REVPAR`
        },
        camere_vendute: {
          it: `Camere Vendute`,
          en: `Room Nights`,
          de: `Verkaufte Zimmer`
        },
        ricavo_medio_camera: {
          it: `Ricavo Medio Camera`,
          en: `Average Room Rate`,
          de: `Durchschnittlicher Raumumsatz`
        },
        mpi: {
          it: `MPI`,
          en: `MPI`,
          de: `MPI`
        },
        ari: {
          it: `ARI`,
          en: `ARI`,
          de: `ARI`
        },
        rgi: {
          it: `RGI`,
          en: `RGI`,
          de: `RGI`
        },
        prezzo_medio: {
          it: `Prezzo Medio`,
          en: `Average Rate`,
          de: `Durchschnittlicher Raumumsatz`
        },
        ricavo_camere: {
          it: `Ricavo Camere`,
          en: `Room Revenue`,
          de: `Umsatz Pro Zimmer`
        },
        ricavo_complessivo: {
          it: `Ricavo Complessivo`,
          en: `Total Revenue`,
          de: `Gesamtumsatz`
        },
        ospiti: {
          it: `Ospiti`,
          en: `Guests`,
          de: `Gäste`
        },
        ospiti_adulti: {
          it: `Ospiti Adulti`,
          en: `Adult Guests`,
          de: `Erwachsene Gäste`
        },
        ospiti_bambini: {
          it: `Ospiti Bambini`,
          en: `Child Guests`,
          de: `KinderGäste`
        },
        camere_cancellate: {
          it: `Camere Cancellate`,
          en: `Cancelled Rooms`,
          de: `Stornierte Zimmer`
        },
        occupazione_cancellata: {
          it: `Occupazione Cancellata`,
          en: `Cancelled Occupancy`,
          de: `Beruf Abgesagt`
        },
        ricavo_cancellato: {
          it: `Ricavo Cancellato`,
          en: `Cancelled Revenue`,
          de: `Gesamtumsatz Storniert`
        },
        revpec: {
          it: `REVPEC`,
          en: `REVPEC`,
          de: `REVPEC`
        },
        revpor: {
          it: `REVPOR`,
          en: `REVPOR`,
          de: `REVPOR`
        },
        trevpar: {
          it: `TREVPAR`,
          en: `TREVPAR`,
          de: `TREVPAR`
        },
        trevpec: {
          it: `TREVPEC`,
          en: `TREVPEC`,
          de: `TREVPEC`
        },
        permanenza_media: {
          it: `Durata Media Soggiorno`,
          en: `Average Stay`,
          de: `Durschnittliche Aufenthaltsdauer`
        },
        indice_travel_appeal: {
          it: `Travel Appeal TAI Score`,
          en: `Travel Appeal TAI Score`,
          de: `Travel Appeal TAI Score`
        },
        ricavo_medio_ospite: {
          it: `Ricavo Medio Ospite`,
          en: `Average Guest Rate`,
          de: `Durchschnittlicher Umsatz Pro Gast`
        },
        media_ospiti: {
          it: `Media Ospiti`,
          en: `Guest Average`,
          de: `Durschnittliche Gäste`
        },
        media_camere_vendute: {
          it: `Media Camere Vendute`,
          en: `Room Night Average`,
          de: `Durschnittlich Verkaufte Zimmer`
        },
        media_ricavo_camere: {
          it: `Media Ricavo Camere`,
          en: `Average Room Revenue`,
          de: `Durschnittlicher Erös Pro Zimmer`
        },
        media_ricavo_complessivo: {
          it: `Media Ricavo Complessivo`,
          en: `Average Total Revenue`,
          de: `Durschnittlicher Gesamtumsatz`
        },
        occupazione_posti_letto: {
          it: `Occupazione Letti`,
          en: `Bed Occupancy`,
          de: `Bettenbelegung`
        },
        occupazione_camere_senza_ricavo: {
          it: `Occupazione Complimentary`,
          en: `Complimentary Occupancy`,
          de: `Kostenlose Belegung`
        },
        strutture_campionate: {
          it: `Strutture Campionate`,
          en: `Sampled Properties`,
          de: `Beispielhafte Betriebe`
        },
        camere_disponibili: {
          it: `Camere Disponibili`,
          en: `Room Available`,
          de: `Verfügbare Zimmer`
        },
        letti_disponibili: {
          it: `Letti Disponibili`,
          en: `Beds Available`,
          de: `Verfügbare Betten`
        },
        camere_invendute: {
          it: `Camere Invendute`,
          en: `Unsold Rooms`,
          de: `Verkaufte Zimmer`
        },
        percentuale_camere_invendute: {
          it: `Percentuale Camere Invendute`,
          en: `Unsold Rooms Percentile`,
          de: `Prozentsatz Nicht Verkaufte Zimmer`
        },
        camere_vendute_senza_ricavo: {
          it: `Camere Vendute Complimentary`,
          en: `Complimentary Sold Rooms`,
          de: `Kostenlose Verkaufte Zimmer`
        }
      },
      camping: {
        occupazione: {
          it: `Occupazione`,
          en: `Occupancy`,
          de: `Belegung`
        },
        revpau: {
          it: `REVPAU`,
          en: `REVPAU`,
          de: `REVPAU`
        },
        unità_vendute: {
          it: `Unità Vendute`,
          en: `Unit Nights`,
          de: `Verkaufte Einheit`
        },
        ricavo_medio_unità: {
          it: `Ricavo Medio Unità`,
          en: `Average Unit Rate`,
          de: `Durchschnittlicher Zimmer Einnahmen`
        },
        mpi: {
          it: `MPI`,
          en: `MPI`,
          de: `MPI`
        },
        ari: {
          it: `ARI`,
          en: `ARI`,
          de: `ARI`
        },
        rgi: {
          it: `RGI`,
          en: `RGI`,
          de: `RGI`
        },
        prezzo_medio: {
          it: `Prezzo Medio`,
          en: `Average Rate`,
          de: `Durchschnittlicher Einheit Einnahmen`
        },
        ricavo_unità: {
          it: `Ricavo Unità`,
          en: `Unit Revenue`,
          de: `Umsatz Pro Einheit`
        },
        ricavo_complessivo: {
          it: `Ricavo Complessivo`,
          en: `Total Revenue`,
          de: `Gesamtumsatz`
        },
        ospiti: {
          it: `Ospiti`,
          en: `Guests`,
          de: `Gäste`
        },
        ospiti_adulti: {
          it: `Ospiti Adulti`,
          en: `Adult Guests`,
          de: `Erwachsene Gäste`
        },
        ospiti_bambini: {
          it: `Ospiti Bambini`,
          en: `Child Guests`,
          de: `KinderGäste`
        },
        unità_cancellate: {
          it: `Unità Cancellate`,
          en: `Cancelled Units`,
          de: `Stornierte Einheit`
        },
        occupazione_cancellata: {
          it: `Occupazione Cancellata`,
          en: `Cancelled Occupancy`,
          de: `Beruf Abgesagt`
        },
        ricavo_cancellato: {
          it: `Ricavo Cancellato`,
          en: `Cancelled Revenue`,
          de: `Gesamtumsatz Storniert`
        },
        revpec: {
          it: `REVPEC`,
          en: `REVPEC`,
          de: `REVPEC`
        },
        revpor: {
          it: `REVPOR`,
          en: `REVPOR`,
          de: `REVPOR`
        },
        trevpar: {
          it: `TREVPAR`,
          en: `TREVPAR`,
          de: `TREVPAR`
        },
        trevpec: {
          it: `TREVPEC`,
          en: `TREVPEC`,
          de: `TREVPEC`
        },
        permanenza_media: {
          it: `Durata Media Soggiorno`,
          en: `Average Stay`,
          de: `Durschnittliche Aufenthaltsdauer`
        },
        indice_travel_appeal: {
          it: `Travel Appeal TAI Score`,
          en: `Travel Appeal TAI Score`,
          de: `Travel Appeal TAI Score`
        },
        ricavo_medio_ospite: {
          it: `Ricavo Medio Ospite`,
          en: `Average Guest Rate`,
          de: `Durchschnittlicher Umsatz Pro Gast`
        },
        media_ospiti: {
          it: `Media Ospiti`,
          en: `Guest Average`,
          de: `Durschnittliche Gäste`
        },
        media_unità_vendute: {
          it: `Media Unità Vendute`,
          en: `Unit Night Average`,
          de: `Durschnittlich Verkaufte Einheit`
        },
        media_ricavo_unità: {
          it: `Media Ricavo Unità`,
          en: `Average Unit Revenue`,
          de: `Durschnittlicher Erös Pro Einheit`
        },
        media_ricavo_complessivo: {
          it: `Media Ricavo Complessivo`,
          en: `Average Total Revenue`,
          de: `Durschnittlicher Gesamtumsatz`
        },
        occupazione_posti_letto: {
          it: `Occupazione Letti`,
          en: `Bed Occupancy`,
          de: `Bettenbelegung`
        },
        occupazione_unità_senza_ricavo: {
          it: `Occupazione Complimentary`,
          en: `Complimentary Occupancy`,
          de: `Kostenlose Belegung`
        },
        strutture_campionate: {
          it: `Strutture Campionate`,
          en: `Sampled Properties`,
          de: `Beispielhafte Betriebe`
        },
        unità_disponibili: {
          it: `Unità Disponibili`,
          en: `Unit Available`,
          de: `Verfügbare Einheit`
        },
        letti_disponibili: {
          it: `Letti Disponibili`,
          en: `Beds Available`,
          de: `Verfügbare Betten`
        },
        unità_invendute: {
          it: `Unità Invendute`,
          en: `Unsold Units`,
          de: `Verkaufte Einheit`
        },
        percentuale_unità_invendute: {
          it: `Percentuale Unità Invendute`,
          en: `Unsold Units Percentile`,
          de: `Prozentsatz Nicht Verkaufte Einheit`
        },
        unità_vendute_senza_ricavo: {
          it: `Unità Vendute Complimentary`,
          en: `Complimentary Sold Units`,
          de: `Kostenlose Verkaufte Einheit`
        }
      },
      campeggio: {
        occupazione: {
          it: `Occupazione`,
          en: `Occupancy`,
          de: `Belegung`
        },
        revpau: {
          it: `REVPAU`,
          en: `REVPAU`,
          de: `REVPAU`
        },
        unità_vendute: {
          it: `Unità Vendute`,
          en: `Unit Nights`,
          de: `Verkaufte Einheit`
        },
        ricavo_medio_unità: {
          it: `Ricavo Medio Unità`,
          en: `Average Unit Rate`,
          de: `Durchschnittlicher Zimmer Einnahmen`
        },
        mpi: {
          it: `MPI`,
          en: `MPI`,
          de: `MPI`
        },
        ari: {
          it: `ARI`,
          en: `ARI`,
          de: `ARI`
        },
        rgi: {
          it: `RGI`,
          en: `RGI`,
          de: `RGI`
        },
        prezzo_medio: {
          it: `Prezzo Medio`,
          en: `Average Rate`,
          de: `Durchschnittlicher Einheit Einnahmen`
        },
        ricavo_unità: {
          it: `Ricavo Unità`,
          en: `Unit Revenue`,
          de: `Umsatz Pro Einheit`
        },
        ricavo_complessivo: {
          it: `Ricavo Complessivo`,
          en: `Total Revenue`,
          de: `Gesamtumsatz`
        },
        ospiti: {
          it: `Ospiti`,
          en: `Guests`,
          de: `Gäste`
        },
        ospiti_adulti: {
          it: `Ospiti Adulti`,
          en: `Adult Guests`,
          de: `Erwachsene Gäste`
        },
        ospiti_bambini: {
          it: `Ospiti Bambini`,
          en: `Child Guests`,
          de: `KinderGäste`
        },
        unità_cancellate: {
          it: `Unità Cancellate`,
          en: `Cancelled Units`,
          de: `Stornierte Einheit`
        },
        occupazione_cancellata: {
          it: `Occupazione Cancellata`,
          en: `Cancelled Occupancy`,
          de: `Beruf Abgesagt`
        },
        ricavo_cancellato: {
          it: `Ricavo Cancellato`,
          en: `Cancelled Revenue`,
          de: `Gesamtumsatz Storniert`
        },
        revpec: {
          it: `REVPEC`,
          en: `REVPEC`,
          de: `REVPEC`
        },
        revpor: {
          it: `REVPOR`,
          en: `REVPOR`,
          de: `REVPOR`
        },
        trevpar: {
          it: `TREVPAR`,
          en: `TREVPAR`,
          de: `TREVPAR`
        },
        trevpec: {
          it: `TREVPEC`,
          en: `TREVPEC`,
          de: `TREVPEC`
        },
        permanenza_media: {
          it: `Durata Media Soggiorno`,
          en: `Average Stay`,
          de: `Durschnittliche Aufenthaltsdauer`
        },
        indice_travel_appeal: {
          it: `Travel Appeal TAI Score`,
          en: `Travel Appeal TAI Score`,
          de: `Travel Appeal TAI Score`
        },
        ricavo_medio_ospite: {
          it: `Ricavo Medio Ospite`,
          en: `Average Guest Rate`,
          de: `Durchschnittlicher Umsatz Pro Gast`
        },
        media_ospiti: {
          it: `Media Ospiti`,
          en: `Guest Average`,
          de: `Durschnittliche Gäste`
        },
        media_unità_vendute: {
          it: `Media Unità Vendute`,
          en: `Unit Night Average`,
          de: `Durschnittlich Verkaufte Einheit`
        },
        media_ricavo_unità: {
          it: `Media Ricavo Unità`,
          en: `Average Unit Revenue`,
          de: `Durschnittlicher Erös Pro Einheit`
        },
        media_ricavo_complessivo: {
          it: `Media Ricavo Complessivo`,
          en: `Average Total Revenue`,
          de: `Durschnittlicher Gesamtumsatz`
        },
        occupazione_posti_letto: {
          it: `Occupazione Letti`,
          en: `Bed Occupancy`,
          de: `Bettenbelegung`
        },
        occupazione_unità_senza_ricavo: {
          it: `Occupazione Complimentary`,
          en: `Complimentary Occupancy`,
          de: `Kostenlose Belegung`
        },
        strutture_campionate: {
          it: `Strutture Campionate`,
          en: `Sampled Properties`,
          de: `Beispielhafte Betriebe`
        },
        unità_disponibili: {
          it: `Unità Disponibili`,
          en: `Unit Available`,
          de: `Verfügbare Einheit`
        },
        letti_disponibili: {
          it: `Letti Disponibili`,
          en: `Beds Available`,
          de: `Verfügbare Betten`
        },
        unità_invendute: {
          it: `Unità Invendute`,
          en: `Unsold Units`,
          de: `Verkaufte Einheit`
        },
        percentuale_unità_invendute: {
          it: `Percentuale Unità Invendute`,
          en: `Unsold Units Percentile`,
          de: `Prozentsatz Nicht Verkaufte Einheit`
        },
        unità_vendute_senza_ricavo: {
          it: `Unità Vendute Complimentary`,
          en: `Complimentary Sold Units`,
          de: `Kostenlose Verkaufte Einheit`
        }
      }
    },
    descrizioni: {
      hotel: {
        occupazione: {
          it: `Indica la percentuale di camere vendute sulle camere disponibili.
            L'occupazione è calcolata considerando solo gli hotel <b>aperti</b> nel periodo.`,
          en: `Indicates the percentage of rooms sold out of available rooms. Occupancy is calculated considering only hotels <b>open</b> in the period.`,
          de: `Gibt das prozentuelle Verhältnis der verkauften Zimmer zu den verfügbaren Zimmern an`
        },
        adr: {
          it: `Indica il prezzo medio di vendita di una camera. Viene calcolato dividendo il <b>ricavo camere</b> per le camere vendute.
            Il ricavo camere è il valore di vendita della sola camera, escludendo il valore del trattamento, degli extra, e l'IVA.`,
          en: `Indicates the average selling price of a room. It is calculated by dividing the <b>room revenue</b> by the number of rooms sold. The room rate is the sales value of the room only, excluding the value of the arrangement, extras, and VAT.`,
          de: `ADR (Average Daily Rate)</b> Drückt den durchschnittlichen Verkaufspreis eines Zimmers aus. Er wird berechnet indem der Umsatzerlös der Zimmer durch die verkauften Zimmer geteilt wird.`
        },
        revpar: {
          it: `Indica il ricavo medio generato per camera disponibile, a prescindere dal fatto che sia occupata.
            Viene calcolato dividendo il <b>ricavo camere</b> per le camere disponibili. Il ricavo camere è il valore di vendita della sola camera, escludendo il valore del trattamento, degli extra, e l'IVA.`,
          en: `Indicates the average revenue generated per available room, regardless of whether it is occupied. It is calculated by dividing the <b>room revenue</b> by the available rooms. Room revenue is the sales value of the room only, excluding the value of the treatment, extras, and VAT.`,
          de: `Gibt den durchschnittlichen Umsatz pro Zimmer an. Er wird berechnet, indem der Umsatzerlös aus Zimmern durch die verfügbaren Zimmer geteilt wird.`
        },
        camere_vendute: {
          it: `Indica il numero di camere vendute.`,
          en: `Indicates the number of rooms sold.`,
          de: `Verkaufte Zimmer`
        },
        ricavo_medio_camera: {
          it: `Indica il fatturato medio per camera occupata. Viene calcolato dividendo tra il <b>ricavo totale</b> ed il numero delle camere vendute.
            Il ricavo totale è il valore di vendita della camera comprensivo del trattamento, escludendo gli extra e l'IVA.`,
          en: `Indicates the average turnover per occupied room. It is calculated by dividing the <b>total revenue</b> by the number of rooms sold. The total revenue is the sale value of the room including the treatment, excluding extras and VAT`,
          de: `REVPOR (Revenue for Occupied Room) </ b> Gibt den durchschnittlichen Verkaufspreis eines Zimmers zuzüglich der Vereinbarung an. Er wird berechnet, indem der Gesamtumsatz durch die verkauften Zimmer geteilt wird.`
        },
        // prezzo_medio: {
        //   it: `Indica il fatturato medio per camera occupata. Viene calcolato dividendo tra il <b>ricavo totale</b> ed il numero delle camere vendute.
        //     Il ricavo totale è il valore di vendita della camera comprensivo del trattamento, escludendo gli extra e l'IVA.`,
        //   en: ``,
        //   de: ``
        // },
        mpi: {
          it: `Determina se la tua struttura ha conquistato una buona quota di mercato, in termini di occupazione delle camere. È un punteggio calcolato come rapporto percentuale fra l'occupazione della tua struttura e quella del gruppo di confronto moltiplicato per 100.
              Un  punteggio <b>MPI</b> superiore a 100 indica che la tua struttura guadagna più quote di mercato rispetto al gruppo di confronto.`,
          en: `Determines whether your property has gained a good market share, in terms of room occupancy. This is a score calculated as the percentage ratio of your property's occupancy to that of the comparison group multiplied by 100. A <b>MPI</b> score above 100 indicates that your facility is gaining more market share than the comparison group.`,
          de: `Bestimmt, ob Ihre Immobilie einen guten Marktanteil in Bezug auf die Zimmerbelegung erreicht hat. Dieser Wert errechnet sich aus dem prozentualen Verhältnis der Belegung Ihres Hauses zu der der Vergleichsgruppe, multipliziert mit 100. Ein <b>MPI</b>-Wert über 100 zeigt an, dass Ihre Einrichtung mehr Marktanteile gewinnt als die Vergleichsgruppe,`
        },
        ari: {
          it: `Esprime l'indice di punteggio delle tariffe. Indica se le tariffe della tua struttura sono mediamente più alte o più basse rispetto al gruppo di confronto. È un punteggio calcolato come rapporto percentuale tra la tariffa media della tua struttura rispetto a quella del gruppo di confronto moltiplicato per 100.
              Un punteggio <b>ARI</b> inferiore a 100 significa che la tua struttura sta vendendo mediamente a tariffe inferiori rispetto al gruppo di riferimento.`,
          en: `Provides the rate score index. Indicates whether your facility's rates are higher or lower on average than the comparison group. It is a score calculated as the percentage ratio of your facility's average rate compared to the comparison group's rate multiplied by 100. A <b>ARI</b> score of less than 100 means that your facility is selling on average at lower rates than the reference group.`,
          de: `Liefert den Index der Ratenbewertung. Gibt an, ob die Raten Ihrer Einrichtung im Durchschnitt höher oder niedriger sind als die der Vergleichsgruppe. Es handelt sich um einen Wert, der sich aus dem prozentualen Verhältnis zwischen dem Durchschnittssatz Ihrer Einrichtung und dem Satz der Vergleichsgruppe multipliziert mit 100 ergibt. Ein <b>ARI</b>-Wert von weniger als 100 bedeutet, dass Ihre Einrichtung im Durchschnitt zu niedrigeren Sätzen verkauft als die Vergleichsgruppe`
        },
        rgi: {
          it: `È il più importante punteggio di indice di redditività.
              Indica la capacità di generare redditività per ogni camera disponibile rispetto al mercato di riferimento.
              Il punteggio si calcola come rapporto percentuale tra il REVPAR della tua struttura e il REVPAR medio del gruppo di riferimento moltiplicato per 100.
              Un punteggio RGI inferiore a 100 significa che le camere del gruppo di riferimento generano maggiore redditività rispetto alle camere della tua struttura.`,
          en: `It is the most important profitability index score.
             It indicates the ability to generate profitability for each available room with respect to the reference market.
             The score is calculated as the percentage ratio between your facility's REVPAR and the average REVPAR of the reference group multiplied by 100.
             An RGI score of less than 100 means that the rooms in the target group generate more profitability than the rooms in your facility.`,
          de: `Er gibt die Fähigkeit an, für jedes verfügbare Zimmer eine Rentabilität im Vergleich zum Referenzmarkt zu erzielen. Der Wert wird berechnet als das prozentuale Verhältnis zwischen dem REVPAR Ihrer Einrichtung und dem durchschnittlichen REVPAR der Referenzgruppe, multipliziert mit 100. Ein RGI-Wert von weniger als 100 bedeutet, dass die Zimmer in der Zielgruppe eine höhere Rentabilität erzielen als die Zimmer in Ihrer Einrichtung`
        },
        ricavo_camere: {
          it: `Indica il ricavo generato dalla vendita delle sole camere, escludendo eventuali costi di colazione pasti ed extra. Il valore è espresso al netto dell'IVA.`,
          en: `Indicates the revenue generated by the sale of the rooms only, excluding any costs of breakfast, meals and extras. The value is expressed net of VAT`,
          de: `Gibt die Einnahmen an, die nur durch den Verkauf der Zimmer erzielt werden, ohne Kosten für Frühstück, Mahlzeiten und Extras. Der Wert ist ohne MwSt. angegeben.`
        },
        ricavo_complessivo: {
          it: `Indica il ricavo generato dalla vendita delle camere, includendo eventuali costi di trattamento (colazione e pasti). Il valore è espresso al netto dell'IVA.`,
          en: `Total Revenue includes the revenue generated by Room night sales and arrangement value. it excludes extras and VAT`,
          de: `Die Gesamteinnahmen umfassen die Einnahmen aus den Verkäufen von Zimmern und Mahlzeiten, ohne Extras und Mehrwertsteuer.`
        },
        ospiti: {
          it: `Numero complessivo di ospiti presenti.`,
          en: `Total number of guests present.`,
          de: `Gibt die Anzahl der anwesenden Gäste an`
        },
        ospiti_adulti: {
          it: `Numero complessivo di ospiti adulti presenti`,
          en: `Total number of adult guests present`,
          de: `Gibt die Anzahl der anwesenden erwachsenen Gäste an`
        },
        ospiti_bambini: {
          it: `Numero complessivo di ospiti bambini presenti`,
          en: `Total number of children guests present`,
          de: `Gibt die Anzahl der anwesenden Kinder an`
        },
        camere_cancellate: {
          it: `Indica il numero di camere cancellate`,
          en: `Indicate the number of cancelled rooms`,
          de: `Gibt die Anzahl der stornierten Zimmer an`
        },
        occupazione_cancellata: {
          it: `Indica la percentuale di camere cancellate  sulle camere disponibili.`,
          en: `Indicate the percentage of cancelled rooms out of available rooms.`,
          de: `Gibt den Prozentsatz der stornierten Zimmer im Verhältnis zu den verfügbaren Zimmern an,`
        },
        ricavo_cancellato: {
          it: `Indica il totale del ricavo complessivo delle prenotazioni cancellate.
            Il ricavo complessivo indica il ricavo generato dalla vendita delle camere, includendo eventuali costi di trattamento (colazione e pasti). Il valore è espresso al netto dell'IVA.`,
          en: `Indicates the total revenue from cancelled reservations. Total revenue indicates the revenue generated from the sale of rooms, including any arrangement costs (breakfast and meals). The value is expressed net of VAT.`,
          de: `Gibt die Gesamteinnahmen aus stornierten Buchungen an. Der Gesamterlös gibt den aus dem Verkauf von Zimmern erzielten Erlös an, einschließlich etwaiger Arrangementkosten (Frühstück und Mahlzeiten). Der Wert ist ohne MwSt. angegeben`
        },
        revpec: {
          it: `indica il  Ricavo totale medio per ospite.
              Viene calcolato dividendo il <b>ricavo camera</b> per il numero di ospiti. Il ricavo camera indica il ricavo generato dalla vendita delle sole camere, escludendo eventuali costi di colazione pasti ed extra. Il valore è espresso al netto dell'IVA.`,
          en: `indicates the average total revenue per guest. 
              It is calculated by dividing the <b>room revenue</b> by the number of guests. Room revenue indicates the revenue generated from the sale of rooms only, excluding any costs of breakfast, meals and extras. The value is expressed net of VAT`,
          de: `Gibt den durchschnittlichen Verkaufspreis pro Gast an. Er wird berechnet, indem der Umsatzerlös aus Zimmern durch die Anzahl der Gäste geteilt wird`
        },
        revpor: {
          it: `Indica il Ricavo Totale Medio per camera venduta.
              Viene calcolato dividendo il <b>ricavo complessivo</b> per le camere vendute. Il ricavo complessivo  è il ricavo generato dalla vendita delle camere, includendo eventuali costi di trattamento (colazione e pasti). Il valore è espresso al netto dell'IVA.`,
          en: `Indicate the Average Total Revenue per room sold.
              It is calculated by dividing the <b>total revenue</b> by the number of rooms sold. The total revenue is the revenue generated by the sale of the rooms, including any treatment costs (breakfast and meals). The value is expressed net of VAT.`,
          de: `REVPOR (Revenue for Occupied Room) </ b> Gibt den durchschnittlichen Verkaufspreis eines Zimmers zuzüglich der vereinbarten inkludierten Leistungen (Mahlzeiten) an. Er wird berechnet, indem der Gesamtumsatz für die verkauften Zimmer geteilt wird.`
        },
        trevpar: {
          it: `Indica il Ricavo totale medio per camera disponibile.
              Viene calcolato dividendo il <b>ricavo complessivo</b> per le camere disponibili. Il ricavo complessivo  è il ricavo generato dalla vendita delle camere, includendo eventuali costi di trattamento (colazione e pasti). Il valore è espresso al netto dell'IVA`,
          en: `Indicate the average total revenue per available room. 
              It is calculated by dividing the <b>total revenue</b> by the available rooms. The total revenue is the revenue generated by the sale of the rooms, including any treatment costs (breakfast and meals). The value is expressed net of VAT`,
          de: `Gibt die durchschnittlichen Einnahmen die durch ein Zimmer erzielt wurden zuzüglich der vereinbarten inkludierten Leistungen (Mahlzeiten) an. Er wird berechnet, indem der Gesamtumsatz durch die verfügbaren Zimmer geteilt wird`
        },
        trevpec: {
          it: `Indica il  Ricavo totale medio per ospite.
              Viene calcolato dividendo il <b>ricavo complessivo</b> per il numero di ospiti. Il ricavo complessivo  è il ricavo generato dalla vendita delle camere, includendo eventuali costi di trattamento (colazione e pasti). Il valore è espresso al netto dell'IVA`,
          en: `Indicate the average total revenue per guest. 
              It is calculated by dividing the <b>total revenue</b> by the number of guests. The total revenue is the revenue generated by the sale of the rooms, including any treatment costs (breakfast and meals). The value is expressed net of VAT`,
          de: `Gibt den durchschnittlichen Verkaufspreis pro Gast an. Er wird berechnet, indem der Gesamtumsatz durch die Anzahl der Gäste geteilt wird.`
        },
        permanenza_media: {
          it: `Indica la durata media delle prenotazioni espresse in giorni.`,
          en: `Indicates the average duration of bookings in days.`,
          de: `Gibt die durchschnittliche Anzahl an Buchungen in Tagen an`
        },
        indice_travel_appeal: {
          it: ``,
          en: ``,
          de: ``
        },
        ricavo_medio_ospite: {
          it: `Indica il  Ricavo totale medio per ospite.
              Viene calcolato dividendo il <b>ricavo complessivo</b> per il numero di ospiti. Il ricavo complessivo  è il ricavo generato dalla vendita delle camere, includendo eventuali costi di trattamento (colazione e pasti). Il valore è espresso al netto dell'IVA`,
          en: `Indicate the average total revenue per guest. 
              It is calculated by dividing the <b>total revenue</b> by the number of guests. The total revenue is the revenue generated from the sale of the rooms, including any treatment costs (breakfast and meals). The value is expressed net of VAT`,
          de: `Gibt den durchschnittlichen Verkaufspreis pro Gast an. Er wird berechnet, indem der Umsatzerlös aus Zimmern durch die Anzahl der Gäste geteilt wird`
        },
        media_ospiti: {
          it: `Indica il numero medio delle persone ospitate per singola struttura ed è calcolato dividendo il totale degli ospiti presenti in un determinato periodo per il numero di giorni che compongono quel periodo.`,
          en: `Indicates the average number of people hosted per single structure and is calculated by dividing the total number of guests present in a given period by the number of days that make up that period.`,
          de: `Gibt den Durchschnitt an Gästen an`
        },
        media_camere_vendute: {
          it: `Indica il numero medio delle camere vendute per singola struttura ed è calcolato dividendo il totale delle camere vendute in un determinato periodo per il numero di giorni che compongono quel periodo.`,
          en: `Average of room nights sold over a timeframe - Indicates the average number of rooms sold per individual facility and is calculated by dividing the total number of rooms sold in a given period by the number of days that make up that period.`,
          de: `Gibt die durchschnittliche Anzahl der verkauften Zimmer pro Einrichtung an und wird berechnet, indem die Gesamtzahl der verkauften Zimmer in einem bestimmten Zeitraum durch die Anzahl der Tage in diesem Zeitraum geteilt wird.`
        },
        media_ricavo_camere: {
          it: ``,
          en: ``,
          de: ``
        },
        media_ricavo_complessivo: {
          it: `Indica il ricavo medio camera per struttura ed è calcolato dividendo il Ricavo camera di un determinato periodo per il numero di giorni che compongono quel periodo`,
          en: `Indicates the average total revenue per structure and is calculated by dividing the Room Revenue of a given period by the number of days that make up that period`,
          de: `Durschnittlicher Gesamterlös`
        },
        occupazione_posti_letto: {
          it: `Indica la percentuale di posti letto  venduti sui posti letto disponibili. L'occupazione è calcolata considerando solo gli hotel <b>aperti</b> nel periodo.`,
          en: `Indicates the percentage of beds sold out of available beds. Occupancy is calculated considering only hotels <b>open</b> in the period.`,
          de: `Zeigt den Prozentsatz der verkauften Betten im Verhältnis zu den verfügbaren Betten an. Bei der Berechnung der Belegung werden nur Hotels berücksichtigt, die in diesem Zeitraum <b>offen</b> sind`
        },
        occupazione_camere_senza_ricavo: {
          it: ``,
          en: ``,
          de: ``
        },
        strutture_campionate: {
          it: `Indica il numero totale delle strutture che competono alla restituzione della statistica`,
          en: `Indicate the total number of facilities responsible for returning the statistics`,
          de: `Geben Sie die Anzahl der Betriebe an, die zur Generierung der Daten beitragen`
        },
        camere_disponibili: {
          it: `Indica il numero totale delle camere disponibili indipendententemente dalle vendite.`,
          en: `Indicate the total number of rooms available regardless of sales`,
          de: `Verfügbare Zimmer`
        },
        letti_disponibili: {
          it: `Indica il numero totale dei letti disponibili indipendententemente dalle vendite`,
          en: `Indicate the total number of beds available regardless of sales`,
          de: `Verfügbare Betten`
        },
        camere_invendute: {
          it: `Indica il numero totale delle camere che risultano invendute`,
          en: `Indicate the total number of unsold rooms`,
          de: `Geben Sie die Anzahl der nicht verkauften Zimmer an`
        },
        percentuale_camere_invendute: {
          it: `Indica la percentuale delle camere invendute sul totale delle  camere disponibili.`,
          en: `Indicate the percentage of unsold rooms out of the total available rooms.`,
          de: `Geben Sie das prozentuelle Verhältnis der nicht verkauften Zimmern zu den verfügbaren Zimmern an.`
        },
        camere_vendute_senza_ricavo: {
          it: ``,
          en: ``,
          de: ``
        }
      },
      camping: {
        occupazione: {
          it: `Indica la percentuale delle unità vendute sulle unità disponibili dello stesso prodotto. L'occupazione è calcolata considerando solo i <b>campeggi</b> aperti nel periodo.`,
          en: ``,
          de: ``
        },
        revpau: {
          it: `Indica il ricavo medio generato per unità disponibile, a prescindere dal fatto che sia occupata.
            Viene calcolato dividendo il ricavo unità per le unità disponibili. Il ricavo unità è il valore di vendita della sola unità, escludendo  eventuali extra di costi lenzuola e pulizie. Il valore è espresso al netto IVA.`,
          en: ``,
          de: ``
        },
        unità_vendute: {
          it: `Indica il numero di unità vendute.`,
          en: ``,
          de: ``
        },
        ricavo_medio_unità: {
          it: `Indica il prezzo medio di vendita. Viene calcolato dividendo il <b>ricavo unità</b> per le unità vendute. Il ricavo unità non è comprensivo di eventuali extra servizio come lenzuola e pulizie. Il valore è espresso al netto IVA.`,
          en: ``,
          de: ``
        },
        prezzo_medio: {
          it: `Indica il prezzo medio di vendita. Viene calcolato dividendo il <b>ricavo unità</b> per le unità vendute. Il ricavo unità non è comprensivo di eventuali extra servizio come lenzuola e pulizie. Il valore è espresso al netto IVA.`,
          en: ``,
          de: ``
        },
        mpi: {
          it: `Determina se la tua struttura ha conquistato una buona quota di mercato, in termini di occupazione delle unità. È un punteggio calcolato come rapporto percentuale fra l'occupazione della tua struttura e quella del gruppo di confronto moltiplicato per 100.
              Un  punteggio <b>MPI</b> superiore a 100 indica che la tua struttura guadagna più quote di mercato rispetto al gruppo di confronto.`,
          en: ``,
          de: ``
        },
        ari: {
          it: `Esprime l'indice di punteggio delle tariffe. Indica se le tariffe della tua struttura sono mediamente più alte o più basse rispetto al gruppo di confronto. È un punteggio calcolato come rapporto percentuale tra la tariffa media della tua struttura rispetto a quella del gruppo di confronto moltiplicato per 100.
              Un punteggio <b>ARI</b> inferiore a 100 significa che la tua struttura sta vendendo mediamente a tariffe inferiori rispetto al gruppo di riferimento.`,
          en: ``,
          de: ``
        },
        rgi: {
          it: `È il più importante punteggio di indice di redditività.
              Indica la capacità di generare redditività per ogni unità disponibile rispetto al mercato di riferimento.
              Il punteggio si calcola come rapporto percentuale tra il REVPAU della tua struttura e il REVPAU medio del gruppo di riferimento moltiplicato per 100.
              Un punteggio RGI inferiore a 100 significa che le unità del gruppo di riferimento generano maggiore redditività rispetto alle unità della tua struttura.`,
          en: ``,
          de: ``
        },
        ricavo_unità: {
          it: `Indica il ricavo generato dalla vendita della sole unità, escludendo eventuali costi di lenzuola e pulizie. Il valore è espresso al netto dell'IVA.`,
          en: ``,
          de: ``
        },
        ricavo_complessivo: {
          it: `Indica il ricavo generato dalla vendita delle unità, includendo eventuali costi di lenzuola e pulizie. Il valore è espresso al netto dell'IVA.`,
          en: ``,
          de: ``
        },
        ospiti: {
          it: `Numero complessivo di ospiti presenti.`,
          en: ``,
          de: ``
        },
        ospiti_adulti: {
          it: `Numero complessivo di ospiti adulti presenti`,
          en: ``,
          de: ``
        },
        ospiti_bambini: {
          it: `Numero complessivo di ospiti bambini presenti`,
          en: ``,
          de: ``
        },
        unità_cancellate: {
          it: `Indica il numero di unità cancellate`,
          en: ``,
          de: ``
        },
        occupazione_cancellata: {
          it: `Indica la percentuale di unità cancellate sulle unità disponibili.`,
          en: ``,
          de: ``
        },
        ricavo_cancellato: {
          it: `Indica il ricavo totale delle prenotazioni  cancellate`,
          en: ``,
          de: ``
        },
        revpec: {
          it: `Rappresenta il prezzo medio di vendita per ospite.
              Si calcola come il rapporto tra il ricavo totale unità e numero di ospiti presenti.`,
          en: ``,
          de: ``
        },
        permanenza_media: {
          it: `Indica la durata media delle prenotazioni espresse in giorni.
              Vengono escluse dal calcolo le prenotazioni con durata superiore ai 30 giorni `,
          en: ``,
          de: ``
        },
        indice_travel_appeal: {
          it: ``,
          en: ``,
          de: ``
        },
        ricavo_medio_ospite: {
          it: `Indica il prezzo medio per ospite. Viene calcolato dividendo il <b>ricavo unità</b> per il numero di ospiti presenti.
            Il ricavo unità non è comprensivo di eventuali extra servizio come lenzuola e pulizie. Il valore è espresso al netto IVA.`,
          en: ``,
          de: ``
        },
        media_ospiti: {
          it: `Indica il numero medio delle persone ospitate per singola struttura ed è calcolato dividendo il totale degli ospiti presenti in un determinato periodo per il numero di giorni che compongono quel periodo.`,
          en: ``,
          de: ``
        },
        media_unità_vendute: {
          it: `Indica il numero medio di unità vendute per singola struttura ed è calcolato dividendo il totale delle unità vendute in un determinato periodo per il numero di giorni che compongono quel periodo.`,
          en: ``,
          de: ``
        },
        media_ricavo_unità: {
          it: `Indica il ricavo medio unità per struttura ed è calcolato dividendo il Ricavo unità  di un determinato periodo per il numero di giorni che compongono quel periodo.`,
          en: ``,
          de: ``
        },
        media_ricavo_complessivo: {
          it: `Indica il ricavo complessivo medio per struttura ed è calcolato dividendo il ricavo complessivo di un determinato periodo per il numero di giorni che compongono  quel periodo.`,
          en: ``,
          de: ``
        },
        occupazione_unità_senza_ricavo: {
          it: ``,
          en: ``,
          de: ``
        },
        strutture_campionate: {
          it: `Indica il numero totale delle strutture che competono alla restituzione della statistica`,
          en: ``,
          de: ``
        },
        unità_disponibili: {
          it: `Indica il numero totale delle unità disponibili indipendententemente dalle vendite.`,
          en: ``,
          de: ``
        },
        unità_invendute: {
          it: `Indica il numero totale delle unità che risultano invendute`,
          en: ``,
          de: ``
        },
        percentuale_unità_invendute: {
          it: `Indica la percentuale delle unità invendute sulle unità disponibili`,
          en: ``,
          de: ``
        },
        unità_vendute_senza_ricavo: {
          it: ``,
          en:	``,
          de: ``
        }
      },
      campeggio: {
        occupazione: {
          it: `Indica la percentuale delle unità vendute sulle unità disponibili dello stesso prodotto. L'occupazione è calcolata considerando solo i <b>campeggi</b> aperti nel periodo.`,
          en: ``,
          de: ``
        },
        revpau: {
          it: `Indica il ricavo medio generato per unità disponibile, a prescindere dal fatto che sia occupata.
            Viene calcolato dividendo il ricavo unità per le unità disponibili. Il ricavo unità è il valore di vendita della sola unità, escludendo  eventuali extra di costi lenzuola e pulizie. Il valore è espresso al netto IVA.`,
          en: ``,
          de: ``
        },
        unità_vendute: {
          it: `Indica il numero di unità vendute.`,
          en: ``,
          de: ``
        },
        ricavo_medio_unità: {
          it: `Indica il prezzo medio di vendita. Viene calcolato dividendo il <b>ricavo unità</b> per le unità vendute. Il ricavo unità non è comprensivo di eventuali extra servizio come lenzuola e pulizie. Il valore è espresso al netto IVA.`,
          en: ``,
          de: ``
        },
        prezzo_medio: {
          it: `Indica il prezzo medio di vendita. Viene calcolato dividendo il <b>ricavo unità</b> per le unità vendute. Il ricavo unità non è comprensivo di eventuali extra servizio come lenzuola e pulizie. Il valore è espresso al netto IVA.`,
          en: ``,
          de: ``
        },
        mpi: {
          it: `Determina se la tua struttura ha conquistato una buona quota di mercato, in termini di occupazione delle unità. È un punteggio calcolato come rapporto percentuale fra l'occupazione della tua struttura e quella del gruppo di confronto moltiplicato per 100.
              Un  punteggio <b>MPI</b> superiore a 100 indica che la tua struttura guadagna più quote di mercato rispetto al gruppo di confronto.`,
          en: ``,
          de: ``
        },
        ari: {
          it: `Esprime l'indice di punteggio delle tariffe. Indica se le tariffe della tua struttura sono mediamente più alte o più basse rispetto al gruppo di confronto. È un punteggio calcolato come rapporto percentuale tra la tariffa media della tua struttura rispetto a quella del gruppo di confronto moltiplicato per 100.
              Un punteggio <b>ARI</b> inferiore a 100 significa che la tua struttura sta vendendo mediamente a tariffe inferiori rispetto al gruppo di riferimento.`,
          en: ``,
          de: ``
        },
        rgi: {
          it: `È il più importante punteggio di indice di redditività.
              Indica la capacità di generare redditività per ogni unità disponibile rispetto al mercato di riferimento.
              Il punteggio si calcola come rapporto percentuale tra il REVPAU della tua struttura e il REVPAU medio del gruppo di riferimento moltiplicato per 100.
              Un punteggio RGI inferiore a 100 significa che le unità del gruppo di riferimento generano maggiore redditività rispetto alle unità della tua struttura.`,
          en: ``,
          de: ``
        },
        ricavo_unità: {
          it: `Indica il ricavo generato dalla vendita della sole unità, escludendo eventuali costi di lenzuola e pulizie. Il valore è espresso al netto dell'IVA.`,
          en: ``,
          de: ``
        },
        ricavo_complessivo: {
          it: `Indica il ricavo generato dalla vendita delle unità, includendo eventuali costi di lenzuola e pulizie. Il valore è espresso al netto dell'IVA.`,
          en: ``,
          de: ``
        },
        ospiti: {
          it: `Numero complessivo di ospiti presenti.`,
          en: ``,
          de: ``
        },
        ospiti_adulti: {
          it: `Numero complessivo di ospiti adulti presenti`,
          en: ``,
          de: ``
        },
        ospiti_bambini: {
          it: `Numero complessivo di ospiti bambini presenti`,
          en: ``,
          de: ``
        },
        unità_cancellate: {
          it: `Indica il numero di unità cancellate`,
          en: ``,
          de: ``
        },
        occupazione_cancellata: {
          it: `Indica la percentuale di unità cancellate sulle unità disponibili.`,
          en: ``,
          de: ``
        },
        ricavo_cancellato: {
          it: `Indica il ricavo totale delle prenotazioni  cancellate`,
          en: ``,
          de: ``
        },
        revpec: {
          it: `Rappresenta il prezzo medio di vendita per ospite.
              Si calcola come il rapporto tra il ricavo totale unità e numero di ospiti presenti.`,
          en: ``,
          de: ``
        },
        permanenza_media: {
          it: `Indica la durata media delle prenotazioni espresse in giorni.
              Vengono escluse dal calcolo le prenotazioni con durata superiore ai 30 giorni `,
          en: ``,
          de: ``
        },
        indice_travel_appeal: {
          it: ``,
          en: ``,
          de: ``
        },
        ricavo_medio_ospite: {
          it: `Indica il prezzo medio per ospite. Viene calcolato dividendo il <b>ricavo unità</b> per il numero di ospiti presenti.
            Il ricavo unità non è comprensivo di eventuali extra servizio come lenzuola e pulizie. Il valore è espresso al netto IVA.`,
          en: ``,
          de: ``
        },
        media_ospiti: {
          it: `Indica il numero medio delle persone ospitate per singola struttura ed è calcolato dividendo il totale degli ospiti presenti in un determinato periodo per il numero di giorni che compongono quel periodo.`,
          en: ``,
          de: ``
        },
        media_unità_vendute: {
          it: `Indica il numero medio di unità vendute per singola struttura ed è calcolato dividendo il totale delle unità vendute in un determinato periodo per il numero di giorni che compongono quel periodo.`,
          en: ``,
          de: ``
        },
        media_ricavo_unità: {
          it: `Indica il ricavo medio unità per struttura ed è calcolato dividendo il Ricavo unità  di un determinato periodo per il numero di giorni che compongono quel periodo.`,
          en: ``,
          de: ``
        },
        media_ricavo_complessivo: {
          it: `Indica il ricavo complessivo medio per struttura ed è calcolato dividendo il ricavo complessivo di un determinato periodo per il numero di giorni che compongono  quel periodo.`,
          en: ``,
          de: ``
        },
        occupazione_unità_senza_ricavo: {
          it: ``,
          en: ``,
          de: ``
        },
        strutture_campionate: {
          it: `Indica il numero totale delle strutture che competono alla restituzione della statistica`,
          en: ``,
          de: ``
        },
        unità_disponibili: {
          it: `Indica il numero totale delle unità disponibili indipendententemente dalle vendite.`,
          en: ``,
          de: ``
        },
        unità_invendute: {
          it: `Indica il numero totale delle unità che risultano invendute`,
          en: ``,
          de: ``
        },
        percentuale_unità_invendute: {
          it: `Indica la percentuale delle unità invendute sulle unità disponibili`,
          en: ``,
          de: ``
        },
        unità_vendute_senza_ricavo: {
          it: ``,
          en:	``,
          de: ``
        }
      }
    }
  }

</script>
