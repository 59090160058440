<template>
  {#if comparazione.valore != null}
    <div
        class="comparazione"
        class:positiva={comparazione.positiva}
        class:uguale={comparazione.uguale}
        class:negativa={comparazione.negativa}>
      <div class="valore">
        {#if comparazione.impossibile}
          n/d -
        {:else}
          {#if comparazione.positiva}
            <Icona tipo="triangolo-su"/>
          {:else if comparazione.uguale}
            =
          {:else if comparazione.negativa}
            <Icona tipo="triangolo-giù"/>
          {/if}
          <Numero
              numero={comparazione.valore}
              unità={comparazione.unità}
              valuta={serie.valuta}
              mostra_zero={true}
              riduci={true}
              decimali={comparazione.indicatore.decimali}/>
        {/if}
      </div>
      {#if mostra_dettagli}
        <div class="comparato-con">
          vs.
          {#if comparazione.gruppo != null}
            {comparazione.gruppo.nome}
            ({t.gruppi[comparazione.gruppo.collezione][$l]})
          {/if}

          {#if comparazione.periodo != null}
            {t.periodi[comparazione.periodo.nome][$l]}
            {#if comparazione.periodo.osservato}
              {comparazione.periodo.data_osservazione.to_date.format("%e %B %Y")}
            {/if}
          {/if}
        </div>
        <div class="valori">
          <div class="confronto">
            <Numero
                numero={comparazione.valori[0]}
                unità={serie.unità}
                valuta={serie.valuta}
                mostra_zero={true}
                riduci={true}
                decimali={comparazione.indicatore.decimali}/>
            vs
            <Numero
                numero={comparazione.valori[1]}
                unità={serie.unità}
                valuta={serie.valuta}
                mostra_zero={true}
                riduci={true}
                decimali={comparazione.indicatore.decimali}/>            
          </div>
          {#if comparazione.unità == "punti_percentuali"}
            <div class="comparazione-percentuale">
              {#if comparazione.positiva}
                <Icona tipo="triangolo-su"/>
              {:else if comparazione.uguale}
                =
              {:else if comparazione.negativa}
                <Icona tipo="triangolo-giù"/>
              {/if}
              <Numero
                  numero={comparazione_percentuale}
                  unità="percentuale"
                  mostra_zero={true}
                  riduci={true}
                  decimali={comparazione.indicatore.decimali}/>
            </div>
          {/if}
        </div>
      {/if}
    </div>
  {/if}
</template>

<style>
  :global(div.comparazione) {
    white-space:  nowrap;
  }

  /* Valore. */
  :global(div.comparazione div.valore) {
    display:      flex;
    align-items:  center;
    font-size:    20px;
    text-align:   right;
    margin-right: 14px;
  }
  :global(div.comparazione.positiva div.valore) {
    color:        var(--colore-verde);
  }
  :global(div.comparazione.uguale div.valore) {
    color:        var(--colore-testo-leggero);
  }
  :global(div.comparazione.negativa div.valore) {
    color:        var(--colore-rosso);
  }
  :global(div.comparazione div.valori) {
    letter-spacing: normal;
    font-size:      13px;
  }
  :global(div.comparazione div.valori div.comparazione-percentuale) {
    display:        flex;
    align-items:    center;
  }
  :global(div.comparazione.negativa div.valori div.comparazione-percentuale) {
    color:        var(--colore-rosso);
  }
  :global(div.comparazione.positiva div.valori div.comparazione-percentuale) {
    color:        var(--colore-verde);
  }
  :global(div.comparazione.uguale div.valori div.comparazione-percentuale) {
    color:        var(--colore-testo-leggero);
  }
  :global(div.comparazione div.comparato-con) {
    margin-top:   7px;
    color:        var(--colore-testo-leggero);
    font-size:    13px;
  }
  :global(div.comparazione div.valori) {
    display:          flex;
    justify-content:  space-between;
    align-items:      center;
    flex-wrap:        wrap;
  }
</style>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import Numero from "@/base/componenti/Numero.svelte"
  import { dizionario_comparatore } from "@/base/componenti/rapporti/Comparatore.svelte"
  import { periodi } from "@/base/sorgenti/hbenchmark/periodi"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  export let serie            = {}
  export let serie_comparata  = {}
  export let statistica       = {}
  export let comparazione     = {}
  export let mostra_dettagli  = false

  const t = avvia_localizzazione(dizionario_comparazione)
  const indicatori_di_penetrazione = [ "mpi", "rgi", "ari" ]
  
  let comparazione_percentuale = null
  let indicatore = statistica.indicatori[serie.indice_indicatore]

  $: if (serie.totale == null || serie_comparata.totale == null) {
    // Comparazione impossibile.
    comparazione.impossibile  = true
    comparazione.valore       = null
  } else {
    // Comparazione possibile, almeno uno dei due valori presente.
    comparazione.valori = [
      (serie_comparata.totale || 0),
      (serie.totale || 0) ]
    if (indicatori_di_penetrazione.includes(indicatore.codice)) {
      comparazione.valore = (comparazione.valori[0] * 100) / comparazione.valori[1]
        
      comparazione.positiva = comparazione.valore >  100
      comparazione.uguale   = comparazione.valore == 100
      comparazione.negativa = comparazione.valore <  100
    }else {
      comparazione.valore = comparazione.valori[0] - comparazione.valori[1]

      if (serie.unità == "percentuale")
        comparazione.unità = "punti_percentuali"
      else
        comparazione.unità = serie.unità
  
      comparazione.positiva = comparazione.valore >  0
      comparazione.uguale   = comparazione.valore == 0
      comparazione.negativa = comparazione.valore <  0
    }

    if (serie.indice_gruppo != serie_comparata.indice_gruppo)
      comparazione.gruppo = statistica.gruppi[serie.indice_gruppo]
    else
      comparazione.gruppo = null

    if (serie.indice_periodo != serie_comparata.indice_periodo)
      comparazione.periodo = statistica.cornice_temporale.periodi[serie.indice_periodo]
    else
      comparazione.periodo = null

    comparazione.indicatore = indicatore
  }

  $: if (comparazione.unità == "punti_percentuali") {
    comparazione_percentuale = (comparazione.valori[0] - comparazione.valori[1]) / comparazione.valori[1] * 100
  }
</script>

<script context="module">
  export const dizionario_comparazione = {
    gruppi: dizionario_comparatore.gruppi,
    periodi
  }
</script>
