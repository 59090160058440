<template>
  <div class="barra-messaggio"
      class:chiusa={!barra_espansa}
      transition:fade={{ duration: 500 }}>
    <div class="contenuto-messaggio">
      {#if messaggio}
        {@html messaggio}
      {:else}
        <slot/>
      {/if}
    </div>
    {#if richiudibile}
      <div
          class="bottone-visualizzazione"
          on:click={espandi_collassa_messaggio}>
        {#if barra_espansa}
          {t.vedi_meno[$l]}
        {:else}
          {t.vedi_tutto[$l]}
        {/if}
      </div>
    {/if}
  </div>
</template>

<style>
  :global(div.barra-messaggio) {
    background:       #B9DDFF;
    display:          flex;
    flex-wrap:        wrap;
    color:            var(--colore-testo-leggero);
    padding:          7px 28px 7px 7px;
    z-index:          11;
    border-radius:    3px;
    justify-content:  space-between;
  }
  :global(div.barra-messaggio.chiusa div.contenuto-messaggio) {
    flex-wrap:  nowrap;
  }
  :global(div.barra-messaggio.chiusa div.contenuto-messaggio) {
    height:         20px;
    width:          calc(100% - 200px);
    overflow:       hidden;
    text-overflow:  ellipsis;
    white-space:    nowrap;
  }
  :global(div.barra-messaggio div.contenuto-messaggio) {
    width:          100%;
  }
  
  :global(div.barra-messaggio > div.bottone-visualizzazione) {
    font-weight:      bold;
    text-decoration:  underline;
    cursor:           pointer;
  }
</style>

<script>
  import { fade } from "svelte/transition"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  export let messaggio = null
  export let richiudibile = false

  const t = avvia_localizzazione(dizionario_barra_messaggio)

  let barra_espansa = true

  function espandi_collassa_messaggio() {
    if (barra_espansa) barra_espansa = false
    else barra_espansa = true
  }
</script>

<script context="module">
  export const dizionario_barra_messaggio = {
    vedi_tutto: {
      it: `Vedi tutto`,
      en: `See all`,
      de: `Alles anzeigen`
    },
    vedi_meno: {
      it: `Vedi meno`,
      en: `See less`,
      de: `Weniger anzeigen`
    },
  }
</script>