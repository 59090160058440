import { writable } from "svelte/store"

// Sessioni del Portale.
let sessione_in_deposito      = deposito_locale.prendi("sessione_corrente", {})
const sessione_corrente       = writable(sessione_in_deposito)
const sessioni_selezionabili  = writable([])
const gruppi_comparabili      = writable([])

// Sessione corrente, es: un hotel o un territorio.
sessione_corrente.cambia_in = function(nuova_sessione) {
  deposito_locale.deposita("sessione_corrente", nuova_sessione)
  return sessione_corrente.update(utente => nuova_sessione)
}
sessione_corrente.su_cambio = sessione_corrente.subscribe

// Sessioni selezionabili, es: tutti gli hotel di un utente, o tutti i
// territori di un operatore territoriale.
sessioni_selezionabili.cambia_in = function(nuove_sessioni) {
  return sessioni_selezionabili.update(sessioni => nuove_sessioni)
}
sessioni_selezionabili.su_cambio = sessioni_selezionabili.subscribe

// Gruppi comparabili, ovvero tutti i gruppi che è possibile
// utilizzare per la richiesta di una statistica.
gruppi_comparabili.cambia_in = function(nuovi_gruppi) {
  return gruppi_comparabili.update(gruppi => nuovi_gruppi)
}
gruppi_comparabili.su_cambio = gruppi_comparabili.subscribe

export {
  sessione_corrente,
  sessioni_selezionabili,
  gruppi_comparabili }
