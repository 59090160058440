<template>
  <GestioneStruttura>
    <div class="controllo-dati">
      {#if in_caricamento}
        <Caricatore/>
      {:else}
        <div class="margini-positivi">
          <!-- {#if $sessione_corrente.collezione == "strutture"}
            <StatoAzioniStruttura
                id_struttura={$sessione_corrente._id}/>
          {/if} -->

          <div class="azioni">
            <Selezione
                etichetta={t.tipo_controllo[$l]}
                valore={controllo_attivo}
                opzioni={opzioni_controllo}
                on:change={cambia_controllo}>
            </Selezione>

            <IntervalloDate
                etichetta={t.etichetta_periodo[$l]}
                mostra_intervalli={true}
                codice_intervallo={cornice_temporale.codice}
                date_intervallo={[ cornice_temporale.da, cornice_temporale.a ]}
                on:change={aggiorna_cornice_temporale}/>
          </div>
          
          {#if controllo_attivo == "tabella"}
            <div class="contenitore-tabella-controllo-dati">
              <Tabella
                  {colonne}
                  {valore_cella}
                  righe={dati}
                  dizionario={t}
                  intestazione_fissa={true}
                  let:riga={riga}
                  let:colonna={colonna}
                  let:valore={valore}>
                {#if colonna == "azioni"}
                  <Bottone
                      piccolo={true}
                      variante="primario-invertito"
                      on:click={evento => richiedi_dettagli(riga.data)}>
                    <Icona tipo="ricerca"/>
                  </Bottone>
                {:else}
                  {valore_cella(riga, colonna, valore)}
                {/if}
              </Tabella>
            </div>
          {:else}
            <Statistica
                sessione={$sessione_corrente}
                statistica={statistica}
                esportabile={false}
                configurabile={false}/>
          {/if}
        </div>
      {/if}

      <Modale
          scorribile={false}
          richiudibile={true}
          schermo_intero={true}
          titolo={t.modale.titolo[$l]}
          bind:aperta={modale_dettagli_soggiorni_aperta}>
        <div class="modale-contenuto">
          {#if in_caricamento_dettagli}
            <Caricatore/>
          {:else}
            <TabellaSoggiorni
                {data}
                {soggiorni}
                struttura={$sessione_corrente}/>
          {/if}
        </div>
      </Modale>
    </div>
  </GestioneStruttura>
</template>

<style>
  :global(div.controllo-dati div.azioni) {
    display:          flex;
    justify-content:  space-between;
  }
  :global(div.controllo-dati div.azioni > *) {
    width: 230px;
  }
  :global(div.controllo-dati div.statistica div.intestazione) {
    display:          none;
  }
  :global(div.controllo-dati div.inserimento.intervallo-date) {
    margin-bottom:    0;
  }
  :global(div.controllo-dati div.inserimento.intervallo-date div.contenitore-calendari) {
    z-index:          10;
  }
  :global(div.controllo-dati div.modale-contenuto) {
    display:          flex;
  }
  :global(div.contenitore-tabella-controllo-dati) {
    height: calc(100vh - 300px);
  }
  :global(div.contenitore-tabella-controllo-dati div.contenitore-tabella) {
    height: 100%;
  }
</style>

<script>
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Icona from "@/base/componenti/Icona.svelte"
  import IntervalloDate from "@/base/componenti/inserimenti/date/Intervallo.svelte"
  import Modale from "@/base/componenti/Modale.svelte"
  import Selezione from "@/base/componenti/Selezione.svelte"
  import Statistica from "@/base/componenti/Statistica.svelte"
  import Tabella from "@/base/componenti/Tabella.svelte"
  import TabellaSoggiorni from "@/base/componenti/tabelle/Soggiorni.svelte"
  import GestioneStruttura from "@/portale/componenti/GestioneStruttura.svelte"
  import StatoAzioniStruttura from "@/portale/componenti/StatoAzioniStruttura.svelte"
  import { onDestroy } from "svelte"
  import { tick } from "svelte"
  import { sessione_corrente } from "@/portale/sorgenti/sessione"
  import { localizzazione as l } from "@/base/sorgenti/svuby"

  const t = avvia_localizzazione()
  const cornice_predefinita = { da: Date.today.sub(365, "days"), a: Date.today }
  const possibili_controlli = [ "generale", "trattamento", "canale", "mercato", "nazionalità", "tabella" ]

  let modale_dettagli_soggiorni_aperta = false
  let in_caricamento_dettagli = false
  let in_caricamento = true
  let cornice_memorizzata = deposito_locale.prendi("cornice_controllo_dati", null)
  let cornice_temporale = cornice_memorizzata != null ? cornice_memorizzata : cornice_predefinita
  let colonne = [ "data", "disponibili", "vendute", "ricavo", "azioni" ]
  let data = Date.today
  let serie = []
  let dati = []
  let soggiorni = []
  let controllo_attivo = "generale"
  let statistica = null

  $: opzioni_controllo = possibili_controlli.filter(controllo => {
    if (controllo == "trattamento" && $sessione_corrente.tipo != "hotel") return false

    return true
  }).map(controllo => {
    return { valore: controllo, testo:t[controllo][$l]}
  })

  $: parametri_statistica = {
    generale: {
      sessione: $sessione_corrente,
      gruppi: [ $sessione_corrente ],
      granularità: "giornaliera",
      cornice_temporale: cornice_temporale,
      mostra_etichette_valori: true,
      indicatori: $sessione_corrente.tipo == "hotel" ? [
        { codice: "occupazione" },
        { codice: "ricavo_complessivo" },
        { codice: "revpor" },
        { codice: "adr" },
      ] : [
        { codice: "occupazione" },
        { codice: "ricavo_complessivo" },
        { codice: "ricavo_medio_unità", dettagliato_per: "prodotto" },
      ]
    },
    trattamento: {
      sessione: $sessione_corrente,
      gruppi: [ $sessione_corrente ],
      granularità: "mensile",
      cornice_temporale: cornice_temporale,
      mostra_etichette_valori: true,
      indicatori: [
        { codice: "occupazione", dettagliato_per: "trattamento" },
        { codice: $sessione_corrente.tipo == "hotel" ? "revpor" : "ricavo_medio_unità", dettagliato_per: "trattamento" },
      ]
    },
    canale: {
      sessione: $sessione_corrente,
      gruppi: [ $sessione_corrente ],
      granularità: "mensile",
      cornice_temporale: cornice_temporale,
      mostra_etichette_valori: true,
      indicatori: [
        { codice: "occupazione", dettagliato_per: "canale" },
        { codice: $sessione_corrente.tipo == "hotel" ? "revpor" : "ricavo_medio_unità", dettagliato_per: "canale" },
      ]
    },
    mercato: {
      sessione: $sessione_corrente,
      gruppi: [ $sessione_corrente ],
      granularità: "mensile",
      cornice_temporale: cornice_temporale,
      mostra_etichette_valori: true,
      indicatori: [
        { codice: "occupazione", dettagliato_per: "mercato" },
        { codice: $sessione_corrente.tipo == "hotel" ? "revpor" : "ricavo_medio_unità", dettagliato_per: "mercato" },
      ]
    },
    nazionalità: {
      sessione: $sessione_corrente,
      gruppi: [ $sessione_corrente ],
      granularità: "mensile",
      cornice_temporale: cornice_temporale,
      mostra_etichette_valori: true,
      indicatori: [
        { codice: "occupazione", dettagliato_per: "nazionalità" }
      ]
    },
    tabella: {
      sessione: $sessione_corrente,
      gruppi: [ $sessione_corrente ],
      granularità: "giornaliera",
      cornice_temporale: cornice_temporale,
      mostra_etichette_valori: false,
      indicatori: [
        { codice: $sessione_corrente.tipo == "hotel" ? "camere_vendute" : "unità_vendute",
          segmentazione: { totale: true } },
        { codice: $sessione_corrente.tipo == "hotel" ? "camere_disponibili" : "unità_disponibili",
          segmentazione: { totale: true } },
        { codice: "ricavo_complessivo", segmentazione: { totale: true } }
      ]
    }
  }

  $: if (controllo_attivo == "tabella" && !in_caricamento) {
    serie = statistica.serie
    dati = serie[0].punti.map((punto, indice) => {
      let data = punto[0]
      let vendute = punto[1]
      let disponibili = serie[1].punti[indice][1]
      let ricavo = serie[2].punti[indice][1]

      return { data, vendute, disponibili, ricavo }
    })
  }

  ////
  // Cambia tipo controllo
  async function cambia_controllo(evento) {
    in_caricamento = true
    
    controllo_attivo = evento.detail.valore

    richiedi_dati()
  }

  ////
  // Richiede i dati.
  async function richiedi_dati() {
    in_caricamento = true

    let parametri = {
      sessione: $sessione_corrente,
      gruppi: [ $sessione_corrente ],
      granularità: "giornaliera",
      cornice_temporale: cornice_temporale,
      mostra_etichette_valori: true,
      indicatori: $sessione_corrente.tipo == "hotel" ? [
        { codice: "occupazione" },
        { codice: "ricavo_complessivo" },
        { codice: "revpor" },
        { codice: "adr" },
      ] : [
        { codice: "occupazione" },
        { codice: "ricavo_complessivo" },
        { codice: "ricavo_medio_unità", dettagliato_per: "prodotto" },
      ]
    }

    let risposta = null
    if (parametri_statistica == null) {
      risposta = await retro.chiama(
        "POST",
        retro.estremi.portale.statistiche,
        parametri)
    }else {
      risposta = await retro.chiama(
        "POST",
        retro.estremi.portale.statistiche,
        parametri_statistica[controllo_attivo])
    }
    if (risposta.status == 500) return in_caricamento = false

    statistica = risposta.contenuto.statistica

    await tick()

    in_caricamento = false
  }

  ////
  // Richiede i dettagli.
  async function richiedi_dettagli(data_dettaglio) {
    modale_dettagli_soggiorni_aperta = true
    in_caricamento_dettagli = true

    let risposta = await retro.chiama(
      "GET",
      retro.estremi.portale.soggiorni,
      { id_struttura: $sessione_corrente._id, data: data_dettaglio.to_date.format("%Y-%m-%d") })

    data = data_dettaglio.to_date
    soggiorni = risposta.contenuto.soggiorni

    in_caricamento_dettagli = false
  }

  ////
  // Aggiorna cornice temporale.
  function aggiorna_cornice_temporale(evento) {
    let date_intervallo = evento.detail.date_intervallo
    let codice_intervallo = evento.detail.codice_intervallo

    if (codice_intervallo != null) {
      cornice_temporale.codice = codice_intervallo
      delete cornice_temporale.da
      delete cornice_temporale.a
    } else {
      delete cornice_temporale.codice
      cornice_temporale.da = date_intervallo[0].format("%Y-%m-%d")
      cornice_temporale.a = date_intervallo[1].format("%Y-%m-%d")
    }

    deposito_locale.deposita("cornice_controllo_dati", cornice_temporale)
    richiedi_dati()
  }

  ////
  // Valore cella.
  function valore_cella(riga, colonna, valore, indice_riga, indice_colonna) {
    switch (colonna) {
      case "data":
        if (valore == null) return "-"
        return valore.to_date.format("%d %B %Y")
      case "vendute":
      case "disponibili":
        if (valore == null) return "-"
        return valore.format({ unit: "pura", reduce: true })
      case "ricavo":
        if (valore == null) return "-"
        return valore.format({ unit: serie[2].unità, valuta: serie[2].valuta, reduce: true })
      default:
        return valore || "-"
    }
  }

  // Al cambio sessione, richiede le dati.
  const scollega = sessione_corrente.su_cambio(richiedi_dati)
  // Quando distrutto, scollega i cambi.
  onDestroy(scollega)

  // Avvia localizzazione.
  function avvia_localizzazione() {
    return {
      tipo_controllo:{
        it: `Tipo controllo`,
        en: `Control type`,
        de: `Steuerungstyp`
      },
      generale:{
        it: `Generale`,
        en: `General`,
        de: `Allgemein`
      },
      trattamento: {
        it: `Trattamento`,
        en: `Rate`,
        de: `Rate`
      },
      canale: {
        it: `Canale`,
        en: `Channel`,
        de: `Kanal`
      },
      mercato: {
        it: `Mercato`,
        en: `Market`,
        de: `Markt`
      },
      nazionalità: {
        it: `Nazione`,
        en: `Nationality`,
        de: `Nationalität`
      },
      tabella:{
        it: `Tabella`,
        en: `Table`,
        de: `Tabelle`
      },
      etichetta_periodo: {
        it: `Periodo`,
        en: `Period`,
        de: `Zeitraum`
      },
      modale: {
        titolo: {
          it: `Dettaglio Soggiorni`,
          en: `Stay Details`,
          de: `Aufenthalts-Details`
        }
      },
      colonne: {
        data: {
          it: `Data`,
          en: `Date`,
          de: `Daten`
        },
        disponibili: {
          it: `Disponibili`,
          en: `Available`,
          de: `Verfügbar`
        },
        vendute: {
          it: `Vendute`,
          en: `Sold`,
          de: `Verkauft`
        },
        ricavo: {
          it: `Ricavo`,
          en: `Revenue`,
          de: `Umsatz`
        },
        azioni: {
          it: `Dettagli`,
          en: `Details`,
          de: `Details`
        }
      }
    }
  }
</script>
