<template>
  <div
    class="titolo-secondario"
    class:no-imbottitura={no_imbottitura}>
    <h3><slot></slot></h3>

    <slot name="azioni"></slot>
  </div>
</template>

<style>
:global(div.titolo-secondario){
  margin-bottom:    12px;
  display:          flex;
  align-items:      center;
  justify-content:  space-between;
  padding:          0 var(--padding-base-main);
}
:global(div.titolo-secondario.no-imbottitura){
  padding:          0;
}

:global(div.titolo-secondario h3){
  margin:       0;
  font-size:    16px;
  font-weight:  bold;
  line-height:  30px;
  color:        var(--colore-titolo);
}

</style>

<script>
  export let no_imbottitura = false
</script>