<template>
  {#if in_caricamento}
    <Caricatore/>
  {:else}
    <div class="modulo-concorrente">
      <Modulo 
          esteso={true}
          metodo={metodo}
          indirizzo={indirizzo}
          parametri={parametri}
          gestore={gestore_risposta}
          validatore={validatore_modulo}
          bind:in_salvataggio={in_salvataggio}
          bind:errori={errori}>
        {#if solo_rinomina}
          <Inserimento
              errore={errori.nome}
              etichetta={t.nome[$l]}
              bind:valore={concorrente.nome}/>
        {:else}
          <div class="modifica-strutture">
            <div class="contenitore-strutture-selezionabili">
              <Inserimento
                  cancellabile={true}
                  etichetta={t.etichetta_ricerca[$l]}
                  bind:valore={stringa_ricerca}
                  on:input={rimbalza_ricerca}/>
              <div class="strutture-selezionabili">
                {#if in_ricerca}
                  <Caricatore/>
                {:else}
                  {#each strutture_selezionabili as struttura, indice (struttura._id)}
                    <StrutturaConcorrente
                        {struttura}
                        aggiungibile={true}
                        disabilitato={concorrente.id_strutture.length >= 10}
                        on:aggiunta={aggiungi_struttura}/>
                  {/each}
                {/if}
              </div>
            </div>
            <div class="contenitore-strutture-selezionate">
              <label for="">
                <span>{t.strutture_selezionate[$l]}</span>
                <b>{concorrente.id_strutture.length} / 10</b>
              </label>
              <div class="strutture-selezionate">
                {#each strutture_selezionate as struttura, indice (struttura._id)}
                  <StrutturaConcorrente
                      {struttura}
                      rimuovibile={true}
                      on:rimossa={rimuovi_struttura}/>
                {/each}
              </div>
            </div>
          </div>
        {/if}
        <div class="azioni">
          <Bottone
              tipo="invio"
              disabilitato={concorrente.id_strutture.length < 5 || in_salvataggio}>
            {#if in_salvataggio}
              {t.salvataggio_in_corso[$l]}
            {:else}
              {t.salva[$l]}
            {/if}
          </Bottone>
        </div>
      </Modulo>
    </div>
  {/if}

  <Messaggio
      di_errore={true}
      senza_scadenza={true}
      bind:visualizzato={messaggio_errore_visualizzato}>
    {messaggio_errore}
  </Messaggio>
</template>

<style>
  :global(div.modulo-concorrente),
  :global(div.modulo-concorrente > form) {
    height:         100%;
  }
  :global(div.modulo-concorrente div.modifica-strutture) {
    display:        flex;
    flex-grow:      1;
    overflow:       hidden;
  }
  :global(div.modulo-concorrente div.contenitore-strutture-selezionabili),
  :global(div.modulo-concorrente div.contenitore-strutture-selezionate) {
    display:        flex;
    max-width:      50%;
    flex-direction: column;
    flex-grow:      1;
  }
  :global(div.modulo-concorrente div.strutture-selezionabili),
  :global(div.modulo-concorrente div.strutture-selezionate) {
    flex-grow:      1;
    overflow:       auto;
    padding-right:  7px;
  }
  :global(div.modulo-concorrente div.contenitore-strutture-selezionate) {
    padding:        14px 0 0 14px;
    margin-top:     20px;
  }
  :global(div.modulo-concorrente div.contenitore-strutture-selezionate > label) {
    display:          flex;
    justify-content:  space-between;
    color:            var(--colore-testo-leggero);
    font-weight:      500;
    padding:          0 14px 0 7px;
    margin-bottom:    29px;
    cursor:           default;
    text-transform:   uppercase;
  }
</style>

<script>
  import Modulo from "@/base/componenti/Modulo.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Messaggio from "@/base/componenti/Messaggio.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import StrutturaConcorrente from "@/base/componenti/moduli/concorrente/Struttura.svelte"
  import { onMount } from "svelte"
  import { createEventDispatcher } from "svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { sessione_corrente } from "@/portale/sorgenti/sessione"

  export let concorrente    = {}
  export let solo_rinomina  = false

  const propaga = createEventDispatcher()
  const t = avvia_localizzazione(dizionario_concorrente)

  $: indirizzo  = concorrente._id != null ?
    retro.estremi.portale.concorrente(concorrente._id) :
    retro.estremi.portale.concorrenti
  $: metodo     = concorrente._id != null ?
    "PATCH" : "POST"
  $: parametri  = solo_rinomina ?
    { concorrente: { nome: concorrente.nome } } :
    { concorrente: concorrente, id_struttura: concorrente.id_struttura }

  let errori = { nome: null }
  let messaggio_errore_visualizzato = false
  let messaggio_errore = ""
  let stringa_ricerca = ""
  let in_ricerca = true
  let in_caricamento = true
  let in_salvataggio = false
  let strutture = []
  let strutture_selezionabili = []
  let strutture_selezionate = []

  // Strutture filtrate sulla ricerca.
  $: espressione_ricerca = new RegExp(`.*${stringa_ricerca}.*`, "i")

  ////
  // Validazione del modulo.
  function validatore_modulo() {
    if (concorrente.nome == null || concorrente.nome == "")
      errori.nome = t.validazioni.richiesto[$l]
  }

  ////
  // Gestione della gestione del modulo.
  function gestore_risposta(risposta) {
    if (risposta.riuscita) {
      concorrente = risposta.contenuto.concorrente
      propaga("change", { concorrente })
    } else {
      switch (risposta.stato) {
        case 409:
          imposta_messaggio_errore(risposta.contenuto)
          break
        default:
          messaggio_errore = t.errori.spiacenti[$l]
          break
      }
      messaggio_errore_visualizzato = true
      in_salvataggio = false
    }
  }

  ////
  // Imposta il messaggio di errore.
  function imposta_messaggio_errore(contenuto) {
    switch (contenuto.errore) {
      case "modifiche_esaurite":
        messaggio_errore = t.esaurite_modifiche[$l]
        break
      case "non_abbastanza_modifiche":
        messaggio_errore = t.almeno_due_modifiche[$l]
        break
      case "strutture_della_stessa_catena":
        messaggio_errore = t.no_stessa_catena[$l]
        break
      case "simile":
        messaggio_errore = `${t.troppo_simile[$l]} (${contenuto.a.nome})`
        break
      default:
        messaggio_errore = t.errori.spiacenti[$l]
    }
  }

  ////
  // Richiede le strutture.
  async function richiedi_strutture() {
    if (solo_rinomina) return in_caricamento = false

    in_caricamento = true

    let risposta = await retro.chiama("GET",
      retro.estremi.portale.struttura(concorrente.id_struttura))

    if (risposta.riuscita) {
      strutture = risposta.contenuto.strutture.map(struttura => {
        let stringa_ricerca = [
          struttura.nome,
          struttura.città,
          struttura.indirizzo ].compact.join(" ")
        return { ...struttura, stringa_ricerca }
      })
      filtra_strutture()
      in_caricamento = false
    } else {
      messaggio_errore = t.errore_caricamento[$l]
      messaggio_errore_visualizzato = true
    }
  }

  ////
  // Aggiunge la struttura al concorrente.
  function aggiungi_struttura(evento) {
    let struttura_aggiunta = evento.detail.struttura
    concorrente.id_strutture = [ ...concorrente.id_strutture, struttura_aggiunta._id ]
    filtra_strutture()
  }

  ////
  // Rimuove la struttura dal concorrente.
  function rimuovi_struttura(evento) {
    let struttura_rimossa = evento.detail.struttura
    concorrente.id_strutture = concorrente.id_strutture.filter(id_struttura => {
      return id_struttura != struttura_rimossa._id
    })
    filtra_strutture()
  }

  ////
  // Filtra le strutture in base alla ricerca.
  function filtra_strutture() {
    strutture_selezionabili = strutture.filter(struttura => {
      if (struttura._id == $sessione_corrente._id) return false // DA FARE BACKEND? ``
      if (concorrente.id_strutture.include(struttura._id)) return false
      return struttura.stringa_ricerca.match(espressione_ricerca) != null
    })
    strutture_selezionate = strutture.filter(struttura => {
      return concorrente.id_strutture.include(struttura._id)
    })
    in_ricerca = false
  }

  ////
  // Rimbalza la ricerca.
  function rimbalza_ricerca() {
    clearTimeout(in_ricerca)
    in_ricerca = setTimeout(filtra_strutture, 500)
    strutture_selezionabili = []
  }

  // Quando pronto, richiede le strutture.
  onMount(richiedi_strutture)
</script>

<script context="module">
  export const dizionario_concorrente = {
    nome: {
      it: `Nome`,
      en: `Name`,
      de: `Name`
    },
    etichetta_ricerca: {
      it: `Cerca struttura`,
      en: `Find property`,
      de: `Betrieb finden`
    },
    strutture_selezionate: {
      it: `Strutture selezionate`,
      en: `Selected properties`,
      de: `Ausgewählte Betriebe`
    },
    esaurite_modifiche: {
      it: `Esaurite le modifiche a disposizione.`,
      en: `Available changes are over.`,
      de: `Keine verfügbaren Änderungen.`
    },
    almeno_due_modifiche: {
      it: `Effettuare almeno due modifiche alle strutture.`,
      en: `Make at least two changes to the properties.`,
      de: `Ändern Sie mindestens zwei Eigenschaften der Betriebe.`
    },
    no_stessa_catena: {
      it: `Non è possibile selezionare strutture della stessa catena.`,
      en: `You cannot select properties of the same group.`,
      de: `Sie können keine Betriebe derselben Gruppe wählen.`
    },
    troppo_simile: {
      it: `Troppo simile ad un altro gruppo di competitor.`,
      en: `Too similar to another competitive set.`,
      de: `Zu ähnlich zu einer anderen Gruppe von Wettbewerbern.`
    },
    errore_caricamento: {
      it: `Spiacenti, si è verificato un errore nel caricamento dei dati.`,
      en: `Sorry, there was an error while loading data.`,
      de: `Beim Laden der Daten ist ein Fehler aufgetreten.`
    }
  }
</script>