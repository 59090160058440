<template>
  <section id="messaggio-accesso">
    <div class="contenitore">
      <slot/>
      <slot name="loghi">
        <div class="loghi-contenitore"><img src="{window.risolviImg(logo)}" alt="HBenchmark Logo" height="52"></div>
      </slot>
      <slot name="testi">
        <h1>
          {#if titolo}
            {titolo}
          {:else}
            {t.titolo[$l]}
          {/if}
        </h1>

        <p>{t.testo_1[$l]}</p>

        <Lista lista={lista_testi}/>
      </slot>
    </div>

    <div class="footer">
      <small>
        &copy; {Date.today.year} HBenchmark s.r.l. - P.IVA03998320240
        <a href="{collegamenti_politiche.riserbo[$l]}" target="_blank">
          Privacy Policy
        </a>
        -
        <a href="{collegamenti_politiche.biscottini[$l]}" target="_blank">
          Cookie Policy
        </a>
      </small>
    </div>
  </section>
</template>

<style>
  :global(section#messaggio-accesso) {
    width:            55%;
    color:            var(--colore-titolo);
    position:         relative;
    padding-left:     9%;
    padding-right:    20%;
    left:             0;
    top:              0;
    display:          flex;
    flex-direction:   column;
    justify-content:  flex-start;
    background-color: var(--colore-bianco);
    min-height:       100%;
  }
  :global(section#messaggio-accesso > div.contenitore) {
    position:         relative;
    display:          flex;
    flex-direction:   column;
    height:           100%;
  }

  :global(section#messaggio-accesso > div.contenitore > h1) {
    margin-top:     0;
    margin-bottom:  40px;
  }

  :global(section#messaggio-accesso > .footer) {
    position:   absolute;
    bottom:     15px;
    transform:  translateY(-50%);
  }

  :global(div#accesso div[slot="valore"] div.opzione-localizzatore) {
    color:            var(--colore-testo);
  }
  :global(div#accesso div[slot="opzione"] div.opzione-localizzatore) {
    color:            var(--colore-testo);
  }
  :global(div#accesso p) {
    margin:           0 0 14px 0;
  }
  :global(div#accesso div.loghi-contenitore){
    padding-top:      40px;
    margin-bottom:    30px;
    height:           fit-content;
  }
</style>

<script>

  import Lista from "@/base/componenti/Lista.svelte"
  import logo from "@/base/immagini/logo-completo.svg"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  export let titolo = null

  const t = avvia_localizzazione(dizionario_messaggio)

  $: lista_testi = [
    {
      testo: t.testo_lista_1[$l]
    },{
      testo: t.testo_lista_2[$l]
    },{
      testo: t.testo_lista_3[$l]
    }
  ]

  const collegamenti_politiche = {
    riserbo: {
      it: `https://www.iubenda.com/privacy-policy/81333460`,
      en: `https://www.iubenda.com/privacy-policy/40732172`,
      de: `https://www.iubenda.com/privacy-policy/74782468`
    },
    biscottini: {
      it: `https://www.iubenda.com/privacy-policy/81333460/cookie-policy`,
      en: `https://www.iubenda.com/privacy-policy/40732172/cookie-policy`,
      de: `https://www.iubenda.com/privacy-policy/74782468/cookie-policy`
    }
  }
</script>

<script context="module">
  export const dizionario_messaggio = {
    titolo: {
      it: `Partecipa al progetto HBenchmark`,
      en: `Take part in the HBenchmark project`,
      de: `Nehmen Sie am HBenchmark-Projekt teil`
    },
    testo_1: {
      it: `Grazie ad HBenchmark potrai:`,
      en: `Thanks to HBenchmark you can:`,
      de: `Dank HBenchmark können Sie:`
    },
    testo_lista_1: {
      it: `<strong>Conoscere</strong> prezzi medi del mercato per ogni tipologia di prodotto (storico e previsionale)`,
      en: `<strong> Know </strong> average market prices for each type of product (historical and forecast)`,
      de: `<strong> Kennen </strong> durchschnittliche Marktpreise für jeden Produkttyp (historisch und prognostiziert)`
    },
    testo_lista_2: {
      it: `Avere informazioni sui <strong>prezzi e occupazione</strong> per ogni canale, segmento di mercato, nazionalità`,
      en: `Get information on <strong> prices and occupancy </strong> for each channel, market segment, nationality`,
      de: `Informieren Sie sich über <strong> Preise und Auslastung </strong> für jeden Kanal, Marktsegment und Nationalität`
    },
    testo_lista_3: {
      it: `Valutare <strong>investimenti</strong> grazie a informazioni di <strong>redditività sul settore</strong> nel mercato locale`,
      en: `Evaluate <strong> investments </strong> thanks to <strong> profitability information on the sector </strong> in the local market`,
      de: `Bewerten Sie <strong> Investitionen </strong> dank <strong> Rentabilitätsinformationen zum Sektor </strong> auf dem lokalen Markt`
    }
  }
</script>