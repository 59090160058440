const categorie = [
  "No-Star",
  "2*",
  "3*",
  "3*S",
  "4*",
  "4*S",
  "5*",
  "5*S" ]

export { categorie }
