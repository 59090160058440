<template>
  <ul class="lista">
    {#each lista as elemento}
      <li>
        <Icona tipo="fatto"></Icona>
        {@html elemento.testo}</li>
    {/each}
  </ul>
</template>

<style>
  :global(ul.lista ){
    list-style: none;
    margin:     0;
    padding:    0;
  }

  :global(ul.lista li){
    margin-left:    46px;
    position:       relative;
    padding-top:    5px;
    padding-bottom: 12px;
    line-height:    2;
    font-size:      14.3px;
  }

  :global(ul.lista li .icona){
    background:       var(--colore-primario);
    width:            30px;
    height:           30px;
    left:             -46px;
    top:              3px;
    position:         absolute;
    display:          inline-flex;
    justify-content:  center;
    align-items:      center;
    color:            white;
    border-radius:    50%;
    font-size:        18px;
    vertical-align:   middle;
  }
</style>

<script>
  import Icona from "@/base/componenti/Icona.svelte"

  /**
   * @type {{testo: string}[]}
   */
  export let lista = [];
</script>