<template>
  {#if !autenticato}
    <Rotta schermo_intero={true} richiede_autenticazione={false}>
      <div class="contenitore-registrazione">
        <div id="accesso">
          {#if $dominio == "firenze"}
            <MessaggioFirenze/>
          {:else if $dominio == "bergamo"}
            <MessaggioBergamo/>
          {:else if $dominio == "genova"}
            <MessaggioGenova/>
          {:else if $dominio == "livigno"}
            <MessaggioLivigno/>
          {:else if $dominio == "padova"}
            <MessaggioPadova/>
          {:else if $dominio == "trentino"}
            <MessaggioTrentino/>
          {:else if $dominio == "veneto"}
            <MessaggioVeneto/>
          {:else if $dominio == "confcommercio-verona"}
            <MessaggioConfcommercioVerona/>
          {:else}
            <Messaggio/>
          {/if}
          <Sfondo dominio={$dominio}/>
          <Modulo/>
        </div>
      </div>
    </Rotta>
  {/if}
</template>

<style>
  :global(div.contenitore-registrazione) {
      min-height: 100%;
      overflow: auto;
  }
  :global(div#accesso) {
      --top-margin: 19%;
      display: flex;
      align-items: stretch;
      min-height: 100%;
  }
</style>

<script>
  import Rotta from "@/base/componenti/Rotta.svelte"
  import Sfondo from "@/base/componenti/accesso/Sfondo.svelte"
  import Modulo from "@/base/componenti/moduli/Registrazione.svelte"
  import Messaggio from "@/base/componenti/accesso/Messaggio.svelte"
  import MessaggioFirenze from "@/portale/componenti/accesso/messaggi_per_dominio/Firenze.svelte"
  import MessaggioBergamo from "@/portale/componenti/accesso/messaggi_per_dominio/Bergamo.svelte"
  import MessaggioLivigno from "@/portale/componenti/accesso/messaggi_per_dominio/Livigno.svelte"
  import MessaggioGenova from "@/portale/componenti/accesso/messaggi_per_dominio/Genova.svelte"
  import MessaggioPadova from "@/portale/componenti/accesso/messaggi_per_dominio/Padova.svelte"
  import MessaggioTrentino from "@/portale/componenti/accesso/messaggi_per_dominio/Trentino.svelte"
  import MessaggioVeneto from "@/portale/componenti/accesso/messaggi_per_dominio/Veneto.svelte"
  import MessaggioConfcommercioVerona from "@/portale/componenti/accesso/messaggi_per_dominio/ConfcommercioVerona.svelte"
  import { utente_corrente } from "@/base/sorgenti/svuby"
  import { dominio } from "@/portale/sorgenti/dominio"
  import { onMount } from "svelte"

  // Parametri Trentino Marketing.
  const PARAMETRI_TRENTINO_MARKETING = [
    "sid",
    "did",
    "gid",
    "ref_id",
    "nome",
    "cognome",
    "email",
    "emailStruttura",
    "nomeStruttura",
    "indirizzoStruttura",
    "cittàStruttura",
    "provinciaStruttura",
    "capStruttura",
    "telefonoStruttura" ]
  const parametri_rotta = navigatore.parametri()

  let autenticato = $utente_corrente != null

  // Imposta i parametri Trentino Marketing.
  let parametri_trentino_marketing = {}
  PARAMETRI_TRENTINO_MARKETING.forEach(parametro => {
    if (parametri_rotta[parametro] != null)
      parametri_trentino_marketing[parametro] = parametri_rotta[parametro]
  })
  if (Object.keys(parametri_trentino_marketing).length > 0)
    deposito_locale.deposita("parametri_trentino_marketing", parametri_trentino_marketing)

  ////
  // Verifica che non ci sia alcun utente autenticato.
  function verifica_utente_non_autenticato() {
    if (!autenticato) return
    navigatore.verso("/")
  }

  ////
  // Quando pronto, verifica che non ci sia alcun utente corrente.
  // Se invece esiste un utente autenticato, rimanda alla pagina principale.
  onMount(verifica_utente_non_autenticato)
</script>
