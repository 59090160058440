<template>
  {#if mostra_legenda_serie}
    <LegendaSerie
        {indicatore}
        {statistica}
        {serie}
        on:evidenzia={attiva_disattiva_evidenziazione}
        on:mostra={mostra_serie}
        on:nascondi={nascondi_serie}/>
  {/if}

  {#if visualizza_come == "colonna"}
    <Colonna
        bind:pronto={pronto}
        bind:grafico={grafico}
        bind:opzioni_highcharts={opzioni_highcharts}
        {modalità_scura}/>
  {:else if visualizza_come == "colonna_per_categorie"}
    <ColonnaPerCategorie
        bind:pronto={pronto}
        bind:grafico={grafico}
        bind:opzioni_highcharts={opzioni_highcharts}
        {modalità_scura}/>
  {:else if visualizza_come == "torta"}
    <Torta
        bind:pronto={pronto}
        bind:grafico={grafico}
        bind:opzioni_highcharts={opzioni_highcharts}
        {modalità_scura}/>
  {:else if visualizza_come == "mappamondo"}
    <!--MappaMondo-->
    <Torta
        bind:pronto={pronto}
        bind:grafico={grafico}
        bind:opzioni_highcharts={opzioni_highcharts}
        {modalità_scura}/>
  {:else if visualizza_come == "tabella"}
    <Tabella
        bind:pronto={pronto}
        bind:grafico={grafico}/>
  {:else}
    <Linea
        bind:pronto={pronto}
        bind:grafico={grafico}
        bind:opzioni_highcharts={opzioni_highcharts}
        {modalità_scura}/>
  {/if}

  {#if statistica.tipo_grafico != "tabella"}
    <div
        id={id_univoco}
        class="grafico"
        bind:this={elemento_grafico}/>
  {/if}

  <StruttureContribuenti
      {statistica}
      {serie}/>
</template>

<style>        
  :global(div.grafico) {
    height:      427px;
  }
  :global(.highcharts-credits) {
    display:     none;
  }
  :global(div.grafico img.meteo-grafico){
    height:      1.5rem;
    width:       1.5rem;
  }
</style>

<script>
  import Highcharts from "highcharts"
  import LegendaSerie from "@/base/componenti/statistiche/LegendaSerie.svelte"
  import StruttureContribuenti from "@/base/componenti/statistiche/StruttureContribuenti.svelte"
  import Linea from "@/base/componenti/statistiche/grafici/Linea.svelte"
  import Colonna from "@/base/componenti/statistiche/grafici/Colonna.svelte"
  import ColonnaPerCategorie from "@/base/componenti/statistiche/grafici/ColonnaPerCategorie.svelte"
  import Torta from "@/base/componenti/statistiche/grafici/Torta.svelte"
  import MappaMondo from "@/base/componenti/statistiche/grafici/MappaMondo.svelte"
  import Tabella from "@/base/componenti/statistiche/grafici/Tabella.svelte"
  import { tick } from "svelte"
  import { onMount } from "svelte"
  import { onDestroy } from "svelte"
  import { localizzazione } from "@/base/sorgenti/svuby"

  export let statistica = {}
  export let indicatore = {}
  export let serie = []
  export let anteprima = false
  export let modalità_scura = false

  let atto = anteprima ? "anteprima" : "finale"
  let id_univoco = `${statistica._id}_${serie[0].indice_indicatore}_${atto}`
  let grafico = null
  let grafico_highcharts = null
  let opzioni_highcharts = {}
  let pronto = false
  let elemento_grafico = null
  let visualizza_come = null

  const granularità_colonnari = [ "mensile", "annuale" ]
  const scollega = localizzazione.su_cambio(disegna_grafico)

  $: mostra_legenda_serie = (statistica.cornice_temporale.periodi.length > 1 ||statistica.gruppi.length > 1 ||
    indicatore.dettagliato) && statistica.tipo_grafico != "tabella"

  ////
  // Determina il tipo di visualizzazione del grafico.
  async function determina_tipo_visualizzazione() {
    if (elemento_grafico == null && statistica.tipo_grafico != "tabella") return
    
    visualizza_come = "linea"
    if (granularità_colonnari.include(statistica.granularità))
      visualizza_come = "colonna"
    if (statistica.granularità == "totale")
      visualizza_come = "colonna_per_categorie"
    if (statistica.granularità == "totale" && indicatore.dettagliato)
      visualizza_come = "torta"
    if (statistica.granularità == "totale" && indicatore.dettagliato_per == "nazionalità")
      visualizza_come = "mappamondo"
    if (statistica.tipo_grafico == "tabella")
      visualizza_come = "tabella"

    console.log("Grafico".colore("verde"), `: nuovo grafico <${visualizza_come.capitalize}>.`)
    
    pronto = false
    await tick()
    
    grafico = {
      statistica: statistica,
      indicatore: indicatore,
      serie: serie }
    }

  ////
  // Disegna il grafico.
  function disegna_grafico(arg) {
    if (grafico == null) return
    if (elemento_grafico == null) return
    if (elemento_grafico != null) distruggi_grafico()

    console.log("Grafico".colore("verde"), ": disegno grafico.")

    imposta_colori_etichette()
    opzioni_highcharts.legend = { enabled: false }
    grafico_highcharts = Highcharts.chart(id_univoco, opzioni_highcharts)
  }

  ////
  // Distrugge il grafico.
  function distruggi_grafico(arg) {
    if (grafico == null || grafico_highcharts == null) return
    grafico_highcharts.destroy()
  }

  ////
  // Scollega cambio localizzazione e distrugge grafico.
  function scollega_e_distruggi() {
    scollega()
    distruggi_grafico()
  }

  ////
  // Imposta i colori delle etichette.
  function imposta_colori_etichette() {
    if (grafico == null) return
    if (opzioni_highcharts.xAxis != null && opzioni_highcharts.xAxis.labels != null)
      opzioni_highcharts.xAxis.labels.style = { color: "var(--colore-testo-leggero)" }
    if (opzioni_highcharts.yAxis != null && opzioni_highcharts.yAxis.labels != null)
      opzioni_highcharts.yAxis.labels.style = { color: "var(--colore-testo-leggero)" }
  }

  ////
  // Mostra la serie sul grafico
  function mostra_serie(evento) {
    let indice_specifico
    let indice_serie_per_periodo
    let serie_da_mostrare = {}

    switch (visualizza_come) {
      case "torta":
        indice_specifico = grafico_highcharts.series[0].data.findIndex(punto => punto.indice == evento.detail.indice)
        grafico_highcharts.series[0].data[indice_specifico].setVisible(true)
        
        break;
      case "colonna_per_categorie":
      case "mappamondo":
        serie_da_mostrare = grafico.serie.find(serie => serie.indice == evento.detail.indice)
        if (grafico.indicatore.dettagliato)
          indice_serie_per_periodo = grafico_highcharts.series.findIndex(serie => {
            return serie.userOptions.indice_periodo == serie_da_mostrare.indice_periodo &&
              serie.userOptions.name == serie_da_mostrare.segmento
          })          
        else indice_serie_per_periodo = grafico_highcharts.series.findIndex(serie => {
            return serie.userOptions.indice_periodo == serie_da_mostrare.indice_periodo})

        indice_specifico = grafico_highcharts.series[indice_serie_per_periodo].data.findIndex(punto => {
          return punto.indice == evento.detail.indice})

        grafico_highcharts.series[indice_serie_per_periodo].points[indice_specifico].update({
          ...grafico_highcharts.series[indice_serie_per_periodo].points[indice_specifico],
          y: serie_da_mostrare.punti[0][1],
        })

        break;
    
      default:
        let indice_serie = grafico_highcharts.series.findIndex(serie => serie.userOptions.indice == evento.detail.indice)
        grafico_highcharts.series[indice_serie].show()
        break;
    }
  }
  
  ////
  // Mostra la serie sul grafico
  function nascondi_serie(evento) {
    let indice_specifico
    let indice_serie_per_periodo
    let serie_da_nascondere = {}

    switch (visualizza_come) {
      case "torta":
        indice_specifico = grafico_highcharts.series[0].data.findIndex(punto => punto.indice == evento.detail.indice)
        grafico_highcharts.series[0].data[indice_specifico].setVisible(false)
        
        break;
      case "colonna_per_categorie":
      case "mappamondo":
        serie_da_nascondere = grafico.serie.find(serie => serie.indice == evento.detail.indice)
        if (grafico.indicatore.dettagliato)
          indice_serie_per_periodo = grafico_highcharts.series.findIndex(serie => {
            return serie.userOptions.indice_periodo == serie_da_nascondere.indice_periodo &&
              serie.userOptions.name == serie_da_nascondere.segmento
          })          
        else indice_serie_per_periodo = grafico_highcharts.series.findIndex(serie => {
            return serie.userOptions.indice_periodo == serie_da_nascondere.indice_periodo})

        indice_specifico = grafico_highcharts.series[indice_serie_per_periodo].data.findIndex(punto => {
          return punto.indice == evento.detail.indice})

        grafico_highcharts.series[indice_serie_per_periodo].points[indice_specifico].update({
          ...grafico_highcharts.series[indice_serie_per_periodo].points[indice_specifico],
          y: undefined,
        })

        break;
    
      default:
        let indice_serie = grafico_highcharts.series.findIndex(serie => serie.userOptions.indice == evento.detail.indice)
        grafico_highcharts.series[indice_serie].hide()
        break;
    }
  }

  ////
  // Mostra la serie sul grafico
  function attiva_disattiva_evidenziazione(evento) {
    switch (visualizza_come) {
      case "torta":
        if (evento.detail) {
          grafico_highcharts.series[0].points.forEach((punto, indice_punto) => {
            if (punto.indice != evento.detail.indice) {
              grafico_highcharts.series[0].points[indice_punto].setState("inactive")
            }
          })
        } else {
          grafico_highcharts.series[0].points.forEach((serie, indice_punto) => {
            grafico_highcharts.series[0].points[indice_punto].setState("normal")
          })
        }  

        break;

      case "colonna_per_categorie":
      case "mappamondo":
        if (evento.detail) {
          let serie_da_evidenziare = grafico.serie.find(serie => serie.indice == evento.detail.indice)

          grafico_highcharts.series.forEach((serie, indice_serie) => {
            serie.points.forEach((punto, indice_punto) => {
              if (punto.indice != serie_da_evidenziare.indice) {
                grafico_highcharts.series[indice_serie].points[indice_punto].setState("inactive")
              }
            })
          })
        } else
          grafico_highcharts.series.forEach((serie, indice_serie) => {
            serie.points.forEach((punto, indice_punto) => {
                grafico_highcharts.series[indice_serie].points[indice_punto].setState("normal")
            })
          })

        break;

      default:
        if (evento.detail) {
          grafico_highcharts.series.forEach((serie, indice_serie) => {
            if (serie.userOptions.indice != evento.detail.indice)
              grafico_highcharts.series[indice_serie].setState("inactive")
          })
        } else {
          grafico_highcharts.series.forEach((serie, indice_serie) => {
            grafico_highcharts.series[indice_serie].setState("normal")
          })
        }

        break;
    }
  }

  // Al cambio dei parametri, ridetermina il tipo visualizzazione.
  $: if (serie != null) determina_tipo_visualizzazione()

  // Quando pronto, disegna il grafico.
  $: if (pronto == true) disegna_grafico()

  // Quando pronto, determina il tipo visualizzazione.
  onMount(determina_tipo_visualizzazione)
  // Quando distrutto, elimina il grafico.
  onDestroy(scollega_e_distruggi)
</script>
