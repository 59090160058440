<template>
  {#each migliori_7_nazionalità_più_altre as codice_nazione, indice}
    {#if indice < numero_serie_mostrate}
      <div class="elenco-serie">
        <div class="nazionalità">
          <span class="classifica">
            # {indice + 1}
          </span>
          {#if codice_nazione == "non_specificato"}
            <div class="senza-bandiera">
              <b>-</b>
            </div>
          {:else if codice_nazione == "altre"}
            <div class="altre"/>
          {:else}
            <div class="bandiera">
              <img src={bandiera_nazione(codice_nazione)} alt={codice_nazione}/>
            </div>
          {/if}
          {#if nazionalità[codice_nazione] == null}
            n.d.
          {:else}
            <span class="nome">
              {nazionalità[codice_nazione][$l]}
            </span>
          {/if}
        </div>

        {#each statistica.gruppi as gruppo, indice_gruppo}
          <div class="gruppo-serie">
            <div class="gruppo">
              {gruppo.nome}
              {#if gruppo.categorie != null && gruppo.categorie.length > 0}
                - {gruppo.categorie.join(" - ")}
              {/if}
            </div>
            {#each statistica.cornice_temporale.periodi as periodo, indice_periodo}
              {#if indice_gruppo == 0 && indice_periodo == 0}
                <Serie
                    {statistica}
                    serie={serie_per_gruppo_periodo_e_nazionalità[indice_gruppo][indice_periodo][codice_nazione]}
                    on:evidenzia
                    on:mostra
                    on:nascondi/>
              {:else}
                <Serie
                    {statistica}
                    serie={serie_per_gruppo_periodo_e_nazionalità[indice_gruppo][indice_periodo][codice_nazione]}
                    serie_comparata={serie_per_gruppo_periodo_e_nazionalità[0][0][codice_nazione]}
                    on:evidenzia
                    on:mostra
                    on:nascondi/>
              {/if}
            {/each}
          </div>
        {/each}
      </div>      
    {/if}      
  {/each}
</template>

<style>
  :global(div.legenda-serie div.nazionalità) {
    display:      flex;
    align-items:  center;
  }
  :global(div.legenda-serie div.nazionalità > span.classifica) {
    width:        28px;
  }
  :global(div.legenda-serie div.nazionalità > span.nome) {
    text-transform: uppercase;
  }
  :global(div.legenda-serie div.nazionalità > div.altre),
  :global(div.legenda-serie div.nazionalità > div.bandiera),
  :global(div.legenda-serie div.nazionalità > div.senza-bandiera) {
    width:        24px;
    height:       16px;
    margin:       0 7px;
    overflow:     hidden;
    display:      flex;
  }
  :global(div.legenda-serie div.nazionalità > div.bandiera > img) {
    width:        24px;
  }
  :global(div.legenda-serie div.nazionalità > div.senza-bandiera) {
    align-items:      center;
    justify-content:  center;
  }
  :global(div.legenda-serie div.nazionalità > div.altre) {
    background:   var(--colore-disabilitato);
  }
</style>

<script>
  import Serie from "@/base/componenti/statistiche/legenda_serie/Serie.svelte"
  import { nazionalità } from "@/base/sorgenti/svuby/nazionalità"
  import { bandiera_nazione } from "@/base/sorgenti/svuby"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { createEventDispatcher } from "svelte"

  export let indicatore = {}
  export let statistica = {}
  export let serie_per_gruppo_e_periodo = {}
  export let numero_serie_mostrate = 8
  export let numero_limite_serie = 99

  const propaga = createEventDispatcher()

  let serie_per_gruppo_periodo_e_nazionalità = {}

  $: migliori_7_nazionalità_più_altre = indicatore.dettagliato_per == "nazionalità" ?
    [ ...indicatore.migliori_7_nazionalità, "altre" ] : []
  $: numero_limite_serie = migliori_7_nazionalità_più_altre.length

  ////
  // Raggruppa le serie anche per nazionalità.
  function raggruppa_serie_per_gruppo_periodo_e_nazionalità() {
    statistica.gruppi.forEach((gruppo, indice_gruppo) => {
      serie_per_gruppo_periodo_e_nazionalità[indice_gruppo] = {}

      statistica.cornice_temporale.periodi.forEach((periodo, indice_periodo) => {
        serie_per_gruppo_periodo_e_nazionalità[indice_gruppo][indice_periodo] = {}

        let serie = serie_per_gruppo_e_periodo[indice_gruppo][indice_periodo]
        let serie_per_nazionalità = {}
        migliori_7_nazionalità_più_altre.forEach(codice_nazione => {
          serie_per_nazionalità[codice_nazione] = serie.find(serie => serie.segmento == codice_nazione)
        })
        serie_per_gruppo_periodo_e_nazionalità[indice_gruppo][indice_periodo] = serie_per_nazionalità
      })
    })
  }

  // Quando cambiano le serie, raggruppa.
  $: if (serie_per_gruppo_e_periodo != null) raggruppa_serie_per_gruppo_periodo_e_nazionalità()
</script>
