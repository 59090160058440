// Periodi.
const periodi = {
  periodo: {
    it: `Periodo`,
    en: `Period`,
    de: `Zeitraum`
  },
  corrente: {
    it: `Corrente`,
    en: `Current`,
    de: `Aktuell`
  },
  anno_precedente: {
    it: `Anno Precedente`,
    en: `Previous Year`,
    de: `Letztes Jahr`
  },
  due_anni_precedenti: {
    it: `Due Anni Precedenti`,
    en: `Two Years Ago`,
    de: `Vor zwei Jahren`
  },
  duemiladiciannove: {
    it: `Anno 2019`,
    en: `Year 2019`,
    de: `Jahr 2019`
  },
  corrente_osservato: {
    it: `Corrente al`,
    en: `Current at`,
    de: `Aktuell bei`
  },
  anno_precedente_osservato: {
    it: `Anno Precedente al`,
    en: `Previous Year at`,
    de: `Letztes Jahr an`
  },
  due_anni_precedenti_osservato: {
    it: `Due Anni Precedenti al`,
    en: `Two Years Ago at`,
    de: `Vor zwei Jahren an`
  },
  duemiladiciannove_osservato: {
    it: `Anno 2019 al`,
    en: `Year 2019 at`,
    de: `Jahr 2019 an`
  },
  domenica_su_domenica: {
    it: `Domenica su Domenica`,
    en: `Sunday to Sunday`,
    de: `Sonntag bis Sonntag`
  },
  campione_su_campione: {
    it: `Campione su Campione`,
    en: `Sample on Sample`,
    de: `Probe für Probe`
  }
}

// Intervalli predefiniti.
const intervalli_predefiniti = {
  ultimi_30_giorni: {
    nome: {
      it: `Ultimi 30 Giorni`,
      en: `Last 30 Days`,
      de: `Letzte 30 Tage` },
    date: [ Date.today.sub(30, "days").utc, Date.today.utc ]
  },
  ultimi_60_giorni: {
    nome: {
      it: `Ultimi 60 Giorni`,
      en: `Last 60 Days`,
      de: `Letzte 60 Tage` },
    date: [ Date.today.sub(60, "days").utc, Date.today.utc ]
  },
  ultimi_90_giorni: {
    nome: {
      it: `Ultimi 90 Giorni`,
      en: `Last 90 Days`,
      de: `Letzte 90 Tage` },
    date: [ Date.today.sub(90, "days").utc, Date.today.utc ]
  },
  prossimi_30_giorni: {
    nome: {
      it: `Prossimi 30 Giorni`,
      en: `Next 30 Days`,
      de: `Nächste 30 Tage` },
    date: [ Date.today.utc, Date.today.add(30, "days").utc ]
  },
  prossimi_60_giorni: {
    nome: {
      it: `Prossimi 60 Giorni`,
      en: `Next 60 Days`,
      de: `Nächste 60 Tage` },
    date: [ Date.today.utc, Date.today.add(60, "days").utc ]
  },
  prossimi_90_giorni: {
    nome: {
      it: `Prossimi 90 Giorni`,
      en: `Next 90 Days`,
      de: `Nächste 90 Tage` },
    date: [ Date.today.utc, Date.today.add(90, "days").utc ]
  },
  prossimi_365_giorni: {
    nome: {
      it: `Prossimi 365 Giorni`,
      en: `Next 365 Days`,
      de: `Nächste 365 Tage` },
    date: [ Date.today.utc, Date.today.add(365, "days").utc ]
  },
  ultimi_e_prossimi_30_giorni: {
    nome: {
      it: `Ultimi e Prossimi 30 Giorni`,
      en: `Next and Last 30 Days`,
      de: `Nächste und letzte 30 Tage` },
    date: [ Date.today.sub(30, "days").utc, Date.today.add(30, "days").utc ]
  },
  anno_in_corso: {
    nome: {
      it: `Anno In Corso`,
      en: `Current Year`,
      de: `Aktuelles Jahr` },
    date: [ Date.today.beginning_of_year.utc, Date.today.end_of_year.utc ]
  },
  da_inizio_anno: {
    nome: {
      it: `Da Inizio Anno`,
      en: `Since Beginning of Year`,
      de: `Seit Anfang des Jahres` },
    date: [ Date.today.beginning_of_year.utc, Date.today.utc ]
  }
}

const granularità_predefinite = {
  giornaliera: {
    it: `Giornaliera`,
    en: `Daily`,
    de: `Täglich`
  },
  settimanale: {
    it: `Settimanale`,
    en: `Weekly`,
    de: `Wöchentlich`
  },
  mensile: {
    it: `Mensile`,
    en: `Monthly`,
    de: `Monatlich`
  },
  annuale: {
    it: `Annuale`,
    en: `Yearly`,
    de: `Jährlich`
  },
  totale: {
    it: `Totale`,
    en: `Total`,
    de: `Gesamt`
  }
}

export {
  periodi,
  intervalli_predefiniti,
  granularità_predefinite }