<template>
  {#if !autenticato}
    <Rotta schermo_intero={true} richiede_autenticazione={false}>
      <div class="contenitore-recupero-password">
        <div id="accesso">
          {#if $dominio == "firenze"}
            <MessaggioFirenze/>
          {:else if $dominio == "bergamo"}
            <MessaggioBergamo/>
          {:else if $dominio == "genova"}
            <MessaggioGenova/>
          {:else if $dominio == "livigno"}
            <MessaggioLivigno/>
          {:else if $dominio == "padova"}
            <MessaggioPadova/>
          {:else if $dominio == "trentino"}
            <MessaggioTrentino/>
          {:else if $dominio == "veneto"}
            <MessaggioVeneto/>
          {:else if $dominio == "confcommercio-verona"}
            <MessaggioConfcommercioVerona/>
          {:else}
            <Messaggio/>
          {/if}
          <Sfondo dominio={$dominio}/>
          {#if inserisci_gettone}
            <ModuloRipristino
                {email}
                bind:inserisci_gettone={inserisci_gettone}/>
          {:else}
            <ModuloRecupero
                bind:email={email}
                bind:inserisci_gettone={inserisci_gettone}/>
          {/if}
        </div>
      </div>
    </Rotta>
  {/if}
</template>

<style>
  :global(div.contenitore-recupero-password) {
      min-height: 100%;
      overflow: auto;
  }
  :global(div#accesso) {
      --top-margin: 19%;
      display: flex;
      align-items: stretch;
      min-height: 100%;
  }
</style>

<script>
  import Rotta from "@/base/componenti/Rotta.svelte"
  import Sfondo from "@/base/componenti/accesso/Sfondo.svelte"
  import ModuloRecupero from "@/base/componenti/moduli/RecuperoPassword.svelte"
  import ModuloRipristino from "@/base/componenti/moduli/RipristinoPassword.svelte"
  import Messaggio from "@/base/componenti/accesso/Messaggio.svelte"
  import MessaggioFirenze from "@/portale/componenti/accesso/messaggi_per_dominio/Firenze.svelte"
  import MessaggioBergamo from "@/portale/componenti/accesso/messaggi_per_dominio/Bergamo.svelte"
  import MessaggioLivigno from "@/portale/componenti/accesso/messaggi_per_dominio/Livigno.svelte"
  import MessaggioGenova from "@/portale/componenti/accesso/messaggi_per_dominio/Genova.svelte"
  import MessaggioPadova from "@/portale/componenti/accesso/messaggi_per_dominio/Padova.svelte"
  import MessaggioTrentino from "@/portale/componenti/accesso/messaggi_per_dominio/Trentino.svelte"
  import MessaggioVeneto from "@/portale/componenti/accesso/messaggi_per_dominio/Veneto.svelte"
  import MessaggioConfcommercioVerona from "@/portale/componenti/accesso/messaggi_per_dominio/ConfcommercioVerona.svelte"
  import { utente_corrente } from "@/base/sorgenti/svuby"
  import { dominio } from "@/portale/sorgenti/dominio"
  import { onMount } from "svelte"

  let autenticato = $utente_corrente != null
  let inserisci_gettone = false
  let email = null

  ////
  // Verifica che non ci sia alcun utente autenticato.
  function verifica_utente_non_autenticato() {
    if (!autenticato) return
    navigatore.verso("/")
  }

  ////
  // Quando pronto, verifica che non ci sia alcun utente corrente.
  // Se invece esiste un utente autenticato, rimanda alla pagina principale.
  onMount(verifica_utente_non_autenticato)
</script>
