<template>
  <GestioneStruttura>
    {#if in_caricamento}
      <Caricatore/>
    {:else}
      <div class="info-struttura">
        <div class="sinistra">
          <InformazioniGenerali
              {struttura}
              {inserimenti}
              {in_modifica}
              {gestionali}
              on:apri={apri_modifiche}
              on:annulla={annulla_modifiche}
              on:salva={salva_modifiche}/>

          {#if struttura.tipo != "hotel"}
            <DisponibilitàCamping
                {struttura}
                on:visualizza={apri_modale_disponibilità}/>
          {:else}
            <DisponibilitàHotel
                {struttura}
                {inserimenti}
                {in_modifica}
                on:apri={apri_modifiche}
                on:annulla={annulla_modifiche}
                on:salva={salva_modifiche}
                on:visualizza={apri_modale_disponibilità}/>
          {/if}
        </div>
        <div class="destra">
          <InformazioniGeografiche
              {struttura}
              {inserimenti}
              {in_modifica}
              on:apri={apri_modifiche}
              on:annulla={annulla_modifiche}
              on:salva={salva_modifiche}/>
          {#if struttura.tipo == "hotel"}
            <Scorpori
                {struttura}
                {inserimenti}
                {in_modifica}
                on:apri={apri_modifiche}
                on:annulla={annulla_modifiche}
                on:salva={salva_modifiche}/>
          {:else if struttura.tipo == "camping"}
            <!-- TODO: qui verranno messi gli scorpori extra servizi -->
          {/if}
          <!-- <LettoriTrasmissioni {struttura}/> -->
        </div>
      </div>

      <Modale
          richiudibile={true}
          schermo_intero={true}
          bind:aperta={modale_disponibilità_aperta}
          titolo={t.titolo_disponibilità[$l]}>
        <div class="modale-contenuto">
          <TabellaDisponibilità
              {struttura}
              {indirizzo}/>
        </div>
      </Modale>
    {/if}
  </GestioneStruttura>
</template>

<style>
  :global(div.info-struttura) {
    display:          flex;
    padding:          0 calc(var(--padding-base-main) / 2);
  }
  :global(div.info-struttura div.sinistra),
  :global(div.info-struttura div.destra) {
    width:            50%;
    padding:          0 6px;
  }
  :global(div.info-struttura div.scheda) {
    margin-bottom:    calc(var(--padding-base-main) * 2);
  }

  /* Etichette. */
  :global(div.info-struttura div.etichetta-scheda) {
    display:          flex;
    align-items:      center;
    justify-content:  space-between;
    padding-left:     var(--padding-base-main);
    margin-bottom:    13px;
  }
  :global(div.info-struttura div.etichetta-scheda label) {
    font-weight:      bold;
    line-height:      30px;
    color:            var(--colore-testo-leggero);
  }
  :global(div.info-struttura div.etichetta-scheda div.azioni) {
    display:          flex;
    align-items:      center;
  }

  /* Campi. */
  :global(div.info-struttura div.scheda div.campo) {
    display:          flex;
    align-items:      center;
    justify-content:  space-between;
    padding:          0;
    line-height:      24px;
  }
  :global(div.info-struttura div.scheda:not(.modifica_in_corso) div.campo:not(:last-child)) {
    margin-bottom:    27px;
  }
  :global(div.info-struttura div.scheda.modifica_in_corso div.campo:not(:last-child)) {
    margin-bottom:    7px;
  }
  :global(div.info-struttura div.scheda div.nota) {
    padding:          0 14px;
    margin-top:       -14px;
    margin-bottom:    7px;
    font-size:        12px;
    color:            var(--colore-testo-leggero);
  }
  :global(div.info-struttura div.scheda div.campo div.nome),
  :global(div.info-struttura div.scheda div.campo div.valore),
  :global(div.info-struttura div.scheda div.campo div.valore-inserimento) {
    flex-grow:        1;
    /* flex-basis:       0; */
  }
  :global(div.info-struttura div.scheda div.campo div.valore) {
    display:          flex;
    align-items:      center;
    justify-content:  flex-end;
    font-weight:      700;
  }
  :global(div.info-struttura div.scheda div.campo div.valore span.fanalino) {
    margin-right:     7px;
  }
  :global(div.info-struttura div.scheda div.campo div.valore-inserimento div.inserimento),
  :global(div.info-struttura div.scheda div.campo div.valore-inserimento div.selezione) {
    width:            100%;
    margin-bottom:    0;
  }

  /* Modale Disponibilità. */
  div.modale-contenuto {
    display:          flex;
    flex-direction:   column;
  }
</style>

<script>
  import GestioneStruttura from "@/portale/componenti/GestioneStruttura.svelte"
  import InformazioniGenerali from "@/portale/rotte/gestione_struttura/struttura/InformazioniGenerali.svelte"
  import InformazioniGeografiche from "@/portale/rotte/gestione_struttura/struttura/InformazioniGeografiche.svelte"
  import DisponibilitàHotel from "@/portale/rotte/gestione_struttura/struttura/DisponibilitàHotel.svelte"
  import DisponibilitàCamping from "@/portale/rotte/gestione_struttura/struttura/DisponibilitàCamping.svelte"
  import Scorpori from "@/portale/rotte/gestione_struttura/struttura/Scorpori.svelte"
  import LettoriTrasmissioni from "@/portale/rotte/gestione_struttura/struttura/LettoriTrasmissioni.svelte"
  import Modale from "@/base/componenti/Modale.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import TabellaDisponibilità from "@/base/componenti/tabelle/DisponibilitàCamping.svelte"
  import { sessione_corrente } from "@/portale/sorgenti/sessione"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { onDestroy } from "svelte"
  import { onMount } from "svelte"

  const t = avvia_localizzazione()
  const indirizzo = retro.estremi.portale.disponibilità

  let struttura_in_caricamento = true
  let gestionale_in_caricamento = true
  let modale_disponibilità_aperta = false
  let struttura = {}
  let inserimenti = {}
  let gestionali = []
  let in_modifica = {
    informazioni_generali:  false,
    scorpori:               false }
  
  $: in_caricamento = struttura_in_caricamento || gestionale_in_caricamento

  ////
  // Richiede i dati della struttura.
  async function richiedi_struttura() {
    struttura_in_caricamento = true

    let risposta = await retro.chiama("POST",
      retro.estremi.portale.struttura($sessione_corrente._id),
      { relazioni_esposte: {
        territorio: [ "nome", "valuta" ],
        località: [ "nome" ],
        zona: [ "nome" ]
      } })

    struttura = risposta.contenuto.struttura
    inizializza_inserimenti_da_struttura()

    struttura_in_caricamento = false
  }

  ////
  // Richiede i gestionali.
  async function richiedi_gestionali() {
    gestionale_in_caricamento = true

    let risposta = await retro.chiama("GET",
      retro.estremi.licenze.gestionali)

    gestionali = risposta.contenuto.gestionali

    gestionale_in_caricamento = false
  }

  ////
  // Inizializza gli inserimenti dalla struttura.
  function inizializza_inserimenti_da_struttura() {
    inserimenti = {
      ...struttura,
      scorpori: {
        colazione: { ...struttura.scorpori.colazione },
        mezza_pensione: { ...struttura.scorpori.mezza_pensione },
        pensione_completa: { ...struttura.scorpori.pensione_completa },
        tutto_incluso: { ...struttura.scorpori.tutto_incluso },
    } }
  }

  ////
  // Apre la modifica della sezione.
  function apri_modifiche(evento) {
    let sezione = evento.detail
    in_modifica[sezione] = true
  }

  ////
  // Salva le modifiche della sezione.
  function salva_modifiche(evento) {
    let sezione = evento.detail
    in_modifica[sezione] = true
  }

  ////
  // Annulla le modifiche della sezione.
  function annulla_modifiche(evento) {
    let sezione = evento.detail
    in_modifica[sezione] = false
    inizializza_inserimenti_da_struttura()
  }

  ////
  // Apre la modale disponibilità.
  function apri_modale_disponibilità() {
    modale_disponibilità_aperta = true
  }

  onMount(richiedi_gestionali)

  // Al cambio sessione, richiede i dati struttura.
  const scollega_cambio_sessione = sessione_corrente.su_cambio(richiedi_struttura)
  // Quando distrutto, stacca il collegamento del cambio sessione.
  onDestroy(scollega_cambio_sessione)

  // Avvia localizzazione.
  function avvia_localizzazione() {
    return {
      titolo_disponibilità: {
        it: `Disponibilità`,
        en: `Inventory`,
        de: `Verfügbarkeit`
      }
    }
  }
</script>
