<template>
  <section
      id="sfondo-accesso"
      class:schermo-intero={schermo_intero}>
      <div class="sfondo" style="background-image: url({window.risolviImg(indirizzo_immagine)})"></div>
      <Localizzatore apertura="sotto"/>
    </section>
</template>

<style>
  :global(section#sfondo-accesso) {
    width:            45%;
    position:         relative;
  }
  :global(section#sfondo-accesso div.sfondo) {
    background-size:  cover;
    height:           100%;
    filter:           grayscale(100%);
  }
  :global(section#sfondo-accesso:after) {
    content:          '';
    display:          block;
    position:         absolute;
    top:              0;
    bottom:           0;
    left:             0;
    right:            0;
    background-color: #081BCD;
    opacity:          0.55;
  }
  :global(section#sfondo-accesso > div.selezione) {
    width:            82px;
    position:         absolute;
    z-index:          3;
    right:            15%;
    top:              66px;
    margin-bottom:    0;
    transform:        translateY(-50%);
  }
  :global(section#sfondo-accesso > div.selezione .opzione-localizzatore img) {
    width:            18px
  }
  :global(section#sfondo-accesso > div.selezione .contenitore-valore) {
    background:       #F8F8F9;
    border-radius:    40px;
    padding:          6px 9px;
    height:           auto;
    font-size:        12px;
  }
  :global(section#sfondo-accesso > div.selezione .contenitore-icona) {
    height:           auto;
    top:              50%;
    transform:        translateY(-50%);
  }
</style>

<script>
  import Localizzatore                from "@/base/componenti/Localizzatore.svelte"
  import sfondo_accesso_1             from "@/base/immagini/sfondi/1.jpg"
  import sfondo_accesso_2             from "@/base/immagini/sfondi/2.jpg"
  import sfondo_accesso_3             from "@/base/immagini/sfondi/3.jpg"
  import sfondo_accesso_4             from "@/base/immagini/sfondi/4.jpg"
  import sfondo_accesso_5             from "@/base/immagini/sfondi/5.jpg"
  import sfondo_accesso_bibione       from "@/base/immagini/sfondi/bibione.jpg"
  import sfondo_accesso_bergamo       from "@/base/immagini/sfondi/bergamo.jpg"
  import sfondo_accesso_caorle        from "@/base/immagini/sfondi/caorle.jpg"
  import sfondo_accesso_firenze       from "@/base/immagini/sfondi/firenze.jpg"
  import sfondo_accesso_gardaveneto   from "@/base/immagini/sfondi/gardaveneto.jpg"
  import sfondo_accesso_genova        from "@/base/immagini/sfondi/genova.jpg"
  import sfondo_accesso_jesolo        from "@/base/immagini/sfondi/jesolo.jpg"
  import sfondo_accesso_livigno1      from "@/base/immagini/sfondi/livigno-1.jpg"
  import sfondo_accesso_livigno2      from "@/base/immagini/sfondi/livigno-2.jpg"
  import sfondo_accesso_lugano        from "@/base/immagini/sfondi/lugano.jpg"
  import sfondo_accesso_milano        from "@/base/immagini/sfondi/milano.jpg"
  import sfondo_accesso_padova_1      from "@/base/immagini/sfondi/padova-1.jpg"
  import sfondo_accesso_padova_2      from "@/base/immagini/sfondi/padova-2.jpg"
  import sfondo_accesso_padova_3      from "@/base/immagini/sfondi/padova-3.jpg"
  import sfondo_accesso_sardegna      from "@/base/immagini/sfondi/sardegna.jpg"
  import sfondo_accesso_trentino      from "@/base/immagini/sfondi/trentino.jpg"
  import sfondo_accesso_venezia       from "@/base/immagini/sfondi/venezia.jpg"
  import sfondo_accesso_verona        from "@/base/immagini/sfondi/verona.jpg"
  import sfondo_accesso_verona_città  from "@/base/immagini/sfondi/verona_citta.jpeg"
  import sfondo_accesso_ticino        from "@/base/immagini/sfondi/ticino.jpeg"

  export let schermo_intero = false
  export let dominio = "altri"

  ////
  // Mappa degli sfondi disponibili per ogni dominio.
  const SFONDI_PER_DOMINIO = {
    bibione:      [ sfondo_accesso_bibione ],
    bergamo:      [ sfondo_accesso_bergamo ],
    caorle:       [ sfondo_accesso_caorle ],
    firenze:      [ sfondo_accesso_firenze ],
    gardaveneto:  [ sfondo_accesso_gardaveneto ],
    genova:       [ sfondo_accesso_genova ],
    jesolo:       [ sfondo_accesso_jesolo ],
    livigno:      [ sfondo_accesso_livigno1, sfondo_accesso_livigno2 ],
    lugano:       [ sfondo_accesso_lugano ],
    milano:       [ sfondo_accesso_milano ],
    padova:       [ sfondo_accesso_padova_1, sfondo_accesso_padova_2, sfondo_accesso_padova_3 ],
    sardegna:     [ sfondo_accesso_sardegna ],
    trentino:     [ sfondo_accesso_trentino ],
    venezia:      [ sfondo_accesso_venezia ],
    verona:       [ sfondo_accesso_verona ],
    ticino:       [ sfondo_accesso_ticino ],
    "confcommercio-verona":       [ sfondo_accesso_verona_città ],
    altri:        [ sfondo_accesso_1, sfondo_accesso_2, sfondo_accesso_3, sfondo_accesso_4, sfondo_accesso_5 ] }

  ////
  // Lista dei domini che hanno uno sfondo.
  const DOMINI_CON_SFONDO = Object.keys(SFONDI_PER_DOMINIO)

  let indirizzo_immagine = ""

  ////
  // Determina l'immagine di sfondo, in base al dominio.
  function determina_sfondo_accesso() {
    let sfondi_per_dominio_corrente = null

    if (DOMINI_CON_SFONDO.include(dominio)) {
      sfondi_per_dominio_corrente = SFONDI_PER_DOMINIO[dominio]
    } else {
      sfondi_per_dominio_corrente = SFONDI_PER_DOMINIO.altri
    }

    let numero_sfondi_per_dominio_corrente = sfondi_per_dominio_corrente.length
    let indice_sfondo_a_caso = Math.trunc(Math.random() * numero_sfondi_per_dominio_corrente)
    let sfondo_a_caso = sfondi_per_dominio_corrente[indice_sfondo_a_caso]

    indirizzo_immagine = sfondo_a_caso
  }

  $: if (dominio != null) determina_sfondo_accesso()
</script>
