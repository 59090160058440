<template>
  <section id="modulo-recupero" bind:this={elemento}>
    <div class="modulo">
      <h2>{t.recupera[$l]}</h2>
      <Modulo
          {indirizzo}
          {parametri}
          metodo="DELETE"
          gestore={gestore_risposta}
          validatore={validatore_modulo}
          bind:in_salvataggio={invio_in_corso}
          bind:errori={errori}>
        {#if invio_confermato && !invio_in_corso}
          <p>{@html `${t.conferma_invio_email_1[$l]} <span class="email">${email}</span> ${t.conferma_invio_email_2[$l]}`}</p>

          <div class="azioni">
            <Bottone
                variante="primario"
                on:click={evento => inserisci_gettone = true}>
              {t.inserisci_gettone[$l]}
            </Bottone>
          </div>
          
          <div class="collegamenti">
            <div class="collegamento">
              <span>{t.email_non_arrivata[$l]}</span>
              <a href="{window.location.href}"
                  on:click={evento => invio_confermato = false}>
                {t.rinvia[$l]}
              </a>
            </div>
          </div>
        {:else}
          <p>
            {t.testo_recupero_password[$l]}
          </p>
          {#if errori.generico != null}
            <div class="errore-generico">
              {t.errori[errori.generico][$l]}.
            </div>
          {/if}

          <Inserimento
              tipo="email"
              etichetta={t.email[$l]}
              segnaposto={t.email_segnaposto[$l]}
              errore={errori.email != null ? t.validazioni[errori.email][$l] : null}
              bind:valore={email}/>

          {#if invio_in_corso}
            <Caricatore/>
          {:else}
            <div class="azioni">
              <Bottone tipo="invio">
                  {t.invia[$l]}
              </Bottone>
            </div>
            <div class="collegamenti">
              <div class="collegamento">
                <span>{t.hai_già_un_account[$l]}</span>
                <a href="{'#'}"
                    on:click|preventDefault={vai_a_accesso}>
                  {t.accedi[$l]}
                </a>
              </div>
            </div>
          {/if}
        {/if}
      </Modulo>
    </div>
  </section>
</template>

<style>
  :global(section#modulo-recupero) {
    width:          38.2%;
    max-width:      450px;
    height:         calc(100vh - var(--top-margin));
    position:       fixed;
    top:            var(--top-margin);
    left:           43%;
    display:        flex;
    align-items:    flex-start;
  }
  :global(section#modulo-recupero div.modulo) {
    width:          100%;
    background:     var(--colore-sfondo);
    padding:        32px;
    border-radius:  5px;
    box-shadow:     0px 10px 20px rgba(0, 0, 0, 0.1);
  }
  :global(section#modulo-recupero div.modulo h2) {
    margin-top: 0;
    color:      var(--colore-titolo);
  }
  :global(section#modulo-recupero div.modulo p) {
    color: var(--colore-titolo);
  }
  :global(section#modulo-recupero div.modulo p span.email) {
    font-weight:  500;
    color:        var(--colore-primario); 
  }
  :global(section#modulo-recupero div.errore-generico) {
    color:          var(--colore-rosso);
    margin-bottom:  14px;
  }
  :global(section#modulo-recupero div.modulo form) {
    width:          100%;
  }
  :global(section#modulo-recupero div.modulo div.collegamenti) {
    margin-top:     28px;
  }
  :global(section#modulo-recupero div.modulo div.collegamenti div.collegamento) {
    display:          flex;
    align-items:      center;
    justify-content:  flex-start;
    font-size:        12px;
  }
  :global(section#modulo-recupero div.modulo div.collegamenti div.collegamento a) {
    margin:           0 8px;
    line-height:      30px;
    font-size:        12px;
  }
</style>

<script>
  import Modulo from "@/base/componenti/Modulo.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  export let inserisci_gettone = false
  export let email = null

  const t = avvia_localizzazione(dizionario_recupero)
  const indirizzo = retro.estremi.anagrafe.password
  const gestione_errore_precedente = window.onunhandledrejection

  let elemento
  let errori = { email: null, generico: null }
  let invio_in_corso = false
  let invio_confermato = false

  // Parametri recupero.
  $: parametri = { email, localizzazione: $l }

  ////
  // Validazione del modulo.
  function validatore_modulo() {
    if (email == null || email == "")
      errori.email = "richiesto"
  }

  ////
  // Gestione della gestione del modulo.
  function gestore_risposta(risposta) {
    if (risposta.status == 405 || risposta.status == 404) errori.email = "nessun_account_collegato"
    else if (risposta.contenuto.successo) invio_confermato = true
    else errori.generico = true
    invio_in_corso = false
  }

  ////
  // Va alla pagina di recupero della password.
  function vai_a_accesso(evento) {
    navigatore.verso("/accesso")
  }
</script>

<script context="module">
  export const dizionario_recupero = {
    email: {
      it: `Email *`,
      en: `Email *`,
      de: `E-Mail *`
    },
    conferma_invio_email_1: {
      it: `Ti abbiamo inviato un messaggio al tuo indirizzo e-mail`,
      en: `We have sent you a message to your e-mail address`,
      de: `Wir haben eine Nachricht an Ihre E-Mail-Adresse gesendet`
    },
    conferma_invio_email_2: {
      it: `contenente il gettone per la reimpostazione della password.`,
      en: `containing the token for password reset.`,
      de: `enthält die Zeichen für das Zurücksetzen des Passworts.`
    },
    accedi: {
      it: `Accedi`,
      en: `Login`,
      de: `Anmeldung`
    },
    recupera: {
      it: `Recupera password`,
      en: `Password recovery`,
      de: `Passwort wiederherstellen`
    },
    rinvia: {
      it: `Rinvia`,
      en: `Re send`,
      de: `Erneut senden`
    },
    email_fornita: {
      it: `Email Fornita`,
      en: `Email Provided`,
      de: `E-Mail bereitgestellt`
    },
    email_non_arrivata: {
      it: `Non è arrivata l'email?`,
      en: `Didn't the email arrive?`,
      de: `Die E-Mail ist nicht angekommen?`
    },
    hai_già_un_account: {
      it: `Hai già un account?`,
      en: `Do you already have an account?`,
      de: `Haben Sie bereits ein Konto?`
    },
    inserisci_gettone: {
      it: `Inserisci il Gettone`,
      en: `Insert the Token`,
      de: `Setzen Sie die Zeichen ein`
    },
    email_segnaposto: {
      it: `Inserisci la tua email`,
      en: `Write your email`,
      de: `Geben Sie Ihre E-Mail-Adresse ein`
    },
    testo_recupero_password: {
      it: `Inserisci il tuo indirizzo e-mail per la registrazione.
        Ti invieremo un'e-mail con un link per reimpostare la password`,
      en: `Enter your e-mail address for registration.
        We will send you an email with a link to reset your password`,
      de: `Geben Sie zur Registrierung Ihre E-Mail-Adresse ein.
        Wir senden Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts.`
    },
    validazioni: {
      nessun_account_collegato: {
        it: `Non ci sono account collegati a questa email`,
        en: `There are no accounts linked to this email`,
        de: `Mit dieser E-Mail-Adresse sind keine Konten verknüpft`
      },
      richiesto: {
        it: `Questo campo è obbligatorio`,
        en: `This field is required`,
        de: `Dieses Feld ist erforderlich`
      }
    },
    errori: {
      credenziali_non_valide: {
        it: `Credenziali non valide`,
        en: `Invalid credentials`,
        de: `Ungültige Anmeldeinformationen`
      },
      altro: {
        it: `Si è verificato un errore.`,
        en: `An error occurred.`,
        de: `Ein Fehler ist aufgetreten.`
      }
    }
  }
</script>