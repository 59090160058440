<template>
  <div class="etichetta-scheda">
    <label for="">{t.etichetta[$l]}</label>

    <div class="azioni">
      {#if in_modifica.informazioni_generali}
        <Bottone
            piccolo={true}
            variante="secondario-invertito"
            on:click={evento => propaga("annulla", "informazioni_generali")}>
          {t.annulla[$l]}
        </Bottone>
        <Bottone
            piccolo={true}
            variante="primario-invertito"
            on:click={evento => propaga("salva", "informazioni_generali")}>
          {t.salva[$l]}
        </Bottone>
      {:else}
        <!-- <Bottone
            piccolo={true}
            variante="primario-invertito"
            on:click={evento => propaga("apri", "informazioni_generali")}>
          <Icona tipo="modifica"/>
        </Bottone> -->
      {/if}
    </div>
  </div>

  <Scheda modifica_in_corso={in_modifica.informazioni_generali}>
    <div class="campo">
      <div class="nome">{t.campi.nome[$l]}</div>
      {#if in_modifica.informazioni_generali}
        <div class="valore-inserimento">
          <Inserimento bind:valore={inserimenti.nome}/>
        </div>
      {:else}
        <div class="valore">{struttura.nome || "-"}</div>
      {/if}
    </div>

    <div class="campo">
      <div class="nome">{t.campi.tipo[$l]}</div>
      <div class="valore">{t[struttura.tipo][$l] || "-"}</div>
    </div>

    <div class="campo">
      <div class="nome">{t.campi.gestionale[$l]}</div>
      {#if in_modifica.informazioni_generali}
        <div class="valore-inserimento">
          <Selezione
              ricercabile={true}
              opzioni={opzioni_gestionali}
              bind:valore={struttura.id_gestionale}/>
        </div>
      {:else}
        <div class="valore">{ricava_gestionale(struttura.id_gestionale) || "-"}</div>
      {/if}
    </div>

    <div class="campo">
      <div class="nome">{t.campi.categoria[$l]}</div>
      {#if in_modifica.informazioni_generali}
        <div class="valore-inserimento">
          <Selezione
              opzioni={categorie}
              bind:valore={inserimenti.categoria}/>
        </div>
      {:else}
        <div class="valore">{struttura.categoria || "-"}</div>
      {/if}
    </div>

    <div class="campo">
      <div class="nome">{t.campi.telefono[$l]}</div>
      {#if in_modifica.informazioni_generali}
        <div class="valore-inserimento">
          <Inserimento bind:valore={inserimenti.telefono}/>
        </div>
      {:else}
        <div class="valore">{struttura.telefono || "-"}</div>
      {/if}
    </div>
  </Scheda>
</template>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import Scheda from "@/base/componenti/Scheda.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import Selezione from "@/base/componenti/Selezione.svelte"
  import { categorie } from "@/base/sorgenti/hbenchmark/categorie"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { createEventDispatcher } from "svelte"
  import { onMount } from "svelte"

  export let struttura = {}
  export let inserimenti = {}
  export let in_modifica = {}
  export let gestionali = []

  const propaga = createEventDispatcher()
  const t = avvia_localizzazione(dizionario_informazioni_generali)

  $: opzioni_gestionali = gestionali.map(gestionale => {
      return {
        testo: gestionale.nome,
        valore: gestionale._id
      }
    })

  ////
  // Ricava nome gestionale
  function ricava_gestionale(id) {
    let gestionale = gestionali.find( gestionale => gestionale._id == id)

    if (gestionale == null) return
    return gestionale.nome
  }
</script>

<script context="module">
  export const dizionario_informazioni_generali = {
    etichetta: {
      it: `Informazioni Generali`,
      en: `Generic Info`,
      de: `Allgemeine Informationen`
    },
    campi: {
      nome: {
        it: `Nome`,
        en: `Name`,
        de: `Name`
      },
      tipo: {
        it: `Tipo`,
        en: `Type`,
        de: `Typ`
      },
      gestionale: {
        it: `Gestionale`,
        en: `Management`,
        de: `Verwaltung`
      },
      categoria: {
        it: `Categoria`,
        en: `Category`,
        de: `Kategorie`
      },
      indirizzo: {
        it: `Indirizzo`,
        en: `Address`,
        de: `Adresse`
      },
      città: {
        it: `Città`,
        en: `City`,
        de: `Stadt`
      },
      provincia: {
        it: `Provincia`,
        en: `Province`,
        de: `Bundesland`
      },
      telefono: {
        it: `Telefono`,
        en: `Phone`,
        de: `Telefon`
      }
    }
  }
</script>
