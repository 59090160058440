<template>
  <div class="etichetta-scheda">
    <label for="">{t.etichetta[$l]}</label>
    <!-- <div class="azioni">
      {#if in_modifica.disponibilità}
        <Bottone
            piccolo={true}
            variante="secondario-invertito"
            on:click={evento => propaga("annulla", "disponibilità")}>
          {t.annulla[$l]}
        </Bottone>
        <Bottone
            piccolo={true}
            variante="primario-invertito"
            on:click={evento => propaga("salva", "disponibilità")}>
          {t.salva[$l]}
        </Bottone>
      {:else}
        <Bottone
            piccolo={true}
            variante="primario-invertito"
            on:click={evento => propaga("apri", "disponibilità")}>
          <Icona tipo="modifica"/>
        </Bottone>
      {/if}
    </div> -->
  </div>
  <Scheda>
    <div class="campo">
      <div class="nome">{t.campi.camere_disponibili[$l]}</div>
      {#if in_modifica.disponibilità}
        <div class="valore-inserimento">
          <Inserimento bind:valore={inserimenti.unità_disponibili}/>
        </div>
      {:else}
        <div class="valore">{struttura.unità_disponibili || "-"}</div>
      {/if}
    </div>

    <div class="campo">
      <div class="nome">{t.campi.letti_disponibili[$l]}</div>
      {#if in_modifica.disponibilità}
        <div class="valore-inserimento">
          <Inserimento bind:valore={inserimenti.letti_disponibili}/>
        </div>
      {:else}
        <div class="valore">{struttura.letti_disponibili || "-"}</div>
      {/if}
    </div>

    <div class="campo">
      <div class="nome">
        <b>{struttura.disponibilità || 0}</b>
        {t.campi.disponibilità[$l]}
      </div>
      <div class="valore">
        <!-- <Bottone
            piccolo={true}
            variante="primario-invertito"
            on:click={evento => modale_nuova_disponibilità_aperta = true}>
          {t.aggiungi[$l]}
        </Bottone> -->
      </div>
    </div>

    {#if disponibilità.length > 0}
      <div class="tabella">
        {#if in_caricamento}
          <Caricatore/>
        {:else}
          <Tabella
              {colonne}
              {valore_cella}
              righe={disponibilità}
              dizionario={t}
              intestazione_fissa={true}
              let:riga={riga}
              let:colonna={colonna}
              let:valore={valore}>
            {valore_cella(riga, colonna, valore)}
          </Tabella>
        {/if}
      </div>
    {/if}
  </Scheda>

  <Modale
      scorribile={true}
      richiudibile={true}
      titolo={t.titolo_nuova_disponibilità[$l]}
      bind:aperta={modale_nuova_disponibilità_aperta}>
    <div class="modale-contenuto">
      <ModuloDisponibilità/>
    </div>
  </Modale>  
</template>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import Scheda from "@/base/componenti/Scheda.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import Tabella from "@/base/componenti/Tabella.svelte"
  import Modale from "@/base/componenti/Modale.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import ModuloDisponibilità from "@/base/componenti/moduli/Disponibilità.svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { createEventDispatcher } from "svelte"
  import { onMount } from "svelte"

  export let struttura = {}
  export let inserimenti = {}
  export let in_modifica = {}

  const propaga = createEventDispatcher()
  const t = avvia_localizzazione(dizionario_disponibilità)

  let disponibilità = []
  let totale_disponibilità = 0
  let modale_nuova_disponibilità_aperta = false
  let in_caricamento = false
  let colonne = ["codice_prodotto", "prodotto", "disponibili"]

  ////
  // Richiede le disponibilità.
  async function richiedi_disponibilità() {
    in_caricamento = true

    let parametri = {
      data_inizio: `${Date.today.year}-08-01`,
      data_termine: `${Date.today.year}-08-01` }
      
    let risposta = await retro.chiama(
      "GET",
      retro.estremi.portale.disponibilità, {
        id_struttura: struttura._id,
        filtri: parametri })

    disponibilità = risposta.contenuto.disponibilità.map((disponibilità, indice) => {
      return { ...disponibilità, indice }
    })
  
    disponibilità.forEach(disponibilità_singola => {
      if(disponibilità_singola.disponibili != null) totale_disponibilità += disponibilità_singola.disponibili
    })

    in_caricamento = false
  }

  ////
  // Determina il valore di una cella.
  function valore_cella(riga, colonna, valore, indice_riga, indice_colonna) {
    switch (colonna) {
      case "prodotto":
        if (valore == null) return "-"
        return t[valore][$l]
      default:
        return valore || "-"
    }
  }

  onMount(() => richiedi_disponibilità())
</script>

<script context="module">
  export const dizionario_disponibilità = {
    titolo_nuova_disponibilità: {
      it: `Aggiungi disponibilità`,
      en: `Add availability`,
      de: `Verfügbarkeit hinzufügen`
    },
    etichetta: {
      it: `Disponibilità`,
      en: `Inventory`,
      de: `Bestandsaufnahme`
    },
    campi: {
      camere_disponibili: {
        it: `Camere Disponibili`,
        en: `Available Rooms`,
        de: `Verfügbare Zimmer`
      },
      letti_disponibili: {
        it: `Letti Disponibili`,
        en: `Available Beds`,
        de: `Verfügbare Betten`
      },
      disponibilità: {
        it: `Disponibilità Inserite`,
        en: `Inserted Inventory Data`,
        de: `Verfügbarkeit eingeben`
      }
    }
  }
</script>
