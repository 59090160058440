<template>
  <div class="legenda-serie">
    {#if indicatore.dettagliato_per == "nazionalità"}
      <PerNazionalità
          {indicatore}
          {statistica}
          {serie_per_gruppo_e_periodo}
          {numero_serie_mostrate}
          bind:numero_limite_serie={numero_limite_serie}
          on:evidenzia
          on:mostra
          on:nascondi/>
    {:else if indicatore.dettagliato}
      <PerComposizione
          {statistica}
          {serie_per_gruppo_e_periodo}
          {numero_serie_mostrate}
          dettagliato_per={indicatore.dettagliato_per}
          bind:numero_limite_serie={numero_limite_serie}
          on:evidenzia
          on:mostra
          on:nascondi/>
    {:else}
      <Standard
          {statistica}
          {serie_per_gruppo_e_periodo}
          {numero_serie_mostrate}
          bind:numero_limite_serie={numero_limite_serie}
          on:evidenzia
          on:mostra
          on:nascondi/>
    {/if}
  </div>
  {#if numero_limite_serie > 3}
    <Bottone
        variante="primario-invertito"
        piccolo={true}
        on:click={mostra_nascondi_serie}>
      {#if numero_serie_mostrate == 3}
        {t.mostra_tutte[$l]}
      {:else}
        {t.nascondi[$l]}
      {/if}
    </Bottone>
  {/if}
</template>

<style>
  :global(div.legenda-serie) {
    display:    flex;
    flex-wrap:  wrap;
    padding:    7px;
  }
  :global(div.legenda-serie div.elenco-serie) {
    width:          calc(100% / 3);
    display:        flex;
    padding:        14px;
    flex-direction: column;
  }

  :global(div.legenda-serie div.gruppo) {
    margin:       7px 0;
    font-weight:  500;
    font-size:    13px;
  }
</style>

<script>
  import PerNazionalità from "@/base/componenti/statistiche/legenda_serie/PerNazionalità.svelte"
  import PerComposizione from "@/base/componenti/statistiche/legenda_serie/PerComposizione.svelte"
  import Standard from "@/base/componenti/statistiche/legenda_serie/Standard.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import { localizzazione as l} from "@/base/sorgenti/svuby/localizzazione"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  export let indicatore = {}
  export let statistica = {}
  export let serie = []

  let serie_per_gruppo_e_periodo = {}
  let numero_serie_mostrate = 3
  let numero_limite_serie = 99

  let t = avvia_localizzazione(dizionario_legenda_serie)

  ////
  // Raggruppa le serie per gruppo e periodo.
  function raggruppa_serie_per_gruppo_e_periodo() {
    serie_per_gruppo_e_periodo = {}

    statistica.gruppi.forEach((gruppo, indice_gruppo) => {
      serie_per_gruppo_e_periodo[indice_gruppo] = {}
      statistica.cornice_temporale.periodi.forEach((periodo, indice_periodo) => {
        serie_per_gruppo_e_periodo[indice_gruppo][indice_periodo] = []
      })
    })

    serie.forEach(serie => {
      let puntatore = serie_per_gruppo_e_periodo[serie.indice_gruppo][serie.indice_periodo]
      serie_per_gruppo_e_periodo[serie.indice_gruppo][serie.indice_periodo] = [
        ...puntatore, serie ]
    })
  }

  ////
  // Mostra o nasconde le nazionalità oltre la quarta
  function mostra_nascondi_serie() {
    switch (numero_serie_mostrate) {
      case 3:
        numero_serie_mostrate = numero_limite_serie
        break;
      
      case numero_limite_serie:
        numero_serie_mostrate = 3
        break;
      default:
        numero_serie_mostrate = serie_per_gruppo_e_periodo.length
        break;
    }
  }

  // Al cambio di serie, raggruppa.
  $: if (serie != null) raggruppa_serie_per_gruppo_e_periodo()
</script>

<script context="module">
  export const dizionario_legenda_serie = {
    mostra_tutte: {
      it: `Mostra tutti i segmenti`,
      en: `Show all segments`,
      de: `Alle Segmente anzeigen`
    }
  }
</script>