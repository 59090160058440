import { writable } from "svelte/store"

// Abbonamento.
let abbonamento_in_deposito = deposito_locale.prendi("abbonamento", null)
const abbonamento = writable(abbonamento_in_deposito)

abbonamento.su_cambio = abbonamento.subscribe
abbonamento.sovrascrivi = function(nuovo_abbonamento) {
  deposito_locale.deposita("abbonamento", nuovo_abbonamento)
  return abbonamento.update(utente => nuovo_abbonamento)
}

// Parametri abbonamento predefiniti.
const parametri_abbonamento_personalizzato = function(localizzazione, dominio) {
  return {
    passo_raggiunto:        0,
    passo_corrente:         0,
    nome_piano:             "plus",
    flusso_personalizzato:  dominio,
    struttura: {
      tipo:       "hotel",
      categoria:  "3*",
    },
    cliente: {
      nazione:    localizzazione == "de" ? "AT" : "IT"
    }
  }
}

export {
  abbonamento,
  parametri_abbonamento_personalizzato }
