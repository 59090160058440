<template>
  <div class="scheda" class:modifica_in_corso={modifica_in_corso}>
    <slot/>
  </div>
</template>

<style>        
  :global(div.scheda) {
    background: var(--colore-bianco);
    box-shadow: 0 5px 12px 0 var(--colore-ombreggiatura);
    padding:    24px 32px;
  }
</style>

<script>
  export let modifica_in_corso=false;
</script>
