<template>
  <RottaNuovoAbbonamento titolo={t.titolo[$l]}>
    <div class="passo-progresso-abbonamento">
      {#if in_caricamento}
        <Caricatore/>
      {:else}
        <Modulo 
            gestore={gestore}
            validatore={validatore}
            bind:errori={errori}>
          <TitoloSecondario>{t.titolo_secondario[$l]}</TitoloSecondario>
          <Combo 
              nome="gestionale"
              etichetta={t.selezione[$l]}
              selezionato={!gestionale_non_in_lista}
              on:change={evento => gestionale_non_in_lista = false}/>
          {#if !gestionale_non_in_lista}
            <Selezione 
                icona="espandi"
                ricercabile={true}
                disabilitata={gestionale_non_in_lista}
                opzioni={gestionali}
                bind:valore={$abbonamento.struttura.id_gestionale}/>
          {/if}
          <Combo 
              nome="gestionale"
              etichetta={t.non_in_lista[$l]}
              selezionato={gestionale_non_in_lista}
              on:change={evento => gestionale_non_in_lista = true}/>
          {#if gestionale_non_in_lista}
            <Inserimento
                errore={errori.nome}
                bind:valore={$abbonamento.struttura.nome_gestionale}/>
          {/if}
          <div class="azioni">
            <Bottone 
                tipo="invio"
                variante="primario">
              {t.continua[$l]}
            </Bottone>
            <Bottone 
                variante="testo-leggero-invertito"
                on:click={torna_indietro}>
              {t.torna_indietro[$l]}
            </Bottone>
          </div>
        </Modulo>
      {/if}
    </div>
  </RottaNuovoAbbonamento>
</template>

<style>
  div.passo-progresso-abbonamento {
    overflow:   visible !important;
  }
</style>

<script>
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Modulo from "@/base/componenti/Modulo.svelte"
  import Selezione from "@/base/componenti/Selezione.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import Combo from "@/base/componenti/Combo.svelte"
  import TitoloSecondario from "@/base/componenti/TitoloSecondario.svelte"
  import RottaNuovoAbbonamento from "@/portale/componenti/abbonamento/RottaNuovoAbbonamento.svelte";
  import { onMount } from "svelte"
  import { abbonamento } from "@/portale/sorgenti/abbonamento"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  const t = avvia_localizzazione(dizionario_gestionale)

  let in_caricamento = true
  let gestionale_non_in_lista = $abbonamento.struttura.nome_gestionale ? true : false
  let gestionali = []
  let errori = { nome: null }

  // Valida i dati struttura.
  function validatore() {
    if (gestionale_non_in_lista && !$abbonamento.struttura.nome_gestionale)
      errori.nome = t.validazioni.richiesto[$l]
  }

  ////
  // Torna al passo precedente
  function torna_indietro() {
    $abbonamento.passo_corrente -= 1
    navigatore.verso("/nuovo_abbonamento/struttura")
  }

  // Gestisce il passaggio al prossimo passo.
  function gestore() {
    if (gestionale_non_in_lista) delete $abbonamento.struttura.id_gestionale

    $abbonamento.passo_corrente = 2
    if ($abbonamento.passo_raggiunto < 2) $abbonamento.passo_raggiunto = 2
    if ($abbonamento.buono_sconto == null) {
      $abbonamento.buono_sconto = {}
      $abbonamento.codice_buono_sconto = ""
    }
    abbonamento.sovrascrivi($abbonamento)
    navigatore.verso("/nuovo_abbonamento/piano")
  }

  // Quando pronto, richiede i gestionali.
  async function richiedi_gestionali() {
    in_caricamento = true

    let risposta = await retro.chiama("GET", retro.estremi.licenze.gestionali)
    gestionali = risposta.contenuto.gestionali.map(gestionale => {
      return { valore: gestionale._id, testo: gestionale.nome }
    })
    if ($abbonamento.struttura.id_gestionale == null)
      $abbonamento.struttura.id_gestionale = gestionali[0].valore

    in_caricamento = false
  }

  // Quando pronto, richiede i gestionali.
  onMount(richiedi_gestionali)
</script>

<script context="module">
  export const dizionario_gestionale = {
    titolo: {
      it: `Il mio gestionale`,
      en: `My management`,
      de: `Mein Management-System`
    },
    titolo_secondario: {
      it: `Seleziona il gestionale`,
      en: `Select the management system`,
      de: `Management-System wählen`
    },
    selezione: {
      it: `Il mio gestionale è:`,
      en: `My ERP is:`,
      de: `Mein Property Management System (PMS)ist:`
    },
    non_in_lista: {
      it: `Il mio gestionale non è nella lista`,
      en: `My ERP is not in this list`,
      de: `Mein Management-System ist nicht in der Liste`
    },
    nome: {
      it: `Inserisci il nome del tuo gestionale`,
      en: `Please type your ERP name`,
      de: `Bitte geben Sie den Namen Ihres PMS ein`
    }
  }
</script>
