<template>
  <div class="granularità-predefinite">
    <div class="titolo">{t.titolo[$l]}</div>
    {#each lista_granularità as granularità}
      <Bottone
          piccolo={true}
          on:click={() => propaga("selected", granularità)}
          variante={codice_selezionato == granularità.codice ? "primario-invertito" : "secondario-invertito"}>
        {t[granularità.codice][$l]}
      </Bottone>
    {/each}
  </div>
</template>

<style>
  :global(div.granularità-predefinite) {
    width:          230px;
    display:        flex;
    flex-direction: column;
    align-items:    flex-start;
  }
  
  :global(div.granularità-predefinite div.titolo) {
    height:         35px;
    padding:        5px 7px 3px 7px;
    align-self:     center;
  }
  
  :global(div.granularità-predefinite button) {
    width:            100%;
    height:           35px;
    justify-content:  flex-start;
  }
</style>

<script>
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { granularità_predefinite } from "@/base/sorgenti/hbenchmark/periodi"
  import { createEventDispatcher } from "svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"

  export let granularità = granularità_predefinite
  export let codice_selezionato = null

  const t = avvia_localizzazione(dizionario_granularità_predefinite)
  const oggi = Date.today
  const propaga = createEventDispatcher()

  $: lista_granularità = granularità.map((codice, granularità) => {
    return { codice: codice, nome: granularità }
  })
</script>

<script context="module">
  export const dizionario_granularità_predefinite = {
    titolo: {
      it: `Granularità`,
      en: `Granularity`,
      de: `Granularität`
    },
    ...granularità_predefinite
  }
</script>
