<template>
  <div class="contenitore-riproduttore">
    <iframe
        bind:this={riproduttore}
        width={larghezza_riproduttore}
        height={altezza_riproduttore}
        src={`https://www.youtube.com/embed/${id_video}`}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
  </div>
</template>

<style>
  :global(div.contenitore-riproduttore){
    width: 100%;
  }
</style>

<script>
  export let id_video = null
  export let larghezza_riproduttore = "100%"

  let riproduttore = null
  let altezza_riproduttore = "315px"

  $: if (riproduttore != null && larghezza_riproduttore) altezza_riproduttore = (riproduttore.clientWidth * 9) / 16
</script>