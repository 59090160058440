<template>
  {#if serie_per_indicatore}
    <Schedario schede={schede_serie}/>
  {:else}
    <GraficoMisto
        {statistica}
        serie={serie_per_grafico_misto}
        {anteprima}
        {modalità_scura}/>
  {/if}
</template>

<style>
  :global(div.schedario > div.etichette > div.etichetta) {
    max-width: calc(100% / 5);
    width: 100%;
  }
  :global(div.schedario > div.etichette > div.etichetta >
   div.indicatore > div.comparazioni > div.comparazione > div.comparato-con) {
    white-space: normal;
  }
</style>

<script>
  import Schedario from "@/base/componenti/Schedario.svelte"
  import Grafico from "@/base/componenti/statistiche/Grafico.svelte"
  import GraficoMisto from "@/base/componenti/statistiche/GraficoMisto.svelte"
  import Indicatore from "@/base/componenti/statistiche/Indicatore.svelte"

  export let anteprima = false
  export let statistica = null
  export let modalità_scura = false

  let schede_serie = []
  let serie_per_indicatore = null
  let serie_per_grafico_misto = []

  ////
  // Determina se raggruppare le serie per indicatore.
  function determina_se_raggruppare_serie_per_indicatore() {
    serie_per_indicatore = statistica.tipo_grafico != "diagramma_misto"
    if (serie_per_indicatore) raggruppa_serie_per_indicatore()
    else serie_per_grafico_misto = statistica.serie.map((serie, indice) => {
      return { ...serie, indice }
    })
  }

  ////
  // Raggruppa le serie per indicatore.
  function raggruppa_serie_per_indicatore(argomento) {
    let serie_raggruppate = []

    statistica.serie.each((serie) => {
      let indice_indicatore = serie.indice_indicatore
      if (serie_raggruppate[indice_indicatore] == null)
        serie_raggruppate[indice_indicatore] = []
      serie_raggruppate[indice_indicatore].push(serie)
    })

    serie_raggruppate = serie_raggruppate.map(gruppo_serie =>{
      let gruppo = gruppo_serie.map((serie, indice) => {
        return { ...serie, indice }
      })
      return gruppo
    })

    schede_serie = statistica.indicatori.map((indicatore, indice) => {
      let proprietà = {
        statistica: statistica,
        indicatore: indicatore,
        serie: serie_raggruppate[indice],
        modalità_scura }

      return {
        etichetta: { componente: Indicatore, proprietà: proprietà },
        contenuto: { componente: Grafico,    proprietà: { ...proprietà, anteprima } }
      }
    })
  }

  ////
  // Aggiorna la statistica.
  function aggiorna_statistica(evento) {
    let indicatori = evento.detail.indicatori
    if (statistica != null) statistica.indicatori = indicatori
    propaga("change", { indicatori })
  }

  // Al cambio della statistica.
  $: if (statistica != null) determina_se_raggruppare_serie_per_indicatore()
</script>
