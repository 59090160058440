<template>
  <button
      tabindex="-1"
      type={tipo_bottone}
      disabled={disabilitato}
      title={titolo_sovrimpressione}
      class="{variante}"
      class:piccolo={piccolo}
      on:click>
    <slot/>
  </button>
</template>

<style>        
  :global(button) {
    display:          flex;
    justify-content:  center;
    align-items:      center;
    padding:          12px 24px;
    margin:           0;
    text-transform:   uppercase;
    font-weight:      700;
    font-size:        14px;
    font-family:      "Inter", sans-serif;
    line-height:      16.94px;
    letter-spacing:   0.55px;
    white-space:      nowrap;
    border:           1px solid transparent;
    border-radius:    24px;
    background-color: transparent;
    cursor:           pointer;
    transition:       all .3s;
  }
  :global(button:hover) {
    background-color: var(--colore-sorvolo);
  }
  :global(button:focus) {
    outline:          none;
  }
  :global(button.piccolo) {
    padding:          3.5px 14px;
  }
  :global(button.grande) {
    padding:          16px 32px;
  }
  :global(button[disabled]:not(.piano)) {
    background:       var(--colore-disabilitato) !important;
    color:            var(--colore-testo-leggero) !important;
    border:           1px solid var(--colore-disabilitato) !important;
    cursor:           not-allowed;
  }

  /* Piano. */
  :global(button.piano) {
    display:          flex;
    width:            100%;
    padding:          0;
    border:           0;
    background-color: transparent;
    border-color:     transparent;
    color:            inherit;
    outline:          none;
    box-shadow:       none;
    text-align:       left;
    text-transform:   none;
    letter-spacing:   1px;
  }
  :global(button.piano:hover) {
    background-color: var(--colore-sorvolo);
  }

  /* Primario. */
  :global(button.primario) {
    border:           1px solid var(--colore-primario);
    color:            var(--colore-bianco);
    background-color: var(--colore-primario);
  }
  :global(button.primario:hover) {
    background-color: var(--colore-primario-evidenziato);
  }
  :global(button.primario-invertito) {
    border:           1px solid transparent;
    color:            var(--colore-primario);
  }
  :global(button.primario-inverito:hover) {
    color:            var(--colore-primario-evidenziato);
  }
  :global(button.primario-invertito-con-bordo) {
    border:           1px solid var(--colore-primario);
    color:            var(--colore-primario);
  }
  :global(button.primario-inverito-con-bordo:hover) {
    border:           1px solid var(--colore-primario-evidenziato);
    color:            var(--colore-primario-evidenziato);
  }

  /* Secondario. */
  :global(button.secondario) {
    border:           1px solid var(--colore-secondario);
    color:            var(--colore-bianco);
    background-color: var(--colore-secondario);
  }
  :global(button.secondario:hover) {
    background-color: var(--colore-secondario-evidenziato);
  }
  :global(button.secondario-invertito) {
    border:           1px solid transparent;
    color:            var(--colore-secondario);
  }
  :global(button.secondario-inverito:hover) {
    color:            var(--colore-secondario-evidenziato);
  }
  :global(button.secondario-invertito-con-bordo) {
    border:           1px solid var(--colore-secondario);
    color:            var(--colore-secondario);
  }
  :global(button.secondario-inverito-con-bordo:hover) {
    border:           1px solid var(--colore-secondario-evidenziato);
    color:            var(--colore-secondario-evidenziato);
  }

  /* Rosso. */
  :global(button.rosso) {
    border:           1px solid var(--colore-rosso);
    color:            var(--colore-bianco);
    background-color: var(--colore-rosso);
  }
  :global(button.rosso:hover) {
    background-color: var(--colore-rosso-evidenziato);
  }
  :global(button.rosso-invertito) {
    border:           1px solid transparent;
    color:            var(--colore-rosso);
  }
  :global(button.rosso-inverito:hover) {
    color:            var(--colore-rosso-evidenziato);
  }
  :global(button.rosso-invertito-con-bordo) {
    border:           1px solid var(--colore-rosso);
    color:            var(--colore-rosso);
  }
  :global(button.rosso-inverito-con-bordo:hover) {
    border:           1px solid var(--colore-rosso-evidenziato);
    color:            var(--colore-rosso-evidenziato);
  }

  /* Testo leggero. */
  :global(button.testo-leggero) {
    border:           1px solid var(--colore-testo-leggero);
    color:            var(--colore-bianco);
    background-color: var(--colore-testo-leggero);
  }
  :global(button.testo-leggero:hover) {
    background-color: var(--colore-secondario-evidenziato);
  }
  :global(button.testo-leggero-invertito) {
    border:           1px solid transparent;
    color:            var(--colore-testo-leggero);
  }
  :global(button.testo-leggero-inverito:hover) {
    color:            var(--colore-primario);
  }
  :global(button.testo-leggero-invertito-con-bordo) {
    border:           1px solid var(--colore-primario);
    color:            var(--colore-testo-leggero);
  }
  :global(button.testo-leggero-inverito-con-bordo:hover) {
    border:           1px solid var(--colore-primario-evidenziato);
    color:            var(--colore-primario-evidenziato);
  }
</style>

<script>
  export let variante = "primario"
  export let tipo = "bottone"
  export let disabilitato = false
  export let piccolo = false
  export let titolo_sovrimpressione = null

  let tipo_bottone = "button"

  $: if (tipo == "invio") tipo_bottone = "submit"
  $: if (tipo != "invio") tipo_bottone = "button"
</script>
