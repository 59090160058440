<template>
  <div class="concorrente">
    {#if da_creare}
      <div class="immagine-da-creare"/>
    {:else}
      <img src={ window.risolviImg(mappa) } height="128" width="128" alt=""/>
      <img src={ window.risolviImg(marcatore) } height="48" width="48" alt="" class="marcatore-1"/>
      <img src={ window.risolviImg(marcatore) } height="48" width="48" alt="" class="marcatore-2"/>
    {/if}
    <div class="proprietà">
      <h3 class="nome">
        {#if da_creare}
          <span class="da-creare">
            {t.non_ancora_creato[$l]}
          </span>
        {:else}
          {concorrente.nome}
        {/if}
      </h3>
      {#if !da_creare}
        <div class="strutture">
          {conteggio_strutture} {t.strutture[$l]}
        </div>
        <div class="modifiche">
          {contatore_modifiche} / 5 {t.modifiche[$l]}
        </div>
      {/if}
      <div class="azioni">
        {#if da_creare}
          <Bottone
              piccolo={true}
              variante="primario-invertito"
              on:click={evento => modale_modifica_aperta = true}>
            {t.crea[$l]}
          </Bottone>
        {:else}
          <Bottone
              piccolo={true}
              variante="secondario-invertito"
              on:click={evento => modale_rinomina_aperta = true}>
            {t.rinomina[$l]}
          </Bottone>
          <Bottone
              piccolo={true}
              variante="primario-invertito"
              on:click={evento => modale_modifica_aperta = true}>
            {t.modifica[$l]}
          </Bottone>
        {/if}
      </div>
    </div>
  </div>

  <Modale
      richiudibile={true}
      titolo={t.titolo_modale_rinomina[$l]}
      bind:aperta={modale_rinomina_aperta}>
    <div class="modale-contenuto">
      <ModuloConcorrente
          solo_rinomina={true}
          concorrente={concorrente}
          on:change={aggiorna_concorrente}/>
    </div>
  </Modale>
  <Modale
      scorribile={false}
      richiudibile={true}
      schermo_intero={true}
      titolo={t.titolo_modale_modifica[$l]}
      bind:aperta={modale_modifica_aperta}>
    <div class="modale-contenuto">
      <ModuloConcorrente
          bind:concorrente={concorrente}
          on:change={notifica_salvataggio}/>
    </div>
  </Modale>

  <Messaggio bind:visualizzato={messaggio_visualizzato}>
    {messaggio}
  </Messaggio>
</template>

<style>
  :global(div.concorrente) {
    display:        flex;
    position:       relative;
    background:     var(--colore-bianco);
    border:         1px solid var(--colore-bordi);
    margin:         calc(var(--padding-base-main) * 2) var(--padding-base-main);
    box-shadow:     0 5px 10px 0 var(--colore-ombreggiatura);
  }

  /* Immagine. */
  :global(div.concorrente div.immagine-da-creare) {
    width:          128px;
    height:         128px;
    background:     var(--colore-sorvolo);
  }
  :global(div.concorrente img.marcatore-1) {
    position:       absolute;
    width:          32px;
    height:         32px;
    top:            17px;
    left:           17px;
  }
  :global(div.concorrente img.marcatore-2) {
    position:       absolute;
    width:          32px;
    height:         32px;
    top:            48px;
    left:           72px;
  }

  /* Proprietà. */
  :global(div.concorrente div.proprietà) {
    display:        flex;
    flex-direction: column;
    flex-grow:      1;
    padding:        14px;
  }
  :global(div.concorrente div.proprietà h3.nome) {
    margin:         0;
    margin-bottom:  7px;
  }
  :global(div.concorrente div.proprietà h3.nome span.da-creare) {
    color:          var(--colore-segnaposto);
  }
  :global(div.concorrente div.proprietà div.azioni) {
    display:        flex;
    align-self:     flex-end;
    margin-top:     auto;
  }
</style>

<script>
  import Modale from "@/base/componenti/Modale.svelte"
  import Messaggio from "@/base/componenti/Messaggio.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import ModuloConcorrente from "@/base/componenti/moduli/Concorrente.svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { createEventDispatcher } from "svelte"
  import mappa from "@/base/immagini/concorrente-mappa.png"
  import marcatore from "@/base/immagini/marcatore-mappa.svg"


  export let concorrente = {}

  const t = avvia_localizzazione(dizionario_concorrente)
  const propaga = createEventDispatcher()

  let messaggio = ""
  let messaggio_visualizzato = false
  let modale_modifica_aperta = false
  let modale_rinomina_aperta = false

  $: conteggio_strutture = (concorrente.id_strutture || []).length
  $: contatore_modifiche = concorrente.contatore_modifiche || 0
  $: da_creare = conteggio_strutture == 0 && contatore_modifiche == 0

  ////
  // Aggiorna il concorrente.
  function aggiorna_concorrente(evento) {
    if (evento.detail == null || evento.detail.concorrente == null) return

    concorrente = evento.detail.concorrente
    modale_modifica_aperta = false
    modale_rinomina_aperta = false
    propaga("change", { concorrente })
  }

  ////
  // Notifica il salvataggio.
  function notifica_salvataggio() {
    modale_modifica_aperta = false
    messaggio = t.salvato_con_successo[$l]
    messaggio_visualizzato = true
  }
</script>

<script context="module">
  export const dizionario_concorrente = {
    non_ancora_creato: {
      it: `Non ancora creato`,
      en: `Not yet created`,
      de: `Noch nicht erstellt`
    },
    strutture: {
      it: `strutture`,
      en: `properties`,
      de: `Betriebe`
    },
    modifiche: {
      it: `modifiche`,
      en: `edits`,
      de: `Änderungen`
    },
    salvato_con_successo: {
      it: `Competitor salvato con successo.`,
      en: `Competitor saved successfully.`,
      de: `Wettbewerber erfolgreich gespeichert.`
    },
    titolo_modale_rinomina: {
      it: `Rinomina Competitor`,
      en: `Rename Competitor`,
      de: `Wettbewerber umbenennen`
    },
    titolo_modale_modifica: {
      it: `Modifica Competitor`,
      en: `Edit Competitor`,
      de: `Wettbewerber bearbeiten`
    }
  }
</script>
