<template>
</template>

<script>
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { granularità_predefinite as granularità } from "@/base/sorgenti/hbenchmark/periodi"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { periodi } from "@/base/sorgenti/hbenchmark/periodi"

  const t = avvia_localizzazione()
  
  export let grafico = null
  export let pronto = false
  export let opzioni_highcharts = {}
  export let modalità_scura = false
  
  let colore_etichette_ascisse = "#666666"

  $: periodo_corrente = grafico != null ?
    grafico.statistica.cornice_temporale.periodi.find(periodo => periodo.nome == "corrente") :
    null
  $: if (modalità_scura) {
    colore_etichette_ascisse = "#bbbbbb"
  } else {
    colore_etichette_ascisse = "#666666"
  }

  ////
  // Genera le opzioni grafico highcharts.
  function genera_opzioni_highcharts() {
    if (grafico == null) return

    let opzioni_highcharts_colonna = { title: { text: null } }

    grafico.serie.sort((a, b) => {
      return a.indice_gruppo - b.indice_gruppo || b.indice_periodo - a.indice_periodo
    })

    // Serie.
    opzioni_highcharts_colonna.series = grafico.serie.map(genera_serie)
    // Sovraimpressione.
    opzioni_highcharts_colonna.tooltip = {
      useHTML: true,
      backgroundColor: "#fff",
      formatter: formattatore_sovraimpressione
    }
    // Asse Ascisse.
    opzioni_highcharts_colonna.xAxis = {
      type: "datetime",
      labels: { rotation: -45, formatter: formattatore_etichette_ascisse, style: {color: colore_etichette_ascisse} }
    }
    // Asse Ordinate.
    opzioni_highcharts_colonna.yAxis = { title: null }

    console.log("Colonna".colore("verde"), ": grafico pronto.")

    opzioni_highcharts = opzioni_highcharts_colonna
    pronto = true
  }

  ////
  // Generatore serie.
  function genera_serie(serie, indice) {
    let periodo = grafico.statistica.cornice_temporale.periodi[serie.indice_periodo]
    let pila = `${serie.indice_periodo}${serie.indice_gruppo}`
    let punti = serie.punti.map((punto, indice) => {
      let data = punto[0].to_date.utc
      let anno = data.year
      let valore = punto[1]

      // if (data != null) data.setYear(1972)
      // if (valore != null) valore = valore.round(1)

      return { x: indice, y: valore, data }
    })

    return {
      type: "column",
      data: punti,
      color: serie.colore,
      stack: pila,
      stacking: grafico.indicatore.impilabile,
      marker: { enabled: false },
      dataLabels: {
        enabled: grafico.statistica.mostra_etichette_valori,
        formatter: formattatore_arrotondatore
      },
      unità: serie.unità,
      valuta: serie.valuta,
      indicatore: grafico.indicatore,
      gruppo: grafico.statistica.gruppi[serie.indice_gruppo],
      periodo: periodo,
      segmento: serie.segmento,
      scostamento: periodo.scostamento,
      indice: serie.indice
    }
  }

  ////
  // Formattatore etichette ascisse.
  function formattatore_etichette_ascisse() {
    if (grafico.statistica.granularità == "totale") return granularità.totale[$l]

    try {
      let serie_periodo_corrente = grafico.statistica.serie.find(serie => serie.indice_periodo == 0)
      let data_numerica = serie_periodo_corrente.punti[this.value][0]
      let data = new Date(data_numerica)

      if (grafico.statistica.granularità == "annuale")
        return data.format("%Y")
      else if (grafico.statistica.granularità == "mensile")
        return data.format("%B %Y")
      else
        return "-"
    } catch (errore) {
      return "-"
    }
  }

  ////
  // Formattatore della sovraimpressione.
  function formattatore_sovraimpressione() {
    let punto = this
    
    let data = punto.point.data
    let anno = data.format("%Y")
    let data_formattata = data.granulate(grafico.statistica.granularità)
    if (grafico.statistica.granularità == "mensile") data_formattata = `${data_formattata} ${anno}`
    if (grafico.statistica.granularità == "totale")
      data_formattata = periodi[punto.point.series.userOptions.periodo.nome][$l]

    let punto_serie = punto.series.userOptions
    let punto_gruppo = punto_serie.gruppo
    let punto_periodo = punto_serie.periodo
    let punto_segmento = punto_serie.segmento
    let punto_indicatore = punto_serie.indicatore
    let nome_periodo = periodi[punto_periodo.nome][$l]
    let nome_gruppo = punto_gruppo.nome
    let valore_punto = punto.y.format({
      unit: punto_serie.unità,
      currency: punto_serie.valuta
    })

    let stringa_descrizione

    if (punto_segmento != null) {
      let nome_segmento
      if (punto_segmento == "altri" && punto_indicatore.dettagliato_per == "canale") nome_segmento = t.altri_canali[$l]
      else if (t[punto_segmento] == null) nome_segmento = "n.d."
      else nome_segmento = t[punto_segmento][$l]

      if (punto_gruppo.categorie != null && punto_gruppo.categorie.length > 0)
      nome_gruppo = `${nome_gruppo} - ${punto_gruppo.categorie.join(" - ")}`

      return [
        data_formattata,
        nome_periodo,
        nome_gruppo,
        `${nome_segmento}: <b>${valore_punto}</b>`
      ].join("<br/>")
    } else {
      if (punto_gruppo.categorie != null && punto_gruppo.categorie.length > 0)
        stringa_descrizione = `${nome_gruppo} - ${punto_gruppo.categorie.join(" - ")}: <b>${valore_punto}</b>`
      else stringa_descrizione = `${nome_gruppo}: <b>${valore_punto}</b>`

      return [
        data_formattata,
        nome_periodo,
        stringa_descrizione
      ].join("<br/>")
    }
  }

  ////
  // Rimuove la virgola dall'etichetta
  function formattatore_arrotondatore() {
    return Math.round(this.y).format({
      unit: this.series.userOptions.unità,
      currency: this.series.userOptions.valuta
    })
  }

  // Ad ogni aggiornamento, genera le opzioni grafico linea.
  $: if (grafico != null) genera_opzioni_highcharts()
</script>
