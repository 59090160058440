<template>
</template>

<script>
  import nebbia from "@/base/immagini/immagini_meteo/nebbia.png"
  import nuvoloso from "@/base/immagini/immagini_meteo/nuvoloso.png"
  import sereno_variabile from "@/base/immagini/immagini_meteo/sereno_variabile.png"
  import nevischio from "@/base/immagini/immagini_meteo/nevischio.png"
  import pioggia from "@/base/immagini/immagini_meteo/pioggia.png"
  import neve from "@/base/immagini/immagini_meteo/neve.png"
  import sereno from "@/base/immagini/immagini_meteo/sereno.png"
  import temporale from "@/base/immagini/immagini_meteo/temporale.png"
  import { periodi } from "@/base/sorgenti/hbenchmark/periodi"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { createEventDispatcher } from "svelte"

  export let grafico = null
  export let pronto = false
  export let opzioni_highcharts = {}
  export let modalità_scura = false

  const t = avvia_localizzazione()
  const intervalli = {
    giornaliera:  24 * 3600 * 1000,
    settimanale:  24 * 3600 * 1000 * 7 }
  const immagini_meteo = {
    sereno: sereno,
    pioggia: pioggia,
    nebbia: nebbia,
    neve: neve,
    temporale: temporale,
    sereno_variabile: sereno_variabile,
    nuvoloso: nuvoloso,
    nevischio: nevischio
  }

  let meteo = []
  let eventi = []
  let colore_etichette_ascisse = "#666666"

  $: if (grafico != null) {
    meteo = grafico.statistica.meteo
    eventi = grafico.statistica.eventi
  }
  $: if (modalità_scura) {
    colore_etichette_ascisse = "#bbbbbb"
  } else {
    colore_etichette_ascisse = "#666666"
  }

  let propaga = createEventDispatcher()

  $: periodo_corrente = grafico != null ?
    grafico.statistica.cornice_temporale.periodi.find(periodo => periodo.nome == "corrente") :
    null

  ////
  // Genera le opzioni grafico highcharts.
  function genera_opzioni_highcharts() {
    if (grafico == null) return

    let opzioni_highcharts_linea = { title: { text: null } }

    // Serie.
    opzioni_highcharts_linea.series = [...grafico.serie.map(genera_serie), meteo]
    // Sovraimpressione.
    opzioni_highcharts_linea.tooltip = {
      split: true,
      useHTML: true,
      backgroundColor: "#fff",
      formatter: formattatore_sovraimpressione
    }
    // Asse Ascisse.
    opzioni_highcharts_linea.xAxis = []
    
    opzioni_highcharts_linea.xAxis[0] = {
      tickInterval: 1,
      plotBands: genera_bande(),
      crosshair: { width: 1, color: "#5f6167" },
      labels: { rotation: -45, formatter: formattatore_etichette_ascisse, style: { color: colore_etichette_ascisse } }
    }
    // Asse Ordinate.
    opzioni_highcharts_linea.yAxis = {
      title: null,
      labels: { formatter: formattatore_etichette_ordinate } }
    if (grafico.indicatore.codice.include("occupazione")) {
      opzioni_highcharts_linea.yAxis.max = 105
      opzioni_highcharts_linea.yAxis.min = 0
      opzioni_highcharts_linea.yAxis.endOnTick = false
    }

    // Mostra meteo
    if (grafico.statistica.mostra_meteo) {
      opzioni_highcharts_linea.xAxis[1] = {
        granularity: "giornaliera", linkedTo: 0, tickLength: 0, opposite : true,
        labels: {
          useHTML: true, rotation: 0,
          formatter: formattatore_condizioni_meteo
        }
      }

      opzioni_highcharts_linea.xAxis[2] = {
        title: { text: "Temperatura media °C", align: "middle", useHTML: true },
        granularity: "giornaliera", linkedTo: 0, tickLength: 0, opposite: true,
        labels: {
          useHTML: true, rotation: 0,
          formatter: formattatore_temperatura
        }
      }
    }

    console.log("Linea".colore("verde"), ": grafico pronto.")
    opzioni_highcharts = opzioni_highcharts_linea
    pronto = true
  }

  ////
  // Generatore serie.
  function genera_serie(serie, indice) {
    let periodo = grafico.statistica.cornice_temporale.periodi[serie.indice_periodo]

    let punti = serie.punti.map((punto, indice) => {
      let data = punto[0].to_date.utc
      let valore = punto[1]

      return { x: indice, y: valore, data }
    })

    return {
      xAxis: 0,
      type: "spline",
      data: punti,
      color: serie.colore,
      lineWidth: 3,
      connectNulls: true,
      marker: { enabled: false },
      dataLabels: {
        enabled: grafico.statistica.mostra_etichette_valori,
        formatter: formattatore_arrotondatore
      },
      zoneAxis: "x",
      zones: genera_zone_nulle(serie),
      unità: serie.unità,
      valuta: serie.valuta,
      indicatore: grafico.indicatore,
      segmento: serie.segmento,
      gruppo: grafico.statistica.gruppi[serie.indice_gruppo],
      periodo: periodo,
      indice: serie.indice
    }
  }

  ////
  // Genera le zone nulle della serie.
  function genera_zone_nulle(serie) {
    if (grafico.statistica.granularità != "giornaliera") return []

    let zone = []

    // Nuova zona diversa dalla precedente.
    function nuova_zona(valore, indice) {
      zone.push({
        nulla:      valore == null,
        value:      indice + 0.5,
        color:      valore == null ? "#ccc" : undefined,
        dashStyle:  valore == null ? "dot"  : "solid"
      })
    }

    // Estende l'ultima zona di un altra posizione.
    function estendi(zona, indice) {
      zona.value = indice + 0.5
    }

    serie.punti.forEach((punto, indice) => {
      let ultima_zona = zone[zone.length - 1]
      let valore = punto[1]

      if (indice == 0) return nuova_zona(valore, indice)
      if (valore == null && ultima_zona.nulla)  estendi(ultima_zona, indice)
      if (valore == null && !ultima_zona.nulla) nuova_zona(valore, indice)
      if (valore != null && ultima_zona.nulla)  nuova_zona(valore, indice)
      if (valore != null && !ultima_zona.nulla) estendi(ultima_zona, indice)
    })

    return zone
  }

  ////
  // Genera le bande dei fine settimana e degli eventi.
  function genera_bande() {
    if (grafico.statistica.granularità != "giornaliera") return []

    let bande = []
    let date = []
    let serie_periodo_corrente = grafico.statistica.serie.find(serie => serie.indice_periodo == 0)

    // Genera bande fine settimana
    serie_periodo_corrente.punti.forEach((punto, indice) => {
      let data = punto[0].to_date.utc
      if (data.weekday == 7) {
        let inizio = indice - 1.5
        let termine = indice + 0.5

        bande.push({
          from: inizio,
          to: termine,
          zIndex: 0,
          color: "var(--colore-sorvolo)" })
      }

      date = [...date, punto[0]]
    })

    // Genera bande eventi
    if (grafico.statistica.mostra_eventi && eventi != null) {
      eventi.forEach((evento, indice_evento) => {
        bande.push({
          from: evento.indice_da - 0.5,
          to: evento.indice_a + 0.5,
          id: `banda_evento_${indice_evento}`,
          color: `${evento.colore}50`,
          label: {
            text: evento.nome,
            textAlign: "left",
            align: indice_evento > 0 && evento.indice_da == eventi[indice_evento - 1].indice_da ? "center" : "left",
            useHTML: true,
            rotation: 90 },
          zIndex: 0
        })
      })
    }

    return bande
  }

  ////
  // Formattatore etichette ascisse.
  function formattatore_etichette_ascisse() {
    try {
      let serie_periodo_corrente = grafico.statistica.serie.find(serie => serie.indice_periodo == 0)
      let data_numerica = serie_periodo_corrente.punti[this.value][0]
      let data = new Date(data_numerica)

      if (data.weekday > 5)
        return `<b>${data.format("%e %B")}</b>`
      else
        return data.format("%e %B")
    } catch (errore) {
      return "-"
    }
  }

  ////
  // Formattatore etichette ordinate.
  function formattatore_etichette_ordinate() {
    let serie = this.chart.series[0].userOptions
    return this.value.format({ unit: serie.unità, currency: serie.valuta })
  }

  ////
  // Formattatore della sovraimpressione.
  function formattatore_sovraimpressione() {
    let marcatore = this
    let punto_corrente = marcatore.points.sort(punto => punto.point.data).first
    let data_corrente_formattata = punto_corrente.point.data.granulate(grafico.statistica.granularità)
    
    let punti_formattati = marcatore.points.map(punto => {
      let data_formattata = punto.point.data.granulate(grafico.statistica.granularità)
      let data_grassetto = `<b>${data_formattata}</b>`
      let punto_serie = punto.series.userOptions
      let punto_gruppo = punto_serie.gruppo
      let punto_periodo = punto_serie.periodo
      let punto_indicatore = punto_serie.indicatore
      let nome_periodo = periodi[punto_periodo.nome][$l]
      let punto_segmento = punto_serie.segmento
      if (punto_periodo.osservato)
        nome_periodo = [ nome_periodo, punto_periodo.data_osservazione.to_date.format("%e %B %Y") ].join(" ")
      let nome_gruppo = punto_gruppo.nome
      
      let valore_punto = punto.y.format({
        unit: punto_serie.unità,
        currency: punto_serie.valuta,
        digits: 1
      })

      let stringa_descrizione

      if (punto_segmento != null) {
        let nome_segmento
        if (punto_segmento == "altri" && punto_indicatore.dettagliato_per == "canale") nome_segmento = t.altri_canali[$l]
        else nome_segmento = t[punto_segmento][$l]

        if (punto_gruppo.categorie != null && punto_gruppo.categorie.length > 0)
          stringa_descrizione = `${nome_segmento} - ${punto_gruppo.categorie.join(" - ")}: <b>${valore_punto}</b>`
        else stringa_descrizione = `${nome_segmento}: <b>${valore_punto}</b>`

        return [
          nome_periodo,
          data_grassetto,
          stringa_descrizione
        ].join("<br/>")
      } else {
        if (punto_gruppo.categorie != null && punto_gruppo.categorie.length > 0)
          stringa_descrizione = `${nome_gruppo} - ${punto_gruppo.categorie.join(" - ")}: <b>${valore_punto}</b>`
        else stringa_descrizione = `${nome_gruppo}: <b>${valore_punto}</b>`

        return [
          nome_periodo,
          data_grassetto,
          stringa_descrizione
        ].join("<br/>")
      }
    })

    return [ data_corrente_formattata, ...punti_formattati ]
  }

  ////
  // Rimuove la virgola dall'etichetta
  function formattatore_arrotondatore() {
    return Math.round(this.y).format({
      unit: this.series.userOptions.unità,
      currency: this.series.userOptions.valuta
    })
  }

  ////
  // Inserisce immagini meteo
  function formattatore_condizioni_meteo() {
    try {
      let serie_periodo_corrente = grafico.statistica.serie.find(serie => serie.indice_periodo == 0)
      let data_numerica = serie_periodo_corrente.punti[this.value][0]
      let data = new Date(data_numerica).format("%Y-%m-%d")

      if (meteo[data].condizioni) {
        return `
          <img class="meteo-grafico"
          src="/${immagini_meteo[meteo[data].condizioni]}"/>`        
      } else {
        return "-"
      }         
    } catch (error) {}
    return "-"
  }

  ////
  // Formatta i gradi
  function formattatore_temperatura() {
    try {
      let serie_periodo_corrente = grafico.statistica.serie.find(serie => serie.indice_periodo == 0)
      let data_numerica = serie_periodo_corrente.punti[this.value][0]
      let data = new Date(data_numerica).format("%Y-%m-%d")

      if (meteo[data].condizioni) {
        return meteo[data].temperatura        
      } else {
        return "-"
      }         
    } catch (error) {}
    return "-"
  }

  // Ad ogni aggiornamento, genera le opzioni grafico linea.
  $: if (grafico != null) genera_opzioni_highcharts()
</script>
