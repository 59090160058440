<template>
  <div class="etichetta-scheda">
    <label for="">{t.etichetta[$l]}</label>

    <div class="azioni">
      {#if in_modifica.informazioni_generali}
        <Bottone
            piccolo={true}
            variante="secondario-invertito"
            on:click={evento => propaga("annulla", "informazioni_generali")}>
          {t.annulla[$l]}
        </Bottone>
        <Bottone
            piccolo={true}
            variante="primario-invertito"
            on:click={evento => propaga("salva", "informazioni_generali")}>
          {t.salva[$l]}
        </Bottone>
      {:else}
        <!-- <Bottone
            piccolo={true}
            variante="primario-invertito"
            on:click={evento => propaga("apri", "informazioni_generali")}>
          <Icona tipo="modifica"/>
        </Bottone> -->
      {/if}
    </div>
  </div>

  <Scheda modifica_in_corso={in_modifica.informazioni_generali}>
    <div class="campo">
      <div class="nome">{t.campi.territorio[$l]}</div>
      <div class="valore">{struttura.territorio.nome || "-"}</div>
    </div>

    <div class="campo">
      <div class="nome">{t.campi.zona[$l]}</div>
      <div class="valore">{struttura.zona.nome || "-"}</div>
    </div>

    <div class="campo">
      <div class="nome">{t.campi.località[$l]}</div>
      <div class="valore">{struttura.località.nome || "-"}</div>
    </div>

    <div class="campo">
      <div class="nome">{t.campi.indirizzo[$l]}</div>
      {#if in_modifica.informazioni_generali}
        <div class="valore-inserimento">
          <Inserimento bind:valore={inserimenti.indirizzo}/>
        </div>
      {:else}
        <div class="valore">{struttura.indirizzo || "-"}</div>
      {/if}
    </div>

    <div class="campo">
      <div class="nome">{t.campi.città[$l]}</div>
      {#if in_modifica.informazioni_generali}
        <div class="valore-inserimento">
          <Inserimento bind:valore={inserimenti.città}/>
        </div>
      {:else}
        <div class="valore">{struttura.città || "-"}</div>
      {/if}
    </div>

    <div class="campo">
      <div class="nome">{t.campi.provincia[$l]}</div>
      {#if in_modifica.informazioni_generali}
        <div class="valore-inserimento">
          <Inserimento bind:valore={inserimenti.provincia}/>
        </div>
      {:else}
        <div class="valore">{struttura.provincia || "-"}</div>
      {/if}
    </div>

    <div class="campo">
      <div class="nome">{t.campi.cap[$l]}</div>
      {#if in_modifica.informazioni_generali}
        <div class="valore-inserimento">
          <Inserimento bind:valore={inserimenti.cap}/>
        </div>
      {:else}
        <div class="valore">{struttura.cap || "-"}</div>
      {/if}
    </div>
  </Scheda>
</template>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import Scheda from "@/base/componenti/Scheda.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import Selezione from "@/base/componenti/Selezione.svelte"
  import { categorie } from "@/base/sorgenti/hbenchmark/categorie"
  import { createEventDispatcher } from "svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  export let struttura = {}
  export let inserimenti = {}
  export let in_modifica = {}

  const propaga = createEventDispatcher()
  const t = avvia_localizzazione(dizionario_informazioni_generali)
</script>

<script context="module">
  export const dizionario_informazioni_generali = {
    etichetta: {
      it: `Informazioni Generali`,
      en: `Generic Info`,
      de: `Allgemeine Informationen`
    },
    campi: {
      territorio: {
        it: `Territorio`,
        en: `Territory`,
        de: `Gebiet`
      },
      zona: {
        it: `Zona`,
        en: `Area`,
        de: `Bereich`
      },
      località: {
        it: `Località`,
        en: `Location`,
        de: `Standort`
      },
      indirizzo: {
        it: `Indirizzo`,
        en: `Address`,
        de: `Adresse`
      },
      città: {
        it: `Città`,
        en: `City`,
        de: `Stadt`
      },
      provincia: {
        it: `Provincia`,
        en: `Province`,
        de: `Bundesland`
      },
      cap: {
        it: `CAP`,
        en: `POSTAL CODE`,
        de: `Postleitzahl`
      }
    }
  }
</script>
