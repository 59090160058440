<template>
  <div
      class="inserimento combo"
      class:disabilitato={disabilitato}>
    <label>
      {#if etichetta_lato_sinistro}
        {etichetta}
      {/if}

      <input 
          type="radio"
          name={nome}
          value={valore}
          disabled={disabilitato}
          checked={selezionato}
          on:change/>
      <div class="combo"/>

      {#if etichetta_lato_destro}
        {etichetta}
      {/if}

      <slot/>
    </label>

    {#if messaggio != null}
      <span class="messaggio">{@html messaggio}</span>
    {/if}
  </div>
</template>

<style>
  :global(div.inserimento.combo label) {
    width:              100%;
    display:            flex;
    cursor:             pointer;
    align-items:        center;
  }

  /* Input. */
  :global(div.inserimento.combo input[type="radio"]) {
    display:            none;
  }
  :global(div.inserimento.combo input[type="radio"]:checked + div.combo) {
    border-color:       var(--colore-primario);
  }
  :global(div.inserimento.combo input[type="radio"]:checked + div.combo:before) {
    background-color:   var(--colore-primario);
    visibility:         visible;
    width:              10px;
    height:             10px;
  }

  /* Tondino. */
  :global(div.inserimento.combo div.combo) {
    position:           relative;
    width:              20px;
    height:             20px;
    margin:             0 7px;
    border:             2px solid var(--colore-testo);
    background-color:   transparent;
    border-radius:      50%;
  }
  :global(div.inserimento.combo div.combo:before) {
    content:            "";
    position:           absolute;
    left:               0; 
    right:              0; 
    top:                0; 
    bottom:             0; 
    margin:             auto; 
    border-radius:      50%;
    background-color:   var(--colore-testo);
    width:              0;
    height:             0;
    visibility:         hidden;
    transition:         .1s;
  }

  :global(div.inserimento.combo.disabilitato label) {
    cursor:             not-allowed;
    opacity:            0.5;
  }

  :global(div.inserimento.combo span.messaggio) {
    display:            block;
    font-size:          11px;
    margin-left:        7px;
    margin-bottom:      7px;
    color:              var(--colore-testo-leggero);
  }
</style>

<script>
  export let valore = ""
  export let nome = ""
  export let selezionato = false
  export let disabilitato = false
  export let etichetta = null
  export let messaggio = null
  export let lato_etichetta = "destro"

  let etichetta_lato_destro, etichetta_lato_sinistro

  $: if (etichetta != null) etichetta_lato_destro = lato_etichetta == "destro"
  $: if (etichetta != null) etichetta_lato_sinistro = lato_etichetta == "sinistro"
</script>
