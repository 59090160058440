<template>
  <div class="tabella-trasmissioni">
    <div class="filtri">
      <div class="inserimenti">
        {t.trasmissioni_trovate[$l]}:&nbsp;
        <b>{trasmissioni.length}</b>
      </div>

      {#if indirizzo != null}
        <Bottone
            piccolo={true}
            variante="primario"
            on:click={apri_modale_invio_archivio}>
          {t.invia_archivio[$l]}
        </Bottone>
      {/if}
    </div>

    <Tabella
        {colonne}
        {valore_cella}
        righe={trasmissioni}
        dizionario={t}
        let:riga={riga}
        let:colonna={colonna}
        let:valore={valore}>
      {#if colonna == "stato"}
        <div class="flessibile">
          {#if valore == null}
            <Fanalino stato="ignorato"/>
          {:else}
            <Fanalino stato={valore}/> &nbsp; {t[valore][$l]}
          {/if}
        </div>
      {:else if colonna == "scarica"}
        <Bottone
            variante="primario-invertito"
            on:click={evento => scarica_trasmissione(riga)}>
          <Icona tipo="esporta"/>
        </Bottone>
      {:else if colonna == ""}
        <Bottone
            variante="primario-invertito"
            on:click={evento => mostra_registro(riga.registro)}>
          <Icona tipo="info"/>
        </Bottone>
      {:else}
        {valore_cella(riga, colonna, valore)}
      {/if}
    </Tabella>

    <Modale
        espansa={true}
        scorribile={true}
        richiudibile={true}
        mostra_eccedenze={false}
        titolo={t.modale.titolo[$l]}
        bind:aperta={modale_invio_archivio_aperta}>
      <div class="modale-contenuto">
        <p>{@html t.modale.messaggio[$l]}</p>
        <div class="azioni">
          <div class="invio-archivio">
            <em>{t.modale.titolo[$l]}</em>
            <Archivio
                {indirizzo}
                {intestazioni}
                parametri={{ id_struttura: struttura._id }}
                on:risposta={propaga_risposta}/>
          </div>
        </div>
      </div>
    </Modale>

    <Modale
        espansa={true}
        richiudibile={true}
        scorribile={false}
        titolo="Registro"
        bind:aperta={modale_registro_aperta}
        on:chiusa={evento => registro_da_mostrare = []}>
      <Registro
          registro={registro_da_mostrare}
          limita_altezza={true}/>
    </Modale>
  </div>
</template>

<style>
  :global(div.tabella-trasmissioni) {
    flex-grow:        1;
    display:          flex;
    flex-direction:   column;
    overflow:         hidden;
  }
  :global(div.tabella-trasmissioni div.filtri) {
    display:          flex;
    align-items:      flex-end;
    justify-content:  space-between;
    margin-bottom:    14px;
  }
  :global(div.tabella-trasmissioni div.filtri > div.inserimenti) {
    display:          flex;
    align-items:      flex-end;
    flex-grow:        1;
    margin-right:     14px;
  }
  :global(div.tabella-trasmissioni div.filtri > div.inserimenti div.selezione) {
    margin-right:     14px;
  }
  :global(div.tabella-trasmissioni div.filtri > div.inserimenti div.selezione),
  :global(div.tabella-trasmissioni div.filtri > div.inserimenti div.inserimento) {
    flex-grow:        1;
    max-width:        350px;
    margin-bottom:    0;
  }

  :global(div.tabella-trasmissioni div.azioni) {
    display:          flex;
    flex-direction:   column;
    margin-bottom:    7px;
  }
  :global(div.tabella-trasmissioni div.tracce-guida) {
    display:          flex;
    align-items:      center;
  }
  :global(div.tabella-trasmissioni div.invio-archivio div.inserimento.archivio) {
    margin-bottom:    0;
  }
</style>

<script>
  import Archivio from "@/base/componenti/inserimenti/Archivio.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Fanalino from "@/base/componenti/Fanalino.svelte"
  import Icona from "@/base/componenti/Icona.svelte"
  import Modale from "@/base/componenti/Modale.svelte"
  import Registro from "@/base/componenti/Registro.svelte"
  import Tabella from "@/base/componenti/Tabella.svelte"
  import { lettori } from "@/base/sorgenti/hbenchmark/trasmissioni"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { createEventDispatcher } from "svelte"

  export let indirizzo = null
  export let trasmissioni = []
  export let struttura = {}
  export let colonne = [ "trasmessa_il", "stato", "numero_soggiorni_ricavati", "" ]

  const t = avvia_localizzazione(dizionario_trasmissioni)
  const propaga = createEventDispatcher()
  const gettone_autorizzazione = biscottini.prendi("gettone")
  const intestazioni = { "Authorization": `Bearer ${gettone_autorizzazione}` }

  let parametri = {}
  let registro_da_mostrare = []
  let modale_registro_aperta = false
  let modale_invio_archivio_aperta = false

  ////
  // Apre la modale di invio archivio.
  function apri_modale_invio_archivio() {
    modale_invio_archivio_aperta = true
  }

  ////
  // Propaga la risposta.
  function propaga_risposta(evento) {
    modale_invio_archivio_aperta = false
    setTimeout(() => propaga("invio", { risposta: evento.detail.risposta }), 300)
  }

  ////
  // Scarica la trasmsisione.
  function scarica_trasmissione(trasmissione) {
    propaga("download", { trasmissione: trasmissione })
  }

  ////
  // Apre la modale con il registro richiesto.
  function mostra_registro(registro) {
    registro_da_mostrare = registro
    modale_registro_aperta = true
  }

  ////
  // Determina il valore di una cella.
  function valore_cella(riga, colonna, valore, indice_riga, indice_colonna) {
    switch (colonna) {
      case "trasmessa_il":
        if (valore == null) return "-"
        return valore.to_date.format("%d %B %Y %H:%M")
      case "codice_lettore":
        if (valore == null) return "-"
        if (lettori[valore] == null || lettori[valore].nome == null) return "-"
        return lettori[valore].nome[$l]
      default:
        return valore || "-"
    }
  }
</script>

<script context="module">
  export const dizionario_trasmissioni = {
    trasmissioni_trovate: {
      it: `Numero trasmissioni trovate`,
      en: `Number of transmissions`,
      de: `Anzahl der Übertragungen`
    },
    invia_archivio: {
      it: `Invia File`,
      en: `Send File`,
      de: `Datei senden`
    },
    modale: {
      titolo: {
        it: `Invia File`,
        en: `Send File`,
        de: `Datei senden`
      },
      messaggio: {
        it: `È possibile inviare dati ad HBenchmark in modo manuale. Per assicurarsi che i dati vengano processati correttamente, è obbligatorio aderire ad un tracciato
          predefinito. Per scaricare una traccia guida del tracciato <a href="https://portal.hbenchmark.it/archivi/traccia-guida.xlsx" target="_blank">clicca qui</a>. <br/>
          In caso di tracciato non conforme assicurarsi che sia stato approvato da HBenchmark. (<a href="mailto:supporto@hbenchmark.it" target="_blank">supporto@hbenchmark.it</a>)`,
        en: `It is possible to send data to HBenchmark manually. To ensure that your data will be processed correctly, it is mandatory to comply to a default layout. To download a guide track  <a href="https://portal.hbenchmark.it/archivi/traccia-guida.xlsx" target="_blank"> click here </a>. <br/>
          In case of non-compliant track layout make sure it has been approved by HBenchmark.(<a href="mailto:supporto@hbenchmark.it" target="_blank">supporto@hbenchmark.it</a>)`,
        de: `Es ist möglich, Daten manuell an HBenchmark zu senden. Um eine korrekte Verarbeitung Ihrer Daten zu gewährleisten, ist die Einhaltung eines Standardlayouts zwingend erforderlich. Um eine Anleitung herunterzuladen, <a href="https://portal.hbenchmark.it/archivi/traccia-guida.xlsx" target="_blank"> klicken Sie hier. </a>. <br/> 
          Im Falle eines nicht konformen Layouts, vergewissern Sie sich, dass es von HBenchmark genehmigt wurde. (<a href="mailto:supporto@hbenchmark.it" target="_blank">supporto@hbenchmark.it</a>)`,
      },
      scarica_traccia_guida: {
        titolo: {
          it: `Scarica Traccia Guida`,
          en: `Download Example Layout`,
          de: `Beispiellayout herunterladen`
        },
        prenotazioni: {
          it: `Prenotazioni`,
          en: `Reservations`,
          de: `Reservierungen`
        },
        disponibilità: {
          it: `Disponibilità`,
          en: `Inventory`,
          de: `Inventar`
        }
      }
    },
    colonne: {
      stato: {
        it: `Stato`,
        en: `Status`,
        de: `Status`
      },
      trasmessa_il: {
        it: `Data`,
        en: `Date`,
        de: `Datum`
      },
      codice_lettore: {
        it: `Integrazione`,
        en: `Integration`,
        de: `Integration`
      },
      numero_soggiorni_ricavati: {
        it: `Dati Ricavati`,
        en: `Obtained Data`,
        de: `Erhobene Daten`
      },
      scarica: {
        it: `Scarica`,
        en: `Download`,
        de: `Herunterladen`
      }
    }
  }
</script>
