<template>
  <div class="intervalli-predefiniti">
    <div class="titolo">{t.titolo[$l]}</div>
    {#each lista_intervalli as intervallo}
      <Bottone
          piccolo={true}
          on:click={() => propaga("selected", intervallo)}
          variante={codice_selezionato == intervallo.codice ? "primario-invertito" : "secondario-invertito"}>
        {t[intervallo.codice].nome[$l]}
      </Bottone>
    {/each}
  </div>
</template>

<style>
  :global(div.intervalli-predefiniti) {
    width:          230px;
    display:        flex;
    flex-direction: column;
    align-items:    flex-start;
  }
  
  :global(div.intervalli-predefiniti div.titolo) {
    height:         35px;
    padding:        5px 7px 3px 7px;
    align-self:     center;
  }
  
  :global(div.intervalli-predefiniti button) {
    width:            100%;
    height:           35px;
    justify-content:  flex-start;
  }
</style>

<script>
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { intervalli_predefiniti } from "@/base/sorgenti/hbenchmark/periodi"
  import { createEventDispatcher } from "svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"

  export let intervalli = intervalli_predefiniti
  export let codice_selezionato = null

  const t = avvia_localizzazione()
  const oggi = Date.today
  const propaga = createEventDispatcher()

  $: lista_intervalli = intervalli.map((codice, intervallo) => {
    return { codice: codice, ...intervallo }
  })

  // Avvia localizzazione.
  function avvia_localizzazione() {
    return {
      titolo: {
        it: `Più Utilizzati`,
        en: `Most Used`,
        de: `Am meisten benutzt`
      },
      ...intervalli_predefiniti
    }
  }
</script>
