<template>
  <RottaNuovoAbbonamento>
    <div class="passo-progresso-abbonamento schermo-intero annullato">
      <div class="contenitore-messaggio">
        <h1 class="errore">
          {t.errori.spiacenti[$l]}.
        </h1>
        <p>
          {t.pagamento_annullato[$l]}.
        </p>
        <p>
          {@html t.errori.contatta_supporto[$l]}.
        </p>
        <div class="azioni">
          <Bottone 
              variante="primario"
              on:click={torna_a_creazione_abbonamento}>
            {t.riprova[$l]}
          </Bottone>
        </div>
      </div>
    </div>
  </RottaNuovoAbbonamento>
</template>

<style>
  :global(div.passo-progresso-abbonamento.annullato){
    display:          flex;
    justify-content:  center;
    align-items:      center;
    height:           calc(100vh - 61px);
  }
  :global(div.passo-progresso-abbonamento.annullato div.contenitore-messaggio h1.errore){
    font-size:    35px;
    line-height:  45px;
  }
</style>

<script>
  import RottaNuovoAbbonamento from "@/portale/componenti/abbonamento/RottaNuovoAbbonamento.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import { abbonamento } from "@/portale/sorgenti/abbonamento"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { onMount } from "svelte"

  const t = avvia_localizzazione(dizionario_annullato)

  // Torna a creazione abbonamento.
  function torna_a_creazione_abbonamento() {
    navigatore.verso("/nuovo_abbonamento/pagamento")
  }
  
  onMount(() => {
    $abbonamento.pagamento_annullato = true
    abbonamento.sovrascrivi($abbonamento)
  })
</script>

<script context="module">
  export const dizionario_annullato = {
    pagamento_annullato: {
      it: `Il pagamento non è andato a buon fine`,
      en: `The payment was canceled`,
      de: `Die Zahlung wurde storniert`
    }    
  }
</script>