<template>
  <Profilo>
    <div class="padding-positivi">
      <Tabella
          {colonne}
          {valore_cella}
          righe={clienti}
          dizionario={t}
          intestazione_fissa={true}
          let:riga={riga}
          let:colonna={colonna}
          let:valore={valore}>
        {#if colonna == ""}
          <Bottone
              piccolo={true}
              variante="primario-invertito"
              on:click={evento => apri_dati_fatturazione(riga)}>
            {t.visualizza[$l]}
          </Bottone>
        {:else}
          {valore_cella(riga, colonna, valore)}
        {/if}
      </Tabella>
    </div>
  </Profilo>

  <Modale
      richiudibile={true}
      titolo={cliente_da_mostrare.ragione_sociale}
      bind:aperta={modale_fatturazione_aperta}>
      <div class="modale-contenuto">
        <TitoloSecondario>{t.indirizzo_fatturazione[$l]}</TitoloSecondario>

        <div class="campo">
          <div class="nome">{t.colonne.ragione_sociale[$l]}</div>
          <div class="valore">{cliente_da_mostrare.ragione_sociale || "-"}</div>
        </div>

        <div class="campo">
          <div class="nome">{t.colonne.indirizzo[$l]}</div>
          <div class="valore">{cliente_da_mostrare.indirizzo || "-"}</div>
        </div>

        <div class="campo">
          <div class="nome">{t.colonne.città[$l]}</div>
          <div class="valore">{cliente_da_mostrare.città || "-"}</div>
        </div>

        <div class="campo">
          <div class="nome">{t.colonne.provincia[$l]}</div>
          <div class="valore">{cliente_da_mostrare.provincia || "-"}</div>
        </div>

        <div class="campo">
          <div class="nome">{t.colonne.cap[$l]}</div>
          <div class="valore">{cliente_da_mostrare.cap || "-"}</div>
        </div>

        <div class="campo">
          <div class="nome">{t.colonne.partita_iva[$l]}</div>
          <div class="valore">{cliente_da_mostrare.partita_iva || "-"}</div>
        </div>

        <div class="campo">
          <div class="nome">{t.colonne.codice_fiscale[$l]}</div>
          <div class="valore">{cliente_da_mostrare.codice_fiscale || "-"}</div>
        </div>

        <TitoloSecondario>{t.fatturazione_elettronica[$l]}</TitoloSecondario>

        <div class="campo">
          <div class="nome">{t.colonne.email_pec[$l]}</div>
          <div class="valore">{cliente_da_mostrare.email_pec || "-"}</div>
        </div>

        <div class="campo">
          <div class="nome">{t.colonne.codice_fiscale[$l]}</div>
          <div class="valore">{cliente_da_mostrare.codice_fiscale || "-"}</div>
        </div>

        <div class="campo">
          <div class="nome">{t.colonne.email_fatturazione[$l]}</div>
          <div class="valore">{cliente_da_mostrare.email_fatturazione || "-"}</div>
        </div>
      </div>
    </Modale>
</template>

<style>
  :global(div.modale-contenuto div.titolo-secondario) {
    margin-bottom: 24px;
  }
  :global(div.modale-contenuto div.campo) {
    display:          flex;
    align-items:      center;
    justify-content:  space-between;
    padding:          0;
    line-height:      24px;
  }
  :global(div.modale-contenuto div.campo:not(:last-child)) {
    margin-bottom:    27px;
  }
  :global(div.modale-contenuto div.campo:nth-child(8)) {
    margin-bottom:    38px;
  }
  :global(div.modale-contenuto div.campo div.nome),
  :global(div.modale-contenuto div.campo div.valore) {
    flex-grow:        1;
    /* flex-basis:       0; */
  }
  :global(div.modale-contenuto div.campo div.valore) {
    display:          flex;
    align-items:      center;
    justify-content:  flex-end;
    font-weight:      700;
  }
</style>

<script>
  import Profilo from "@/portale/componenti/Profilo.svelte"
  import Tabella from "@/base/componenti/Tabella.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Modale from "@/base/componenti/Modale.svelte"
  import TitoloSecondario from "@/base/componenti/TitoloSecondario.svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { onMount } from "svelte";

  const t = avvia_localizzazione(dizionario_dati_fatturazione)
  const colonne = [ "ragione_sociale", "città", "indirizzo", "partita_iva", "" ]

  let clienti = []
  let cliente_da_mostrare = {}
  let modale_fatturazione_aperta = false

  ////
  // Richiede i dati di fatturazione dell'utente corrente
  async function richiedi_dati_fatturazione() {
    let risposta = await retro.chiama(
      "GET",
      retro.estremi.licenze.clienti)
    
    clienti = risposta.contenuto.clienti
  }

  ////
  // Determina il valore di una cella.
  function valore_cella(riga, colonna, valore, indice_riga, indice_colonna) {
    // switch (colonna) {
    //   case "località":        
    //     return nome_località || "-"
    //   default:
        return valore || "-"
    // }
  }

  ////
  // Apre la modale dei dati di fatturazione
  function apri_dati_fatturazione(cliente) {
    cliente_da_mostrare = cliente
    modale_fatturazione_aperta = true
  }
  
  onMount(richiedi_dati_fatturazione)
</script>

<script context="module">
  export const dizionario_dati_fatturazione = {
    indirizzo_fatturazione: {
      it: `Indirizzo di fatturazione`,
      en: `Billing address`,
      de: `Rechnungsadresse`
    },
    fatturazione_elettronica: {
      it: `Informazioni per fatturazione elettronica`,
      en: `Information for electronic invoicing`,
      de: `Informationen zur elektronischen Rechnungsstellung`
    },
    colonne: {
      ragione_sociale: {
        it: `Ragione sociale`,
        en: `Business name`,
        de: `Firmenname`
      },
      indirizzo: {
        it: `Indirizzo`,
        en: `Address`,
        de: `Adresse`
      },
      città: {
        it: `Città`,
        en: `City`,
        de: `Stadt`
      },
      provincia: {
        it: `Provincia`,
        en: `Province`,
        de: `Bundesland`
      },
      cap: {
        it: `CAP`,
        en: `POSTAL CODE`,
        de: `Postleitzahl`
      },
      partita_iva: {
        it: `P.IVA`,
        en: `VAT`,
        de: `MwSt.`
      },
      codice_fiscale: {
        it: `C.Fiscale`,
        en: `Fiscal Code`,
        de: `Steuernummer`
      },
      email_pec: {
        it: `PEC`,
        en: `PEC`,
        de: `PEC`
      },
      codice_sdi: {
        it: `SDI`,
        en: `SDI`,
        de: `SDI`
      },
      email_fatturazione: {
        it: `Altro indirizzo email`,
        en: `Other email address`,
        de: `Andere E-Mail-Adresse`
      },
    }
  }
</script>