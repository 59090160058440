<template>
  <div class="registro">
    <div
        class="contenuto-registro"
        class:altezza-limitata={limita_altezza}
        bind:this={contenitore_registro}>
      {#if registro == null || registro.length == 0}
        <div class="voce-registro">
          - Non sono presenti voci nel registro.
        </div>
      {:else}
        {#each registro as voce_registro, indice_voce}
          {#if indice_voce <= indice_massimo}
            <div
                class="voce-registro {voce_registro[0]} flessibile giustificati-in-mezzo">
              <div class="messaggio-voce-registro">
                {`- ${voce_registro[1]}`}
              </div>

              {#if voce_registro[3] != null}
                <div class="data-voce-registro">
                  {#if voce_registro[3].to_date.format("%d %B %Y") == Date.today.format("%d %B %Y")}
                    {voce_registro[3].to_date.format("%H:%M")}
                  {:else}
                    {voce_registro[3].to_date.format("%d-%m-%Y %H:%M")}
                  {/if}
                </div>
              {/if}
            </div>
          {/if}
        {/each}
      {/if}
    </div>
  </div>
</template>

<style>
  :global(div.registro) {
    height:           100%;
    width:            100%;
    background-color: var(--colore-sfondo-secondario);
    padding:          15px;
  }
  :global(div.registro div.contenuto-registro) {
    height:           100%;
    width:            100%;
    min-height:       300px;
    background-color: var(--colore-sfondo);
    overflow-x:       auto;
    padding:          0 15px;
  }
  :global(div.registro div.contenuto-registro.altezza-limitata) {
    max-height:       400px;
  }
  :global(div.registro div.contenuto-registro div.voce-registro) {
    width:            100%;
    font-family:      monospace;
    font-size:        13px;
  }
  :global(div.registro div.contenuto-registro div.voce-registro.info) {
    color:            skyblue;
  }
  :global(div.registro div.contenuto-registro div.voce-registro.progresso) {
    color:            magenta;
  }
  :global(div.registro div.contenuto-registro div.voce-registro.allerta) {
    color:            orange;
  }
  :global(div.registro div.contenuto-registro div.voce-registro.errore) {
    color:            red;
  }
  :global(div.registro div.contenuto-registro div.voce-registro div.messaggio-voce-registro) {
    flex: 1;
  }
</style>

<script>
  import { onDestroy } from "svelte"
  import { tick } from "svelte"

  export let registro = []
  export let limita_altezza = false

  let contenitore_registro
  let intervallo_attivo
  let indice_massimo = 0

  $: if (registro == null || registro.length == 0 || (indice_massimo + 1) == registro.length) {
    intervallo_attivo = clearInterval(intervallo_attivo)
  }else if (intervallo_attivo == null) {
    renderizza_riga_per_riga()
  }

  ////
  // Scrolla la pagina fino al fondo.
  async function scrolla_in_fondo() {
    await tick()
    contenitore_registro.scrollTo(0,contenitore_registro.scrollHeight)
  }

  ////
  // Renderizza le righe una alla volta.
  function renderizza_riga_per_riga() {
    if (registro == null || registro.length == 0) return
    intervallo_attivo = setInterval(() => {
      indice_massimo++
      scrolla_in_fondo()
    }, 100)
  }

  onDestroy(() => {
    if (intervallo_attivo == null) return
    clearInterval(intervallo_attivo)
  })
</script>
