<template>
  <div
      class="struttura-concorrente"
      transition:fade|local={{ duration: 500 }}>
    {#if struttura.stato != null &&
        stati_verdi.includes(struttura.stato) &&
        struttura.giorni_da_ultima_trasmissione <= 7}
      <Fanalino stato="completato"/>
    {:else}
      <Fanalino/>
    {/if}
    <div class="proprietà">
      <b>
        {struttura.nome} {struttura.categoria}
        {#if struttura.catene != null}
          {#each struttura.catene as catena, indice}
            <em>{catena.nome}</em>
          {/each}
        {/if}
      </b>
      <span>{struttura.indirizzo}, {struttura.città}</span>
      <span>
        {#if $sessione_corrente.tipo == "hotel"}
        {t.codici.hotel.camere_disponibili[$l]}
        {:else}
        {t.codici.camping.unità_disponibili[$l]}            
        {/if}
        
        {#if struttura.unità_disponibili != null}
          {struttura.unità_disponibili}
        {:else}
          -
        {/if}
      </span>
      <span>
        {t.ultimo_aggiornamento_il[$l]}:
        {#if struttura.ultima_trasmissione_il != null}
          {struttura.ultima_trasmissione_il.to_date.format("%d %B %Y")}
        {:else}
          -
        {/if}
      </span>
    </div>
    {#if aggiungibile}
      <Bottone
          {disabilitato}
          on:click={evento => propaga("aggiunta", { struttura })}
          variante="primario-invertito">
        <Icona tipo="angolare-destra"/>
      </Bottone>
    {/if}
    {#if rimuovibile}
      <Bottone
          on:click={evento => propaga("rimossa", { struttura })}
          variante="primario-invertito">
        <Icona tipo="cestino"/>
      </Bottone>
    {/if}
  </div>
</template>

<style>
  :global(div.modulo-concorrente div.struttura-concorrente) {
    display:        flex;
    align-items:    center;
    background:     var(--colore-bianco);
    padding:        7px;
    margin-bottom:  7px;
    white-space:    nowrap;
    overflow:       hidden;
    text-overflow:  ellipsis;
    box-shadow:     0 5px 10px 0 var(--colore-ombreggiatura);
  }
  :global(div.modulo-concorrente div.struttura-concorrente > div.proprietà) {
    display:        flex;
    flex-direction: column;
    margin-left:    14px;
    margin-right:   auto;
  }
</style>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Fanalino from "@/base/componenti/Fanalino.svelte"
  import { sessione_corrente } from "@/portale/sorgenti/sessione"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { dizionario_indicatore } from "@/base/componenti/statistiche/Indicatore.svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { fade } from "svelte/transition"
  import { createEventDispatcher } from "svelte"

  export let struttura = {}
  export let aggiungibile = false
  export let rimuovibile = false
  export let disabilitato = false

  const stati_verdi = [ "non_sta_trasmettendo", "codifiche_mancanti", "tutto_a_posto" ]
  const propaga = createEventDispatcher()
  const t = avvia_localizzazione(dizionario_struttura)
</script>

<script context="module">
  export const dizionario_struttura = {
    ...dizionario_indicatore,
    ultimo_aggiornamento_il: {
      it: `Ultimo aggiornamento il`,
      en: `Last updated at`,
      de: `Zuletzt aktualisiert am`
    }
  }
</script>