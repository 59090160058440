const lettori = {
  accor_storico: {
    nome: {
      it: `Accor - Storico`,
      en: ``,
      de: ``
    }
  },
  accor_previsionale: {
    nome: {
      it: `Accor - Previsionale`,
      en: ``,
      de: ``
    }
  },
  api_disponibilità: {
    nome: {
      it: `API Disponibilità`,
      en: ``,
      de: ``
    }
  },
  api_hbenchmark: {
    nome: {
      it: `API HBenchmark`,
      en: ``,
      de: ``
    }
  },
  api_hbenchmark_sgs: {
    nome: {
      it: `SGS - API HBenchmark`,
      en: ``,
      de: ``
    }
  },
  casy: {
    nome: {
      it: `Casy`,
      en: ``,
      de: ``
    }
  },
  dylog: {
  },
  elle_hotel: {
  },
  ericsoft: {
  },
  evols_nuconga: {
  },
  evols_web_hotel: {
  },
  evols_web_hotel_obsoleto: {
  },
  fidelio: {
  },
  fidelio_data_cancellazione: {
  },
  gialb: {
  },
  hogatex: {
  },
  hotel_5_stelle: {
  },
  hotel_cube: {
  },
  hotel_cube_data_cancellazione: {
  },
  hotel_druid: {
  },
  hs3: {
  },
  melia: {
  },
  mews: {
  },
  opera_allotment: {
  },
  opera_allotment_ultimo: {
  },
  opera_data_cancellazione_esteso: {
  },
  opera_data_cancellazione: {
  },
  opera_email_historic: {
  },
  opera_email_future: {
  },
  opera_oxi: {
    nome: {
      it: `Opera OXI`,
      en: ``,
      de: ``
    }
  },
  opera_ultimo: {
  },
  opera_ultimo_tab: {
  },
  opera_ultimo_comma: {
  },
  pcs: {
  },
  protel: {
  },
  protel_io: {
    nome: {
      it: `Protel I/O`,
      en: ``,
      de: ``
    }
  },
  protel_air: {
  },
  protel_k00553: {
  },
  protel_svizzera: {
  },
  protel_svizzera_data_cancellazione: {
  },
  reservation_management_system: {
  },
  scrigno: {
  },
  sysdat_storico: {
  },
  sysdat_previsionale: {
  },
  tracciato_hotel: {
    nome: {
      it: `Tracciato Hotel`,
      en: ``,
      de: ``
    }
  },
  tracciato_istat: {
  },
  una_prenotazioni: {
    nome: {
      it: `UNA - Prenotazioni`,
      en: ``,
      de: ``
    }
  },
  una_data_creazione: {
    nome: {
      it: `UNA - DataCreazione`,
      en: ``,
      de: ``
    }
  },
  wubook: {
  }
}

export { lettori }
