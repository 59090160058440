<template>
  <Messaggio>
    <div class="loghi-contenitore genova" slot="loghi">
      <img src={window.risolviImg(logo)} alt="HBenchmark Logo White" height="52">

      <div class="contenitore-immagine">
        <img src={window.risolviImg(logo_comune_genova)} alt="Comune di Genova">
      </div>
    </div>
  </Messaggio>
</template>

<style>
  :global(div#accesso div.loghi-contenitore.genova){
    width:            125%;
    display:          flex;
    justify-content:  space-between;
    align-items:      center;
  }
  :global(div#accesso div.loghi-contenitore.genova div.contenitore-immagine){
    height: 80px;
  }
  :global(div#accesso div.loghi-contenitore.genova div.contenitore-immagine img){
    height: 100%;
  }
</style>

<script>
  import Messaggio from "@/base/componenti/accesso/Messaggio.svelte"
  import logo from "@/base/immagini/logo-completo.svg"
  import logo_comune_genova from "@/base/immagini/loghi/logo-genova-comune-colori.png"
</script>
