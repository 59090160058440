<template>
  <div class="schedario">
    <div class="etichette">
      {#each schede as scheda, indice}
        <div
            class="etichetta"
            class:componente={scheda.etichetta != null && scheda.etichetta.componente != null}
            class:corrente={indice == indice_visualizzato}
            on:click={evento => cambia_scheda_visualizzata(indice)}>
          {#if scheda.etichetta == null}
            Etichetta {indice + 1}
          {:else if scheda.etichetta.componente != null}
            <svelte:component
                this={scheda.etichetta.componente}
                {...scheda.etichetta.proprietà}/>
          {:else}
            {scheda.etichetta}
          {/if}
        </div>
      {/each}
    </div>

    <div class="schede">
      {#if !in_cambio}
        <div class="scheda">
          {#if scheda_visualizzata.contenuto == null}
            Contenuto {indice_visualizzato + 1}
          {:else if scheda_visualizzata.contenuto.componente != null}
            <svelte:component
                this={scheda_visualizzata.contenuto.componente}
                {...scheda_visualizzata.contenuto.proprietà}/>
          {:else}
            {scheda_visualizzata.contenuto}
          {/if}
        </div>        
      {/if}
    </div>
  </div>
</template>

<style>        
  :global(div.schedario) {
    height:         100%;
    display:        flex;
    flex-direction: column;
  }

  /* Etichette. */
  :global(div.schedario > div.etichette) {
    display:    flex;
    width:      100%;
  }
  :global(div.schedario > div.etichette > div.etichetta:not(.componente)) {
    padding:        14px;
    margin-right:   7px;
    text-transform: uppercase;
    cursor:         pointer;
  }
  :global(div.schedario > div.etichette > div.etichetta.corrente:not(.componente)) {
    color:          var(--colore-primario);
    font-weight:    500;
    border-bottom:  2px solid var(--colore-primario);
  }

  /* Schede. */
  :global(div.schedario > div.schede) {
    height:     100%;
    flex-grow:  1;
  }
  :global(div.schedario > div.schede > div.scheda) {
    box-shadow: none;
  }
</style>

<script>
  import { tick } from "svelte"

  export let schede = [];
  export let indice_visualizzato = 0;

  let scheda_visualizzata = schede[indice_visualizzato]
  let in_cambio = false

  ////
  // Cambia la scheda visualizzata.
  async function cambia_scheda_visualizzata(indice) {
    in_cambio = true
    await tick()
    indice_visualizzato = indice;
    scheda_visualizzata = schede[indice_visualizzato]
    in_cambio = false
  }

  // Quando cambiano le schede, aggiorna la scheda visualizzata.
  $: if (schede != null) cambia_scheda_visualizzata(indice_visualizzato)
</script>
