<template>
  {#if DOMINI_CON_BARRA_MESSAGGIO.include($dominio)}
    <div class="messaggio-dominio">
      <BarraMessaggio
          messaggio={t.messaggi_per_dominio[$dominio][$l]}
          richiudibile={true}/>
    </div>
  {/if}
</template>

<style>
  :global(div.messaggio-dominio>div.barra-messaggio) {
    margin:           15px;
  }
</style>

<script>
  import BarraMessaggio from "@/base/componenti/BarraMessaggio.svelte"
  import { dominio } from "@/portale/sorgenti/dominio"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  const t = avvia_localizzazione(dizionario_messaggio_dominio)
  const DOMINI_CON_BARRA_MESSAGGIO = [ "veneto", "bccveneto", "padova", "livigno", "firenze", "genova", "bergamo" ]
</script>

<script context="module">
  export const dizionario_messaggio_dominio = {
    messaggi_per_dominio: {
      veneto: {
        it: `Al termine della registrazione riceverai la fattura riportante in oggetto la seguente dicitura: &laquo;Spesa agevolata a valere sul POR FESR Veneto 2014-2020, Azione 3.3.4 sub C DGR1390/2020&raquo;`,
        en: `At the end of the registration you will receive, following the payment, receipt and invoice bearing the following wording: &laquo;Spesa agevolata a valere sul POR FESR Veneto 2014-2020, Azione 3.3.4 sub C DGR1390/2020&raquo;`,
        de: `Am Ende der Anmeldung erhalten Sie nach erfolgter Zahlung eine Quittung und Rechnung mit folgendem Wortlaut: &laquo;Spesa agevolata a valere sul POR FESR Veneto 2014-2020, Azione 3.3.4 sub C DGR1390/2020&raquo;`
      },
      bccveneto: {
        it: `Al termine della registrazione riceverai, al seguito dell'effettuato pagamento, quietanza e fattura riportante in oggetto la seguente dicitura: &laquo;Spesa agevolata a valere sul POR FESR Veneto 2014-2020, Azione 3.3.4 sub C DGR1390/2020&raquo;`,
        en: `At the end of the registration you will receive, following the payment, receipt and invoice bearing the following wording: &laquo;Spesa agevolata a valere sul POR FESR Veneto 2014-2020, Azione 3.3.4 sub C DGR1390/2020&raquo;`,
        de: `Am Ende der Anmeldung erhalten Sie nach erfolgter Zahlung eine Quittung und Rechnung mit folgendem Wortlaut: &laquo;Spesa agevolata a valere sul POR FESR Veneto 2014-2020, Azione 3.3.4 sub C DGR1390/2020&raquo;`
      },
      padova: {
        it: `Il tuo abbonamento ad <b>HBenchmark</b> viene fornito gratuitamente da <b>Padova Convention Bureau</b>. Non dovrai quindi pagare il canone di licenza annuale. La licenza offerta è quella del piano PLUS. <em>Quest'offerta è riservata ai soli operatori della provincia di Padova.</em>`,
        en: `Your subscription to <b>HBenchmark</b> is provided free of charge by <b>Padova Convention Bureau</b>. You will therefore not have to pay the annual license fee. The license offered is that of the PLUS plan. <em>This offer is reserved for operators in the province of Padua only.</em>`,
        de: `Ihr Abonnement von <b>HBenchmark</b> wird kostenlos vom <b>Padova Convention Bureau</b> bereitgestellt. Sie müssen daher die jährliche Lizenzgebühr nicht bezahlen. Die angebotene Lizenz ist die des PLUS-Plans. <em>Dieses Angebot ist nur Betreibern in der Provinz Padua vorbehalten.</em>`
      },
      livigno: {
        it: `Il tuo abbonamento ad <b>HBenchmark</b> viene fornito gratuitamente da <b>APT Livigno</b> in collaborazione con <b>ATC Livigno</b>. Non dovrai quindi pagare il canone di licenza annuale. La licenza offerta è quella del piano PLUS. <em>Quest'offerta è riservata ai soli operatori del comune di Livigno.</em>`,
        en: `Your subscription to <b>HBenchmark</b> is provided free of charge by <b>APT Livigno</b> in collaboration with <b>ATC Livigno</b>. You will therefore not have to pay the annual license fee. The license offered is that of the PLUS plan. <em>This offer is reserved for operators in the municipality of Livigno only.</em>`,
        de: `Ihr Abonnement für <b>HBenchmark</b> wird kostenlos von <b>APT Livigno</b> in Zusammenarbeit mit <b>ATC Livigno</b> bereitgestellt. Sie müssen daher die jährliche Lizenzgebühr nicht bezahlen. Die angebotene Lizenz ist die des PLUS-Plans. <em>Dieses Angebot ist nur Betreibern in der Gemeinde Livigno vorbehalten.</em>`
      },
      firenze: {
        it: `HBenchmark insieme a ChiantiBanca e Banco Fiorentino offrono alle strutture del territorio di Firenze l'opportunità di iscriversi gratuitamente (entro il <b>31 Dicembre 2021</b>) per 1 anno. L’unica spesa che dovrai sostenere è l'integrazione con il tuo PMS al costo di 350€ una tantum. Al termine dell’annualità il presente contratto non prevede tacito rinnovo: riceverai dunque un’offerta dedicata per rimanere all’interno della nostra piattaforma di Hospitality Data Intelligence.`,
        en: `HBenchmark together with ChiantiBanca and Banco Fiorentino offer structures in the Florence area the opportunity to register for free (by <b>31 Dicember 2021</b>) for 1 year. The only expense you will have to bear is the integration with your PMS at a one-time cost of € 350. At the end of the annuity, this contract does not provide for tacit renewal: you will therefore receive a dedicated offer to stay within our Hospitality Data Intelligence platform.`,
        de: `HBenchmark bietet zusammen mit ChiantiBanca und Banco Fiorentino Strukturen im Raum Florenz die Möglichkeit, sich kostenlos (bis <b>31. Dezember 2021</b>) für 1 Jahr zu registrieren. Der einzige Aufwand, den Sie tragen müssen, ist die Integration mit Ihrem PMS zu einmaligen Kosten von 350 €. Am Ende der Rente sieht dieser Vertrag keine stillschweigende Verlängerung vor: Sie erhalten daher ein spezielles Angebot für den Verbleib auf unserer Hospitality Data Intelligence-Plattform.`
      },
      genova: {
        it: `Il tuo abbonamento ad <b>HBenchmark</b> viene fornito gratuitamente dal <b>Comune di Genova</b>. Non dovrai quindi pagare il canone di licenza annuale. La licenza offerta è quella del piano PLUS. <em>Quest'offerta è riservata ai soli operatori del comune di Genova</em>.`,
        en: `Your subscription to <b>HBenchmark</b> is provided free of charge by the <b>Municipality of Genoa</b>. You will therefore not have to pay the annual license fee. The license offered is that of the PLUS plan. <em>This offer is reserved for operators in the municipality of Genoa only</em>.`,
        de: `Ihr Abonnement von <b>HBenchmark</b> wird von der <b>Gemeinde Genua</b> kostenlos zur Verfügung gestellt. Sie müssen daher die jährliche Lizenzgebühr nicht bezahlen. Die angebotene Lizenz ist die des PLUS-Plans. <em>Dieses Angebot ist nur Betreibern in der Gemeinde Genua vorbehalten</em>.`
      },
      bergamo: {
        it: `Il tuo abbonamento ad <b>HBenchmark</b> viene fornito gratuitamente da <b>VisitBergamo</b>. Non dovrai quindi pagare il canone di licenza annuale. La licenza offerta è quella del piano PLUS. <em>Quest'offerta è riservata ai soli operatori della provincia di Bergamo</em>.`,
        en: `Your subscription to <b>HBenchmark</b> is provided free of charge by <b>VisitBergamo</b>. You will therefore not have to pay the annual license fee. The license offered is that of the PLUS plan. <em>This offer is reserved for operators in the province of Bergamo only</em>.`,
        de: `Ihr Abonnement von <b>HBenchmark</b> wird von <b>VisitBergamo</b> kostenlos zur Verfügung gestellt. Sie müssen daher die jährliche Lizenzgebühr nicht bezahlen. Die angebotene Lizenz ist die des PLUS-Plans. <em>Dieses Angebot ist nur Betreibern in der Provinz Bergamo vorbehalten</em>.`
      }
    }
  }
</script>