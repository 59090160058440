<template>
  <GestioneStruttura>
    {#if in_caricamento}
      <Caricatore/>
    {:else}
      <div class="margini-positivi">

        <TabellaCodifiche
        {codifiche}
        {struttura}
        {segmenti}
        {in_salvataggio}
        {salvataggio_riuscito}
        on:change={richiedi_codifiche}
        on:salva={salva_modifiche}/>
      </div>
    {/if}
  </GestioneStruttura>
</template>

<script>
  import GestioneStruttura from "@/portale/componenti/GestioneStruttura.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import TabellaCodifiche from "@/base/componenti/tabelle/Codifiche.svelte"
  import { onDestroy } from "svelte"
  import { sessione_corrente } from "@/portale/sorgenti/sessione"

  let in_caricamento = true
  let struttura = {}
  let codifiche = []
  let segmenti = {}
  let in_salvataggio = false
  let salvataggio_riuscito = false

  ////
  // Richiede le codifiche.
  async function richiedi_codifiche() {
    in_caricamento = true

    let risposta = await retro.chiama(
      "GET",
      retro.estremi.portale.codifiche,
      { id_struttura: $sessione_corrente._id })

    codifiche = risposta.contenuto.codifiche
    struttura = risposta.contenuto.struttura
    segmenti = risposta.contenuto.segmenti

    in_caricamento = false
  }

  ////
  // Salva le modifiche.
  function salva_modifiche(evento) {
    in_salvataggio = true

    let parametri = {
      id_struttura: $sessione_corrente._id,
      codifiche: Object.values(evento.detail.codifiche_da_aggiornare) }

    retro.chiama("PATCH", retro.estremi.portale.codifiche, parametri, {
      successo: () => {},
      fallimento: () => {} })

    setTimeout(() => {
      in_salvataggio = false
      salvataggio_riuscito = true
      // messaggio_visualizzato = true
    }, 5000)
  }

  // Al cambio sessione, richiede le codifiche.
  const scollega = sessione_corrente.su_cambio(richiedi_codifiche)
  // Quando distrutto, scollega i cambi.
  onDestroy(scollega)
</script>
