<template>
  <section id="modulo-registrazione" bind:this={elemento}>
    <div class="modulo">
      <h2>{t.crea_account[$l]}</h2>
      <Modulo
          {indirizzo}
          {parametri}
          metodo="POST"
          gestore={gestore_risposta}
          validatore={validatore_modulo}
          bind:in_salvataggio={registrazione_in_corso}
          bind:errori={errori}>

        {#if errori.generico != null}
          <div class="errore-generico">
            {t.errori[errori.generico][$l]}.
          </div>
        {/if}

        <div class="nome-cognome">
          <Inserimento
              tipo="testuale"
              etichetta={t.nome[$l]}
              segnaposto={t.segnaposto_nome[$l]}
              errore={errori.nome != null ? t.validazioni[errori.nome][$l] : null}
              bind:valore={nome}/>

          <Inserimento
              tipo="testuale"
              etichetta={t.cognome[$l]}
              segnaposto={t.segnaposto_cognome[$l]}
              errore={errori.cognome != null ? t.validazioni[errori.cognome][$l] : null}
              bind:valore={cognome}/>
        </div>
        <Inserimento
            tipo="email"
            etichetta={t.email[$l]}
            segnaposto={t.segnaposto_email[$l]}
            errore={errori.email != null ? t.validazioni[errori.email][$l] : null}
            bind:valore={email}/>
        <!-- <Inserimento
            tipo="testuale"
            etichetta={t.telefono[$l]}
            segnaposto={t.segnaposto_telefono[$l]}
            errore={errori.telefono != null ? t.validazioni[errori.telefono][$l] : null}
            bind:valore={telefono}/> -->
        <Inserimento
            tipo="password"
            etichetta={t.password[$l]}
            segnaposto={t.segnaposto_password[$l]}
            errore={errori.password != null ? t.validazioni[errori.password][$l] : null}
            bind:valore={password}/>
        <Inserimento
            tipo="password"
            etichetta={t.conferma_password[$l]}
            segnaposto={t.segnaposto_conferma_password[$l]}
            errore={errori.conferma_password != null ? t.validazioni[errori.conferma_password][$l] : null}
            bind:valore={conferma_password}/>
        {#if registrazione_in_corso}
          <Caricatore/>
        {:else}
          <div class="azioni">
            <Bottone tipo="invio">
              {t.iscriviti[$l]}
            </Bottone>
          </div>
          <div class="collegamenti">
            <div class="collegamento">
              <span>{t.accetta_privacy[$l]}</span>
              <a href="{collegamenti_riserbo[$l]}" target="_blank">
                Privacy Policy
              </a>
            </div>
            <div class="collegamento">
              <span>{t.hai_dimenticato_la_password[$l]}</span>
              <a href="{'#'}"
                  on:click|preventDefault={vai_a_recupero_password}>
                {t.recupera[$l]}
              </a>
            </div>
            <div class="collegamento">
              <span>{t.sei_già_registrato[$l]}</span>
              <a href="{'#'}"
                  on:click|preventDefault={vai_a_accesso}>
                {t.accedi[$l]}
              </a>
            </div>
          </div>
        {/if}
      </Modulo>
    </div>
  </section>
</template>

<style>
  :global(section#modulo-registrazione) {
    width:          38.2%;
    max-width:      450px;
    max-height:     95%;
    transform:      translateY(-50%);
    position:       fixed;
    top:            50%;
    left:           43%;
    display:        flex;
    align-items:    flex-start;
    overflow:       auto;
  }
  :global(section#modulo-registrazione div.modulo) {
    width:          100%;
    background:     var(--colore-sfondo);
    padding:        32px;
    border-radius:  5px;
    box-shadow:     0px 10px 20px rgba(0, 0, 0, 0.1);
  }
  :global(section#modulo-registrazione div.modulo div.nome-cognome) {
    display:  flex;
  }
  :global(section#modulo-registrazione div.modulo div.nome-cognome div.inserimento) {
    width:    calc(50% - 7px);
  }
  :global(section#modulo-registrazione div.modulo div.nome-cognome div.inserimento:first-child) {
    margin-right: 14px;
  }
  :global(section#modulo-registrazione div.modulo h2) {
    margin-top: 0;
    color:      var(--colore-titolo);
  }
  :global(section#modulo-registrazione div.errore-generico) {
    color:          var(--colore-rosso);
    margin-bottom:  14px;
  }
  :global(section#modulo-registrazione div.modulo form) {
    width:          100%;
  }
  :global(section#modulo-registrazione div.modulo div.collegamenti) {
    margin-top:     28px;
  }
  :global(section#modulo-registrazione div.modulo div.collegamenti div.collegamento) {
    display:          flex;
    align-items:      center;
    justify-content:  flex-start;
    font-size:        12px;
  }
  :global(section#modulo-registrazione div.modulo div.collegamenti div.collegamento a) {
    margin:           0 8px;
    line-height:      30px;
    font-size:        12px;
  }
</style>

<script>
  import Modulo from "@/base/componenti/Modulo.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import { onMount } from "svelte"
  import { onDestroy } from "svelte"
  import { utente_corrente } from "@/base/sorgenti/svuby"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  const t = avvia_localizzazione(dizionario_registrazione)
  const indirizzo = retro.estremi.anagrafe.registra
  const gestione_errore_precedente = window.onunhandledrejection
  const collegamenti_riserbo = {
    it: `https://www.iubenda.com/privacy-policy/81333460`,
    en: `https://www.iubenda.com/privacy-policy/40732172`,
    de: `https://www.iubenda.com/privacy-policy/74782468`
  }

  let elemento
  let nome = null
  let cognome = null
  let email = null
  let password = null
  let conferma_password = null
  let telefono = null
  let errori = { nome: null, cognome: null, email: null, telefono: null, password: null, conferma_password: null, generico: null }
  let registrazione_in_corso = false

  // Parametri accesso.
  $: parametri = {utente: { nome, cognome, email, telefono, password, conferma_password }}

  // Aggiunge i valori iniziali da Trentino Marketing.
  let parametri_trentino_marketing = deposito_locale.prendi("parametri_trentino_marketing") || {}

  if (parametri_trentino_marketing.nome != null)            nome = parametri_trentino_marketing.nome
  if (parametri_trentino_marketing.cognome != null)         cognome = parametri_trentino_marketing.cognome
  if (parametri_trentino_marketing.email != null)           email = parametri_trentino_marketing.email
  if (parametri_trentino_marketing.emailStruttura != null)  email = parametri_trentino_marketing.emailStruttura

  ////
  // Effettua l'accesso autenticando l'utente.
  function registra(risposta) {
    biscottini.deposita("gettone", risposta.contenuto.gettone)
    utente_corrente.cambia_in(risposta.contenuto.utente)

    console.log(`Autenticato: ${$utente_corrente.email.colore("azzurro")}`)
    if ($utente_corrente.ruolo == "ospite") location.reload()
    else navigatore.verso("/")
  }

  ////
  // Va alla pagina di recupero della password.
  function vai_a_recupero_password(evento) {
    navigatore.verso("/recupero")
  }

  ////
  // Va alla pagina di recupero della password.
  function vai_a_accesso(evento) {
    navigatore.verso("/accesso")
  }

  ////
  // Validazione del modulo.
  function validatore_modulo() {
    if (nome == null || nome == "")
      errori.nome = "richiesto"
    if (cognome == null || cognome == "")
      errori.cognome = "richiesto"
    if (email == null || email == "")
      errori.email = "richiesto"
    if (valida_password(password))
      errori.password = "validazione_password"
    if (password == null || password == "")
      errori.password = "richiesto"
    if (conferma_password == null || conferma_password == "")
      errori.conferma_password = "richiesto"
    if (password != conferma_password)
      errori.generico = "conferma_password_non_valida"
  }

  ////
  // Gestisce gli errori di autenticazione.
  function gestisci_errore_autenticazione(messaggio, sorgente, riga, colonna, errore) {
    if (messaggio != null && messaggio.reason != null && messaggio.reason.non_autorizzato) {
      errori.generico = "credenziali_non_valide"
    } else {
      gestione_errore_precedente(...arguments)
    }

    registrazione_in_corso = false
  }

  ////
  // Gestione della gestione del modulo.
  function gestore_risposta(risposta) {
    if (risposta.riuscita) return registra(risposta)
    switch (risposta.stato) {
      case 404:
        errori.generico = "credenziali_non_valide"
        break
      case 422:
        if (risposta.contenuto && (risposta.contenuto.errore == "esiste già un utente con la stessa email" ||
          risposta.contenuto.errore == "questa email è già registrata")) {
          errori.generico = "email_gia_esistente"
          break
        }
      default:
        errori.generico = "altro"
    }

    registrazione_in_corso = false
  }

  ////
  // Verifica se il password corrisponde ai criteri prestabiliti
  function valida_password(password) {
    if (password == null) return false

    let verica_lunghezza = password.length < 8
    let verifica_minuscole = !contiene_minuscole(password)
    let verifica_maiuscole = !contiene_maiuscole(password)
    let verifica_numeri = !contiene_numeri(password)

    return verica_lunghezza || verifica_minuscole || verifica_maiuscole || verifica_numeri
  }

  ////
  // Verifica se ci sono lettere minuscole in una stringa
  function contiene_minuscole(stringa) {
    return stringa.toUpperCase() != stringa
  }

  ////
  // Verifica se ci sono lettere maiuscole in una stringa
  function contiene_maiuscole(stringa) {
    return stringa.toLowerCase() != stringa
  }

  ////
  // Verifica se ci sono numeri in una stringa
  function contiene_numeri(stringa) {
    return /\d/.test(stringa);
  }

  // Gestione errore autenticazione.
  onMount(() => window.onunhandledrejection = gestisci_errore_autenticazione)
  onDestroy(() => window.onunhandledrejection = gestione_errore_precedente)
</script>

<script context="module">
  export const dizionario_registrazione = {
    nome: {
      it: `Nome *`,
      en: `Name *`,
      de: `Name *`
    },
    cognome: {
      it: `Cognome *`,
      en: `Last name`,
      de: `Nachname`
    },
    email: {
      it: `Email *`,
      en: `Email *`,
      de: `E-Mail *`
    },
    telefono: {
      it: `Telefono *`,
      en: `Phone`,
      de: `Telefonnummer`
    },
    password: {
      it: `Password *`,
      en: `Password *`,
      de: `Passwort *`
    },
    conferma_password: {
      it: `Conferma Password *`,
      en: `Password Confirmation *`,
      de: `Passwort Bestätigung *`
    },
    iscriviti: {
      it: `Iscriviti`,
      en: `Subscribe`,
      de: `Abonnieren`
    },
    crea_account: {
      it: `Crea il tuo account`,
      en: `Create your account`,
      de: `Account erstellen`
    },
    recupera: {
      it: `Recupera`,
      en: `Recover`,
      de: `Wiederherstellen`
    },
    accedi: {
      it: `Accedi`,
      en: `Login`,
      de: `Anmeldung`
    },
    segnaposto_nome: {
      it: `Inserisci il tuo nome`,
      en: `Insert your name`,
      de: `Namen eingeben`
    },
    segnaposto_cognome: {
      it: `Inserisci il tuo cognome`,
      en: `Please enter your surname`,
      de: `Bitte geben Sie Ihren Nachnamen ein`
    },
    segnaposto_email: {
      it: `Inserisci la tua email`,
      en: `Insert your email`,
      de: `E-Mail eingeben`
    },
    segnaposto_telefono: {
      it: `Inserisci il tuo telefono`,
      en: `Enter your phone`,
      de: `Telefonnummer eingeben`
    },
    segnaposto_password: {
      it: `Inserisci la tua password`,
      en: `Enter your password`,
      de: `Passwort eingeben`
    },
    segnaposto_conferma_password: {
      it: `Inserisci nuovamente la tua password`,
      en: `Re-enter your password`,
      de: `Passwort wiederholen`
    },
    hai_dimenticato_la_password: {
      it: `Hai dimenticato la password?`,
      en: `Forgot your password?`,
      de: `Passwort vergessen?`
    },
    sei_già_registrato: {
      it: `Hai già un account?`,
      en: `Already have an account?`,
      de: `Sie haben bereits ein Konto?`
    },
    accetta_privacy: {
      it: `Cliccando su Iscriviti accetti la nostra`,
      en: `By clicking on Subscribe you accept ours`,
      de: `Durch Klicken auf Abonnieren akzeptieren Sie unsere`
    },
    errori: {
      credenziali_non_valide: {
        it: `Credenziali non valide`,
        en: `Invalid credentials`,
        de: `Ungültige Anmeldeinformationen`
      },
      conferma_password_non_valida: {
        it: `La password non corrisponde con la conferma`,
        en: `Password does not match confirmation`,
        de: `Passwort stimmt nicht mit Bestätigung überein`
      },
      email_gia_esistente: {
        it: `Esiste già un utente con questa email`,
        en: `A user with this email already exists`,
        de: `Ein Benutzer mit dieser E-Mail Adresse existiert bereits`
      },
      altro: {
        it: `Si è verificato un errore`,
        en: `An error occurred`,
        de: `Ein Fehler ist aufgetreten`
      }
    }
  }
</script>