<template>
  <div class="inserimento archivio">
    <div class="contenitore-inserimento">
      <label disabled={in_invio ? true : null}>
        {#if lista_archivi.length == 0}
          {t.scegli_archivi[$l]}
        {:else}
          {t.cambia_archivi[$l]}
        {/if}
        <input 
            type="file"
            multiple="true"
            class="inserimento-archivio"
            name={nome}
            placeholder={segnaposto}
            disabled={disabilitato}
            bind:value={valore}
            on:change={popola_lista_archivi}/>
      </label>
      {#if lista_archivi.length > 0}
        <Bottone 
            variante="primario"
            disabilitato={in_invio}
            on:click={invia_archivi}>
          {#if in_invio}
            {t.in_invio[$l]}
          {:else}
            {t.invia[$l]}
          {/if}
        </Bottone>
      {/if}
    </div>

    <div class="lista-archivi">
      {#if in_invio}
        <Caricatore/>
      {:else if lista_archivi.length > 0}
        {#each lista_archivi as archivio, indice}
          <div class="archivio">
            <span>{archivio.nome}</span>
            <span>{archivio.dimensioni}</span>
          </div>
        {/each}
      {/if}
    </div>
  </div>
</template>

<style>
  :global(div.inserimento.archivio) {
    display:        flex;
    flex-direction: column;
  }
  :global(div.inserimento.archivio > div.contenitore-inserimento) {
    display:        flex;
    align-items:    center;
  }
  :global(div.inserimento.archivio label) {
    display:          flex;
    justify-content:  center;
    align-items:      center;
    padding:          10.5px 21px;
    border-radius:    0;
    margin:           0;
    text-transform:   uppercase;
    font-weight:      600;
    line-height:      24px;
    letter-spacing:   0.55px;
    white-space:      nowrap;
    border:           1px solid transparent;
    color:            var(--colore-primario);
    cursor:           pointer;
  }
  :global(div.inserimento.archivio label:hover) {
    background-color: var(--colore-sorvolo);
  }
  :global(div.inserimento.archivio label > input[type="file"]) {
    opacity:          0;
    position:         absolute;
    z-index:          -1;
  }
  :global(div.inserimento.archivio div.lista-archivi) {
    display:          flex;
    flex-direction:   column;
    padding:          0 14px;
  }
  :global(div.inserimento.archivio div.lista-archivi > div.archivio) {
    display:          flex;
    justify-content:  space-between;
    background:       var(--colore-bianco);
    box-shadow:       0 5px 10px 0 var(--colore-ombreggiatura);
    padding:          14px;
    margin:           14px 0;
  }
</style>

<script>
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import { createEventDispatcher } from "svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  export let nome            = ""
  export let valore          = ""
  export let segnaposto      = ""
  export let disabilitato    = false
  export let indirizzo       = null
  export let metodo          = "POST"
  export let intestazioni    = {}
  export let parametri       = {}
  export let lista_archivi   = []

  const t = avvia_localizzazione(dizionario_archivio)
  const propaga = createEventDispatcher()

  // Dimensioni
  const kilobyte = 1024
  const megabyte = kilobyte * 1024
  const gigabyte = megabyte * 1024

  let in_invio = false

  // Aggiorna la lista archivi.
  function popola_lista_archivi(evento) {
    lista_archivi = Array.from(evento.target.files).map(archivio => {
      return { nome: archivio.name, dimensioni: calcola_dimensioni(archivio.size), puntatore: archivio }
    })
  }

  // Calcola dimensioni archivio.
  function calcola_dimensioni(dimensioni) {
    if (dimensioni < kilobyte) {
      return `${dimensioni} byte`
    } else if (dimensioni < megabyte) {
      return `${(dimensioni / kilobyte).round(2)} KB`
    } else if (dimensioni < gigabyte) {
      return `${(dimensioni / megabyte).round(2)} MB`
    } else {
      return `${(dimensioni / gigabyte).round(2)} GB`
    }
  }

  // Invia archivi.
  async function invia_archivi() {
    in_invio = true

    let indirizzo_modulo = indirizzo
    if (Object.keys(parametri).length > 0) indirizzo_modulo = `${indirizzo}?${serializza(parametri)}`

    let dati_modulo = new FormData()
    lista_archivi.each((archivio, indice) => {
      dati_modulo.append(`archivio_${indice}`, archivio.puntatore, archivio.nome)
    })

    propaga("invio", { dati_modulo })

    let risposta = await fetch(
      indirizzo_modulo,
      { method: metodo, headers: intestazioni, body: dati_modulo })

    lista_archivi = []
    in_invio = false

    propaga("risposta", { risposta })
  }
</script>

<script context="module">
  export const dizionario_archivio ={
    in_invio: {
      it: `Invio in corso..`,
      en: `Sending..`,
      de: `Sendet..`
    },
    scegli_archivi: {
      it: `Scegli File`,
      en: `Choose Files`,
      de: `Dateien wählen`
    },
    cambia_archivi: {
      it: `Cambia File`,
      en: `Change Files`,
      de: `Dateien ändern`
    }
  }
</script>