<template>
  <div 
      class="pagamento-abbonamento"
      class:in-invio={in_invio}
      class:nascosto={nascosto}>

    {#if errore_creazione_sessione_pagamento || errore_creazione_abbonamento}
      <p class="errore">
        {t.errori.spiacenti[$l]}.
      </p>
      <p>
        {@html t.errori.contatta_supporto[$l]}.
      </p>
    {/if}

    <div class="azioni">
      <Bottone 
          variante="primario"
          disabilitato={in_invio}
          on:click={crea_sessione_pagamento}>
        {t.continua[$l]}
      </Bottone>

      <Bottone 
          variante="testo-leggero-invertito"
          on:click={torna_indietro}>
        {t.torna_indietro[$l]}
      </Bottone>
    </div>
  </div>
</template>

<style>
  :global(div.pagamento-abbonamento.in-invio) {
    display:  none;
  }
  :global(div.pagamento-abbonamento.nascosto) {
    display:  none;
  }
  :global(div.pagamento-abbonamento h4) {
    margin:   28px 0 7px 0;
  }
  :global(div.pagamento-abbonamento div.azioni) {
    display:          flex;
    justify-content:  space-between;
  }
  
  :global(.StripeElement) {
    display:          block;
    width:            100%;
    height:           47px;
    position:         relative;
    padding:          13px 7px;
    margin:           0 0 14px 0;
    border:           1px solid var(--colore-bordo);
    border-radius:    0;
    background-color: white;
    background-clip:  padding-box;
    color:            var(--colore-testo);
    font-family:      'WorkSans', sans-serif;
    font-size:        14px;
    font-weight:      400;
    line-height:      1.44rem;
    transition:       border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
  
  :global(.StripeElement--focus) {
    border-color:   var(--colore-primario);
    outline:        none;
    box-shadow:     none;
  }
  
  :global(.StripeElement--invalid) {
    border-color:   var(--colore-rosso);
  }
  
  :global(div#carta-errori) {
    border-color:   var(--colore-rosso);
    margin:         -7px 0 7px 0;
  }
</style>

<script>
  import Bottone from "@/base/componenti/Bottone.svelte"
  import { abbonamento } from "@/portale/sorgenti/abbonamento"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { onMount } from "svelte"

  export let in_invio = false
  export let nascosto = false
  export let tipo     = "carta_di_credito"

  const t = avvia_localizzazione()
  const parametri_trentino_marketing = deposito_locale.prendi("parametri_trentino_marketing") || {}
  const DOMINI_CON_PREZZO_ZERO = [ "trentino", "bergamo", "padova", "livigno", "genova", "ticino" ]

  let errore_creazione_abbonamento = false
  let errore_creazione_sessione_pagamento = false
  window.stripe_caricato = false

  // Crea l'abbonamento.
  async function crea_abbonamento() {
    if (errore_creazione_abbonamento) return
    if ($abbonamento.completato) return
    if ($abbonamento.pagamento_annullato) return
    if ($abbonamento._id) return

    if ($abbonamento.struttura.nome_gestionale) delete $abbonamento.struttura.id_gestionale
    if ($abbonamento.struttura.id_gestionale)   delete $abbonamento.struttura.nome_gestionale
    if (!$abbonamento.codice_buono_sconto)      delete $abbonamento.codice_buono_sconto
    if ($abbonamento.id_cliente || $abbonamento.metodo_di_pagamento == "contratto_destinazione") delete $abbonamento.cliente
    if ($abbonamento.struttura.tipo == "campeggio" || $abbonamento.struttura.tipo == "camping") {
      delete $abbonamento.struttura.letti_disponibili
      delete $abbonamento.struttura.unità_disponibili
      $abbonamento.struttura.tipo = "campeggio"
    }
    if (parametri_trentino_marketing.ref_id) {
      $abbonamento.id_struttura_preesistente = parametri_trentino_marketing.ref_id
    }
    
    if (parametri_trentino_marketing != null && Object.keys(parametri_trentino_marketing).length > 0) {
      $abbonamento.struttura.dati_trentino_marketing = {
        sid: parametri_trentino_marketing.sid,
        gid: parametri_trentino_marketing.gid,
        did: parametri_trentino_marketing.did
      }
    }
    $abbonamento.metodo_di_pagamento = tipo
    $abbonamento.localizzazione = $l

    let parametri_abbonamento = {
      abbonamento:  { ...$abbonamento },
      cliente:      $abbonamento.cliente,
      struttura:    $abbonamento.struttura }
    if (DOMINI_CON_PREZZO_ZERO.include($abbonamento.flusso_personalizzato)) delete parametri_abbonamento.cliente
    parametri_abbonamento.struttura.camere_disponibili = $abbonamento.struttura.unità_disponibili

    if (!$abbonamento.flusso_personalizzato) delete parametri_abbonamento.abbonamento.flusso_personalizzato

    let risposta = await retro.chiama("POST",
      retro.estremi.licenze.abbonamenti,
      parametri_abbonamento)

    if (risposta.stato != 201) errore_creazione_abbonamento = true
    // if (risposta.stato == 201) $abbonamento.completato      = true

    $abbonamento.id_cliente = risposta.contenuto.abbonamento.id_cliente
    $abbonamento._id = risposta.contenuto.abbonamento._id
    
    deposito_locale.rimuovi("parametri_trentino_marketing")
    abbonamento.sovrascrivi($abbonamento)
  }

  // Crea la sessione di pagamento.
  async function crea_sessione_pagamento() {
    in_invio = true
    if (tipo == "contratto_destinazione" || tipo == "bonifico" || tipo == "buono_sconto") return vai_direttamente_a_creazione_abbonamento()
    await crea_abbonamento()

    errore_creazione_sessione_pagamento = false

    let parametri_sessione = {
      id_cliente: $abbonamento.id_cliente,
      id_abbonamento: $abbonamento._id,
      metodo_di_pagamento: tipo,
      flusso_personalizzato: $abbonamento.flusso_personalizzato || "" }
    let risposta_sessione

    try {
      risposta_sessione = await retro.chiama("POST",
        retro.estremi.licenze.pagamenti,
        parametri_sessione)
      if (risposta_sessione.stato != 200) throw "errore creazione sessione"
    } catch (errore) {
      in_invio = false
      errore_creazione_sessione_pagamento = true
    }

    if (errore_creazione_sessione_pagamento) return

    let id_sessione_stripe = risposta_sessione.contenuto.id_sessione
    $abbonamento.metodo_di_pagamento = tipo
    $abbonamento.id_sessione_stripe = id_sessione_stripe
    $abbonamento.pagamento_annullato = false
    $abbonamento.completato = false
    abbonamento.sovrascrivi($abbonamento)

    let risposta_cassa = await window.stripe.redirectToCheckout({ sessionId: id_sessione_stripe })

    in_invio = false
  }

  ////
  // Carica il framework di stripe
  function carica_stripe() {
    if (window.stripe_caricato) return
    window.stripe_caricato = true

    let head = document.getElementsByTagName('head')[0]
    let script = document.createElement('script')

    script.type = 'text/javascript'
    script.onload = function() {
      if (retro.indirizzo == `https://ir.hbenchmark.it`) {
        window.stripe = Stripe("pk_live_aFsnK4Hyi3GFhRNPs3UcbuP7")
      } else {
        window.stripe = Stripe("pk_test_hfMcENS7uWOYjJ0Zpb33nisp")
      }
    }
    script.src = 'https://js.stripe.com/v3/'
    
    head.appendChild(script)
  }

  // Va direttamente alla creazione abbonamento.
  async function vai_direttamente_a_creazione_abbonamento() {
    abbonamento.sovrascrivi($abbonamento)
    in_invio = true
    await crea_abbonamento()
    in_invio = false
    navigatore.verso("/nuovo_abbonamento/pagamento/completato")
  }

  ////
  // Torna al passo precedente
  function torna_indietro() {
    if ($abbonamento.piano == null) return navigatore.verso("/gestione_strutture/abbonamenti")

    if ($abbonamento.piano.prezzo_finale == 0) {
      $abbonamento.passo_corrente -= 2
      navigatore.verso("/nuovo_abbonamento/piano")
    } else {
      $abbonamento.passo_corrente -= 1
      navigatore.verso("/nuovo_abbonamento/fatturazione")
    }
  }

  onMount(carica_stripe)
</script>
