<template>
  <RottaNuovoAbbonamento titolo={t.titolo[$l]}>
    <div class="passo-progresso-abbonamento riprendi">
      {#if $abbonamento != null}
        <div class="messaggio-riprendi-abbonamento">
          <TitoloSecondario>{t.allerta[$l]}</TitoloSecondario>
          <p>
            {t.inizio_domanda_ripresa_abbonamento_esistente[$l]}
            <b>{$abbonamento.struttura.nome}</b>
            {t.fine_domanda_ripresa_abbonamento_esistente[$l]}
          </p>
  
          <div class="azioni">
            <Bottone 
                variante="primario"
                on:click={continua_abbonamento_esistente}>
              {t.continua_esistente[$l]}
            </Bottone>
            <Bottone 
                variante="secondario-invertito"
                on:click={riparti_da_zero_con_abbonamento_nuovo}>
              {t.riparti_da_zero[$l]}
            </Bottone>
          </div>
        </div>
      {/if}
    </div>
  </RottaNuovoAbbonamento>
</template>

<style>
  :global(div.passo-progresso-abbonamento.riprendi){
    display:          flex;
    justify-content:  center;
    align-items:      center;
  }
  :global(div.passo-progresso-abbonamento.riprendi div.messaggio-riprendi-abbonamento div.azioni){
    display:    flex;
    margin-top: 30px;
  }
</style>

<script>
  import Bottone from "@/base/componenti/Bottone.svelte"
  import TitoloSecondario from "@/base/componenti/TitoloSecondario.svelte"
  import RottaNuovoAbbonamento from "@/portale/componenti/abbonamento/RottaNuovoAbbonamento.svelte"
  import { abbonamento } from "@/portale/sorgenti/abbonamento"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  const t = avvia_localizzazione(dizionario_gestionale)
  const PASSI = [
    "struttura",
    "gestionale",
    "piano",
    "fatturazione",
    "pagamento" ]

 if ($abbonamento == null || $abbonamento.passo_raggiunto == 0) {
  navigatore.verso("/nuovo_abbonamento/struttura")
 }
 if ($abbonamento != null && $abbonamento.completato) {
  riparti_da_zero_con_abbonamento_nuovo()
 }

  // Cancella abbonamento esistente e reindirizza al primo passo della creazione
  function riparti_da_zero_con_abbonamento_nuovo() {
    $abbonamento = null
    abbonamento.sovrascrivi($abbonamento)
    navigatore.verso("/nuovo_abbonamento/struttura")
  }

  // Reindirizza abbonamento al passo corrente
  function continua_abbonamento_esistente() {
    navigatore.verso(`/nuovo_abbonamento/${PASSI[$abbonamento.passo_raggiunto]}`)
  }
</script>

<script context="module">
  export const dizionario_gestionale = {
    titolo: {
      it: `Riprendi creazione abbonamento`,
      en: `Resume subscription creation`,
      de: `Wiederaufnahme des Abonnements`
    },
    allerta: {
      it: `Stavi già creando un nuovo abbonamento`,
      en: `You were already creating a new subscription`,
      de: `Sie haben bereits ein neues Abonnement erstellt`
    },
    inizio_domanda_ripresa_abbonamento_esistente: {
      it: `Vuoi riprendere la creazione dell'abbonamento per la struttura di nome `,
      en: `Do you want to resume the subscription for the property named `,
      de: `Möchten Sie das Abonnement für den Betrieb namens `
    },
    fine_domanda_ripresa_abbonamento_esistente: {
      it: `?`,
      en: `?`,
      de: `?`
    },
    riparti_da_zero: {
      it: `Inizia Nuovo Abbonamento`,
      en: `Start New Subscription`,
      de: `Neues Abonnement starten`
    },
    continua_esistente: {
      it: `Riprendi Abbonamento`,
      en: `Resume Subscription`,
      de: `Abonnement fortsetzen`
    }
  }
</script>