<template>
  <Rotta>
    <Titolo titolo={t.titolo_gestione_struttura[$l]}/>

    {#if $sessione_corrente.tipo == "camping" || $sessione_corrente.tipo == "campeggio" || $sessione_corrente.tipo == "hotel"}
      <div class="contenitore-bottone-pdf-onboarding">
        <Bottone
            piccolo={true}
            variante="primario"
            on:click={apri_pdf_onboarding}>
          {t.scarica_pdf_onboarding[$l]}
          <Icona tipo="esporta"/>
        </Bottone>
      </div>
    {/if}

    <div class="bottone-torna-all-elenco">
      <Bottone
          variante="primario-invertito-con-bordo"
          on:click={ritorna_all_elenco}>
        {t.torna_all_elenco[$l]}
      </Bottone>
    </div>
    
    <div class="rotte-gestione-struttura">
      <Collegamento nome_rotta="/gestione_strutture/struttura">
        {t.nome_rotta_struttura[$l]}
      </Collegamento>
      {#if $sessione_corrente.abbonamenti != null}
        <Collegamento nome_rotta="/gestione_strutture/abbonamenti">
          {t.nome_rotta_abbonamenti[$l]}
        </Collegamento>
      {/if}
      <Collegamento nome_rotta="/gestione_strutture/concorrenti">
        {t.nome_rotta_concorrenti[$l]}
      </Collegamento>
      {#if $sessione_corrente.tipo == "campeggio"}
        <Collegamento nome_rotta="/gestione_strutture/prodotti">
          {t.nome_rotta_prodotti[$l]}
        </Collegamento>
      {/if}
      <Collegamento nome_rotta="/gestione_strutture/codifiche">
        {t.nome_rotta_codifiche[$l]}
      </Collegamento>
      <Collegamento nome_rotta="/gestione_strutture/controllo_dati">
        {t.nome_rotta_controllo_dati[$l]}
      </Collegamento>
      <Collegamento nome_rotta="/gestione_strutture/trasmissioni">
        {t.nome_rotta_trasmissioni[$l]}
      </Collegamento>
    </div>

    <ContenitoreBase>
      <div class="contenitore-impostazione">
        <slot/>
      </div>
    </ContenitoreBase>
  </Rotta>
</template>

<style>
  :global(div.contenitore-bottone-pdf-onboarding) {
    position: fixed;
    right:    225px;
    top:      0;
    padding:  18px 0;
  }
  :global(div.contenitore-bottone-pdf-onboarding button) {
    font-size: 12px;
  }
  :global(div.contenitore-bottone-pdf-onboarding [class^="icona-"]),
  :global(div.contenitore-bottone-pdf-onboarding [class*=" icona-"]) {
    font-size: 20px;
  }
  :global(div.bottone-torna-all-elenco) {
    position: fixed;
    top:      0;
    right:    0;
    padding: 11px 14px;
  }
  :global(div.rotte-gestione-struttura) {
    display:        flex;
    height:         52px;
    position:       relative;
    margin:         5px 0 0;
    padding:        0;
  }
  :global(div.rotte-gestione-struttura::after) {
    content:            "";
    background-color:   var(--colore-linea);
    position:           absolute;
    width:              100%;
    height:             1px;
    bottom:             0;
    left:               0
  }
  :global(div.rotte-gestione-struttura div.collegamento-barra-laterale) {
    text-transform: uppercase;
    border-left:    0;
  }
  :global(div.rotte-gestione-struttura div.collegamento-barra-laterale a) {
    line-height:        44px;
    padding-left:       var(--padding-base-main);
    padding-right:      var(--padding-base-main);
    border-bottom:      4px solid transparent;
    border-top:         4px solid transparent;
  }
  :global(div.rotte-gestione-struttura div.collegamento-barra-laterale.rotta-corrente ) {
    border:             none;
  }
  :global(div.rotte-gestione-struttura div.collegamento-barra-laterale:not(.rotta-corrente) a) {
    font-weight: normal;
  }
  :global(div.rotte-gestione-struttura div.collegamento-barra-laterale.rotta-corrente a) {
    border-bottom-color:  var(--colore-primario)
  }
  :global(div.contenitore-impostazione > p:first-child) {
   margin-top: 0;
  }
</style>

<script>
  import Rotta from "@/base/componenti/Rotta.svelte"
  import Collegamento from "@/base/componenti/barra_laterale/Collegamento.svelte"
  import Titolo from "@/base/componenti/Titolo.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Icona from "@/base/componenti/Icona.svelte"
  import ContenitoreBase from "@/base/componenti/ContenitoreBase.svelte"
  import { sessione_corrente } from "@/portale/sorgenti/sessione"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  ////
  // Apre il pdf di onboarding
  function apri_pdf_onboarding() {
    if ($sessione_corrente.tipo == "hotel")
      if ($l == "de") window.open("https://preventivi.hbenchmark.it/Hotel_Onboarding_DE.pdf")
      else window.open("https://preventivi.hbenchmark.it/Hotel_Onboarding.pdf")
    else if ($sessione_corrente.tipo == "camping" || $sessione_corrente.tipo == "campeggio")
      window.open("https://preventivi.hbenchmark.it/Camping_Onboarding.pdf")
  }

  ////
  // Ritorna all'elenco delle strutture
  function ritorna_all_elenco() {
    navigatore.verso("/gestione_strutture")
  }

  const t = avvia_localizzazione(dizionario_impostazioni)
</script>

<script context="module">
  export const dizionario_impostazioni = {
    titolo_gestione_struttura: {
      it: `Gestione Struttura`,
      en: `Structure Management`,
      de: `Management Betrieb`
    },
    nome_rotta_struttura: {
      it: `Struttura`,
      en: `Property`,
      de: `Betrieb`
    },
    nome_rotta_abbonamenti: {
      it: `Abbonamenti`,
      en: `Subscriptions`,
      de: `Abonnements`
    },
    nome_rotta_concorrenti: {
      it: `Competitors`,
      en: `Competitors`,
      de: `Wettbewerber`
    },
    nome_rotta_prodotti: {
      it: `Prodotti`,
      en: `Products`,
      de: `Produkte`
    },
    nome_rotta_codifiche: {
      it: `Segmenti`,
      en: `Segments`,
      de: `Segmente`
    },
    nome_rotta_controllo_dati: {
      it: `Controllo Dati`,
      en: `Data Quality`,
      de: `Datenqualität`
    },
    nome_rotta_trasmissioni: {
      it: `Trasmissioni`,
      en: `Transmissions`,
      de: `Übertragungen`
    },
    scarica_pdf_onboarding: {
      it: `Scarica le Procedure di Onboarding`,
      en: `Download the Onboarding Procedures`,
      de: `Onboarding-Verfahren herunterladen`
    },
    torna_all_elenco: {
      it: `Torna all'elenco`,
      en: `Back to the list`,
      de: `Zurück zur Liste`
    }
  }
</script>