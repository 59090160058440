<template>
  <div
      class="serie"
      class:disattivo={disattivo}
      on:mouseenter={evidenzia_serie}
      on:mouseleave={disattiva_evidenzia_serie}
      on:click={attiva_disattiva_spunta}>
    {#if funzione_spunta}
      <div
          class="scatola-colore"
          style="border-color: {serie.colore};">
        <div
            class="colore"
            class:disattivo={disattivo}
            style="background-color: {serie.colore};">
          <div class="spunta"/>
        </div>
      </div>
    {:else}
      <div
          class="colore-semplice"
          style="background-color: {serie.colore};">
      </div>
    {/if}
    <div class="nome">
      {#if nome != null}
        {nome}
      {:else}
        {#if periodo != null}
          {t.periodi[periodo.nome][$l]}
          {#if periodo.osservato}
            <br/>
            {periodo.data_osservazione.to_date.format("%e %B %Y")}
          {/if}
        {/if}
      {/if}
    </div>
    {#if serie_comparata != null}
      <Comparazione
          {serie}
          {serie_comparata}
          {statistica}/>
    {/if}
    <div
        class="totale"
        class:indefinito={serie.totale == null}>
      {#if serie.totale != null}
        <Numero
            numero={serie.totale}
            unità={serie.unità}
            valuta={serie.valuta}
            riduci={true}
            decimali={indicatore.decimali}/>
      {:else}
        -
      {/if}
    </div>
  </div>
</template>

<style>
  :global(div.legenda-serie div.serie) {
    height:         28px;
    display:        flex;
    align-items:    center;
    margin:         7px 0;
    cursor:         pointer;  
  }
  :global(div.legenda-serie div.serie:hover) {
    background-color: var(--colore-sorvolo);
  }
  :global(div.legenda-serie div.serie.disattivo) {
    text-decoration:  line-through;
    color:            var(--colore-testo-leggero);
  }
  :global(div.legenda-serie div.colore-semplice) {
    width:            24px;
    min-width:        24px;
    height:           24px;
    margin-right:     7px;
    padding:          2px;
    background-color: var(--colore-testo);
  }
  :global(div.legenda-serie div.scatola-colore) {
    width:        24px;
    min-width:    24px;
    height:       24px;
    margin-right: 7px;
    border:       2px solid var(--colore-testo);
    padding:      2px;
  }
  :global(div.legenda-serie div.scatola-colore div.colore) {
    width:            100%;
    height:           100%;
    border-radius:    2px;
    position:         relative;
    background-color: var(--colore-testo);
  }
  :global(div.legenda-serie div.scatola-colore div.colore.disattivo) {
    display: none;
  }

  :global(div.serie div.scatola-colore div.colore div.spunta:after) {
    content:          "";
    position:         absolute;
    top:              -3px;
    left:             0;
    right:            0;
    bottom:           0;
    margin:           auto;
    width:            0;
    height:           0;
    border:           solid #ffffff;
    border-width:     0 2px 2px 0;
    transform:        rotate(45deg);
    transition:       0.1s;
    visibility:       hidden;
    opacity:          0;
    z-index:          2;
  }
  :global(div.serie:hover div.scatola-colore div.colore div.spunta:after) {
    visibility: visible;
    width:      5px;
    height:     10px;
    opacity:    1;
  }

  /* Nome. */
  :global(div.legenda-serie div.nome) {
    font-size:      13px;
    line-height:    16px;
    margin-right:   auto;
    white-space:    nowrap;
    overflow:       hidden;
    text-overflow:  ellipsis;
  }

  /* Totale. */
  :global(div.legenda-serie div.totale) {
    min-width:    80px;
    font-size:    24px;
    font-weight:  500;
    text-align:   right;
    white-space:  nowrap;
  }

  /* Comparazione. */
  :global(div.legenda-serie div.serie div.comparazione) {
    margin-left:  7px;
  }
</style>

<script>
  import Numero from "@/base/componenti/Numero.svelte"
  import Comparazione from "@/base/componenti/statistiche/indicatori/Comparazione.svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { periodi } from "@/base/sorgenti/hbenchmark/periodi"
  import { granularità_predefinite as granularità } from "@/base/sorgenti/hbenchmark/periodi"
  import { createEventDispatcher } from "svelte"

  export let nome = null
  export let serie = {}
  export let serie_comparata = null
  export let statistica = {}
  export let disattivo = false
  export let funzione_spunta = true

  const t = { periodi, granularità }
  const propaga = createEventDispatcher()

  let comparazione = {}

  // Determina il periodo di riferimento.
  $: periodo = statistica.cornice_temporale.periodi[serie.indice_periodo]
  // Determina l'indicatore di riferimento
  $: indicatore = statistica.indicatori[serie.indice_indicatore]

  // Determina proprietà della comparazione.
  $: if (serie_comparata != null) {
    comparazione = {}

    if (serie.totale == null && serie_comparata.totale == null) {
      // Comparazione impossibile.
      comparazione.valore = 0
    } else {
      // Comparazione possibile, almeno uno dei due valori presente.
      comparazione.valore = (serie_comparata.totale || 0) - (serie.totale || 0)

      if (serie.unità == "percentuale")
        comparazione.unità == "punti_percentuali"
      else
        comparazione.unità = serie.unità

      comparazione.positiva = comparazione.valore >  0
      comparazione.uguale   = comparazione.valore == 0
      comparazione.negativa = comparazione.valore <  0
    }
  }

  function attiva_disattiva_spunta() {
    if (!funzione_spunta) return
    if (disattivo) {
      disattivo = false
      propaga("mostra", { indice: serie.indice })
    }else {
      disattivo = true
      propaga("nascondi", { indice: serie.indice })
    }
  }

  ////
  // Propaga evento per nascondere serie tranne quella che si sorvola
  function evidenzia_serie() {
    if (serie.totale == null) return
    propaga("evidenzia", { indice: serie.indice })
  }

  ////
  // Propaga evento per nascondere serie tranne quella che si sorvola
  function disattiva_evidenzia_serie() {
    if (serie.totale == null) return
    propaga("evidenzia", false)
  }
</script>
