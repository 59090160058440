<template>
  <div class="calendario">
    <table class="calendario">
      <thead>
        <tr>
          <th class="freccia-mese" on:click={mostra_mese_precedente}>
            <Icona tipo="angolare-sinistra"/>
          </th>
          <th class="nome-mese" colspan="5">
            {mese_osservazione_formattato}
          </th>
          <th class="freccia-mese" on:click={mostra_mese_successivo}>
            <Icona tipo="angolare-destra"/>
          </th>
        </tr>
        <tr>
          {#each iniziali_giorni_settimana as iniziale_giorno_settimana}
            <th>{iniziale_giorno_settimana}</th>
          {/each}
        </tr>
      </thead>
      <tbody>
        {#each tabella_mese as riga_settimana, indice_settimana}
          <tr>
            {#each riga_settimana as cella_giorno, indice_giorno}
              <td 
                  class:disabilitata={!cella_giorno.nel_mese}
                  class:selezionata={cella_giorno.selezionata}
                  class:non-selezionabile={cella_giorno.non_selezionabile}
                  class:in-intervallo={cella_giorno.nell_intervallo}
                  class:inizio-intervallo={cella_giorno.inizio_intervallo}
                  class:fine-intervallo={cella_giorno.fine_intervallo}
                  on:click={evento => seleziona_data(cella_giorno)}>
                {cella_giorno.numero_giorno}
              </td>
            {/each}
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
</template>

<style>
  :global(div.calendario) {
    width:    243px;
    z-index:  1;
  }
  :global(table.calendario) {
    table-layout:   fixed;
    border-spacing: 0;
  }

  /* Mese. */
  :global(table.calendario > tbody > tr > td),
  :global(table.calendario > thead > tr > th) {
    width:        35px;
    height:       35px;
    text-align:   center;
  }

  /* Celle calendario. */
  :global(table.calendario > tbody > tr > td),
  :global(table.calendario > thead th.freccia-mese) {
    cursor:       pointer;
  }
  :global(table.calendario > tbody > tr > td:hover),
  :global(table.calendario > thead th.freccia-mese:hover) {
    background-color: var(--colore-sorvolo);
  }
  :global(table.calendario > tbody > tr > td.disabilitata) {
    color:            var(--colore-disabilitato);
  }

  /* Selezionata. */
  :global(table.calendario > tbody > tr > td.selezionata) {
    color:            var(--colore-bianco);
    background-color: var(--colore-primario);
    border-radius:    100%;
  }

  /* Intervallo. */
  :global(table.calendario > tbody > tr > td.inizio-intervallo),
  :global(table.calendario > tbody > tr > td.fine-intervallo) {
    color:          var(--colore-bianco);
    background:     var(--colore-primario);
    border-radius:  100%;
    position:       relative;
  }
  :global(table.calendario > tbody > tr > td.inizio-intervallo:before),
  :global(table.calendario > tbody > tr > td.fine-intervallo:before) {
    background:   var(--colore-primario-evidenziato);
    content:      ".";
    color:        transparent;
    position:     absolute;
    top:          0px;
    width:        17px;
    height:       35px;
    z-index:      -1;
  }
  :global(table.calendario > tbody > tr > td.inizio-intervallo:before) {
    right:        0;
  }
  :global(table.calendario > tbody > tr > td.fine-intervallo:before) {
    left:         0;
  }
  :global(table.calendario > tbody > tr > td.inizio-intervallo.fine-intervallo:before) {
    content:      none;
  }
  :global(table.calendario > tbody > tr > td.in-intervallo) {
    color:          var(--colore-bianco);
    background:     var(--colore-primario-evidenziato);
  }
</style>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import { localizzazione } from "@/base/sorgenti/svuby"
  import { onDestroy } from "svelte"
  import { createEventDispatcher } from "svelte"

  export let disabilitato = false
  export let date_intervallo = []
  export let data_selezionata = Date.today
  export let data_osservazione = data_selezionata || Date.today
  export let minima_data_selezionabile = Date.today.sub(3, "years").beginning_of_year
  export let massima_data_selezionabile = Date.today.add(2, "years").beginning_of_year

  let propaga = createEventDispatcher()
  let tabella_mese = []
  let giorni_settimana = 7
  let iniziali_giorni_settimana, mese_osservazione_formattato

  ////
  // Localizza date e mese.
  function localizza() {
    iniziali_giorni_settimana = []

    // Genera le iniziali del giorno della settimana.
    giorni_settimana.times((indice_giorno) => {
      let giorno = data_osservazione.beginning_of_week.add(indice_giorno, "days")
      let iniziale_giorno_settimana = giorno.format("%A").substr(0, 1)
      iniziali_giorni_settimana.push(iniziale_giorno_settimana)
    })
    mese_osservazione_formattato = data_osservazione.format("%B %Y")
  }

  ////
  // Genera la tabella con i giorni del mese visualizzato.
  function genera_tabella_mese() {
    localizza()

    let inizio_tabella = data_osservazione.beginning_of_month.beginning_of_week
    let termine_tabella = data_osservazione.end_of_month.end_of_week
    let settimane_tabella = inizio_tabella.difference_in("weeks", termine_tabella)

    tabella_mese = []
    settimane_tabella.times((indice_settimana) => {
      let riga_settimana = genera_riga_settimana(inizio_tabella, indice_settimana)
      tabella_mese.push(riga_settimana)
    })
  }

  ////
  // Genera la riga della settimana.
  function genera_riga_settimana(inizio_tabella, indice_settimana) {
    let celle_settimana = []
    giorni_settimana.times((indice_giorno) => {
      let giorni_da_inizio = (indice_settimana * 7) + indice_giorno
      let data_calendario = inizio_tabella.add(giorni_da_inizio, "days")
      let cella_giorno = genera_cella_giorno(data_calendario)
      celle_settimana.push(cella_giorno)
    })

    return celle_settimana
  }

  ////
  // Genera la cella del giorno.
  function genera_cella_giorno(data_calendario) {
    let fuori_selezione_massima = data_calendario > massima_data_selezionabile ||
      data_calendario < minima_data_selezionabile

    return {
      data: data_calendario,
      numero_giorno: data_calendario.day.to_s,
      nel_mese: data_calendario.month == data_osservazione.month,
      nell_intervallo: è_in_intervallo(data_calendario),
      inizio_intervallo: è_inizio_intervallo(data_calendario),
      fine_intervallo: è_fine_intervallo(data_calendario),
      selezionata: data_calendario.to_i == data_selezionata.to_i,
      non_selezionabile: disabilitato || fuori_selezione_massima
    }
  }

  function è_in_intervallo(data) {
    if (date_intervallo.first == null) return false
    if (date_intervallo.last == null) return false
    return data.between(date_intervallo.first.add(1, "day"), date_intervallo.last.sub(1, "day"))
  }

  function è_inizio_intervallo(data) {
    if (date_intervallo.first == null) return false
    return data.to_i == date_intervallo.first.to_i
  }

  function è_fine_intervallo(data) {
    if (date_intervallo.last == null) return false
    return data.to_i == date_intervallo.last.to_i
  }

  ////
  // Muove il calendario al mese precedente.
  function mostra_mese_precedente() {
    data_osservazione = data_osservazione.sub(1, "month")
  }

  ////
  // Muove il calendario al mese successivo.
  function mostra_mese_successivo() {
    data_osservazione = data_osservazione.add(1, "month")
  }

  ////
  // Seleziona la data.
  function seleziona_data(cella_giorno) {
    data_selezionata = cella_giorno.data
    propaga("change", { data: cella_giorno.data })
  }

  // Al cambio della data selezionata, genera la tabella mese.
  $: if (data_selezionata != null) data_osservazione = data_selezionata
  // Al cambio della data di osservazione, genera la tabella mese.
  $: if (data_osservazione != null) genera_tabella_mese()
  // Al cambio della localizzazione, genera la tabella mese.
  const scollega = localizzazione.su_cambio(localizza)
  // Quando distrutto, scollega i cambi.
  onDestroy(scollega)
</script>
