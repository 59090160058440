<template>
  <Messaggio>
    <div class="loghi-contenitore padova" slot="loghi">
      <img src={window.risolviImg(logo)} alt="HBenchmark Logo White" height="52">

      <div class="contenitore-immagine">
        <img src={window.risolviImg(logo_padova_convention)} alt="Padova Convention & Visitor Bureau">
      </div>
    </div>
  </Messaggio>
</template>

<style>
  :global(div#accesso div.loghi-contenitore.padova){
    width:            128%;
    display:          flex;
    justify-content:  space-between;
    align-items:      center;
  }
  :global(div#accesso div.loghi-contenitore.padova div.contenitore-immagine){
    height: 45px;
  }
  :global(div#accesso div.loghi-contenitore.padova div.contenitore-immagine img){
    height: 100%;
  }
</style>

<script>
  import Messaggio from "@/base/componenti/accesso/Messaggio.svelte"
  import logo from "@/base/immagini/logo-completo.svg"
  import logo_padova_convention from "@/base/immagini/loghi/logo-padova-convention-bureau-colori.png"
</script>
