<template>
</template>

<script>
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { periodi } from "@/base/sorgenti/hbenchmark/periodi"

  const t = avvia_localizzazione()

  export let grafico = null
  export let pronto = false
  export let opzioni_highcharts = {}
  export let modalità_scura = false

  let colore_etichette_ascisse = "#666666"

  $: if (modalità_scura) {
    colore_etichette_ascisse = "#bbbbbb"
  } else {
    colore_etichette_ascisse = "#666666"
  }

  ////
  // Genera le opzioni grafico highcharts.
  function genera_opzioni_highcharts() {
    if (grafico == null) return

    let opzioni_highcharts_colonna = { title: { text: null }, type: "column" }

    // Serie.
    opzioni_highcharts_colonna.series = genera_serie()
    // Sovraimpressione.
    opzioni_highcharts_colonna.tooltip = {
      useHTML: true,
      backgroundColor: "#fff",
      formatter: formattatore_sovraimpressione
    }

    // Opzioni speciali.
    opzioni_highcharts_colonna.plotOptions = { column: { stacking: grafico.indicatore.impilabile } }

    // Asse Ascisse.
    opzioni_highcharts_colonna.xAxis = {
      type: "category",
      reversedStacks: true,
      categories: grafico.statistica.gruppi.map(gruppo => {
        if (gruppo.categorie != null && gruppo.categorie.length > 0)
          return `${gruppo.nome} - ${gruppo.categorie.join(" - ")}`
        else return gruppo.nome
      }),
      labels: {
        style: {color: colore_etichette_ascisse}
      }
    }
    // Asse Ordinate.
    opzioni_highcharts_colonna.yAxis = { title: null }

    console.log("Colonna con categorie".colore("verde"), ": grafico pronto.")

    opzioni_highcharts = opzioni_highcharts_colonna
    pronto = true
  }

  ////
  // Generatore serie.
  function genera_serie() {
    let serie_nuove= []
    if (grafico.indicatore.dettagliato) {
      let serie_per_periodo = []
      grafico.serie.forEach(serie_singola => {
        if (serie_per_periodo[serie_singola.indice_periodo] == null)
          serie_per_periodo[serie_singola.indice_periodo] = []

        serie_per_periodo[serie_singola.indice_periodo].push(serie_singola)
      })
      let serie_per_periodo_e_segmento = []
      serie_per_periodo.forEach(gruppo_periodo => {
        let serie_per_segmento = {}
        gruppo_periodo.forEach(serie_singola => {
          if (serie_per_segmento[serie_singola.segmento] == null)
          serie_per_segmento[serie_singola.segmento] = []
  
          serie_per_segmento[serie_singola.segmento].push(serie_singola)
        })
        serie_per_periodo_e_segmento.push(serie_per_segmento)
      })

      serie_nuove = serie_per_periodo_e_segmento.map(gruppo_periodo => {
        return Object.keys(gruppo_periodo).map(segmento => {
          let punti = gruppo_periodo[segmento].map(serie_singola => {
            return {
              y: serie_singola.punti[0][1],
              color: serie_singola.colore,
              unità: serie_singola.unità,
              valuta: serie_singola.valuta,
              gruppo: grafico.statistica.gruppi[serie_singola.indice_gruppo],
              periodo: grafico.statistica.cornice_temporale.periodi[serie_singola.indice_periodo],
              indice: serie_singola.indice
            }
          })

          return {
            type: "column",
            data: punti,
            name: segmento,
            stack: gruppo_periodo[segmento][0].indice_periodo,
            indice_periodo: gruppo_periodo[segmento][0].indice_periodo,
            dataLabels: {
              enabled: grafico.statistica.mostra_etichette_valori,
              formatter: formattatore_arrotondatore
            }
          }
        })
      })

      serie_nuove = serie_nuove.flat()
    } else {
      let serie_per_periodo = []
      grafico.serie.forEach(serie_singola => {
        if (serie_per_periodo[serie_singola.indice_periodo] == null)
          serie_per_periodo[serie_singola.indice_periodo] = []

        serie_per_periodo[serie_singola.indice_periodo].push(serie_singola)
      })

      serie_nuove = serie_per_periodo.map(gruppo_serie => {
        let punti = gruppo_serie.map(serie_singola => {
          return {
            name: grafico.statistica.gruppi[serie_singola.indice_gruppo],
            y: serie_singola.punti[0][1],
            color: serie_singola.colore,
            unità: serie_singola.unità,
            valuta: serie_singola.valuta,
            gruppo: grafico.statistica.gruppi[serie_singola.indice_gruppo],
            periodo: grafico.statistica.cornice_temporale.periodi[serie_singola.indice_periodo],
            indice: serie_singola.indice
          }
        })

        return {
          type: "column",
          data: punti,
          stack: gruppo_serie[0].indice_periodo,
          indice_periodo: gruppo_serie[0].indice_periodo,
          dataLabels: {
            enabled: grafico.statistica.mostra_etichette_valori,
            formatter: formattatore_arrotondatore
          }
        }
      })
    }
    return serie_nuove
  }

  ////
  // Formattatore della sovraimpressione.
  function formattatore_sovraimpressione() {
    let punto = this.point

    let punto_gruppo = punto.gruppo
    let punto_periodo = punto.periodo
    let punto_segmento = punto.segmento
    let nome_periodo = periodi[punto_periodo.nome][$l]
    let nome_gruppo = punto_gruppo.nome
    let valore_punto = punto.y.format({
      unit: punto.unità,
      currency: punto.valuta
    })

    let stringa_descrizione

    if (punto_segmento != null) {
      if (punto_gruppo.categorie != null && punto_gruppo.categorie.length > 0)
        stringa_descrizione = `${t[punto_segmento][$l]} - ${punto_gruppo.categorie.join(" - ")}: <b>${valore_punto}</b>`
      else stringa_descrizione = `${t[punto_segmento][$l]}: <b>${valore_punto}</b>`

      return [
        nome_periodo,
        nome_gruppo,
        stringa_descrizione
      ].join("<br/>")
    } else {
      if (punto_gruppo.categorie != null && punto_gruppo.categorie.length > 0)
        stringa_descrizione = `${nome_gruppo} - ${punto_gruppo.categorie.join(" - ")}: <b>${valore_punto}</b>`
      else stringa_descrizione = `${nome_gruppo}: <b>${valore_punto}</b>`

      return [
        nome_periodo,
        stringa_descrizione
      ].join("<br/>")
    }
  }

  ////
  // Rimuove la virgola dall'etichetta
  function formattatore_arrotondatore() {
    return Math.round(this.y).format({
      unit: this.point.unità,
      currency: this.point.valuta
    })
  }

  // Ad ogni aggiornamento, genera le opzioni grafico linea.
  $: if (grafico != null) genera_opzioni_highcharts()
</script>
