<template>
  {#each migliori_segmenti as segmento, indice}
    {#if indice < numero_serie_mostrate && segmento != null}
      <div class="elenco-serie">
        <div class="segmento">
          <span class="classifica">
            # {indice + 1}
          </span>
          <span class="nome">
            {#if dettagliato_per == "canale" && segmento == "altri"}
              {t.altri_canali[$l]}
            {:else}
              {t[segmento][$l]}
            {/if}            
          </span>
        </div>

        {#each statistica.gruppi as gruppo, indice_gruppo}
          <div class="gruppo-serie">
            <div class="gruppo">
              {gruppo.nome}
              {#if gruppo.categorie != null && gruppo.categorie.length > 0}
                - {gruppo.categorie.join(" - ")}
              {/if}
            </div>
            {#each statistica.cornice_temporale.periodi as periodo, indice_periodo}
              {#if indice_gruppo == 0 && indice_periodo == 0}
                <Serie
                    {statistica}
                    serie={serie_per_gruppo_periodo_e_segmento[indice_gruppo][indice_periodo][segmento]}
                    on:evidenzia
                    on:mostra
                    on:nascondi/>
              {:else}
                <Serie
                    {statistica}
                    serie={serie_per_gruppo_periodo_e_segmento[indice_gruppo][indice_periodo][segmento]}
                    serie_comparata={serie_per_gruppo_periodo_e_segmento[0][0][segmento]}
                    on:evidenzia
                    on:mostra
                    on:nascondi/>
              {/if}
            {/each}
          </div>
        {/each}
      </div>
    {/if}
  {/each}
</template>

<style>
  :global(div.legenda-serie div.segmento) {
    display:      flex;
    align-items:  center;
  }
  :global(div.legenda-serie div.segmento > span.classifica) {
    width:        28px;
  }
  :global(div.legenda-serie div.segmento > span.nome) {
    text-transform: uppercase;
  }
</style>

<script>
  import Serie from "@/base/componenti/statistiche/legenda_serie/Serie.svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { createEventDispatcher } from "svelte"

  const t = avvia_localizzazione()
  const propaga = createEventDispatcher()

  export let dettagliato_per = ""
  export let numero_limite_serie = 99
  export let numero_serie_mostrate = 99
  export let serie_per_gruppo_e_periodo = {}
  export let statistica = {}

  let serie_per_gruppo_periodo_e_segmento = {}
  let migliori_segmenti = []

  ////
  // Raggruppa le serie anche per segmento.
  function raggruppa_serie_per_gruppo_periodo_e_segmento() {
    statistica.gruppi.forEach((gruppo, indice_gruppo) => {
      serie_per_gruppo_periodo_e_segmento[indice_gruppo] = {}

      statistica.cornice_temporale.periodi.forEach((periodo, indice_periodo) => {
        let serie = serie_per_gruppo_e_periodo[indice_gruppo][indice_periodo]
        let serie_per_segmento = {}

        if (indice_gruppo == 0 && indice_periodo == 0)
          migliori_segmenti = serie.map(serie => serie.segmento)
          numero_limite_serie = migliori_segmenti.length

        serie.forEach(serie => serie_per_segmento[serie.segmento] = serie)
        serie_per_gruppo_periodo_e_segmento[indice_gruppo][indice_periodo] = serie_per_segmento
      })
    })
  }

  // Quando cambiano le serie, raggruppa.
  $: if (serie_per_gruppo_e_periodo != null) raggruppa_serie_per_gruppo_periodo_e_segmento()
</script>
