<template>
  <div class="tabella-disponibilita">
    <div class="azioni-disponibilità">
      <Bottone
          variante="primario-invertito"
          piccolo={true}
          on:click={evento => avvia_esportazione = true}>
        {t.esporta[$l]}
      </Bottone>
      {#if disponibilità_da_salvare.length > 0}
        <Bottone
            variante="primario"
            disabilitato={in_salvataggio}
            piccolo={true}
            on:click={salva_modifiche}>
          {#if in_salvataggio}
            {t.salvataggio_in_corso[$l]}
          {:else}
            {t.salva[$l]}
          {/if}
        </Bottone>
      {/if}
    </div>

    {#if in_salvataggio}
      <div class="ricalcolo-in-corso">
        <Caricatore/>
        <p>{t.ricalcolo_in_corso[$l]}</p>
      </div>
    {:else if in_caricamento}
      <Caricatore/>
    {:else}
      <Tabella
          {colonne}
          {valore_cella}
          {righe}
          dizionario={t}
          disabilitata={in_salvataggio}
          bind:avvia_esportazione={avvia_esportazione}
          let:riga={riga}
          let:colonna={colonna}
          let:valore={valore}>
        {#if colonna == "stato"}
          {#if riga.modificata}
            <Fanalino stato="in_corso"/>
          {:else}
            <Fanalino stato="completato"/>
          {/if}
        {:else if typeof colonna == "object"}
          {#each stagioni as stagione}
            {#if disponibilità_per_prodotto_e_stagione[riga.codice_prodotto][stagione] != null}
              {#if disponibilità_per_prodotto_e_stagione[riga.codice_prodotto][stagione].unità_disponibili == 0}
                <td> - </td>
              {:else}
                <td>{disponibilità_per_prodotto_e_stagione[riga.codice_prodotto][stagione].unità_disponibili}</td>
              {/if}
            {:else}
              <td>{t.disponibilità_mancante[$l]}</td>
            {/if}
          {/each}
        {:else if colonna == "modifica"}
          <Bottone
              variante="primario-invertito"
              on:click={evento => apri_modale_modifica(disponibilità_per_prodotto_e_stagione[riga.codice_prodotto])}>
            <Icona tipo="modifica"/>
          </Bottone>
        {:else}
          {valore_cella(riga, colonna, valore)}
        {/if}
      </Tabella>
    {/if}

    <Messaggio visualizzato={messaggio_visualizzato}>
      {t.salvataggio_completato[$l]}
    </Messaggio>
    <Modale
        scorribile={false}
        richiudibile={true}
        mostra_eccedenze={true}
        titolo={t.modale.titolo[$l]}
        bind:aperta={modale_modifica_aperta}
        on:chiusa={evento => disponibilità_in_modifica = null}>
      {#if disponibilità_in_modifica != null}
        <div class="modale-contenuto">
          <p>
            {t.modale.domanda_modifica.prima_parte[$l]}
            <b>{disponibilità_in_modifica[Object.keys(disponibilità_in_modifica)[0]].codice_prodotto}</b>
          </p>

          {#each Object.keys(disponibilità_in_modifica) as stagione}
            <p>
              {t.modale.domanda_modifica.seconda_parte[$l]}
              <b>{stagione}</b>
            </p>
            <Inserimento
                tipo="numero"
                disabilitato={in_salvataggio}
                bind:valore={disponibilità_in_modifica[stagione].unità_disponibili}/>            
          {/each}
          <div class="modale-azioni">
            <Bottone
                variante="primario"
                disabilitato={in_salvataggio}
                on:click={aggiorna_disponibilità}>
              {t.imposta[$l]}
            </Bottone>
          </div>
        </div>
      {/if}
    </Modale>
  </div>
</template>

<style>
  :global(div.tabella-disponibilita) {
    flex-grow:        1;
    display:          flex;
    flex-direction:   column;
    overflow:         hidden;
  }
  :global(div.tabella-disponibilita div.azioni-disponibilità) {
    display:          flex;
    align-items:      flex-end;
    justify-content:  space-between;
    margin-bottom:    14px;
    padding-right:    14px;
  }
  :global(div.tabella-disponibilita div.azioni-disponibilità > div.inserimenti) {
    display:          flex;
    align-items:      flex-end;
    flex-grow:        1;
    margin-right:     14px;
  }
  :global(div.tabella-disponibilita div.azioni-disponibilità > div.inserimenti div.selezione) {
    margin-right:     14px;
  }
  :global(div.tabella-disponibilita div.azioni-disponibilità > div.inserimenti div.selezione),
  :global(div.tabella-disponibilita div.azioni-disponibilità > div.inserimenti div.inserimento) {
    flex-grow:        1;
    max-width:        350px;
    margin-bottom:    0;
  }
  :global(div.tabella-disponibilita div.ricalcolo-in-corso) {
    display:          flex;
    flex-direction:   column;
    align-items:      center;
  }
</style>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Tabella from "@/base/componenti/Tabella.svelte"
  import Fanalino from "@/base/componenti/Fanalino.svelte"
  import Modale from "@/base/componenti/Modale.svelte"
  import Messaggio from "@/base/componenti/Messaggio.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { onMount } from "svelte"

  export let struttura = {}
  export let indirizzo = null

  const t = avvia_localizzazione(dizionario_disponibilità)

  let disponibilità = []
  let modale_modifica_aperta = false
  let messaggio_visualizzato = false
  let in_caricamento = false
  let in_salvataggio = false
  let stagioni = []
  let disponibilità_per_prodotto = {}
  let disponibilità_per_prodotto_e_stagione = {}
  let righe = []
  let disponibilità_in_modifica = null
  let disponibilità_da_salvare = []
  let avvia_esportazione = false

  $: colonne = [ "stato", "codice_prodotto", "prodotto", { "disponibili": stagioni }, "ultima_modifica", "modifica" ]
  $: if (disponibilità_da_salvare.length > 0) {
    righe.forEach(riga => {
      if (disponibilità_da_salvare.some(disponibilità => disponibilità.codice_prodotto == riga.codice_prodotto)) {
        riga.modificata = true
      }else{
        riga.modificata = false
      }
    })
  }

  ////
  // Apre la modale disponibilità.
  function apri_modale_modifica(disponibilità) {
    if (disponibilità_in_modifica == null) disponibilità_in_modifica = {}

    Object.keys(disponibilità).forEach(stagione => {
      disponibilità_in_modifica[stagione] = { ...disponibilità[stagione] }
    })

    modale_modifica_aperta = true
  }

  ////
  // Aggiorna la disponibilità.
  function aggiorna_disponibilità() {
    disponibilità_per_prodotto_e_stagione[disponibilità_in_modifica[Object.keys(disponibilità_in_modifica)[0]].codice_prodotto] = { ...disponibilità_in_modifica }

    Object.keys(disponibilità_in_modifica).forEach(stagione => {
      disponibilità_da_salvare = disponibilità_da_salvare.filter(disponibilità => {
        return disponibilità.codice_prodotto != disponibilità_in_modifica[stagione].codice_prodotto || disponibilità.stagione != stagione
      })

      disponibilità_da_salvare = [ ...disponibilità_da_salvare, disponibilità_in_modifica[stagione]]
    })
    
    modale_modifica_aperta = false
  }

  ////
  // Salva le modifiche.
  async function salva_modifiche() {
    in_salvataggio = true
      
    let risposta = await retro.chiama(
      "POST",
      indirizzo, {
        id_struttura: struttura._id,
        disponibilità: disponibilità_da_salvare })
    
    disponibilità_da_salvare = []

    alert(t.ricalcolo_in_corso[$l])

    await richiedi_disponibilità()

    in_salvataggio = false
    messaggio_visualizzato = true
  }

  ////
  // Richiede le disponibilità.
  async function richiedi_disponibilità() {
    in_caricamento = true
      
    let risposta = await retro.chiama(
      "GET",
      indirizzo, {
        id_struttura: struttura._id })

    disponibilità = risposta.contenuto.disponibilità.map((disponibilità, indice) => {
      return { ...disponibilità, indice }
    })

    stagioni = risposta.contenuto.stagioni
    
    righe = []
    disponibilità.forEach(disponibilità => {
      if (righe.some(riga => riga.codice_prodotto == disponibilità.codice_prodotto)) return
      righe.push(disponibilità)
    })

    disponibilità.forEach(disponibilità => {
      if (disponibilità.codice_prodotto == null) return
      if (disponibilità_per_prodotto[disponibilità.codice_prodotto] == null) {
        disponibilità_per_prodotto[disponibilità.codice_prodotto] = []
      }
      disponibilità_per_prodotto[disponibilità.codice_prodotto].push(disponibilità)
    })

    Object.keys(disponibilità_per_prodotto).forEach(codice_prodotto => {
      disponibilità_per_prodotto_e_stagione[codice_prodotto] = {}
      disponibilità_per_prodotto[codice_prodotto].forEach(disponibilità => {
        disponibilità_per_prodotto_e_stagione[codice_prodotto][disponibilità.stagione] = disponibilità
      })
    })

    in_caricamento = false
  }

  ////
  // Determina il valore di una cella.
  function valore_cella(riga, colonna, valore, indice_riga, indice_colonna) {
    if (typeof colonna == "object") {
      return stagioni.map(stagione => {
        return disponibilità_per_prodotto_e_stagione[riga.codice_prodotto][stagione].unità_disponibili
      })
    }else{
      switch (colonna) {
        case "prodotto":
          if (valore == null) return "-"
          return t[valore][$l]
        case "ultima_modifica":
          if (riga.modificata_il == null) return "-"
          if (riga.nome_creatore == null) return "-"

          if (riga.modificata_il.to_date.format("%d %B %Y") == Date.today.format("%d %B %Y")) {
            return [
              riga.modificata_il.to_date.format("%d %B %Y"),
              "alle",
              riga.modificata_il.to_date.format("%H:%M"),
              " da ",
              riga.nome_creatore ].join(" ")
          }else {
            return [
              riga.modificata_il.to_date.format("%d %B %Y"),
              " da ",
              riga.nome_creatore ].join(" ")
          }
        default:
          return valore || "-"
      }
    }
  }

  onMount(richiedi_disponibilità)
</script>

<script context="module">
  export const dizionario_disponibilità = {
    imposta: {
      it: `Imposta`,
      en: `Set`,
      de: `Besteuerung`
    },
    ricalcolo_in_corso: {
      it: `Ricalcolo dati in corso: l'operazione potrebbe richiedere qualche minuto.`,
      en: `Data recalc in progress: it might take some minutes.`,
      de: `Daten werden neu berechnet: Es kann einige Minuten dauern.`
    },
    salvataggio_completato: {
      it: `Disponibilità salvate con successo.`,
      en: `Inventory updated successfully.`,
      de: `Inventar erfolgreich aktualisiert.`
    },
    disponibilità_mancante: {
      it: `Disponibilità mancante`,
      en: `No availability`,
      de: `Fehlende Verfügbarkeit`
    },
    modale: {
      titolo: {
        it: `Modifica`,
        en: `Edit`,
        de: `Bearbeiten`
      },
      domanda_modifica: {
        prima_parte: {
          it: `Indicare il numero di `,
          en: `Specify the number of `,
          de: `Geben Sie die Anzahl der `
        },
        seconda_parte: {
          it: `disponibili per la stagione `,
          en: `available for the season `,
          de: `für die Saison verfügbar`
        }
      }
    },
    colonne: {
      codice_prodotto: {
        it: `Prodotto`,
        en: `Product`,
        de: `Produkt`
      },
      disponibili: {
        it: `Unità Disponibili (Per Stagione)`,
        en: `Available Units`,
        de: `Verfügbare Einheiten`
      },
      ultima_modifica: {
        it: `Ultima Modifica`,
        en: `Last Edit`,
        de: `Letzte Bearbeitung`
      },
      modifica: {
        it: `Modifica`,
        en: `Edit`,
        de: `Bearbeiten`
      }
    }
  }
</script>