<template>
  <section id="modulo-ripristino" bind:this={elemento}>
    <div class="modulo">
      <h2>{t.recupera[$l]}</h2>
      <Modulo
          {indirizzo}
          {parametri}
          metodo="DELETE"
          gestore={gestore_risposta}
          validatore={validatore_modulo}
          bind:in_salvataggio={invio_in_corso}
          bind:errori={errori}>
        {#if password_aggiornata_con_successo}
          <p>{t.successo_aggiornamento[$l]}</p>

          <div class="azioni">
            <Bottone
                variante="primario"
                on:click={vai_a_accesso}>
              {t.accedi[$l]}
            </Bottone>
          </div>
        {:else}
          {#if errori.generico != null}
            <div class="errore-generico">
              {t.errori[errori.generico][$l]}.
            </div>
          {/if}

          <Inserimento
              tipo="testuale"
              etichetta={t.gettone[$l]}
              segnaposto={t.segnaposto_gettone[$l]}
              errore={errori.gettone != null ? t.validazioni[errori.gettone][$l] : null}
              lunghezza_massima={4}
              bind:valore={gettone}/>
          <Inserimento
              tipo="password"
              etichetta={t.password[$l]}
              segnaposto={t.segnaposto_password[$l]}
              errore={errori.password != null ? t.validazioni[errori.password][$l] : null}
              bind:valore={password}/>
          <Inserimento
              tipo="password"
              etichetta={t.conferma_password[$l]}
              segnaposto={t.segnaposto_conferma_password[$l]}
              errore={errori.conferma_password != null ? t.validazioni[errori.conferma_password][$l] : null}
              bind:valore={conferma_password}/>
          {#if invio_in_corso}
            <Caricatore/>
          {:else}
            <div class="azioni">
              <Bottone tipo="invio">
                {t.conferma[$l]}
            </Bottone>
            </div>
            <div class="collegamenti">
              <div class="collegamento">
                <span>{t.email_non_arrivata[$l]}</span>
                <a href="{window.location.href}"
                    on:click={evento => inserisci_gettone = false}>
                  {t.rinvia[$l]}
                </a>
              </div>
              <div class="collegamento">
                <span>{t.hai_già_un_account[$l]}</span>
                <a href="{'#'}">{t.accedi[$l]}</a>
              </div>
            </div>
          {/if}
        {/if}
      </Modulo>
    </div>
  </section>
</template>

<style>
  :global(section#modulo-ripristino) {
    width:          38.2%;
    max-width:      450px;
    height:         calc(100vh - var(--top-margin));
    position:       fixed;
    top:            var(--top-margin);
    left:           43%;
    display:        flex;
    align-items:    flex-start;
  }
  :global(section#modulo-ripristino div.modulo) {
    width:          100%;
    background:     var(--colore-sfondo);
    padding:        32px;
    border-radius:  5px;
    box-shadow:     0px 10px 20px rgba(0, 0, 0, 0.1);
  }
  :global(section#modulo-ripristino div.modulo h2) {
    margin-top: 0;
    color:      var(--colore-titolo);
  }
  :global(section#modulo-ripristino div.errore-generico) {
    color:          var(--colore-rosso);
    margin-bottom:  14px;
  }
  :global(section#modulo-ripristino div.modulo form) {
    width:          100%;
  }
  :global(section#modulo-ripristino div.modulo div.collegamenti) {
    margin-top:     28px;
  }
  :global(section#modulo-ripristino div.modulo div.collegamenti div.collegamento) {
    display:          flex;
    align-items:      center;
    justify-content:  flex-start;
    font-size:        12px;
  }
  :global(section#modulo-ripristino div.modulo div.collegamenti div.collegamento a) {
    margin:           0 8px;
    line-height:      30px;
    font-size:        12px;
  }
</style>

<script>
  import Modulo from "@/base/componenti/Modulo.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  export let email = null
  export let inserisci_gettone = true

  const t = avvia_localizzazione(dizionario_accesso)
  const indirizzo = retro.estremi.anagrafe.password

  let elemento
  let gettone = null
  let password = null
  let conferma_password = null
  let errori = { gettone: null, password: null, generico: null }
  let invio_in_corso = false
  let password_aggiornata_con_successo = false

  // Parametri accesso.
  $: parametri = {
    ripristino: { gettone, password, conferma_password },
    email,
    localizzazione: $l
  }

  ////
  // Va alla pagina di registrazione.
  function vai_a_accesso() {
    navigatore.verso("/accesso")
  }

  ////
  // Validazione del modulo.
  function validatore_modulo() {
    if (valida_gettone(gettone))
      errori.gettone = "gettone"
    if (gettone == null || gettone == "")
      errori.gettone = "richiesto"
    if (valida_password(password))
        errori.password = "validazione_password"
    if (password == null || password == "")
      errori.password = "richiesto"
    if (conferma_password != null && conferma_password != password)
      errori.conferma_password = "conferma_non_corrisponde"
    if (conferma_password == null || conferma_password == "")
      errori.conferma_password = "richiesto"
  }

  ////
  // Gestione della gestione del modulo.
  function gestore_risposta(risposta) {
    if (risposta.contenuto.successo == true) return password_aggiornata_con_successo = true
    switch (risposta.stato) {
      case 404:
        errori.generico = "credenziali_non_valide"
        break
      default:
        errori.generico = "altro"
    }

    invio_in_corso = false
  }

  ////
  // Verifica se il gettone aggrada i criteri prestabiliti
  function valida_gettone(gettone) {
    if (gettone == null) return false
    return /\d/.test(gettone) || gettone.length != 4
  }

  ////
  // Verifica se il password corrisponde ai criteri prestabiliti
  function valida_password(password) {
    if (password == null) return false

    let verica_lunghezza = password.length < 8
    let verifica_minuscole = !contiene_minuscole(password)
    let verifica_maiuscole = !contiene_maiuscole(password)
    let verifica_numeri = !contiene_numeri(password)

    return verica_lunghezza || verifica_minuscole || verifica_maiuscole || verifica_numeri
  }

  ////
  // Verifica se ci sono lettere minuscole in una stringa
  function contiene_minuscole(stringa) {
    return stringa.toUpperCase() != stringa
  }

  ////
  // Verifica se ci sono lettere maiuscole in una stringa
  function contiene_maiuscole(stringa) {
    return stringa.toLowerCase() != stringa
  }

  ////
  // Verifica se ci sono numeri in una stringa
  function contiene_numeri(stringa) {
    return /\d/.test(stringa);
  }
</script>

<script context="module">
  export const dizionario_accesso = {
    recupera: {
      it: `Recupera password`,
      en: `Password recovery`,
      de: `Passwort wiederherstellen`
    },
    gettone: {
      it: `Gettone *`,
      en: `Token *`,
      de: `Zeichen *`
    },
    password: {
      it: `Password *`,
      en: `Password *`,
      de: `Passwort *`
    },
    conferma_password: {
      it: `Conferma Password *`,
      en: `Confirm password *`,
      de: `Passwort bestätigen *`
    },
    accedi: {
      it: `Accedi`,
      en: `Login`,
      de: `Anmeldung`
    },
    rinvia: {
      it: `Rinvia`,
      en: `Re send`,
      de: `Erneut senden`
    },
    registrati: {
      it: `Registrati`,
      en: `Register`,
      de: `Registrieren`
    },
    hai_già_un_account: {
      it: `Hai già un account?`,
      en: `Do you already have an account?`,
      de: `Sie haben bereits ein Konto?`
    },
    email_non_arrivata: {
      it: `Non è arrivata l'email?`,
      en: `Didn't the email arrive?`,
      de: `Die E-Mail ist nicht angekommen?`
    },
    successo_aggiornamento: {
      it: `Password aggiornata con successo!`,
      en: `Password updated successfully!`,
      de: `Passwort erfolgreich aktualisiert!`
    },
    segnaposto_gettone: {
      it: `Inserisci il gettone che ti abbiamo inviato`,
      en: `Enter the token we sent you`,
      de: `Geben Sie den Code ein, die wir Ihnen gesendet haben`
    },
    segnaposto_password: {
      it: `Inserisci la tua nuova password`,
      en: `Enter your new password`,
      de: `Neues Passwort eingeben`
    },
    segnaposto_conferma_password: {
      it: `Inserisci nuovamente la tua nuova password`,
      en: `Re-enter your new password`,
      de: `Geben Sie Ihr neues Passwort erneut ein`
    },
    errori: {
      credenziali_non_valide: {
        it: `Credenziali non valide`,
        en: `Invalid credentials`,
        de: `Ungültige Anmeldeinformationen`
      },
      altro: {
        it: `Si è verificato un errore.`,
        en: `An error occurred.`,
        de: `Ein Fehler ist aufgetreten.`
      }
    }
  }
</script>