<template>
  <div class="etichetta-scheda">
    <label for="">{t.etichetta[$l]}</label>
    <div class="azioni">
      <Bottone
          piccolo={true}
          variante="primario-invertito"
          on:click={evento => propaga("visualizza")}>
        <Icona tipo="modifica"/>
      </Bottone>
    </div>
  </div>
  <Scheda>
    <div class="contenitore-tabella-disponibilità">
      {#if in_caricamento}
        <Caricatore/>
      {:else}
        <div class="intestazione-disponibilità">
          <p>{t.disponibilità_totali[$l]} <strong>{totale_disponibilità}</strong></p>

          <p>{t.stagione[$l]} <strong>{Date.today.year}</strong></p>
        </div>
        <Tabella
            {colonne}
            {valore_cella}
            righe={Object.keys(disponibilità_inventariate)}
            dizionario={t}
            intestazione_fissa={true}
            let:riga={riga}
            let:colonna={colonna}
            let:valore={valore}>
          {#if colonna == "prodotto"}
            {t[riga][$l]}
          {:else if colonna == "codici_associati"}
            <div class="codici">
              {disponibilità_inventariate[riga].codici_associati.join(" - ")}
            </div>
          {:else if colonna == "disponibilità_totali"}
            {disponibilità_inventariate[riga].disponibilità_totali}
          {:else}
            {valore_cella(riga, colonna, valore)}
          {/if}
        </Tabella>
      {/if}
    </div>
  </Scheda>
</template>

<style>
  :global(div.scheda div.contenitore-tabella-disponibilità div.intestazione-disponibilità){
    display:          flex;
    justify-content:  space-between;
    padding-right:    15px;
  }
  :global(div.scheda div.contenitore-tabella-disponibilità div.contenitore-tabella table tbody tr td div.codici){
    overflow:       hidden;
    text-overflow:  ellipsis;
    width:          135px;
  }
</style>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import Scheda from "@/base/componenti/Scheda.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Tabella from "@/base/componenti/Tabella.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { createEventDispatcher } from "svelte"
  import { onMount } from "svelte"

  export let struttura = {}

  const propaga = createEventDispatcher()
  const t = avvia_localizzazione(dizionario_disponibilità)

  let disponibilità = []
  let disponibilità_inventariate = {}
  let totale_disponibilità = 0
  let in_caricamento = false
  let colonne = ["prodotto", "codici_associati", "disponibilità_totali"]

  ////
  // Richiede le disponibilità.
  async function richiedi_disponibilità() {
    in_caricamento = true
      
    let risposta = await retro.chiama(
      "GET",
      retro.estremi.portale.disponibilità, {
        id_struttura: struttura._id,
        stagione: [ "uguale_a", Date.today.year] })

    disponibilità = risposta.contenuto.disponibilità.map((disponibilità, indice) => {
      return { ...disponibilità, indice }
    })
  
    disponibilità.forEach(disponibilità_singola => {
      if(disponibilità_singola.unità_disponibili != null) totale_disponibilità += disponibilità_singola.unità_disponibili
    })

    disponibilità.forEach(disponibilità => {
      if (disponibilità.prodotto == null) return
      if (disponibilità_inventariate[disponibilità.prodotto] == null) {
        disponibilità_inventariate[disponibilità.prodotto] = { codici_associati: [], disponibilità_totali: 0 }
      }
      disponibilità_inventariate[disponibilità.prodotto].codici_associati.push(disponibilità.codice_prodotto)
      disponibilità_inventariate[disponibilità.prodotto].disponibilità_totali += disponibilità.unità_disponibili
    })

    in_caricamento = false
  }

  ////
  // Determina il valore di una cella.
  function valore_cella(riga, colonna, valore, indice_riga, indice_colonna) {
    // switch (colonna) {
    //   case "prodotto":
    //     if (valore == null) return "-"
    //     return t[valore][$l]
    //   default:
        return valore || "-"
    // }
  }

  onMount(() => richiedi_disponibilità())
</script>

<script context="module">
  export const dizionario_disponibilità = {
    etichetta: {
      it: `Disponibilità`,
      en: `Inventory`,
      de: `Bestandsaufnahme`
    },
    disponibilità_totali: {
      it: `Disponibilità Totali`,
      en: `Total Availability`,
      de: `Gesamtverfügbarkeit`
    },
    stagione: {
      it: `Stagione`,
      en: `Season`,
      de: `Saison`
    },
    colonne: {
      prodotto: {
        it: `Prodotto`,
        en: `Product`,
        de: `Produkt`
      },
      disponibilità_totali: {
        it: `Totale`,
        en: `Total`,
        de: `Gesamt`
      },
      codici_associati: {
        it: `Codici Associati`,
        en: `Associated Codes`,
        de: `Zugehörige Codes`
      },
    }
  }
</script>
