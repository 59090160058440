<template>
  <Rotta {schermo_intero}>
    <Titolo titolo={t.titolo_accademia[$l]}/>
    
    <div class="contenitore-barra-messaggio">
      <BarraMessaggio messaggio={t.descrizione_accademia[$l]}/>
    </div>

    <ContenitoreBase>
      {#if $sessione_corrente != null && $sessione_corrente.tipo != null}
        {#each lista_video_accademia[$sessione_corrente.tipo] as video }
          <div class="video-accademia">
            <TitoloSecondario no_imbottitura={true}> {video.titolo} </TitoloSecondario>
            <p class="descrizione-video"> {video.descrizione} </p>
            <RiproduttoreYouTube
                id_video={video.id_video}
                larghezza_riproduttore="560px"/>
          </div>
        {/each}
      {/if}
    </ContenitoreBase>

  </Rotta>

  <svelte:window
      on:barra-laterale-aperta={annulla_rapporto_da_aprire}
      on:barra-laterale-chiusa={apri_schermo_intero}
      on:schermo-intero-aperto={visualizza_rapporto_da_aprire}
      on:schermo-intero-chiuso={apri_barra_laterale}/>
</template>

<style>
  :global(div.contenitore-barra-messaggio) {
    margin: 15px;
  }
  :global(div.video-accademia) {
    margin: 0 15px 30px;
  }
  :global(div.video-accademia div.titolo-secondario) {
    margin: 5px 0;
  }
  :global(div.video-accademia p.descrizione-video) {
    margin: 0 0 23px;
  }
</style>

<script>
  import ContenitoreBase from "@/base/componenti/ContenitoreBase.svelte"
  import Rotta from "@/base/componenti/Rotta.svelte"
  import Titolo from "@/base/componenti/Titolo.svelte"
  import TitoloSecondario from "@/base/componenti/TitoloSecondario.svelte"
  import BarraMessaggio from "@/base/componenti/BarraMessaggio.svelte"
  import RiproduttoreYouTube from "@/base/componenti/RiproduttoreYouTube.svelte"
  import { sessione_corrente } from "@/portale/sorgenti/sessione"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { localizzazione as l } from "@/base/sorgenti/svuby"

  const t = avvia_localizzazione(dizionario_accademia)

  let barra_laterale = null
  let schermo_intero = false
  let rapporto = {}
  let rapporto_da_aprire = null

  $: lista_video_accademia = {
    hotel: [
      {
        id_video: "LxB7GQjSRRY",
        titolo: t.titolo_primo_video_hotel[$l],
        descrizione: t.descrizione_primo_video_hotel[$l]
      },
      {
        id_video: "NTbv6mnn29Y",
        titolo: t.titolo_secondo_video_hotel[$l],
        descrizione: t.descrizione_secondo_video_hotel[$l]
      },
      {
        id_video: "6_oTbT5o_Nc",
        titolo: t.titolo_terzo_video_hotel[$l],
        descrizione: t.descrizione_terzo_video_hotel[$l]
      },
    ],
    campeggio: [
      {
        id_video: "TsevHNFViUs",
        titolo: t.titolo_primo_video_campeggio[$l],
        descrizione: t.descrizione_primo_video_campeggio[$l]
      },
      {
        id_video: "GyZTdgzEI4s",
        titolo: t.titolo_secondo_video_campeggio[$l],
        descrizione: t.descrizione_secondo_video_campeggio[$l]
      },
      {
        id_video: "oTYYhNnLgnU",
        titolo: t.titolo_terzo_video_campeggio[$l],
        descrizione: t.descrizione_terzo_video_campeggio[$l]
      },
    ]
  }

  ////
  // Apre il rapporto.
  function apri_rapporto(evento) {
    rapporto_da_aprire = { ...evento.detail.rapporto }
  }
  ////
  // Chiude il rapporto.
  function chiudi_rapporto() {
    rapporto = {}
  }

  ////
  // Visualizza rapporto da aprire.
  function visualizza_rapporto_da_aprire() {
    rapporto = rapporto_da_aprire
  }
  ////
  // Annulla il rapporto da aprire.
  function annulla_rapporto_da_aprire() {
    rapporto_da_aprire = null
  }

  ////
  // Apre lo schermo intero.
  function apri_schermo_intero() {
    if (rapporto_da_aprire == null) return
    schermo_intero = true
  }
  ////
  // Chiude lo schermo intero.
  function chiudi_schermo_intero() {
    schermo_intero = false
  }

  ////
  // Apre la barra laterale.
  function apri_barra_laterale() {
    window.dispatchEvent(new CustomEvent("apri-barra-laterale"))
  }
  ////
  // Chiude la barra laterale.
  function chiudi_barra_laterale() {
    if (rapporto_da_aprire == null) return
    window.dispatchEvent(new CustomEvent("chiudi-barra-laterale"))
  }
</script>

<script context="module">
  export const dizionario_accademia = {
    titolo_accademia: {
      it: `Academy`,
      en: `Academy`,
      de: `Academy`
    },
    descrizione_accademia: {
      it: `In questa sezione <strong>HBenchmark Academy</strong> potrai trovare numerosi video che ti aiuteranno
          ad utilizzare al meglio la nostra piattaforma di <strong>Hospitality Data Intelligence</strong>.`,
      en: `In this section <strong>HBenchmark Academy</strong> you will find several videos that will help you to make
          the best use of our <strong>Hospitality Data Intelligence</strong> platform`,
      de: `In diesem Bereich der <strong>Hbenchmark Academy</strong> finden Sie zahlreiche Videos, die Ihnen helfen,
          unsere <strong>Hospitality-Data-Intelligence-Plattform</strong> optimal zu nutzen`
    },
    titolo_primo_video_hotel: {
      it: `DEMO - Come HBenchmark ti aiuterà a massimizzare i risultati del tuo hotel!`,
      en: `DEMO - How HBenchmark will help you maximise your hotel's performance!`,
      de: `DEMO - Wie HBenchmark Ihnen helfen kann, die Ergebnisse Ihres Hotels zu verbessern!`
    },
    titolo_secondo_video_hotel: {
      it: `TUTORIAL - Come controllare la correttezza dei dati della tua struttura!`,
      en: `TUTORIAL - How to check the correctness of your facility's data!`,
      de: `TUTORIAL - Wie Sie die Richtigkeit der Daten Ihres Betriebes überprüfen können!`
    },
    titolo_terzo_video_hotel: {
      it: `TUTORIAL - Come usare il nuovo configuratore!`,
      en: `TUTORIAL - How to use the new configurator!`,
      de: `TUTORIAL - Wie man den neuen Konfigurator benutzt!`
    },
    titolo_primo_video_campeggio: {
      it: `DEMO - Come HBenchmark ti aiuterà a massimizzare i risultati del tuo camping village!`,
      en: `DEMO - How HBenchmark will help you improve your campsite’s performances!`,
      de: `DEMO - Wie HBenchmark Ihnen helfen kann, die Leistung Ihres Campingplatzes zu verbessern!`
    },
    titolo_secondo_video_campeggio: {
      it: `TUTORIAL - Come controllare la correttezza dei dati del tuo camping village!`,
      en: `TUTORIAL - How to check the correctness of your camping village's data!`,
      de: `TUTORIAL - Wie Sie die Richtigkeit der Daten Ihres Betriebes überprüfen können!`
    },
    titolo_terzo_video_campeggio: {
      it: `TUTORIAL - Come usare il configuratore dei camping village!`,
      en: `TUTORIAL - How to use the new configurator!`,
      de: `TUTORIAL - Wie man den neuen Konfigurator benutzt!`
    },
    descrizione_primo_video_hotel: {
      it: `Una panoramica completa su tutte le funzionalità principali che ti aiuteranno a massimizzare i risultati della tua struttura.`,
      en: `A detailed overview of all the main features that will help you maximise your hotel's performance.`,
      de: `Ein vollständiger Überblick über alle wichtigen Funktionen, die Ihnen helfen, die Ergebnisse Ihres Hotels zu verbessern.`
    },
    descrizione_secondo_video_hotel: {
      it: `Come monitorare la correttezza del flusso metriche importate dal tuo PMS.`,
      en: `How to monitor the correctness of the metrics flow imported from your PMS.`,
      de: `Wie Sie die Richtigkeit der aus Ihrem PMS importierten Datenflüsse überwachen können.`
    },
    descrizione_terzo_video_hotel: {
      it: `Come usarlo al meglio per passare dal Benchmark alla Business Intelligence.`,
      en: `How to best use the configurator in order to switch from Benchmark to Business Intelligence.`,
      de: `Wie Sie den Konfigurator am besten nutzen, um von Benchmark zu Business Intelligence zu wechseln.`
    },
    descrizione_primo_video_campeggio: {
      it: `Una panoramica completa su tutte le funzionalità principali che ti aiuteranno a massimizzare i risultati del tuo campeggio.`,
      en: `A detailed overview of all the main features that will help you maximise your Campsite's performance.`,
      de: `Ein vollständiger Überblick über alle wichtigen Funktionen, die Ihnen helfen, die Ergebnisse Ihres Campingplatzes zu verbessern.`
    },
    descrizione_secondo_video_campeggio: {
      it: `Come monitorare la correttezza del flusso metriche importate dal tuo PMS.`,
      en: `How to monitor the correctness of the metrics flow imported from your PMS.`,
      de: `Wie Sie die Richtigkeit der aus Ihrem PMS importierten Datenflüsse überwachen können.`
    },
    descrizione_terzo_video_campeggio: {
      it: `Come usarlo al meglio per passare dal Benchmark alla Business Intelligence.`,
      en: `How to best use the configurator in order to switch from Benchmark to Business Intelligence.`,
      de: `Wie Sie den Konfigurator am besten nutzen, um von Benchmark zu Business Intelligence zu wechseln.`
    },
  }
</script>