<template>
  <GestioneStruttura>
    {#if in_caricamento}
      <Caricatore/>
    {:else}
      <div class="gestione-abbonamenti">
        <TitoloSecondario>{t.abbonamento_attuale[$l]}</TitoloSecondario>
        <div class="contenitore-abbonamento-attuale">
          <div class="abbonamento-attuale">
            <div class="proprietà-abbonamento">
              <label for="">{t.piano[$l]}</label>
  
              <div class="proprietà-abbonamento-contenuto">
                {$sessione_corrente.abbonamenti[0].nome_piano.toUpperCase()}
              </div>
            </div>
  
            <div class="proprietà-abbonamento">
              <label for="">{t.prezzo_finale[$l]}</label>
  
              <div class="proprietà-abbonamento-contenuto">
                {#if $sessione_corrente.abbonamenti[0].prezzo_finale != null}
                  {$sessione_corrente.abbonamenti[0].prezzo_finale}&nbsp;{@html $valuta_per_dominio}
                {:else}
                  {$sessione_corrente.abbonamenti[0].prezzo_base}&nbsp;{@html $valuta_per_dominio}
                {/if}
              </div>
            </div>
  
            <div class="proprietà-abbonamento">
              <label for="">{t.iscrizione[$l]}</label>
  
              <div class="proprietà-abbonamento-contenuto">
                {sottoscritto_il.format("%d %B %Y")}
              </div>
            </div>
  
            {#if inizio != null}
              <div class="proprietà-abbonamento">
                <label for="">{t.data_inizio[$l]}</label>
  
                <div class="proprietà-abbonamento-contenuto">
                  {inizio.format("%d %B %Y")}
                </div>
              </div>
            {/if}
  
            {#if termine != null}
              <div class="proprietà-abbonamento">
                <label for="">{t.data_termine[$l]}</label>
  
                <div class="proprietà-abbonamento-contenuto">
                  {termine.format("%d %B %Y")}
                </div>
              </div>
            {/if}

            {#if inizio_prova != null}
              <div class="proprietà-abbonamento">
                <label for="">{t.data_inizio[$l]}</label>
  
                <div class="proprietà-abbonamento-contenuto">
                  {inizio_prova.format("%d %B %Y")}
                </div>
              </div>
            {/if}
  
            {#if termine_prova != null}
              <div class="proprietà-abbonamento">
                <label for="">{t.data_termine[$l]}</label>
  
                <div class="proprietà-abbonamento-contenuto">
                  {termine_prova.format("%d %B %Y")}
                </div>
              </div>
            {/if}
  
            <div class="proprietà-abbonamento">
              <label for="">{t.stato[$l]}</label>
  
              <div class="proprietà-abbonamento-contenuto">
                {#if $sessione_corrente.abbonamenti[0].stato == null}
                  <Fanalino stato="ignorato"/>
                {:else}
                  <Fanalino stato={$sessione_corrente.abbonamenti[0].stato}/>
                  &nbsp; {t.stati[$sessione_corrente.abbonamenti[0].stato][$l]}
                {/if}
              </div>
            </div>
  
            {#if $sessione_corrente.abbonamenti[0].stato == "da_completare"}
              <div class="bottone-abbonamento-attuale">
                <Bottone
                    variante="primario"
                    on:click={crea_pagamento_e_reindirizza}>
                  {t.concludi_pagamento[$l]}
                </Bottone>
              </div>
            {:else if $sessione_corrente.abbonamenti[0].metodo_di_pagamento == "carta_di_credito" ||
              $sessione_corrente.abbonamenti[0].metodo_di_pagamento == "addebito_sepa"}
              <div class="bottone-abbonamento-attuale">
                <Bottone
                    variante="primario"
                    on:click={visualizza_pagamento}>
                  {t.gestisci_abbonamento[$l]}
                </Bottone>
              </div>
            {/if}
          </div>

          {#if $sessione_corrente.abbonamenti[0].metodo_di_pagamento == "contratto_destinazione"}
            <BarraMessaggio>
              {@html  t.abbonamento_sostenuto_partner[$l]}
              {#if PORTALI_TERRITORIALI[$sessione_corrente.abbonamenti[0].flusso_personalizzato] != null}
                {t.presenti_nel[$l]}
                <a target="_blank" href="{PORTALI_TERRITORIALI[$sessione_corrente.abbonamenti[0].flusso_personalizzato]}">
                  {t.portale_territoriale[$l]}
                </a>
              {/if}
            </BarraMessaggio>
          {/if}
        </div>

        <div class="dati-fatturazione-struttura">
          {#if $sessione_corrente.clienti != null}
            <div class="blocco">
              <TitoloSecondario>{t.indirizzo_fatturazione[$l]}</TitoloSecondario>
              <Scheda>
                <div class="campo">
                  <div class="nome">{t.campi.ragione_sociale[$l]}</div>
                  <div class="valore">{$sessione_corrente.clienti[0].ragione_sociale || "-"}</div>
                </div>

                <div class="campo">
                  <div class="nome">{t.campi.indirizzo[$l]}</div>
                  <div class="valore">{$sessione_corrente.clienti[0].indirizzo || "-"}</div>
                </div>

                <div class="campo">
                  <div class="nome">{t.campi.città[$l]}</div>
                  <div class="valore">{$sessione_corrente.clienti[0].città || "-"}</div>
                </div>

                <div class="campo">
                  <div class="nome">{t.campi.provincia[$l]}</div>
                  <div class="valore">{$sessione_corrente.clienti[0].provincia || "-"}</div>
                </div>

                <div class="campo">
                  <div class="nome">{t.campi.cap[$l]}</div>
                  <div class="valore">{$sessione_corrente.clienti[0].cap || "-"}</div>
                </div>

                <div class="campo">
                  <div class="nome">{t.campi.partita_iva[$l]}</div>
                  <div class="valore">{$sessione_corrente.clienti[0].partita_iva || "-"}</div>
                </div>

                <div class="campo">
                  <div class="nome">{t.campi.codice_fiscale[$l]}</div>
                  <div class="valore">{$sessione_corrente.clienti[0].codice_fiscale || "-"}</div>
                </div>
              </Scheda>
            </div>
          {/if}

          <div
              class="blocco"
              class:intero={$sessione_corrente.clienti == null}>
            {#if $sessione_corrente.clienti != null}
              <TitoloSecondario>{t.fatturazione_elettronica[$l]}</TitoloSecondario>
              <Scheda>
                <div class="campo">
                  <div class="nome">{t.campi.email_pec[$l]}</div>
                  <div class="valore">{$sessione_corrente.clienti[0].email_pec || "-"}</div>
                </div>

                <div class="campo">
                  <div class="nome">{t.campi.codice_fiscale[$l]}</div>
                  <div class="valore">{$sessione_corrente.clienti[0].codice_fiscale || "-"}</div>
                </div>

                <div class="campo">
                  <div class="nome">{t.campi.email_fatturazione[$l]}</div>
                  <div class="valore">{$sessione_corrente.clienti[0].email_fatturazione || "-"}</div>
                </div>
              </Scheda>
            {/if}

            <TitoloSecondario>{t.contratto[$l]}</TitoloSecondario>
            <Scheda>
              <div class="campo">
                <div class="nome">{t.visualizza_contratto[$l]}</div>
                <div class="valore">
                  <a on:click|preventDefault={apri_modale_contratto} href="/gestione_strutture/abbonamenti">
                    {t.vedi_contratto[$l]}
                  </a>
                </div>
              </div>
            </Scheda>
          </div>
        </div>
      </div>

      <Modale
          richiudibile={true}
          schermo_intero={true}
          titolo={t.contratto[$l]}
          bind:aperta={modale_contratto_aperta}>
        <div class="tasto-esporta">
          <Bottone
              piccolo={true}
              variante="secondario-invertito"
              on:click={window.print}>
            <Icona tipo="esporta"/>
          </Bottone>
        </div>

        <div class="contenitore-contratto">
          {#if $l == "it"}
            <ContrattoItaliano
                {sottoscrivente}
                tipo_struttura={$sessione_corrente.tipo}/>
          {:else if $l == "de"}
            <ContrattoTedesco
                {sottoscrivente}
                tipo_struttura={$sessione_corrente.tipo}/>
          {:else if $l == "en"}
            <ContrattoInglese
                {sottoscrivente}
                tipo_struttura={$sessione_corrente.tipo}/>
          {/if}
        </div>
      </Modale>
    {/if}
  </GestioneStruttura>
</template>

<style>
  :global(div.tasto-esporta){
    position: fixed;
    top:      16px;
    right:    65px;
  }
  :global(div.contenitore-contratto){
    width:  95%;
    margin: 0 auto;
  }
  :global(div.gestione-abbonamenti){
    padding:          0 var(--padding-base-main);
  }
  :global(div.gestione-abbonamenti div.barra-messaggio){
    margin-top:       15px;
  }
  :global(div.gestione-abbonamenti div.contenitore-abbonamento-attuale){
    margin:           10px 0 34px;
  }
  :global(div.gestione-abbonamenti div.contenitore-abbonamento-attuale div.abbonamento-attuale){
    display:          flex;
    justify-content:  space-between;
    background:       var(--colore-bianco);
    box-shadow:       0 5px 12px 0 var(--colore-ombreggiatura);
    padding:          12px 10px;
    border-radius:    8px;
  }
  :global(div.gestione-abbonamenti div.contenitore-abbonamento-attuale div.abbonamento-attuale div.proprietà-abbonamento > label){
    font-size:      13px;
    color:          var(--colore-segnaposto);
    margin-bottom:  15px;
  }
  :global(div.gestione-abbonamenti div.contenitore-abbonamento-attuale div.abbonamento-attuale div.proprietà-abbonamento div.proprietà-abbonamento-contenuto){
    margin-top:   15px;
    font-weight:  500;
    display:      flex;
    align-items:  center;
  }
  :global(div.gestione-abbonamenti div.contenitore-abbonamento-attuale div.abbonamento-attuale div.bottone-abbonamento-attuale){
    display:      flex;
    align-items:  center;
  }
  :global(div.gestione-abbonamenti div.contenitore-abbonamento-attuale div.abbonamento-attuale div.bottone-abbonamento-attuale button){
    padding: 9px 12px;
  }

  /* Dati Fatturazione. */
  :global(div.dati-fatturazione-struttura) {
    display:          flex;
  }
  :global(div.dati-fatturazione-struttura div.blocco) {
    width: calc(50% - 6px);
  }
  :global(div.dati-fatturazione-struttura div.blocco.intero) {
    width: 100%;
  }
  :global(div.dati-fatturazione-struttura div.blocco:not(.intero):first-child) {
    margin-right: 12px;
  }
  :global(div.dati-fatturazione-struttura div.blocco:not(.intero):last-child div.scheda:nth-child(2)) {
    margin-bottom: 15px;
  }
  :global(div.dati-fatturazione-struttura div.scheda div.campo) {
    display:          flex;
    align-items:      center;
    justify-content:  space-between;
    padding:          0;
    line-height:      24px;
  }
  :global(div.dati-fatturazione-struttura div.scheda:not(.modifica_in_corso) div.campo:not(:last-child)) {
    margin-bottom:    27px;
  }
  :global(div.dati-fatturazione-struttura div.scheda div.campo div.nome),
  :global(div.dati-fatturazione-struttura div.scheda div.campo div.valore){
    flex-grow:        1;
  }
  :global(div.dati-fatturazione-struttura div.scheda div.campo div.valore) {
    display:          flex;
    align-items:      center;
    justify-content:  flex-end;
    font-weight:      700;
  }
</style>

<script>
  import GestioneStruttura from "@/portale/componenti/GestioneStruttura.svelte"
  import BarraMessaggio from "@/base/componenti/BarraMessaggio.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Fanalino from "@/base/componenti/Fanalino.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Modale from "@/base/componenti/Modale.svelte"
  import Icona from "@/base/componenti/Icona.svelte"
  import Scheda from "@/base/componenti/Scheda.svelte"
  import TitoloSecondario from "@/base/componenti/TitoloSecondario.svelte"
  import ContrattoItaliano from "@/portale/componenti/abbonamento/contratti/Italiano.svelte"
  import ContrattoTedesco from "@/portale/componenti/abbonamento/contratti/Tedesco.svelte"
  import ContrattoInglese from "@/portale/componenti/abbonamento/contratti/Inglese.svelte"
  import { abbonamento } from "@/portale/sorgenti/abbonamento"
  import { sessione_corrente } from "@/portale/sorgenti/sessione"
  import { valuta_per_dominio } from "@/portale/sorgenti/valuta_per_dominio"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { onDestroy } from "svelte"

  const t = avvia_localizzazione(dizionario_abbonamenti)
  const PORTALI_TERRITORIALI = {
    padova:   "https://www.hbenchmark.com/padova",
    trentino: "https://www.hbenchmark.com/trentino",
    livigno:  "https://www.hbenchmark.com/livigno",
    genova:   "https://www.hbenchmark.com/genova",
    bergamo:  "https://www.hbenchmark.com/bergamo"
  }

  let modale_contratto_aperta = false
  let inizio = null
  let termine = null
  let inizio_prova = null
  let termine_prova = null

  $: sottoscrivente = {
    nome: $sessione_corrente.abbonamenti[0].sottoscrivente,
    sottoscritto_il
  }
  $: in_caricamento = $sessione_corrente.abbonamenti == null
  $: sottoscritto_il = new Date($sessione_corrente.abbonamenti[0].sottoscritto_il)
  $: if ($sessione_corrente.abbonamenti[0].inizio != null) inizio = new Date($sessione_corrente.abbonamenti[0].inizio)
    else inizio = null
  $: if ($sessione_corrente.abbonamenti[0].termine != null) termine = new Date($sessione_corrente.abbonamenti[0].termine)
    else termine = null
  $: if ($sessione_corrente.abbonamenti[0].inizio_periodo_di_prova != null) inizio_prova = new Date($sessione_corrente.abbonamenti[0].inizio_periodo_di_prova)
    else inizio_prova = null
  $: if ($sessione_corrente.abbonamenti[0].termine_periodo_di_prova != null) termine_prova = new Date($sessione_corrente.abbonamenti[0].termine_periodo_di_prova)
    else termine_prova = null

  ////
  // Apre la modale con il contratto
  function apri_modale_contratto(evento) {
    modale_contratto_aperta = true
  }

  ////
  // Richiede il link al pagamento
  async function visualizza_pagamento() {
    let risposta = await retro.chiama("GET",
      retro.estremi.licenze.pagamento($sessione_corrente.clienti[0]._id),{
        indirizzo_di_ritorno: window.location.href
      })

    window.location.replace(risposta.contenuto.pagamento.url)
  }

  ////
  // Crea i dati per attuare il pagamento e reindirizza alla pagina apposita
  function crea_pagamento_e_reindirizza() {
    $abbonamento = $sessione_corrente.abbonamenti[0]
    $abbonamento.pagamento_annullato = true
    $abbonamento.nome_struttura = $sessione_corrente.nome
    abbonamento.sovrascrivi($abbonamento)
    navigatore.verso("/gestione_strutture/pagamento")
  }

  //Al cambio sessione reindirizza alla gestione struttura
  const scollega_cambio_sessione = sessione_corrente.su_cambio(nuova_sessione => {
    if (nuova_sessione.abbonamenti == null) navigatore.verso("/gestione_strutture/struttura")
  })
  onDestroy(scollega_cambio_sessione)
</script>

<script context="module">
  export const dizionario_abbonamenti = {
    abbonamento_attuale: {
      it: `Attuale Piano Abbonamento`,
      en: `Current Subscription Plan`,
      de: `Aktueller Abonnementplan`
    },
    abbonamento_sostenuto_partner: {
      it: `Abbonamento interamente <b>sostenuto dai partner</b>`,
      en: `Subscription entirely <b>supported by partners</b>`,
      de: `Abonnement vollständig <b>unterstützt von partnern</b>,`
    },
    presenti_nel:{
      it: `presenti nel`,
      en: `present in the`,
      de: `die in der`
    },
    portale_territoriale: {
      it: `portale territoriale.`,
      en: `territorial portal`,
      de: `Länder Portal`
    },
    piano: {
      it: `Piano`,
      en: `Floor`,
      de: `Stockwerk`
    },
    prezzo_finale: {
      it: `Prezzo Finale`,
      en: `Final price`,
      de: `Endgültiger Preis`
    },
    iscrizione: {
      it: `Data Iscrizione`,
      en: `Registration date`,
      de: `Registrierungsdatum`
    },
    data_inizio: {
      it: `Data Inizio`,
      en: `Start date`,
      de: `Startdatum`
    },
    data_termine: {
      it: `Data Termine`,
      en: `End date`,
      de: `Enddatum`
    },
    stato: {
      it: `Stato Abbonamento`,
      en: `Subscription status`,
      de: 'Abonnement Status'
    },
    vedi_contratto: {
      it: `Vedi Contratto`,
      en: `View Agreement`,
      de: `Vertrag anzeigen`
    },
    visualizza_contratto: {
      it: `Visualizza contratto accettato`,
      en: `View accepted contract`,
      de: `Akzeptierten Vertrag ansehen`
    },
    gestisci_abbonamento: {
      it: `Gestisci Abbonamento`,
      en: `Manage Subscription`,
      de: `Abonnement verwalten`
    },
    concludi_pagamento: {
      it: `Concludi il Pagamento`,
      en: `Complete the payment`,
      de: `Zahlung abschließen`
    },
    contratto: {
      it: `Contratto`,
      en: `Contract`,
      de: `Vertrag`
    },
    indirizzo_fatturazione: {
      it: `Indirizzo di fatturazione`,
      en: `Billing address`,
      de: `Rechnungsadresse`
    },
    fatturazione_elettronica: {
      it: `Informazioni per fatturazione elettronica`,
      en: `Information for electronic invoicing`,
      de: `Informationen zur elektronischen Rechnungsstellung`
    },
    stati: {
      attivo: {
        it: `Attivo`,
        en: `Active`,
        de: `Aktiv`
      },
      annullato: {
        it: `Annullato`,
        en: `Canceled`,
        de: `Annulliert`
      },
      scaduto: {
        it: `Scaduto`,
        en: `Expired`,
        de: `Abgelaufen`
      },
      in_attesa_pagamento: {
        it: `In attesa pagamento`,
        en: `Waiting payment`,
        de: `Ausstehende Zahlung`
      },
      pagamento_non_riuscito: {
        it: `Pagamento non riuscito`,
        en: `Payment failed`,
        de: `Zahlung fehlgeschlagen`
      },
      da_completare: {
        it: `Pagamento mancante`,
        en: `Missing payment`,
        de: `Fehlende Zahlung`
      },
      in_prova: {
        it: `In prova`,
        en: `Try & Buy`,
        de: `Auf Probe`
      }
    },
    campi: {
      ragione_sociale: {
        it: `Ragione sociale`,
        en: `Business name`,
        de: `Firmenname`
      },
      indirizzo: {
        it: `Indirizzo`,
        en: `Street address`,
        de: `Adresse`
      },
      città: {
        it: `Città`,
        en: `City`,
        de: `Stadt`
      },
      provincia: {
        it: `Provincia`,
        en: `Province`,
        de: `Bundesland`
      },
      cap: {
        it: `CAP`,
        en: `POSTAL CODE`,
        de: `Postleitzahl`
      },
      partita_iva: {
        it: `P.IVA`,
        en: `VAT`,
        de: `MwSt.`
      },
      codice_fiscale: {
        it: `C.Fiscale`,
        en: `VAT number`,
        de: `Umsatzsteuer-Identifikationsnummer`
      },
      email_pec: {
        it: `PEC`,
        en: `PEC`,
        de: `PEC`
      },
      codice_sdi: {
        it: `SDI`,
        en: `SDI`,
        de: `SDI`
      },
      email_fatturazione: {
        it: `Altro indirizzo email`,
        en: `Other email address`,
        de: `Andere E-Mail-Adresse`
      },
    }
  }
</script>