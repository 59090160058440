<template>
  <Profilo>
    <TitoloSecondario>{t.informazioni_utente[$l]}</TitoloSecondario>

    <div class="padding-positivi">
      <Modulo
          {indirizzo}
          {parametri}
          metodo="PATCH"
          gestore={gestore_risposta}
          validatore={validatore_modulo}
          bind:in_salvataggio={salvataggio_in_corso}
          bind:errori={errori}>
        <div class="nome">
          <Inserimento
            tipo="testuale"
            etichetta={t.nome[$l]}
            disabilitato={!in_modifica}
            bind:valore={nuovo_me.nome}/>

          <!-- <Inserimento
            tipo="testuale"
            etichetta={t.cognome[$l]}
            disabilitato={!in_modifica}
            bind:valore={nuovo_me.cognome}/> -->
        </div>

        <Inserimento
          tipo="testuale"
          etichetta={t.email[$l]}
          disabilitato={!in_modifica}
          bind:valore={nuovo_me.email}/>

        <!-- <Inserimento
          tipo="testuale"
          etichetta={t.telefono[$l]}
          disabilitato={!in_modifica}
          bind:valore={nuovo_me.telefono}/> -->

        <div class="localizzatore">
          <TitoloSecondario>{t.lingua[$l]}</TitoloSecondario>
          <Localizzatore
              apertura="sotto"
              esteso={true}/>
        </div>
      </Modulo>
    </div>

  </Profilo>
</template>

<style>
  :global(div.contenitore-profilo form){
    width:  50%;
    margin: 0;
  }
  :global(div.contenitore-profilo form div.localizzatore){
    margin-top: 15px;
    width: 50%;
  }
  :global(div.contenitore-profilo form div.nome){
    display: flex;
  }
  :global(div.contenitore-profilo form div.nome div.inserimento){
    width: calc(50% - 6px);
  }
  :global(div.contenitore-profilo form div.nome div.inserimento:first-child){
    margin-right: 12px;
  }
</style>

<script>
  import Profilo from "@/portale/componenti/Profilo.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import Localizzatore from "@/base/componenti/Localizzatore.svelte"
  import TitoloSecondario from "@/base/componenti/TitoloSecondario.svelte"
  import Modulo from "@/base/componenti/Modulo.svelte"
  import { utente_corrente } from "@/base/sorgenti/svuby"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  const t = avvia_localizzazione(dizionario_anagrafica)
  const indirizzo = retro.estremi.anagrafe

  let in_modifica = false
  let nuovo_me = $utente_corrente
  let salvataggio_in_corso = false
  let errori = {}

  $: parametri = {utente: nuovo_me}

  ////
  // Validazione del modulo.
  function validatore_modulo() {
    return
    if (evento.nome == null || evento.nome == "")
      errori.nome = t.validazioni.richiesto[$l]
  }

  ////
  // Gestione della gestione del modulo.
  function gestore_risposta(risposta) {
    return
    if (risposta.riuscita) {
      evento = risposta.contenuto.evento
      propaga("change", { evento })
    } else {
      messaggio_errore = t.errore_salvataggio[$l]
      messaggio_errore_visualizzato = true
      in_salvataggio = false
    }
  }
</script>

<script context="module">
  export const dizionario_anagrafica = {
    informazioni_utente: {
      it: `Informazioni utente`,
      en: `User information`,
      de: `Nutzerinformation`
    },
    nome: {
      it: `Nome`,
      en: `Name`,
      de: `Name`
    },
    cognome: {
      it: `Cognome`,
      en: `Surname`,
      de: `Nachname`
    },
    email: {
      it: `Email`,
      en: `Email`,
      de: `E-Mail`
    },
    telefono: {
      it: `Telefono`,
      en: `Phone`,
      de: `Telefon`
    },
    lingua: {
      it: `Lingua`,
      en: `Language`,
      de: `Sprache`
    },
    indirizzo: {
      it: `Indirizzo`,
      en: `Address`,
      de: `Adresse`
    },
    città: {
      it: `Città`,
      en: `City`,
      de: `Stadt`
    },
    provincia: {
      it: `Provincia`,
      en: `Province`,
      de: `Bundesland`
    },
    telefono: {
      it: `Telefono`,
      en: `Phone`,
      de: `Telefon`
    }
  }
</script>