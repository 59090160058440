<template>
  {#if sottoscrivente}
    <BarraMessaggio>
      Contratto sottoscritto da <b>{sottoscrivente.nome}</b> il giorno <b>{sottoscrivente.sottoscritto_il.format("%d %B %Y")}</b>
    </BarraMessaggio>
  {/if}
  
  <p class="mt-4">Gentile Cliente, benvenuto sulla piattaforma HBenchmark.</p>
  <p>
    HBenchmark srl con sede legale in Italia, Altavilla Vicentina, via Retrone n.
    16, (VI) - Partita IVA e Codice Fiscale: 03998320240 - Tel n. 0444276202 Mail
    <a href="mailto:info@hbenchmark.it">info@hbenchmark.it</a>
    Legal mail
    <a href="mailto:hbenchmark@legalmail.it">hbenchmark@legalmail.it</a>
    insieme con altre società del Gruppo Develon ha creato e sviluppato questa
    piattaforma, gestita da software proprietario e riservata agli operatori
    professionali dell’industria dell’ospitalità quali albergatori e gestori di
    camping.
  </p>
  <p>
    La Piattaforma HBenchmark, come avrai potuto apprendere cliccando
    sull’apposito pulsante
    <a href="https://preventivi.hbenchmark.it/{tipo_struttura == "hotel" ? "brochure-hotel_IT.pdf" : "flyer-camping_IT.pdf"}" target="_blank">Brochure HB</a>,
    [se non lo hai già fatto ti invitiamo a farlo prima di proseguire]
    è una piattaforma collaborativa web - based che
    normalizza ed organizza informazioni aggregate da cui i suoi algoritmi
    estrapolano e comparano tra di loro indicatori specifici di performance
    economica (KPI o Key Performance Indicators) per l’industria dell’ospitalità
    al fine di facilitare l’ottimizzazione del listino, supportare strategie di
    prezzo dinamico, dare credibilità al rating aziendale e affrontare con
    consapevolezza ogni ipotesi di investimento.
  </p>
  <p>
    Questo contratto di Abbonamento (di seguito “
    <strong>Contratto</strong>
    ) unitamente alla
    <a href="https://www.iubenda.com/privacy-policy/7839661">Privacy Policy</a>
    ed alla Brochure
    <a href="https://preventivi.hbenchmark.it/{tipo_struttura == "hotel" ? "brochure-hotel_IT.pdf" : "flyer-camping_IT.pdf"}" target="_blank">HBenchmark</a>
    che ne costituiscono parte integrante, contiene i termini e le condizioni che
    regolano il tuo accesso alla Piattaforma ed il tuo uso dei servizi richiesti
    e, unitamente al modulo di adesione e pagamento, costituiscono l’accordo
    legale che disciplina i rapporti tra HBenchmark e te (se sei imprenditore
    individuale) o la entità legale da te rappresentata.
  </p>
  <p>
    Il Contratto si intende concluso ed efficace tra HBenchmark e te nel momento
    in cui avrai completato con successo la procedura di abbonamento ed avrai
    effettuato il pagamento del corrispettivo.
  </p>
  <p>&nbsp;</p>
  <p>
    <strong>Art.1. &nbsp;Definizioni</strong>
  </p>
  <p>
    I termini qui sotto indicati con le iniziali maiuscole avranno, nel proseguo
    del contratto, il significato di seguito attribuito
  </p>
  <p>
    <strong>Dati Attività</strong>
    : significa i dati della tua attività, comprensivi di ragione sociale, P.IVA
    sede, mail, PEC telefono, legale rappresentante etc. che ti sarà chiesto di
    inserire nel modulo di abbonamento
  </p>
  <p>
    <strong>Informazioni Prenotazione</strong>
    : significa le informazioni relative alle prenotazioni depositate nel
    programma gestionale dell‘Abbonato e che vengono trasferite ad HB quali&nbsp;
    a titolo di esempio, &nbsp;i seguenti: nome della struttura (il Tuo Hotel o la
    tua attività), prenotazione (senza dati personali), data di soggiorno, data di
    prenotazione, data di arrivo, data di partenza, numero di camere prenotate,
    numero di ospiti adulti, numero di ospiti bambini, tipologia di camera,
    descrizione del trattamento, valore della prenotazione (Camera + Trattamento),
    stato della prenotazione, data di disdetta (se prenotazione annullata),
    nazionalità della prenotazione, codice del canale di prenotazione (diretto,
    internet, TO etc) e codice del settore di mercato della prenotazione. Tali
    informazioni vengono e verranno da noi recepite esclusivamente in forma
    anonima e cioè senza che sia per noi possibile collegare ad esse dati
    identificativi di persone.
  </p>
  <p>&nbsp;</p>
  <p>
    <strong>Informazioni Aggregate</strong>
    : significa le Informazioni Prenotazione di più Abbonati normalizzate ed
    aggregate in forma anonima.
  </p>
  <p>
    <strong>KPI Personali</strong>
    : significa gli indicatori di performance economica, le statistiche ed i
    report che gli algoritmi di HBenchmark elaborano/estraggono dalle
    Informazioni Prenotazione di ogni singolo Abbonato come meglio descritti nella
    <a href="https://preventivi.hbenchmark.it/{tipo_struttura == "hotel" ? "brochure-hotel_IT.pdf" : "flyer-camping_IT.pdf"}" target="_blank">Brochure</a>
    .
  </p>
  <p>
    <strong>KPI Aggregati</strong>
    : significa gli indicatori di performance economica, le statistiche, le
    comparazioni ed i report che gli algoritmi di HBenchmark elaborano/estraggono
    dai Dati Aggregati come meglio descritti nella
    <a href="https://preventivi.hbenchmark.it/{tipo_struttura == "hotel" ? "brochure-hotel_IT.pdf" : "flyer-camping_IT.pdf"}" target="_blank">Brochure</a>.
    Alcuni KPI aggregati potranno essere resi accessibili ai Policy Makers con cui HB collabora.
  </p>
  <p>
    <strong>HB:</strong>
    significa la piattaforma di benchmark alberghiero meglio descritta
    nell’allegato B)
  </p>
  <p>
    <strong>Abbonamento</strong>
    : significa la procedura on-line o off-line descritta nell’Allegato B mediante
    la quale il Cliente può accedere ad HB per il periodo di tempo corrispondente
    al corrispettivo pagato.
  </p>
  <p>
    <strong>Abbonato:</strong>
    significa il soggetto operatore turistico, sia persona fisica sia giuridica,
    che ha perfezionato la procedura di Abbonamento e che, per la durata
    dell’Abbonamento, ha accesso ad HB.
  </p>
  <p>
    <strong>API di collegamento:</strong>
    anche chiamate API REST (Representational State Transfer) sono un insieme di
    interfacce e procedure messe a disposizione da HB per permettere ai Gestionale
    (PMS) di interrogare e collaborare alla piattaforma tramite un canale cifrato
    e protetto da password.
  </p>
  <p>
    <strong>Gestionale:</strong>
    anche chiamato PMS è il sistema tramite il quale l’hotel gestisce il planning
    delle camere, le attività di check-in e check-out, i prezzi delle camere e dei
    servizi associati alla prenotazione e la fatturazione.
  </p>
  <p>
    <strong>Brochure:</strong>
    significa la brochure descrittiva dei servizi offerti da HB che ti viene messa
    a disposizione tramite il seguente
    <a href="https://preventivi.hbenchmark.it/{tipo_struttura == "hotel" ? "brochure-hotel_IT.pdf" : "flyer-camping_IT.pdf"}" target="_blank">Link</a>
  </p>
  <p>
    <strong>Competitive set:</strong>
    significa il tool informatico che ti consente di confrontare i KPI della tua
    struttura con quelli aggregati di un gruppo di altre strutture, in numero non
    inferiore a 5 che tu stesso potrai selezionare dall’elenco Abbonati. Il fatto
    che tu possa, come qualsiasi altro utilizzatore, scegliere nominalmente le
    strutture che faranno parte del gruppo non ha come conseguenza che qualcuno
    possa ricavare o estrarre dai KPI del gruppo le performance di uno o di
    ciascuno dei componenti. Le leggi della statistica ritengono che se un gruppo
    è composto da almeno 4 strutture sia impossibile estrapolare le performance di
    una singola struttura. In ossequio ad un principio prudenziale abbiamo deciso
    di elevare questo numero minimo a 5.
  </p>
  <p>
    <strong>&nbsp;</strong>
  </p>
  <p>
    <strong>Art. 2. Abbonamento e Modalità di accesso</strong>
  </p>
  <p>
    Per ottenere l’accesso alla Piattaforma ed ai KPI Personali ed Aggregati
    dovrai abbonarti ad HB seguendo la procedura guidata cliccando sul link
    <a href="https://portal.hbenchmark.it/">https://portal.hbenchmark.it/</a>
    . Prendi nota che la procedura si bloccherà fintantoché non avrai letto,
    scrollato ed accettato i documenti contrattuali. Ti invitiamo a leggere con
    particolare attenzione le clausole n. 4 (Trattamento delle Informazioni
    Prenotazione ed obblighi di riservatezza) e n. 6 (privacy e consenso).
  </p>
  <p>
    2.1. In tale fase sarai richiesto di inserire alcuni dati sia personali sia
    della tua attività che servono per inserirti nell’elenco abbonati. La
    correttezza, la veridicità e la completezza dei Dati Attività che ti chiediamo
    di fornirci è essenziale per la fornitura dei nostri servizi.
  </p>
  <p>
    Ti preghiamo di prendere nota che qualora verificassimo, anche da un controllo
    a posteriori, che i Dati Attività da te inseriti non sono veritieri e
    corretti, potremo sospendere senza preavviso il tuo accesso alla piattaforma
    HB.
  </p>
  <p>
    2.2. Ad un certo punto ti sarà richiesto di aprire una finestra ove sono
    elencati i programmi gestionali con cui HBenchmark ha realizzato un API di
    collegamento e di indicare tra questi il Gestionale che tu utilizzi.
  </p>
  <p>
    Nel momento in cui selezioni il tuo Gestionale tu, alla fine del processo di
    adesione, dai incarico al tuo gestionale di collegarsi con l’API HBenchmark,
    tramite una procedura guidata, per trasferire: (i) quotidianamente le
    Informazioni Prenotazioni attuali e (ii) al primo accesso, le Informazioni
    Prenotazione relativi ai due anni che precedono, se esistenti.
  </p>
  <p>
    Nel fare ciò tu garantisci che hai legittimamente raccolto e ci invii le
    Informazioni Prenotazione.
  </p>
  <p>2.3. Ulteriori modalità</p>
  <p>
    Se il Gestionale che tu utilizzi non figura tra quelli da noi indicati
    nell’apposito elenco, ti invitiamo a comunicarcelo immediatamente perché
    dovremo adottare diverse modalità di collegamento sempre assistite da un
    wizard; prendi nota che nel caso di impossibilità di stabilire un collegamento
    con il tuo Gestionale non potremo erogare i nostri servizi e tu non potrai
    abbonarti ad HB.
  </p>
  <p>
    Ti verrà inoltre richiesto di inserire una Username e ti verrà comunicata una
    password; sei tenuto a mantenere riservati il tuo account e la tua password e
    a controllare l'accesso al tuo computer e ai tuoi dispositivi, e accetti, nei
    limiti consentiti dalle disposizioni di legge applicabili, di essere ritenuto
    responsabile di tutte le attività che verranno effettuate con il tuo account e
    la tua password. Ti impegni a prendere tutte le precauzioni necessarie per
    garantire che la tua password rimanga sicura e riservata e ti impegni a
    informarci immediatamente nel caso tu abbia motivo di credere che qualunque
    soggetto terzo sia a conoscenza della tua password, o nel caso in cui la tua
    password sia, o presumibilmente stia per essere, utilizzata in modo non
    autorizzato.
  </p>
  <p>
    La procedura di abbonamento è assistita da un wizard e avrai sempre la
    possibilità di essere contattato via chat o telefonica dal nostro servizio
    assistenza.
  </p>
  <p>
    Tieni conto che tra il completamento della procedura di abbonamento e l’invio
    &nbsp;ed il caricamento dei dati sui nostri sistemi intercorrono degli
    inevitabili tempi tecnici. Il nostro customer care sarà a tua disposizione.
  </p>
  <p>
    <strong>Art. 3 - Servizi offerti</strong>
  </p>
  <p>
    I servizi offerti da HBenchmark sono i KPI Personali ed Aggregati ed i
    Competitive Set meglio descritti nella Brochure (
    <a href="https://preventivi.hbenchmark.it/{tipo_struttura == "hotel" ? "brochure-hotel_IT.pdf" : "flyer-camping_IT.pdf"}" target="_blank">
      {#if tipo_struttura == "hotel"}
        https://preventivi.hbenchmark.it/brochure-hotel_IT.pdf
      {:else}
        https://preventivi.hbenchmark.it/flyer-camping_IT.pdf
      {/if}
    </a>
    ) cui tu potrai accedere per tutta la durata del tuo abbonamento.
  </p>
  <p>
    HBenchmark si riserva di offrirti ulteriori servizi di cui ti darà tempestiva
    notizia.
  </p>
  <ol start="4">
    <li>
      <strong>
        -Trattamento delle Informazioni Prenotazione ed obblighi di riservatezza
        di HB
      </strong>
    </li>
  </ol>
  <p>
    4.1. Le Informazioni Prenotazione che noi normalizziamo, analizziamo ed
    elaboriamo in continuo per ottenere i KPI Personali e poi, dopo l’aggregazione
    per ottenere i KPI Aggregati, non consentono di individuare il soggetto cui si
    riferiscono, e pertanto sono anonime e non sono soggette alla regolamentazione
    della normativa a tutela della Privacy.
  </p>
  <p>
    4.2. Tali informazioni aziendali possono invece rientrare sotto la tutela del
    Codice della proprietà industriale come modificato dal D.Lgs. n. 63 del 2018.
  </p>
  <p>
    Abbonandoti ad HB tu acconsenti ed autorizzi anche ai sensi dell’art. 99 del
    Codice della proprietà industriale che:
  </p>
  <ul>
    <li>
      le tue Informazioni Prenotazione vengano inviate- o acquisite - dal tuo
      gestionale ad HB;
    </li>
    <li>
      le tue Informazioni Prenotazione vengano elaborate da HB per estrapolare i
      KPI Personali;
    </li>
    <li>
      le tue Informazioni Prenotazione vengano aggregate in forma anonima con
      quelle degli altri Abbonati al fine di costruire i cluster di località,
      zona, area, tipologia, regione e nazione da cui poi gli algoritmi di HB
      elaborano ed estrapolano i KPI Aggregati visibili su HB.
    </li>
    <li>
      Le tue Informazioni Prenotazione vengano elaborate da HB per estrapolare KPI
      Aggregati in un competitive set.
    </li>
  </ul>
  <p>
    <strong>
      Tieni conto che senza queste autorizzazioni e consensi il servizio non può
      esserti erogato
    </strong>
    .
  </p>
  <p>4.3. Noi ci impegniamo e garantiamo:</p>
  <ul>
    <li>
      a non rendere accessibile al alcuno diverso da te le tue Informazioni
      Prenotazione che saranno custodite in sistemi informatici protetti da
      accessi illeciti o non autorizzati ed i tuoi KPI Personali. Solo tu potrai
      darci istruzioni se vorrai che le tue Informazioni Prenotazioni i tuoi KPI
      Personali vengano resi visibili o trasferiti a terzi soggetti.
    </li>
  </ul>
  <p>&nbsp;</p>
  <ul>
    <li>
      Alla cessazione del rapporto di abbonamento per qualsivoglia motivo ivi
      compresa la risoluzione del Contratto, cesserà automaticamente
      l’acquisizione delle Informazioni Prenotazione e la tua attività non sarà
      più visibile e accessibile su HB; le Informazioni Prenotazione inviateci nel
      periodo di abbonamento che resteranno confuse nelle informazioni aggregate
      storiche non potranno essere riconducibili a te, riconosciute, individuate o
      estratte.
    </li>
  </ul>
  <p>&nbsp;</p>
  <p>
    <strong>
      Art. 5. - Obbligo dell’Abbonato a non diffondere, divulgare,
      commercializzare i KPI Aggregati
    </strong>
  </p>
  <p>
    I KPI Aggregati ed i Competitive Set elaborati da HBenchmark, potranno essere
    da te utilizzati solo all’interno della tua organizzazione e con i tuoi
    partner e collaboratori all’esclusivo fine di analizzare il mercato e
    migliorare le performance e le strategie della tua attività o di programmare
    iniziative o investimenti. Con questo contratto ti impegni a non divulgarli o
    renderli accessibili a terzi estranei e/o al pubblico, e a non trasferirli o
    cederli per scopi commerciali diretti o indiretti. Qualora queste ipotesi si
    verificassero noi potremo sospendere senza preavviso i servizi, risolvere il
    Contratto e chiedere il ristoro di ogni danno.&nbsp;
  </p>
  <p>
    <strong>Art. 6. - &nbsp;Privacy e consenso</strong>
    .
  </p>
  <p>
    <strong>6.1.</strong>
    Nel corso della procedura di Adesione tu ci fornisci alcune informazioni,
    necessarie per la registrazione e l’erogazione dei Servizi, che possono
    includere informazioni e dati sensibili quali il tuo nominativo la tua data di
    nascita e/o il codice fiscale, il tuo indirizzo mail o il numero di telefono,
    l’emittente della tua carta di credito, alcuni numeri e codici.
  </p>
  <p>
    il nominativo, la localizzazione e la categoria della tua attività saranno
    inseriti nell’elenco Abbonati presente su HB e visibile agli altri Abbonati;
  </p>
  <p>&nbsp;</p>
  <p>
    Noi tratteremo i tuoi dati personali con la massima attenzione e prudenza e li
    utilizzeremo solo per fornirti i Servizi, per richiedere la tua opinione e per
    aggiornarti sulle nuove iniziative e su nuovi servizi della nostra società;
    alcuni Tuoi dati potranno essere trattati da società del nostro Gruppo Develon
    che abbiano prodotti e servizi che ritenessimo possano essere di tuo
    interesse, sempre nel rispetto della nostra
    <a href="https://www.iubenda.com/privacy-policy/7839661">Privacy Policy</a>
    .
  </p>
  <p>
    I dati della tua carta di credito invece, non transiteranno per il nostro
    sistema ma fin dall’inizio saranno trattati e conservati esclusivamente dai
    circuiti di pagamento il cui elenco troverai nel modulo di abbonamento.
  </p>
  <p>&nbsp;</p>
  <p>
    <strong>6.2. Vorrai inoltre prendere buona nota che</strong>
    :
  </p>
  <ol>
    <li>
      <strong>a) Transazioni che coinvolgono terze parti:</strong>
      potremo mettere a tua disposizione servizi, applicazioni, skills o prodotti
      forniti da terze parti Se tu accetterai questi servizi noi dovremo
      condividere i dati con queste terze parti.
    </li>
    <li>
      <strong>b) Terzi fornitori di servizi:</strong>
      ci serviamo di altre aziende e di persone fisiche affinché svolgano certe
      attività per nostro conto. Ad esempio, inviare posta cartacea ed e-mail,
      rimuovere le informazioni ricorrenti dalle liste clienti, processare
      pagamenti.
    </li>
  </ol>
  <p>
    Questi terzi fornitori di servizi hanno accesso solo ai dati personali che
    sono necessari per lo svolgimento delle loro attività, ma non potranno
    trattare i dati di cui vengono a conoscenza per scopi ulteriori. Inoltre, tali
    terzi sono tenuti a trattare i dati personali di cui vengono a conoscenza in
    conformità a questa Informativa sulla privacy e alle normative applicabili in
    materia di protezione dei dati personali. Ci avvaliamo inoltre di fornitori di
    servizi tecnici che potrebbero gestire l'infrastruttura tecnica necessaria per
    fornire il Servizio, in particolare di fornitori che ospitano, memorizzano,
    gestiscono e mantengono l'applicazione HB, il suo contenuto e i dati che
    elaboriamo.&nbsp;
  </p>
  <ol>
    <li>
      <strong>c) Trasferimenti di azienda:</strong>
      poiché la nostra attività è in continuo sviluppo potremmo vendere o
      acquistare altre aziende o servizi. In genere, in tali operazioni, i dati
      relativi alla clientela rientrano nel patrimonio aziendale che viene
      trasferito, ma rimangono soggetti al contenuto della precedente Informativa
      sulla privacy eventualmente applicabile (a meno che, naturalmente, il
      cliente non acconsenta diversamente). Qualora HBenchmark S.r.l. o,
      sostanzialmente, il suo patrimonio aziendale venissero acquisiti da terzi, i
      dati personali relativi alla clientela rientrerebbero naturalmente tra i
      beni trasferiti.
    </li>
    <li>
      <strong>d) Tutela di HBenchmark:</strong>
      comunichiamo dati relativi all'account e altri dati personali quando
      riteniamo che tale comunicazione sia conforme ad obblighi di legge; per far
      rispettare o applicare i nostri Termini e Condizioni d’uso e altri accordi;
      o per tutelare i diritti, il patrimonio nonché la sicurezza di HBenchmark
      srl, dei nostri utenti o di altri soggetti.
    </li>
  </ol>
  <p>&nbsp;</p>
  <p>
    <strong>6.3. Consenso</strong>
  </p>
  <p>
    Completando la procedura di abbonamento ti chiederemo di esprimere il tuo
    consenso all’uso dei tuoi dati come descritto nei paragrafi che precedono, con
    le tutele di cui alla nostra Privacy Policy ed alla legislazione vigente.
    Senza tale consenso non potremo erogarti il servizio.
  </p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>
    <strong>Art. 7 - Conclusione, Durata e Rinnovo</strong>
  </p>
  <p>
    Il Contratto si intende concluso ed efficace tra HBenchmark e te nel momento
    in cui avrai completato con successo la procedura di abbonamento ed avrai
    effettuato il pagamento del corrispettivo. Salvo diversi accordi individuali
    l’abbonamento ad HB avrà durata di un anno a far data dal pagamento del
    corrispettivo e si rinnova automaticamente in mancanza di disdetta da inviarsi
    anche via mail almeno 1 giorno prima della scadenza. In mancanza di tua
    disdetta tu accetti ed autorizzi che il Corrispettivo per il rinnovo annuale
    ti venga automaticamente addebitato.
  </p>
  <p>&nbsp;</p>
  <p>
    <strong>Art. 8 - Corrispettivo</strong>
  </p>
  <p>
    8.1. Il corrispettivo per l’abbonamento annuale sarà quello di volta in volta
    pubblicato sul sito al momento dell’Abbonamento oltre IVA di legge e dovrà
    essere corrisposto salvo sconti e condizioni particolari pubblicizzate e
    proposte sul nostro sito. HB si riserva di modificare di il corrispettivo
    dandone comunicazione sul sito.
  </p>
  <p>
    8.2. Per gli utenti che hanno ricevuto una offerta prepagata o scontata
    fornita da parte di un terzo, ente o associazione, &nbsp;accompagnata da un
    Codice, possono applicarsi termini e condizioni separati che saranno
    comunicati insieme al Codice e che si intenderanno accettati dall’Abbonato con
    l’accettazione dell’Offerta.
  </p>
  <p>
    8.3. Nel caso in cui, per motivi tecnici, non sia possibile completare il
    procedimento di abbonamento ti restituiremo immediatamente il corrispettivo
    che avessi già provveduto a pagare.
  </p>
  <p>&nbsp;</p>
  <p>
    <strong>Art. 9- Limitazioni di Responsabilità</strong>
  </p>
  <p>&nbsp;</p>
  <p>
    HB fornisce anche elaborazioni predittive realizzate mediante l’utilizzo di
    algoritmi.
  </p>
  <p>
    Per quanto tali algoritmi siano stati e siano costantemente monitorati ciò non
    elimina, né lo potrebbe, la naturale incertezza delle predizioni sia con
    riferimento a fattori di cui non si sia tenuto conto sia con riferimento
    all’insorgere di fattori imprevisti. HBenchmark non garantisce alcun tasso di
    avveramento delle analisi predittive e non è né sarà responsabile per l’esito
    inatteso di qualsivoglia decisione o scelta tu abbia compiuto basandosi su
    tali analisi predittive.
  </p>
  <p>&nbsp;</p>
  <p>
    <strong>Art. 10 - Modifiche al Presente Contratto</strong>
  </p>
  <p>
    <strong>&nbsp;</strong>
  </p>
  <p>
    HBenchmark si riserva la facoltà di proporre modifiche al presente Contratto
    di Abbonamento, modifiche che avranno effetto solo dopo che tu le avrai
    accettate o via mail o direttamente sulla piattaforma. In caso di rinnovo
    dell’Abbonamento successivo alla comunicazione delle modifiche, queste si
    intenderanno automaticamente accettate.
  </p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>
    <strong>
      Art. 11 - Cessazione o Risoluzione e sopravvivenza degli obblighi di
      riservatezza
    </strong>
  </p>
  <p>
    In caso di cessazione e/o risoluzione del Presente Contratto, fermo ogni
    rimedio previsto dalla legge, le clausole di cui all’art 5 manterranno
    efficacia per un periodo di 5 anni.
  </p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>
    <strong>Art. 12 - Legge applicabile</strong>
  </p>
  <p>&nbsp;</p>
  <p>
    Il presente Contratto di Abbonamento, composto dal modulo di Adesione, dai
    Termini e Condizioni, dalla privacy Policy e dalla 
    <a href="https://preventivi.hbenchmark.it/{tipo_struttura == "hotel" ? "brochure-hotel_IT.pdf" : "flyer-camping_IT.pdf"}" target="_blank">
      Brochure
    </a> soggetto alla
    Legge Italiana. Ogni controversia che non sia possibile risolvere
    amichevolmente sarà oggetto, prima della instaurazione di una causa avanti il
    Giudice competente, di un tentativo di mediazione ai sensi del D.Lgs 28 del
    2010 e successive modifiche ed integrazioni.
  </p>
  <p>&nbsp;</p>
  <p>
    <strong>Art. 13 - Contatti</strong>
  </p>
  <p>
    Per ogni comunicazione, informazione o richiesta, l’Abbonato potrà contattare
    HBenchmark ai seguenti recapiti:
  </p>
  <ul>
    <li>
      <a href="mailto:supporto@hbenchmark.it">supporto@hbenchmark.it</a>
    </li>
    <li>+39 0444 276202</li>
  </ul>
</template>

<script>
  import BarraMessaggio from "@/base/componenti/BarraMessaggio.svelte"
import { each } from "svelte/internal"

  export let sottoscrivente = null
  export let tipo_struttura = "hotel"
</script>