<template>
  <RottaNuovoAbbonamento titolo={t.titolo[$l]}>
    <div class="passo-progresso-abbonamento pagamento">
      <TitoloSecondario>{t.titolo[$l]}</TitoloSecondario>

      <div class="riepilogo-abbonamento">
        <div class="voce-riepilogo-abbonamento">
          <span>{t.struttura[$l]}</span>
          <b>{$abbonamento.struttura.nome}</b>
        </div>
        <div class="voce-riepilogo-abbonamento">
          <span>{t.selezionato[$l]}</span>
          <b class="maiuscolo">{$abbonamento.nome_piano}</b>
        </div>
        <div class="voce-riepilogo-abbonamento">
          <span>{t.canone_annuale[$l]}</span>
          {#if $abbonamento.piano.in_prova}
            <b class="barrato">{$abbonamento.piano.prezzo}</b>&nbsp;{@html $valuta_per_dominio}
          {:else if $abbonamento.piano.scontato}
            {#if $abbonamento.piano.mostra_scontistica}
              <b class="barrato">{$abbonamento.piano.prezzo}</b> &nbsp;
            {/if}
            <b>{$abbonamento.piano.prezzo_scontato}</b>&nbsp;{@html $valuta_per_dominio}
          {:else}
            <b>{$abbonamento.piano.prezzo}</b>&nbsp;{@html $valuta_per_dominio}
          {/if}
        </div>
        {#if $abbonamento.piano.in_prova}
          <div class="voce-riepilogo-abbonamento">
            <span>{t.periodo_di_prova[$l]}</span>
            <b>{$abbonamento.piano.termine_prova}</b>
          </div>
        {/if}
        <div class="voce-riepilogo-abbonamento bordo-superiore">
          <span>{t.costo_integrazione[$l]}</span>
          <b>{$abbonamento.piano.costo_integrazione}</b>&nbsp;{@html $valuta_per_dominio}
        </div>
        <div class="totale-riepilogo-abbonamento">
          <span>{t.totale_senza_iva[$l]}</span>
          <b>{$abbonamento.piano.prezzo_finale}</b>&nbsp;{@html $valuta_per_dominio}
        </div>
        <div class="voce-riepilogo-abbonamento">
          <span>{t.iva[$l]}</span>
          <b>22</b> %
        </div>
        <div class="totale-riepilogo-abbonamento">
          <span>{t.totale[$l]}</span>
          <b>{$abbonamento.piano.prezzo_finale * PERCENTUALE_IVA}</b>&nbsp;{@html $valuta_per_dominio}
        </div>
      </div>

      {#if $abbonamento.flusso_personalizzato == "confcommercio-verona" || $abbonamento.flusso_personalizzato == "veneto"}
        {#if $abbonamento.flusso_personalizzato == "confcommercio-verona"}
          <p>
            La competenza di validità dell'abbonamento sarà dal <b>1 febbraio 2021</b> al <b>31 gennaio 2022</b> <br><br>

            Per completare la registrazione effettuare pagamento a mezzo di bonifico bancario con la seguente causale: <br><br>
            <b><em>DOTAZIONI - Spese per l'acquisto del software HBenchmark - COD. PROG. 4057-0003-866-2020 - ID.10319422</em></b> <br><br>

            Sul conto corrente intestato ad <b>HBenchmark Srl</b>: <br>
            <b>BCC ,Banca Delle Terre Venete Credito Cooperativo</b> <br>
            IBAN: <b>IT21 H083 9960 1000 0000 0120 473</b>
          </p>
        {/if}

        <MetodoDiPagamento 
            tipo="bonifico"
            bind:in_invio={in_invio}/>
      {:else if $abbonamento.piano.prezzo_finale > 0}
        <div class="metodo-pagamento">
          <TitoloSecondario>{t.scegli_metodo_di_pagamento[$l]}</TitoloSecondario>
  
          {#if ambiente_test}
            <p>
              In questa pagina di test il pagamento funziona solo con <b>carta di credito</b>
              utilizzando il numero di carta <b>4242 4242 4242 4242</b>
            </p>
          {/if}

          <Combo 
              nome="tipo_metodo_di_pagamento"
              valore="carta_di_credito"
              etichetta="{t.carta_di_credito[$l]}"
              selezionato={$abbonamento.metodo_di_pagamento == "carta_di_credito"}
              on:change={evento => $abbonamento.metodo_di_pagamento = evento.target.value}>
            <div class="loghi">
              <img alt="" src={window.risolviImg(logo_mastercard)}/>
              <img alt="" src={window.risolviImg(logo_visa)}/>
            </div>
          </Combo>
          <Combo 
              nome="tipo_metodo_di_pagamento"
              valore="addebito_sepa"
              etichetta="{t.bonifico_sepa[$l]}"
              selezionato={$abbonamento.metodo_di_pagamento == "addebito_sepa"}
              on:change={evento => $abbonamento.metodo_di_pagamento = evento.target.value}>
            <div class="loghi">
              <img alt="" src={window.risolviImg(logo_sepa)}/>
            </div>
          </Combo>
  
          {#if $abbonamento.metodo_di_pagamento == "addebito_sepa"}
            <p>{@html t.avviso_ritardo_pagamenti_sepa[$l]}</p>
          {/if}
        </div>

        <MetodoDiPagamento 
            tipo="carta_di_credito"
            bind:in_invio={in_invio}
            nascosto={$abbonamento.metodo_di_pagamento != "carta_di_credito"}/>
        <MetodoDiPagamento 
            tipo="addebito_sepa"
            bind:in_invio={in_invio}
            nascosto={$abbonamento.metodo_di_pagamento != "addebito_sepa"}/>
      {:else}
        <MetodoDiPagamento 
            tipo={$abbonamento.metodo_di_pagamento}
            bind:in_invio={in_invio}/>
      {/if}

      {#if in_invio}
        <div class="caricatore">
          <Caricatore/>
        </div>
      {/if}
    </div>
  </RottaNuovoAbbonamento>
</template>

<style>
  :global(div.passo-progresso-abbonamento.pagamento div.titolo-secondario:first-child) {
    margin-bottom: 20px;
  }
  :global(div.passo-progresso-abbonamento.pagamento div.metodo-pagamento) {
    margin: 10px 0 20px;
  }
  :global(div.passo-progresso-abbonamento.pagamento div.metodo-pagamento div.inserimento.combo div.loghi) {
    margin-left:  auto;
    display:      flex;
  }
  :global(div.passo-progresso-abbonamento.pagamento div.metodo-pagamento div.inserimento.combo div.loghi img) {
    width: 50px;
  }
  :global(div.riepilogo-abbonamento) {
    display:        flex;
    flex-direction: column;
  }
  :global(div.riepilogo-abbonamento div.voce-riepilogo-abbonamento),
  :global(div.riepilogo-abbonamento div.totale-riepilogo-abbonamento) {
    display:      flex;
    margin:       0 0 15px;
    padding-top:  5px 0;
    color:        var(--colore-testo-leggero);
  }
  
  :global(div.riepilogo-abbonamento div.voce-riepilogo-abbonamento > span),
  :global(div.riepilogo-abbonamento div.totale-riepilogo-abbonamento > span) {
    flex-grow: 1;
  }
  :global(div.riepilogo-abbonamento div.voce-riepilogo-abbonamento > b),
  :global(div.riepilogo-abbonamento div.totale-riepilogo-abbonamento > b) {
    font-size:  16px;
  }
  :global(div.riepilogo-abbonamento div.voce-riepilogo-abbonamento > b.maiuscolo),
  :global(div.riepilogo-abbonamento div.totale-riepilogo-abbonamento > b.maiuscolo) {
    text-transform:   uppercase;
  }
  :global(div.riepilogo-abbonamento div.voce-riepilogo-abbonamento > b.barrato),
  :global(div.riepilogo-abbonamento div.totale-riepilogo-abbonamento > b.barrato) {
    text-decoration:  line-through;
  }  
  :global(div.riepilogo-abbonamento div.totale-riepilogo-abbonamento),
  :global(div.riepilogo-abbonamento div.voce-riepilogo-abbonamento.bordo-superiore) {
    padding-top:  15px;
    border-top:   1px solid var(--colore-bordo);
  }
</style>

<script>
  import RottaNuovoAbbonamento from "@/portale/componenti/abbonamento/RottaNuovoAbbonamento.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Combo from "@/base/componenti/Combo.svelte"
  import MetodoDiPagamento from "@/portale/componenti/abbonamento/MetodoDiPagamento.svelte"
  import TitoloSecondario from "@/base/componenti/TitoloSecondario.svelte"
  import logo_mastercard from "@/base/immagini/mastercard.svg"
  import logo_visa from "@/base/immagini/visa.svg"
  import logo_sepa from "@/base/immagini/sepa.svg"
  import { abbonamento } from "@/portale/sorgenti/abbonamento"
  import { dominio } from "@/portale/sorgenti/dominio"
  import { valuta_per_dominio } from "@/portale/sorgenti/valuta_per_dominio"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  const t = avvia_localizzazione(dizionario_pagamento)
  const PERCENTUALE_IVA = 1.22

  let in_invio = false
  let ambiente_test = retro.indirizzo == "https://ir.lab.hbenchmark.it"

  // Imposta il metodo di pagamento predefinito.
  switch ($abbonamento.flusso_personalizzato) {
    case "padova":
    case "livigno":
    case "trentino":
    case "bergamo":
      $abbonamento.metodo_di_pagamento = "contratto_destinazione"
      break
    default:
      if ($abbonamento.piano.prezzo_finale > 0)
        $abbonamento.metodo_di_pagamento = "carta_di_credito"
      else
        $abbonamento.metodo_di_pagamento = "buono_sconto"
  }
</script>

<script context="module">
  export const dizionario_pagamento = {
    titolo: {
      it: `Riepilogo`,
      en: `Summary`,
      de: `Zusammenfassung`
    },
    struttura: {
      it: `Nome Struttura`,
      en: `Facility name`,
      de: `Name der Einrichtung`
    },
    carta_di_credito: {
      it: `Carta di Credito`,
      en: `Credit card`,
      de: `Kreditkarte`
    },
    bonifico_sepa: {
      it: `Bonifico SEPA`,
      en: `SEPA transfer`,
      de: `SEPA-Überweisung`
    },
    selezionato: {
      it: `Piano Selezionato`,
      en: `Selected Plan`,
      de: `Ausgewählter Tarif`
    },
    canone_annuale: {
      it: `Canone Annuale`,
      en: `Yearly Fee`,
      de: `Jahresgebühr`
    },
    periodo_di_prova: {
      it: `In prova fino al`,
      en: `In try & buy until`,
      de: `In Testphase bis`
    },
    totale_senza_iva: {
      it: `Totale (senza IVA)`,
      en: `Total (without VAT)`,
      de: `Insgesamt (ohne Mehrwertsteuer)`
    },
    iva: {
      it: `Imponibile IVA`,
      en: `VAT Tax`,
      de: `Mehrwertsteuer`
    },
    costo_integrazione: {
      it: `Costo Integrazione (una tantum)`,
      en: `Integration cost (one-time only)`,
      de: `Integrationskosten (nur einmalig)`
    },
    avviso_ritardo_pagamenti_sepa: {
      it: `<b>N.B.</b>: questo tipo di pagamento può richiedere fino a 14 giorni per essere completato. Se hai urgenza di ricevere la fattura rapidamente, ti consigliamo di utilizzare la carta di credito.`,
      en: `<b>Note</b>: this type of payment can take up to 14 days to process. If you need the invoice immediately, we suggest that you use a credit card.`,
      de: `<b>Hinweis</b>: Die Bearbeitung dieser Zahlungsart kann bis zu 14 Tage dauern. Wenn Sie die Rechnung sofort benötigen, empfehlen wir Ihnen, eine Kreditkarte zu verwenden.`
    },
    scegli_metodo_di_pagamento: {
      it: `Scegli metodo di pagamento`,
      en: `Choose payment method`,
      de: `Zahlungsart wählen`
    },
    numero_carta: {
      it: `Numero carta`,
      en: `Card number`,
      de: `Kreditkartennummer`
    },
    scadenza_carta: {
      it: `Scadenza`,
      en: `Expiry`,
      de: `Ablaufdatum`
    },
    cvc_carta: {
      it: `CVC`,
      en: `CVC`,
      de: `CVC`
    }
  }
</script>
