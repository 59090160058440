<template>
</template>

<script>
  import { periodi } from "@/base/sorgenti/hbenchmark/periodi"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { createEventDispatcher } from "svelte"

  export let grafico = null
  export let pronto = false
  export let opzioni_highcharts = {}
  export let modalità_scura = false

  const t = avvia_localizzazione()
  const propaga = createEventDispatcher()

  let colore_etichette_ascisse = "#666666"

  $: periodo_corrente = grafico != null ?
    grafico.statistica.cornice_temporale.periodi.find(periodo => periodo.nome == "corrente") :
    null
  $: if (modalità_scura) {
    colore_etichette_ascisse = "#bbbbbb"
  } else {
    colore_etichette_ascisse = "#666666"
  }

  ////
  // Genera le opzioni grafico highcharts.
  function genera_opzioni_highcharts() {
    if (grafico == null) return

    let opzioni_highcharts_torta = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: { text: null }
    }

    // Sovraimpressione.
    opzioni_highcharts_torta.tooltip = {
      useHTML: true,
      backgroundColor: "#fff",
      formatter: formattatore_arrotondatore
    }

    opzioni_highcharts_torta.plotOptions = {
        pie: {
            allowPointSelect: true,
            cursor: 'default',
            dataLabels: {
              enabled: grafico.statistica.mostra_etichette_valori,
              formatter: formattatore_arrotondatore,
              style: {
                color: colore_etichette_ascisse
              }
            }
        }
    },

    // Serie.
    opzioni_highcharts_torta.series = genera_serie()

    opzioni_highcharts = opzioni_highcharts_torta
    pronto = true
    propaga("grafico-pronto")
  }

  ////
  // Generatore serie.
  function genera_serie() {
    let serie = grafico.serie
    let punti = []

    serie.forEach((serie_singola, indice) => {
      serie_singola.punti.forEach(punto => {
        let valore = punto[1]
        
        if (indice == 0) {
          punti = [
            {
              name: `${t[serie_singola.segmento][$l]}`,
              y: valore,
              color: serie_singola.colore,
              unità: serie_singola.unità,
              valuta: serie_singola.valuta,
              gruppo: grafico.statistica.gruppi[serie_singola.indice_gruppo],
              periodo: grafico.statistica.cornice_temporale.periodi[serie_singola.indice_periodo],
              segmento: serie_singola.segmento,
              indice: serie_singola.indice,
              indicatore: grafico.indicatore
            }
          ]
        } else {
          punti = [
            ...punti,
            {
              name: t[serie_singola.segmento] != null ? `${t[serie_singola.segmento][$l]}` : serie_singola.segmento,
              y: valore,
              color: serie_singola.colore,
              unità: serie_singola.unità,
              valuta: serie_singola.valuta,
              gruppo: grafico.statistica.gruppi[serie_singola.indice_gruppo],
              periodo: grafico.statistica.cornice_temporale.periodi[serie_singola.indice_periodo],
              segmento: serie_singola.segmento,
              indice: serie_singola.indice,
              indicatore: grafico.indicatore
            }
          ]
        }
      })
    })

    return [{
      name: "segmenti",
      colorByPoint: true,
      data: punti,
      unità: serie[0].unità,
      valuta: serie[0].valuta,
    }]
  }

  ////
  // Rimuove la virgola dall'etichetta
  function formattatore_arrotondatore() {
    let segmento = this.point.options.segmento
    let nome_gruppo = this.point.options.gruppo.nome
    let categorie = this.point.options.gruppo.categorie
    let periodo = this.point.options.periodo.nome
    let indicatore = this.point.options.indicatore

    if (categorie != null && categorie.length > 0) nome_gruppo = `${nome_gruppo} - ${categorie.join(" - ")}`

    let valore
    if (this.y < 1 && this.y > 0) {
      valore = 1
      valore = valore.format({
        unit: this.series.userOptions.unità,
        currency: this.series.userOptions.valuta  
      })
    }else {
      valore = Math.round(this.y).format({
        unit: this.series.userOptions.unità,
        currency: this.series.userOptions.valuta
      })
    }

    let nome_segmento
    if (segmento == "altri" && indicatore.dettagliato_per == "canale") nome_segmento = t.altri_canali[$l]
    else nome_segmento = t[segmento] != null ? t[segmento][$l] : segmento

    if (grafico.statistica.gruppi.length > 1 && grafico.statistica.cornice_temporale.periodi.length > 1) {
      return `${nome_gruppo} <br/> ${periodi[periodo][$l]} <br/> ${nome_segmento}: <b>${valore}</b>`
    }
    if (grafico.statistica.cornice_temporale.periodi.length > 1) {
      return `${periodi[periodo][$l]} <br/> ${nome_segmento}: <b>${valore}</b>`
    }
    if (grafico.statistica.gruppi.length > 1) {
      return `${nome_gruppo} <br/> ${nome_segmento}: <b>${valore}</b>`
    }
    return `${nome_segmento}: <b>${valore}</b>`
  }

  // Ad ogni aggiornamento, genera le opzioni grafico linea.
  $: if (grafico != null && grafico.serie[0].segmento != null) genera_opzioni_highcharts()
</script>
