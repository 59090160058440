<template>
  <div class="comparatore">
    <div class="contenitore-comparatore">
      <div class="aree">
        {#each aree as area, indice}
          <div
              class="area"
              class:selezionata={indice_area_selezionata == indice}
              on:click={evento => indice_area_selezionata = indice}>
            <span class="nome">
              {t.aree[area][$l]}
            </span>
          </div>
        {/each}
      </div>

      <div class="comparazioni">
        {#each aree as area, indice}
          <div
              class="comparazioni-area"
              class:selezionate={indice_area_selezionata == indice}>
            {#if visualizza_come == "selezione"}
              <Selezione
                  piana={true}
                  ricercabile={true}
                  opzioni={opzioni_comparazioni_attivabili[area]}
                  on:change={aggiorna_gruppo_selezionato}/>
            {:else if visualizza_come == "elenco"}
              {#each comparazioni_attivabili[area] as comparazione, indice}
                <div class="comparazione">
                  <div class="nome">
                    {#if area == "geografiche"}
                      {comparazione.nome} ({t.gruppi[comparazione.collezione][$l]})
                    {:else}
                      {comparazione.nome}
                    {/if}
                  </div>
                  <Bottone
                      piccolo={true}
                      variante="primario-invertito"
                      on:click={evento => aggiungi_comparazione(area, comparazione)}>
                    {t.aggiungi[$l]}
                  </Bottone>
                </div>
              {/each}
            {/if}

            {#if area == "concorrenti" && comparazioni_attivabili[area].length < 3}
              <div class="comparazione">
                <div class="nome">
                  <Bottone
                      piccolo={true}
                      variante="primario-invertito"
                      on:click={vai_a_concorrenti}>
                    <Icona tipo="aggiungi"/>
                    {t.aggiungi_concorrente[$l]}
                  </Bottone>
                </div>
              </div>
            {/if}
          </div>
        {/each}
      </div>
    </div>

    {#if visualizza_come == "selezione"}
      <div class="azioni">
        <Bottone
            variante="primario"
            on:click={conferma_selezione}>
          {t.conferma[$l]}
        </Bottone>
      </div>
    {/if}
  </div>
</template>

<style>
  :global(div.comparazioni-area div.selezione) {
    margin-bottom:  0;
  }
</style>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Selezione from "@/base/componenti/Selezione.svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { createEventDispatcher } from "svelte"

  export let visualizza_come = "elenco"
  export let gruppi_comparabili = []
  export let aree = [ "geografiche", "concorrenti" ]
  export let collezioni_gruppi_geografiche = [ "territori", "zone", "località" ]
  export let collezioni_gruppi_concorrenti = [ "concorrenti" ]

  const propaga = createEventDispatcher()
  const t = avvia_localizzazione(dizionario_comparatore)
  
  let indice_area_selezionata = 0
  let gruppo_selezionato = null

  // Comparazioni.
  $: comparazioni_attivabili = {
    geografiche: gruppi_comparabili.filter(gruppo => collezioni_gruppi_geografiche.include(gruppo.collezione)),
    concorrenti: gruppi_comparabili.filter(gruppo => collezioni_gruppi_concorrenti.include(gruppo.collezione)) }
  $: opzioni_comparazioni_attivabili = {
    geografiche: comparazioni_attivabili.geografiche.map(gruppo => {
      return { valore: gruppo._id, testo: gruppo.nome, ...gruppo }
    }),
    concorrenti: comparazioni_attivabili.concorrenti.map(gruppo => {
      return { valore: gruppo._id, testo: gruppo.nome, ...gruppo }
    })
  }

  ////
  // Aggiunge la comparazione.
  function aggiungi_comparazione(area, comparazione) {
    propaga("click", { area, comparazione })
  }

  ////
  // Aggiorna il gruppo selezionato.
  function aggiorna_gruppo_selezionato(evento) {
    gruppo_selezionato = evento.detail.opzione_selezionata
  }

  ////
  // Conferma la selezione.
  function conferma_selezione() {
    let area_selezionata = aree[indice_area_selezionata]
    aggiungi_comparazione(area_selezionata, gruppo_selezionato)
  }

  ////
  // Naviga alla pagina concorrenti.
  function vai_a_concorrenti() {
    navigatore.verso("/impostazioni/concorrenti")
  }
</script>

<script context="module">
  export const dizionario_comparatore = {
    gruppi: {
      strutture: {
        it: `struttura`,
        en: `property`,
        de: `Betrieb`
      },
      territori: {
        it: `territorio`,
        en: `territory`,
        de: `Gebiet`
      },
      zone: {
        it: `zona`,
        en: `zone`,
        de: `Region`
      },
      località: {
        it: `località`,
        en: `locality`,
        de: `Ort`
      },
      concorrenti: {
        it: `competitor`,
        en: `competitors`,
        de: `Wettbewerber`
      },
      gruppi_personalizzati: {
        it: `personalizzato`,
        en: `custom`,
        de: `benutzerdefiniert`
      }
    },
    aree: {
      geografiche: {
        it: `Geografiche`,
        en: `Geographics`,
        de: `Geographie`
      },
      concorrenti: {
        it: `Competitor`,
        en: `Competitors`,
        de: `Wettbewerber`
      }
    },
    aggiungi: {
      it: `Aggiungi`,
      en: `Add`,
      de: `Hinzufügen`
    },
    aggiungi_concorrente: {
      it: `Crea Competitor`,
      en: `Create Competitor`,
      de: `Wettbewerber erstellen`
    }
  }
</script>