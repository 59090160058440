<template>
  <div class="etichetta-scheda">
    <label for="">
      {t.etichetta_scorpori[$l]}
      <span>
        <a
            href="{"#"}"
            on:click|preventDefault={evento => modale_info_scorporo = true}>
          {t.domanda_info_scorporo[$l]}
        </a>
      </span>
    </label>
    <div class="azioni">
      {#if in_modifica.scorpori}
        <Bottone
            piccolo={true}
            variante="secondario-invertito"
            on:click={evento => propaga("annulla", "scorpori")}>
          {t.annulla[$l]}
        </Bottone>
        <Bottone
            piccolo={true}
            variante="primario-invertito"
            on:click={evento => propaga("salva", "scorpori")}>
          {t.salva[$l]}
        </Bottone>
      {:else}
        <!-- <Bottone
            piccolo={true}
            variante="primario-invertito"
            on:click={evento => propaga("apri", "scorpori")}>
          <Icona tipo="modifica"/>
        </Bottone> -->
      {/if}
    </div>
  </div>
  <Scheda modifica_in_corso={in_modifica.scorpori}>
    <!-- COLAZIONE -->
    <div class="campo">
      <div class="nome">{t.colazione[$l]}</div>
      {#if in_modifica.scorpori}
        <div class="valore-inserimento">
          <Selezione
              opzioni={opzioni_attivazione_scorporo}
              let:opzione={opzione}
              let:opzione_selezionata={opzione_selezionata}
              bind:valore={inserimenti.scorpori.colazione.attivo}>
            <div slot="valore">
              {#if opzione_selezionata.valore == true}
                {t.opzioni_scorpori.attivo[$l]}
              {:else}
                {t.opzioni_scorpori.disattivo[$l]}
              {/if}
            </div>
            <div slot="opzione">
              {#if opzione.valore == true}
                {t.opzioni_scorpori.attivo[$l]}
              {:else}
                {t.opzioni_scorpori.disattivo[$l]}
              {/if}
            </div>
          </Selezione>
        </div>
        <div class="valore-inserimento">
          <Inserimento bind:valore={inserimenti.scorpori.colazione.valore}/>
        </div>
        <div class="valore-inserimento">
          <Selezione
              opzioni={opzioni_metodo_scorporo}
              bind:valore={inserimenti.scorpori.colazione.metodo}/>
        </div>
      {:else}
        <div class="valore">
          {#if struttura.scorpori.colazione.attivo == true}
            <Fanalino stato="attivo"/>
            <span>{t.opzioni_scorpori.attivo[$l]}</span>
          {:else}
            <Fanalino stato="disattivo"/>
            <span>{t.opzioni_scorpori.disattivo[$l]}</span>
          {/if}
        </div>
        <div class="valore">{struttura.scorpori.colazione.valore || "-"} {struttura.territorio.valuta || "EUR"}</div>
        <div class="valore">
          {#if struttura.scorpori.colazione.metodo != null}
            {t.metodi_scorpori[struttura.scorpori.colazione.metodo][$l]}
          {:else}
            -
          {/if}
        </div>
      {/if}
    </div>
    <!-- /COLAZIONE -->

    <!-- MEZZA PENSIONE -->
    <div class="campo">
      <div class="nome">{t.mezza_pensione[$l]}</div>
      {#if in_modifica.scorpori}
        <div class="valore-inserimento">
          <Selezione
              opzioni={opzioni_attivazione_scorporo}
              let:opzione={opzione}
              let:opzione_selezionata={opzione_selezionata}
              bind:valore={inserimenti.scorpori.mezza_pensione.attivo}>
            <div slot="valore">
              {#if opzione_selezionata.valore == true}
                {t.opzioni_scorpori.attivo[$l]}
              {:else}
                {t.opzioni_scorpori.disattivo[$l]}
              {/if}
            </div>
            <div slot="opzione">
              {#if opzione.valore == true}
                {t.opzioni_scorpori.attivo[$l]}
              {:else}
                {t.opzioni_scorpori.disattivo[$l]}
              {/if}
            </div>
          </Selezione>
        </div>
        <div class="valore-inserimento">
          <Inserimento bind:valore={inserimenti.scorpori.mezza_pensione.valore}/>
        </div>
        <div class="valore-inserimento">
          <Selezione
              opzioni={opzioni_metodo_scorporo}
              bind:valore={inserimenti.scorpori.mezza_pensione.metodo}/>
        </div>
      {:else}
        <div class="valore">
          {#if struttura.scorpori.mezza_pensione.attivo == true}
            <Fanalino stato="attivo"/>
            <span>{t.opzioni_scorpori.attivo[$l]}</span>
          {:else}
            <Fanalino stato="disattivo"/>
            <span>{t.opzioni_scorpori.disattivo[$l]}</span>
          {/if}
        </div>
        <div class="valore">{struttura.scorpori.mezza_pensione.valore || "-"} {struttura.territorio.valuta || "EUR"}</div>
        <div class="valore">
          {#if struttura.scorpori.mezza_pensione.metodo != null}
            {t.metodi_scorpori[struttura.scorpori.mezza_pensione.metodo][$l]}
          {:else}
            -
          {/if}
        </div>
      {/if}
    </div>
    <!-- /MEZZA PENSIONE -->

    <!-- PENSIONE COMPLETA -->
    <div class="campo">
      <div class="nome">{t.pensione_completa[$l]}</div>
      {#if in_modifica.scorpori}
        <div class="valore-inserimento">
          <Selezione
              opzioni={opzioni_attivazione_scorporo}
              let:opzione={opzione}
              let:opzione_selezionata={opzione_selezionata}
              bind:valore={inserimenti.scorpori.pensione_completa.attivo}>
            <div slot="valore">
              {#if opzione_selezionata.valore == true}
                {t.opzioni_scorpori.attivo[$l]}
              {:else}
                {t.opzioni_scorpori.disattivo[$l]}
              {/if}
            </div>
            <div slot="opzione">
              {#if opzione.valore == true}
                {t.opzioni_scorpori.attivo[$l]}
              {:else}
                {t.opzioni_scorpori.disattivo[$l]}
              {/if}
            </div>
          </Selezione>
        </div>
        <div class="valore-inserimento">
          <Inserimento bind:valore={inserimenti.scorpori.pensione_completa.valore}/>
        </div>
        <div class="valore-inserimento">
          <Selezione
              opzioni={opzioni_metodo_scorporo}
              bind:valore={inserimenti.scorpori.pensione_completa.metodo}/>
        </div>
      {:else}
        <div class="valore">
          {#if struttura.scorpori.pensione_completa.attivo == true}
            <Fanalino stato="attivo"/>
            <span>{t.opzioni_scorpori.attivo[$l]}</span>
          {:else}
            <Fanalino stato="disattivo"/>
            <span>{t.opzioni_scorpori.disattivo[$l]}</span>
          {/if}
        </div>
        <div class="valore">{struttura.scorpori.pensione_completa.valore || "-"} {struttura.territorio.valuta || "EUR"}</div>
        <div class="valore">
          {#if struttura.scorpori.pensione_completa.metodo != null}
            {t.metodi_scorpori[struttura.scorpori.pensione_completa.metodo][$l]}
          {:else}
            -
          {/if}
        </div>
      {/if}
    </div>
    <!-- /PENSIONE COMPLETA -->

    <!-- TUTTO INCLUSO -->
    <div class="campo">
      <div class="nome">{t.tutto_incluso[$l]}</div>
      {#if in_modifica.scorpori}
        <div class="valore-inserimento">
          <Selezione
              opzioni={opzioni_attivazione_scorporo}
              let:opzione={opzione}
              let:opzione_selezionata={opzione_selezionata}
              bind:valore={inserimenti.scorpori.tutto_incluso.attivo}>
            <div slot="valore">
              {#if opzione_selezionata.valore == true}
                {t.opzioni_scorpori.attivo[$l]}
              {:else}
                {t.opzioni_scorpori.disattivo[$l]}
              {/if}
            </div>
            <div slot="opzione">
              {#if opzione.valore == true}
                {t.opzioni_scorpori.attivo[$l]}
              {:else}
                {t.opzioni_scorpori.disattivo[$l]}
              {/if}
            </div>
          </Selezione>
        </div>
        <div class="valore-inserimento">
          <Inserimento bind:valore={inserimenti.scorpori.tutto_incluso.valore}/>
        </div>
        <div class="valore-inserimento">
          <Selezione
              opzioni={opzioni_metodo_scorporo}
              bind:valore={inserimenti.scorpori.tutto_incluso.metodo}/>
        </div>
      {:else}
        <div class="valore">
          {#if struttura.scorpori.tutto_incluso.attivo == true}
            <Fanalino stato="attivo"/>
            <span>{t.opzioni_scorpori.attivo[$l]}</span>
          {:else}
            <Fanalino stato="disattivo"/>
            <span>{t.opzioni_scorpori.disattivo[$l]}</span>
          {/if}
        </div>
        <div class="valore">{struttura.scorpori.tutto_incluso.valore || "-"} {struttura.territorio.valuta || "EUR"}</div>
        <div class="valore">
          {#if struttura.scorpori.tutto_incluso.metodo != null}
            {t.metodi_scorpori[struttura.scorpori.tutto_incluso.metodo][$l]}
          {:else}
            -
          {/if}
        </div>
      {/if}
    </div>
    <!-- /TUTTO INCLUSO -->
  </Scheda>

  <Modale
      richiudibile={true}
      titolo={t.scorporo[$l]}
      espansa={true}
      bind:aperta={modale_info_scorporo}>
    <div class="modale-contenuto">
      {@html t.contenuto_info_scorporo[$l]}
    </div>
  </Modale>
</template>

<style>
  :global(div.etichetta-scheda label span){
    margin-left: 5px;
    font-size: 11px;
  }
</style>

<script>
  import Scheda from "@/base/componenti/Scheda.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Fanalino from "@/base/componenti/Fanalino.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import Selezione from "@/base/componenti/Selezione.svelte"
  import Modale from "@/base/componenti/Modale.svelte"
  import { createEventDispatcher } from "svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  export let struttura = {}
  export let inserimenti = {}
  export let in_modifica = {}

  const propaga = createEventDispatcher()
  const opzioni_metodo_scorporo = [ "per_persona", "percentuale", "fisso" ]
  const opzioni_attivazione_scorporo = [ true, false ]
  const t = avvia_localizzazione(dizionario_scorpori)

  let modale_info_scorporo = false
</script>

<script context="module">
  export const dizionario_scorpori = {
    etichetta_scorpori: {
      it: `Scorpori`,
      en: `Extra Values`,
      de: `Die Werte der Extras`
    },
    opzioni_scorpori: {
      attivo: {
        it: `Attivo`,
        en: `Active`,
        de: `Aktiv`
      },
      disattivo: {
        it: `Disattivo`,
        en: `Inactive`,
        de: `Inaktiv`
      }
    },
    domanda_info_scorporo: {
      it: `Cos'è uno scorporo?`,
      en: `What are the values of the main extra?`,
      de: `Was sind die Werte der wichtigsten Extras?`
    },
    contenuto_info_scorporo: {
      it: `Indica il <b>costo a persona (iva escluso) imputabile ad ogni tipologia di trattamento</b>
          ( B&B, HB -Mezza Pensione  FB -Pensione Completa). <br/>
          Questo costo a persona  viene  sottratto al prezzo di vendita della camera per il corretto calcolo
          dell ADR ( L'Average Daily Rate , il ricavo medio per camera al netto dei trattamenti e al netto dell’IVA ).`,
      en: `They express <b>the cost per person (excluding VAT) attributable to each type of treatment</b>
          ( B&B, HB - Half Board, FB -Full Board). <br/>
          This cost per person is subtracted from the selling price
          of the room for the correct calculation of the ADR ( Average Daily Rate, the average revenue per
          room net of services and net of VAT )`,
      de: `Sie geben <b>die Kosten pro Person (ohne MwSt.)</b> an, <b>die jeder Behandlungsart zuzurechnen sind</b>
          (B&B, HB -Halbpension, FB -Vollpension). <br/>
          Diese Kosten pro Person werden für die korrekte Berechnung
          der ADR vom Verkaufspreis des Zimmers abgezogen (Average Daily Rate, der durchschnittliche Zimmerumsatz
          ohne Verpflegung und ohne Mehrwertsteuer)`
    },
    metodi_scorpori: {
      per_persona: {
        it: `Per persona`,
        en: `Per person`,
        de: `pro Person`
      },
      percentuale: {
        it: `Percentuale`,
        en: `Percentage`,
        de: `Prozentsatz`
      },
      fisso: {
        it: `Fisso`,
        en: `Fixed`,
        de: `Fest`
      },
    }
  }
</script>
