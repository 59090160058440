<template>
  <Rotta>
    <Titolo titolo={t.titolo_profilo[$l]}/>
    
    <div class="rotte-profilo">
      <Collegamento nome_rotta="/profilo/anagrafica">
        {t.nome_rotta_anagrafica[$l]}
      </Collegamento>
      <Collegamento nome_rotta="/profilo/dati_fatturazione">
        {t.nome_rotta_dati_fatturazione[$l]}
      </Collegamento>
    </div>

    <div class="bottone-nuove-funzionalità">
      <Bottone
          piccolo={true}
          variante="primario"
          on:click={evento => window.open("https://preventivi.hbenchmark.it/HBenchmark_Comunicazionenuovapiattaforma.pdf")}>
        {t.scopri_novità[$l]}!
      </Bottone>
    </div>

    <ContenitoreBase>
      <div class="contenitore-profilo">
        <slot/>
      </div>
    </ContenitoreBase>
  </Rotta>
</template>

<style>
  :global(div.rotte-profilo) {
    display:        flex;
    height:         52px;
    position:       relative;
    margin:         5px 0 0;
    padding:        0;
  }
  :global(div.rotte-profilo::after) {
    content:            "";
    background-color:   var(--colore-linea);
    position:           absolute;
    width:              100%;
    height:             1px;
    bottom:             0;
    left:               0
  }
  :global(div.rotte-profilo div.collegamento-barra-laterale) {
    text-transform: uppercase;
    border-left:    0;
  }
  :global(div.rotte-profilo div.collegamento-barra-laterale a) {
    line-height:        44px;
    padding-left:       var(--padding-base-main);
    padding-right:      var(--padding-base-main);
    border-bottom:      4px solid transparent;
    border-top:         4px solid transparent;
  }
  :global(div.rotte-profilo div.collegamento-barra-laterale.rotta-corrente ) {
    border:             none;
  }
  :global(div.rotte-profilo div.collegamento-barra-laterale:not(.rotta-corrente) a) {
    font-weight: normal;
  }
  :global(div.rotte-profilo div.collegamento-barra-laterale.rotta-corrente a) {
    border-bottom-color:  var(--colore-primario)
  }
  :global(div.contenitore-impostazione > p:first-child) {
   margin-top: 0;
  }
  :global(div.bottone-nuove-funzionalità) {
   position:  fixed;
   top:       20px;
   right:     15px;
  }
</style>

<script>
  import Rotta from "@/base/componenti/Rotta.svelte"
  import Collegamento from "@/base/componenti/barra_laterale/Collegamento.svelte"
  import Titolo from "@/base/componenti/Titolo.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Icona from "@/base/componenti/Icona.svelte"
  import ContenitoreBase from "@/base/componenti/ContenitoreBase.svelte"
  import { sessione_corrente } from "@/portale/sorgenti/sessione"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  const t = avvia_localizzazione(dizionario_profilo)
</script>

<script context="module">
  export const dizionario_profilo = {
    titolo_profilo: {
      it: `Il mio Account`,
      en: `My account`,
      de: `Mein Konto`
    },
    nome_rotta_anagrafica: {
      it: `Anagrafica`,
      en: `Registry`,
      de: `Registrierung`
    },
    nome_rotta_dati_fatturazione: {
      it: `Dati di Fatturazione`,
      en: `Billing information`,
      de: `Rechnungsinformationen`
    },
    scopri_novità: {
      it: `Scopri le novità della nuova piattaforma`,
      en: `Find out what's new in the new platform`,
      de: `Finden Sie heraus, was es Neues auf der neuen Plattform gibt`
    },
  }
</script>