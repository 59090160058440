<template>
  <Messaggio>
    <div class="loghi-contenitore livigno" slot="loghi">
      <img src={window.risolviImg(logo)} alt="HBenchmark Logo White" height="52">

      <div class="contenitore-immagine">
        <img src={window.risolviImg(logo_livigno)} alt="Livigno Feel the Alps">
      </div>
    </div>
  </Messaggio>
</template>

<style>
  :global(div#accesso div.loghi-contenitore.livigno){
    width:            125%;
    display:          flex;
    justify-content:  space-between;
    align-items:      center;
  }
  :global(div#accesso div.loghi-contenitore.livigno div.contenitore-immagine){
    height: 45px;
  }
  :global(div#accesso div.loghi-contenitore.livigno div.contenitore-immagine img){
    height: 100%;
  }
</style>

<script>
  import Messaggio from "@/base/componenti/accesso/Messaggio.svelte"
  import logo from "@/base/immagini/logo-completo.svg"
  import logo_livigno from "@/base/immagini/loghi/logo-livigno-colori.svg"
</script>
