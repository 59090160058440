<template>
  <div
      class="inserimento intervallo-date"
      bind:this={elemento}>
    <Inserimento
        {etichetta}
        icona="calendario"
        {disabilitato}
        {errore}
        bind:valore={intervallo_manuale}
        on:input={converti_date_inserite_manualmente}
        on:focus={evento => calendari_aperti = true}/>
    {#if calendari_aperti}
      <div
          class="contenitore-calendari"
          class:ancoraggio-destra={ancoraggio == "destra"}
          class:ancoraggio-sinistra={ancoraggio == "sinistra"}
          transition:fade={{ duration: 300 }}>
        <div class="calendari">
          {#if mostra_intervalli}
            <IntervalliPredefiniti
                {intervalli}
                codice_selezionato={codice_intervallo}
                on:selected={seleziona_intervallo}/>
          {/if}
          <Calendario
              {minima_data_selezionabile}
              {massima_data_selezionabile}
              bind:data_selezionata={date_intervallo[0]}
              on:change={evento => controlla_intervallo("inizio")}
              date_intervallo={date_intervallo}/>
          <Calendario
              {minima_data_selezionabile}
              {massima_data_selezionabile}
              bind:data_selezionata={date_intervallo[1]}
              on:change={evento => controlla_intervallo("termine")}
              date_intervallo={date_intervallo}/>
          {#if mostra_granularità}
            <GranularitàPredefinite
                codice_selezionato={codice_granularità}
                on:selected={seleziona_granularità}/>
          {/if}
        </div>
        {#if !autochiudi}
          <div
              class="azioni"
              bind:this={elemento_azioni}>
            <Bottone
                piccolo={true}
                variante="primario-invertito"
                on:click={propaga_modifica}>
              {t.conferma[$l]}
            </Bottone>
          </div>
        {/if}
      </div>
    {/if}
  </div>

  <svelte:window on:click={determina_se_pressione_esterna}/>
</template>

<style>        
  :global(div.inserimento.intervallo-date) {
    position:   relative;
  }
  :global(div.inserimento.intervallo-date > div.contenitore-calendari) {
    display:        flex;
    flex-direction: column;
    position:       absolute;
    top:            100%;
    background:     var(--colore-bianco);
    box-shadow:     0 5px 10px 0 var(--colore-ombreggiatura);
    z-index:        10;
  }
  :global(div.inserimento.intervallo-date > div.contenitore-calendari.con-intervalli) {
    min-width:  700px;
  }
  :global(div.inserimento.intervallo-date > div.contenitore-calendari.ancoraggio-destra) {
    right:      0;
  }
  :global(div.inserimento.intervallo-date > div.contenitore-calendari.ancoraggio-sinistra) {
    left:       0;
  }
  :global(div.inserimento.intervallo-date > div.contenitore-calendari div.calendari) {
    display:    flex;
  }
  :global(div.inserimento.intervallo-date > div.contenitore-calendari div.calendario) {
    margin:     3.5px;
  }
  :global(div.inserimento.intervallo-date > div.contenitore-calendari div.azioni) {
    display:          flex;
    justify-content:  flex-end;
    margin:           7px;
  }
</style>

<script>
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import Calendario from "@/base/componenti/inserimenti/date/Calendario.svelte"
  import IntervalliPredefiniti from "./IntervalliPredefiniti.svelte"
  import GranularitàPredefinite from "./GranularitàPredefinite.svelte"
  import { fade } from "svelte/transition"
  import { createEventDispatcher } from "svelte"
  import { localizzazione as l } from "../../../sorgenti/svuby"
  import { avvia_localizzazione } from "../../../sorgenti/svuby"
  import { intervalli_predefiniti } from "../../../sorgenti/hbenchmark/periodi"

  export let autochiudi = false
  export let etichetta = null
  export let disabilitato = false
  export let ancoraggio = "destra"
  export let date_intervallo = [
    Date.today.sub(15, "days"),
    Date.today.add(15, "days") ]
  export let intervalli = intervalli_predefiniti
  export let mostra_intervalli = false
  export let mostra_granularità = false
  export let codice_intervallo = null
  export let codice_granularità = null
  export let minima_data_selezionabile = Date.today.sub(3, "years").beginning_of_year
  export let massima_data_selezionabile = Date.today.add(2, "years").beginning_of_year
  export let errore = null

  const t = avvia_localizzazione()
  const propaga = createEventDispatcher()

  let data_inizio, data_termine
  let elemento, elemento_azioni
  let calendari_aperti = false
  let intervallo_formattato = ""
  let intervallo_manuale = ""

  ////
  // Formatta l'intervallo.
  function formatta_intervallo() {
    if (codice_intervallo != null)
      intervallo_formattato = intervalli_predefiniti[codice_intervallo].nome[$l]
    else
      intervallo_formattato = [
        date_intervallo.first.format("%d/%m/%Y"),
        date_intervallo.last.format("%d/%m/%Y") ].join(" - ")

    intervallo_manuale = intervallo_formattato
  }

  ////
  // Controlla che l'intervallo sia valido.
  function controlla_intervallo(estremo) {
    codice_intervallo = null

    if (date_intervallo == null) return
    if (date_intervallo.first == null) return
    if (date_intervallo.last == null) return

    switch (estremo) {
      case "inizio":
        if (date_intervallo.first > date_intervallo.last)
          date_intervallo = [ date_intervallo.first, date_intervallo.first ]
        return
      case "termine":
        if (date_intervallo.last < date_intervallo.first)
          date_intervallo = [ date_intervallo.last, date_intervallo.last ]
        return
    }
  }

  ////
  // Converte la data inserita manualmente.
  function converti_date_inserite_manualmente() {
    let [ data_manuale_inizio, data_manuale_termine ] = intervallo_manuale.split("-")
    if (data_manuale_inizio == null || data_manuale_termine == null) return

    data_manuale_inizio = Date.convert(data_manuale_inizio, "calendario")
    data_manuale_termine = Date.convert(data_manuale_termine, "calendario")
    if (isNaN(data_manuale_inizio.to_i)) return
    if (isNaN(data_manuale_termine.to_i)) return

    if (data_manuale_inizio.outside(minima_data_selezionabile, massima_data_selezionabile)) return
    if (data_manuale_termine.outside(minima_data_selezionabile, massima_data_selezionabile)) return

    date_intervallo = [ data_manuale_inizio, data_manuale_termine ]
    formatta_intervallo()
  }

  ////
  // Seleziona la granularità.
  function seleziona_granularità(evento) {
    let granularità = evento.detail
    codice_granularità = granularità.codice
  }

  ////
  // Seleziona l'intervallo.
  function seleziona_intervallo(evento) {
    let intervallo = evento.detail
    codice_intervallo = intervallo.codice
  }

  ////
  // Propaga la modifica.
  function propaga_modifica() {
    calendari_aperti = false
    propaga("change", { date_intervallo, codice_intervallo, codice_granularità })
  }

  ////
  // Determina se è stata fatta una pressione su un'area
  // esterna alla selezione. Se è così, nascondi le opzioni.
  function determina_se_pressione_esterna(evento) {
    if (disabilitato) return
    if (elemento == null) return
    if (evento == null || evento.target == null) return
    if (elemento_azioni != null && elemento_azioni.contains(evento.target)) return

    if (!elemento.contains(evento.target)) calendari_aperti = false
  }

  // Converte automaticamente date passate come stringa.
  $: if (date_intervallo[0] != null && date_intervallo[0].constructor.name == "String") date_intervallo[0] = date_intervallo[0].to_date.utc
  $: if (date_intervallo[1] != null && date_intervallo[1].constructor.name == "String") date_intervallo[1] = date_intervallo[1].to_date.utc
  // Ricava automaticamente date per codici intervallo.
  $: if (codice_intervallo != null) date_intervallo = intervalli[codice_intervallo].date
  // Ricava automaticamente data inizio e termine dalle date intervallo.
  $: if (date_intervallo != null) data_inizio = date_intervallo.first
  $: if (date_intervallo != null) data_termine = date_intervallo.last
  // Esegue la formattazione dell'intervallo.
  $: if (data_inizio != null && data_termine != null) formatta_intervallo()
</script>
