<template>
  <div class="modulo-disponibilità">
    <Modulo 
        esteso={true}
        metodo={"PUT"}
        indirizzo={indirizzo}
        parametri={parametri}
        gestore={gestore_risposta}
        validatore={validatore_modulo}
        bind:in_salvataggio={in_salvataggio}
        bind:errori={errori}>
      <IntervalloDate
          etichetta={t.seleziona_date[$l]}
          date_intervallo={[ parametri.disponibilità.inizio, parametri.disponibilità.termine ]}
          on:change={aggiorna_cornice_temporale}/>
      
      <Inserimento
          errore={errori.nome}
          etichetta={t.camere_disponibili[$l]}
          tipo={"numero"}
          bind:valore={unità_disponibili}/>

      <!-- <Inserimento
          errore={errori.nome}
          etichetta={t.letti_disponibili[$l]}
          tipo={"numero"}
          bind:valore={letti_disponibili}/> -->
      <div class="azioni">
        <Bottone
            tipo="invio"
            disabilitato={in_salvataggio}>
          {#if in_salvataggio}
            {t.salvataggio_in_corso[$l]}
          {:else}
            {t.salva[$l]}
          {/if}
        </Bottone>
      </div>
    </Modulo>
  </div>
</template>

<style>
</style>

<script>
  import Modulo from "@/base/componenti/Modulo.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import IntervalloDate from "@/base/componenti/inserimenti/date/Intervallo.svelte"
  import { createEventDispatcher } from "svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { sessione_corrente } from "@/portale/sorgenti/sessione"

  export let indirizzo = null
  export let id_struttura = null

  const propaga = createEventDispatcher()
  const t = avvia_localizzazione(dizionario_disponibilità)

  let errori = { nome: null }
  let in_salvataggio = false
  let unità_disponibili = 0
  let letti_disponibili = 0
  
  $: parametri  = {
    disponibilità:{
      unità_disponibili,
      // letti_disponibili,
      inizio: Date.today.sub(30, "days"),
      termine: Date.today.add(30, "days")
    },
    id_struttura
  }  

  ////
  // Validazione del modulo.
  function validatore_modulo() {
    return
  }

  ////
  // Gestione della gestione del modulo.
  function gestore_risposta(risposta) {
    if (risposta.riuscita) {
      propaga("richiedi")
    } else {
      in_salvataggio = false
    }
  }

  ////
  // Aggiorna cornice temporale evento.
  function aggiorna_cornice_temporale(evento_ascoltato) {
    parametri.disponibilità.inizio = evento_ascoltato.detail.date_intervallo[0]
    parametri.disponibilità.termine = evento_ascoltato.detail.date_intervallo[1]
  }
</script>

<script context="module">
  export const dizionario_disponibilità = {
    camere_disponibili: {
      it: `Camere disponibili`,
      en: `Rooms available`,
      de: `Verfügbare Zimmer`
    },
    letti_disponibili: {
      it: `Letti disponibili`,
      en: `Beds available`,
      de: `Verfügbare Betten`
    },
    seleziona_date: {
      it: `Seleziona le date in cui occorrono le nuove disponibilità`,
      en: `Select the dates when new availabilities are needed`,
      de: `Wählen Sie das Datum, an dem neue Verfügbarkeiten benötigt werden`
    }
  }
</script>