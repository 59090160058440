<script context="module">
  export const dizionario_rapporti_pubblici = {
    titolo: {
      it: `In Evidenza`,
      en: `Most Used`,
      de: `Meist benutzt`
    },
    principali_indicatori_di_performance: {
      it: `Principali indicatori di performance`,
      en: `Main key performance indexes`,
      de: `Wichtigste Leistungskennzahlen`
    },
    ricavo_medio_per_fascia_di_prezzo: {
      it: `Prezzo medio per fascia di prezzo`,
      en: `Average price per range`,
      de: `Durchschnittspreis nach Preisklasse`
    },
    ricavo_medio_per_dimensioni: {
      it: `Prezzo medio per dimensioni in MQ`,
      en: `Average price by size in square meters`,
      de: `Durchschnittspreis pro Größe in qm`
    },
    ricavo_medio_per_numero_locali: {
      it: `Prezzo medio per numero di locali`,
      en: `Average price by number of areas`,
      de: `Durchschnittspreis nach Zimmeranzahl`
    },
    occupazione_per_nazionalità: {
      it: `Tasso di Occupazione per Nazionalità`,
      en: `Occupation by nationality`,
      de: `Beschäftigungsquote nach Nationalität`
    },
    occupazione_per_nazionalità_territorio: {
      it: `Occupazione dettagliato per Nazionalità (%)`,
      en: ``,
      de: ``
    },
    occupazione_per_canali: {
      it: `Occupazione per Canale di Vendita`,
      en: `Employment by Sales Channel`,
      de: `Beschäftigung nach Vertriebskanal`
    },
    canale_piazzole_ricavo_medio: {
      it: `Piazzole - Ricavo medio per Canale`,
      en: `Pitches - Average revenue per channel`,
      de: `Stellplätze - Durchschnittlicher Umsatz pro Kanal`
    },
    canale_caravan_ricavo_medio: {
      it: `Caravan - Ricavo medio per Canale`,
      en: `Caravan - Average revenue per channel`,
      de: `Wohnwagen - Durchschnittlicher Umsatz pro Kanal`
    },
    canale_bungalow_ricavo_medio: {
      it: `Bungalow - Ricavo medio per Canale`,
      en: `Bungalow - Average revenue per channel`,
      de: `Bungalow - Durchschnittlicher Umsatz pro Kanal`
    },
    focus_piazzole: {
      it: `Focus Piazzole`,
      en: `Focus Pitches`,
      de: `Fokus Stellplätze`
    },
    descrizione_focus_piazzole: {
      it: `Quanto sono occupate le piazzole e qual è la loro redditività?`,
      en: `How busy are the pitches and what is their profitability?`,
      de: `Wie ausgelastet sind die Stellplätze und wie hoch ist ihre Rentabilität?`
    },
    focus_bungalow: {
      da_rifare: ``,
      it: `Focus Bungalow/Villette`,
      en: `Focus Bungalow`,
      de: `Fokus Bungalow`
    },
    descrizione_focus_bungalow: {
      it: `Quanto sono occupati i bungalow e qual è la loro redditività?`,
      en: `How busy are the bungalows and what is their profitability?`,
      de: `Wie ausgelastet sind die Bungalows und wie hoch ist ihre Rentabilität?`
    },
    focus_caravan: {
      it: `Focus Caravan/Casette Mobili`,
      en: `Focus Caravan / Mobile Homes`,
      de: `Fokus Wohnwagen / Mobile Homes`
    },
    descrizione_focus_caravan: {
      it: `Quanto sono occupati i caravan e qual è la loro redditività?`,
      en: `How busy are the caravans and what is their profitability?`,
      de: `Wie ausgelastet sind die Wohnwagen und wie hoch ist ihre Rentabilität?`
    },
    focus_canali: {
      it: `Focus Canali di Vendita`,
      en: `Focus Sales Channels`,
      de: `Fokus Vertriebskanäle`
    },
    descrizione_focus_canali: {
      it: `Quali sono i principali canali di vendita e qual è la loro redditività?`,
      en: `What are the main sales channels and what is their profitability?`,
      de: `Was sind die wichtigsten Vertriebskanäle und wie hoch ist ihre Rentabilität?`
    },
    descrizione_focus_canali_territorio: {
      it: `Report Standard - Analisi Dettagliata sui Canali di vendita`,
      en: ``,
      de: ``
    },
    focus_nazionalità: {
      it: `Focus Nazionalità`,
      en: `Focus Nationality`,
      de: `Fokus Nationalität`
    },
    focus_trattamenti: {
      it: `Focus Trattamenti`,
      en: `Focus Treatments`,
      de: `Fokus Behandlungen`
    },
    descrizione_focus_trattamenti_hotel: {
      it: `Report Standard - Analisi Dettagliata sui Trattamenti per Hotel`,
      en: `Standard Report - Detailed Analysis on Hotel Treatments`,
      de: `Standardbericht - Detaillierte Analyse zu Hotel-Behandlungen`
    },
    descrizione_focus_trattamenti: {
      it: `Report Standard - Analisi Dettagliata sui Trattamenti`,
      en: `Standard Report - Detailed Analysis on Treatments`,
      de: `Standardbericht - Detaillierte Analyse der Behandlungen`
    },
    ricavo_medio_camera_trattamento: {
      it: `Ricavo Medio Camera per Trattamento`,
      en: `Average Room Revenue for Treatment`,
      de: `Durchschnittlicher Zimmerumsatz pro Behandlung`
    },
    ricavo_medio_camera_mensile_trattamento: {
      it: `Ricavo Medio Camera Mensile per Trattamento`,
      en: `Average Monthly Room Revenue per Treatment`,
      de: `Durchschnittlicher monatlicher Zimmerumsatz pro Behandlung`
    },
    ricavo_medio_ospite_trattamento: {
      it: `Ricavo Medio Ospite per Trattamento`,
      en: `Average Guest Revenue for Treatment`,
      de: `Durchschnittlicher Gästeumsatz pro Behandlung`
    },
    occupazione_per_trattamento: {
      it: `Occupazione per Trattamento`,
      en: `Employment for Treatment`,
      de: `Beschäftigung pro Behandlung`
    },
    occupazione_acquisita_canale: {
      it: `Occupazione Acquisita per Canale di Vendita`,
      en: `Occupation acquired by sales channel`,
      de: `Beschäftigung über Vertriebskanal erworben`
    },
    composizione_occupazione_canale: {
      it: `Composizione Occupazione per Canale di Vendita`,
      en: `Employment breakdown by sales channel`,
      de: `Beschäftigungsverteilung nach Vertriebskanälen`
    },
    ricavo_medio_camera_canale: {
      it: `Ricavo Medio Camera per Canale di Vendita`,
      en: `Average Room Revenue by Sales Channel`,
      de: `Durchschnittlicher Zimmerumsatz nach Vertriebskanal`
    },
    focus_mercati: {
      it: `Focus Mercati di vendita`,
      en: `Focus Sales markets`,
      de: `Fokus Absatzmärkte`
    },
    descrizione_focus_mercati_hotel: {
      it: `Report Standard - Analisi Dettagliata sui Mercati di Vendita per Hotel`,
      en: `Standard Report - Detailed Analysis on the Sales Markets by Hotel`,
      de: `Standardbericht - Detaillierte Analyse der Absatzmärkte nach Hotels`
    },
    descrizione_focus_mercati: {
      it: `Report Standard - Analisi Dettagliata sui Mercati di Vendita`,
      en: `Standard Report - Detailed Analysis on Sales Markets`,
      de: `Standardbericht - Detaillierte Analyse der Absatzmärkte`
    },
    occupazione_per_mercato: {
      it: `Occupazione per Mercato di Vendita`,
      en: `Employment by Sales Market`,
      de: `Beschäftigung nach Absatzmärkten`
    },
    percentuale_camere_mercato: {
      it: `Percentuale Camere Vendute per Segmento di Mercato`,
      en: `Percentage of Rooms Sold by Market Segment`,
      de: `Prozentsatz der verkauften Zimmer nach Marktsegment`
    },
    percentuale_camere_mercato_mensile: {
      it: `Occupazione dettagliato per Mercato`,
      en: ``,
      de: ``
    },
    descrizione_focus_nazionalità: {
      it: `Da dove vengono gli ospiti e chi sono i più alto-spendenti?`,
      en: `Where do the guests come from and who are the highest-spenders?`,
      de: `Woher kommen die Gäste und wer zahlt am meisten?`
    },
    descrizione_focus_nazionalità_territorio: {
      it: `Report Standard - Analisi Dettagliata sulle Nazionalità`,
      en: ``,
      de: ``
    },
    percentuale_camere_nazionalità: {
      it: `Percentuale Camere Vendute per Nazionalità`,
      en: `Percentage of Rooms Sold by Nationality`,
      de: `Prozentsatz der verkauften Zimmer nach Nationalität`
    },
    percentuale_camere_canale_mensile: {
      it: `Occupazione dettagliato per Canale (%)`,
      en: ``,
      de: ``
    },
    camere_vendute_canale: {
      it: `Camere Vendute dettagliato per Canale`,
      en: ``,
      de: ``
    },
    camere_vendute_mercato_giornaliera: {
      it: `Camere Vendute dettagliato per Mercato`,
      en: ``,
      de: ``
    },
    camere_vendute_trattamento: {
      it: `Camere Vendute dettagliato per Trattamento`,
      en: ``,
      de: ``
    },
    ricavo_medio_camera_mercato: {
      it: `Ricavo Medio Camera per Mercato di Vendita`,
      en: `Average Room Revenue by Sales Market`,
      de: `Durchschnittlicher Zimmerumsatz nach Absatzmarkt`
    },
    percentuale_camere_ricavo_totale_trattamento: {
      it: `Occupazione dettagliato per Trattamento (%)`,
      en: ``,
      de: ``
    },
    camere_vendute_nazionalità: {
      it: `Camere Vendute dettagliato per Nazionalità`,
      en: ``,
      de: ``
    },
    ricavo_complessivo: {
      it: `Ricavo Complessivo`,
      en: ``,
      de: ``
    },
    ricavo_medio_camera_nazionalità: {
      it: `Ricavo Medio Camera - Italia, Germania, Austria e Svizzera`,
      en: ``,
      de: ``
    },
    focus_cancellazioni: {
      it: `Focus Cancellazioni`,
      en: `Focus Cancellations`,
      de: `Fokus Stornierungen`
    },
    focus_evento: {
      it: `Focus evento`,
      en: `Event focus`,
      de: `Fokus Veranstaltungen`
    },
    descrizione_focus_evento: {
      it: `Analisi Dettagliata di un Evento`,
      en: `Detailed Analysis of an Event`,
      de: `Detaillierte Analyse einer Veranstaltung`
    },
    kpi_evento_giornaliero: {
      it: `KPI Principali Evento - Giornaliero`,
      en: `Main KPIs Event - Daily`,
      de: `Haupt-KPIs Veranstaltung - täglich`
    },
    kpi_evento_totale: {
      it: `KPI Principali Evento - Totale`,
      en: `Main KPIs Event - Total`,
      de: `Haupt-KPIs Veranstaltung - Gesamt`
    },
    ricavo_medio_camere_nazionalità: {
      it: `Ricavo Medio Camera per Nazionalità`,
      en: `Average Room Revenue by Nationality`,
      de: `Durchschnittlicher Zimmerumsatz nach Nationalität`
    },
    focus_durata_media_prenotazione: {
      it: `Focus Durata Media Prenotazione`,
      en: `Focus Duration Medium Booking`,
      de: `Fokus durchschnittliche Buchungsdauer`
    },
    descrizione_focus_durata_media_prenotazione: {
      it: `Analisi delle durate medie delle prenotazioni nei diversi segmenti`,
      en: `Analysis of the average booking durations in the different segments`,
      de: `Analyse der durchschnittlichen Buchungsdauer in den verschiedenen Segmenten`
    },
    durata_media_prenotazione_prodotto: {
      it: `Durata Media Soggiorno per Tipologia di Prodotto`,
      en: `Average Duration of Stay by Product Type`,
      de: `Durchschnittliche Aufenthaltsdauer nach Produkttyp`
    },
    durata_media_prenotazione_nazionalità: {
      it: `Durata Media Soggiorno per Nazionalità`,
      en: `Average Duration of Stay by Nationality`,
      de: `Durchschnittliche Aufenthaltsdauer nach Nationalität`
    },
    durata_media_prenotazione_piazzola_nazionalità: {
      it: `Piazzole Durata Media Soggiorno per Nazionalità`,
      en: `Pitches Average Duration of Stay by Nationality`,
      de: `Stellplätze Durchschnittliche Aufenthaltsdauer nach Nationalität`
    },
    durata_media_prenotazione_bungalow_nazionalità: {
      it: `Bungalow Durata Media Soggiorno per Nazionalità`,
      en: `Bungalow Average Duration Stay by Nationality`,
      de: `Bungalow durchschnittliche Aufenthaltsdauer nach Nationalität`
    },
    focus_redditività_unità: {
      it: `Focus Redditività per Unità Disponibile`,
      en: `Focus Profitability per Unit Available`,
      de: `Fokus Rentabilität pro verfügbare Einheit`
    },
    descrizione_focus_redditività_unità: {
      it: ``,
      en: ``,
      de: ``
    },
    redditività_unità_revpau: {
      it: `REVPAU (Prezzo Medio per Unità Disponibile) `,
      en: `REVPAU (Average Price per Available Unit)`,
      de: `REVPAU (Durchschnittspreis pro verfügbarer Einheit)`
    },
    ricavo_medio_piazzola_nazionalità: {
      it: `Piazzole Ricavo Medio per Nazionalità`,
      en: `Pitches Average Revenue by Nationality`,
      de: `Durchschnittlicher Umsatz der Stellplätze nach Nationalität`
    },
    ricavo_medio_caravan_nazionalità: {
      it: `Caravan Ricavo Medio per Nazionalità`,
      en: `Caravan Average Revenue by Nationality`,
      de: `Durchschnittlicher Umsatz mit Wohnwagen nach Nationalität`
    },
    descrizione_focus_cancellazioni: {
      it: `Analisi dell'Occupazione cancellata e del Ricavo cancellato dettagliato per Prodotto`,
      en: `Analysis of Cancelled Occupation rate and Cancelled Revenue by Product`,
      de: `Analyse der Stornierungsrate und stornierter Umsatz pro Produkt und Nationalität`
    },
    principali_indicatori_cancellazioni: {
      it: `Indicatori principali: Occupazione cancellata e Ricavo cancellato`,
      en: `Main key indexes: Cancelled Occupancy rate and Cancelled Revenue`,
      de: `Wichtigste Indikatoren: Stornierungsrate und stornierter Umsatz`
    },
    principali_indicatori_cancellazioni_per_prodotto_e_nazionalità: {
      it: `Occupazione cancellata e Ricavo cancellato mensile per Prodotto e Nazionalità`,
      en: `Monthly Cancelled Occupation rate and Monthly Cancelled Revenue by Product and Nationality `,
      de: `Monatliche Stornierungsrate und monatlicher stornierter Umsatz pro Produkt und Nationalität `
    },
    principali_indicatori_cancellazioni_per_piazzole: {
      it: `Occupazione cancellata e Ricavo cancellato dettagliato per Piazzole`,
      en: `Cancelled Occupation rate and Cancelled Revenue by Pitches`,
      de: `Stornierungsrate und stornierter Umsatz pro Stellplatz`
    },
    principali_indicatori_cancellazioni_per_caravan: {
      it: `Occupazione cancellata e Ricavo cancellato dettagliato per Caravan`,
      en: `Cancelled Occupation rate and Cancelled Revenue by Caravan`,
      de: `Stornierungsrate und stornierter Umsatz pro Wohnwagen`
    },
    focus_pasqua: {
      it: `Focus Pasqua e 25 Aprile ${Date.today.year}`,
      en: `Focus Analysis Easter and 25th April ${Date.today.year}`,
      de: `Fokusanalyse Ostern und 25. April ${Date.today.year}`
    },
    descrizione_focus_pasqua: {
      it: `Come sta performando la tua struttura per il periodo di Pasqua e per il 25 Aprile?`,
      en: `How is your hotel performing for Easter and April 25th?`,
      de: `Wie entwickelt sich Ihre Struktur in der Zeit zwischen Ostern und dem 25. April?`
    },
    principali_indicatori_pasqua: {
      it: `Indicatori principali granularità giornaliera`,
      en: `Main key indexes daily granularity`,
      de: `Wichtigste Indikatoren tägliche Granularität `
    },
    ricavo_medio_per_trattamento_pasqua: {
      it: `Ricavo Medio Camera giornaliero per Trattamento`,
      en: `Average daily room revenue per treatment`,
      de: `Durchschnittlicher täglicher Zimmerumsatz pro Behandlung`
    },
    percentuale_camere_vendute_pasqua: {
      it: `Percentuale Camere Vendute per Nazionalità`,
      en: `Percentage of Rooms Sold by Nationality`,
      de: `Prozentsatz der verkauften Zimmer nach Nationalität`
    },
    occupazione_ricavo_cancellate_pasqua: {
      it: `Occupazione cancellata e Ricavo cancellato`,
      en: `Cancelled Occupancy rate and Cancelled Revenue`,
      de: `Stornierungsrate und stornierter Umsatz`
    },
    focus_2_giugno: {
      it: `Focus Ponte 2 Giugno 2022`,
      en: `Focus Analysis Juni 2nd Bridge 2022`,
      de: `Fokusanalyse 2. Juni Brücke 2022`
    },
    descrizione_focus_2_giugno: {
      it: `Come sta performando la tua struttura per il ponte del 2 Giugno?`,
      en: `How is your hotel performing for the Bridge of June 2nd?`,
      de: `Wie entwickelt sich Ihre Struktur für die Brücke am 2. Juni?`
    },
    principali_indicatori_2_giugno: {
      it: `Indicatori principali granularità giornaliera`,
      en: `Main key indexes daily granularity`,
      de: `Wichtigste Indikatoren tägliche Granularität `
    },
    ricavo_medio_per_trattamento_2_giugno: {
      it: `Ricavo Medio Camera giornaliero per Trattamento`,
      en: `Average daily room revenue per treatment`,
      de: `Durchschnittlicher täglicher Zimmerumsatz pro Behandlung`
    },
    percentuale_camere_vendute_2_giugno: {
      it: `Percentuale Camere Vendute per Nazionalità`,
      en: `Percentage of Rooms Sold by Nationality`,
      de: `Prozentsatz der verkauften Zimmer nach Nationalität`
    },
    durata_media_soggiorno_2_giugno: {
      it: `Durata Media del Soggiorno granularità giornaliera`,
      en: `Average Length of Stay daily granularity`,
      de: `Durchschnittliche Aufenthaltsdauer tägliche Granularität`
    },
    occupazione_ricavo_cancellate_2_giugno: {
      it: `Occupazione cancellata e Ricavo cancellato`,
      en: `Cancelled Occupancy rate and Cancelled Revenue`,
      de: `Stornierungsrate und stornierter Umsatz`
    },
    focus_prodotti_camping: {
      it: `Focus prodotti`,
      en: ``,
      de: ``
    },
    descrizione_focus_prodotti_camping: {
      it: `Analisi dettagliata dell'occupazione e del ricavo medio unità per Piazzole, Bungalow/Villette,
          Caravan/Casette mobili e Tende/Glamping`,
      en: ``,
      de: ``
    },
    indicatori_principali_prodotto_mensile_camping: {
      it: `Occupazione dettagliato per Prodotto granularità mensile`,
      en: ``,
      de: ``
    },
    ricavo_medio_unità_prodotto_mensile_camping: {
      it: `Ricavo medio unità dettagliato per Prodotto granularità mensile`,
      en: ``,
      de: ``
    },
    revpau_prodotto_mensile_camping: {
      it: `RevPAU dettagliato per Prodotto granularità mensile`,
      en: ``,
      de: ``
    },
    ricavo_complessivo_prodotto_mensile_camping: {
      it: `Ricavo complessivo dettagliato per Prodotto granularità mensile`,
      en: ``,
      de: ``
    },
    descrizione_focus_piazzole_territorio_camping: {
      it: `Analisi dettagliata dell'occupazione e del ricavo medio unità delle Piazzole`,
      en: ``,
      de: ``
    },
    occupazione_piazzole_giornaliera_camping: {
      it: `Indicatori principali Piazzole granularità giornaliera`,
      en: ``,
      de: ``
    },
    ricavo_medio_unità_piazzole_giornaliera_camping: {
      it: `Ricavo medio unità Piazzole per singola Fascia di prezzo granularità mensile`,
      en: ``,
      de: ``
    },
    ricavo_medio_dimensioni_piazzole_camping: {
      it: `Ricavo medio unità Piazzole per Dimensione in MQ granularità mensile`,
      en: ``,
      de: ``
    },
    permanenza_media_piazzole_giornaliera: {
      it: `Durata Media Soggiorno Piazzole granularità giornaliera`,
      en: ``,
      de: ``
    },
    descrizione_focus_bungalow_territorio_camping: {
      it: `Analisi dettagliata dell'occupazione e del ricavo medio unità dei Bungalow/Villette`,
      en: ``,
      de: ``
    },
    occupazione_bungalow_giornaliera_camping: {
      it: `Indicatori principali Bungalow/Villette granularità giornaliera`,
      en: ``,
      de: ``
    },
    ricavo_medio_unità_bungalow_giornaliera_camping: {
      it: `Ricavo medio unità Bungalow/Villette per singola Fascia di prezzo granularità mensile`,
      en: ``,
      de: ``
    },
    ricavo_medio_dimensioni_bungalow_camping: {
      it: `Ricavo medio unità Bungalow/Villette per Locali granularità mensile`,
      en: ``,
      de: ``
    },
    permanenza_media_bungalow_giornaliera: {
      it: `Durata Media Soggiorno Bungalow/Villette granularità giornaliera`,
      en: ``,
      de: ``
    },
    descrizione_focus_caravan_territorio_camping: {
      it: `Analisi dettagliata dell'occupazione e del ricavo medio unità dei Caravan/Casette mobili`,
      en: ``,
      de: ``
    },
    occupazione_caravan_giornaliera_camping: {
      it: `Principali indicatori Caravan/Casette mobili granularità giornaliera`,
      en: ``,
      de: ``
    },
    ricavo_medio_unità_caravan_giornaliera_camping: {
      it: `Ricavo medio unità Caravan/Casette mobili per singola Fascia di prezzo granularità mensile`,
      en: ``,
      de: ``
    },
    ricavo_medio_dimensioni_caravan_camping: {
      it: `Ricavo medio unità Caravan/Casette mobili per Locali granularità mensile`,
      en: ``,
      de: ``
    },
    permanenza_media_caravan_giornaliera: {
      it: `Durata Media Soggiorno Caravan/Casette mobili granularità giornaliera`,
      en: ``,
      de: ``
    },
    focus_tende: {
      it: `Focus Tende/Glamping`,
      en: ``,
      de: ``
    },
    descrizione_focus_tende_territorio_camping: {
      it: `Analisi dettagliata dell'occupazione e del ricavo medio unità delle Tende/Glamping`,
      en: ``,
      de: ``
    },
    occupazione_tende_giornaliera_camping: {
      it: `Principali indicatori Tende/Glamping granularità giornaliera`,
      en: ``,
      de: ``
    },
    ricavo_medio_unità_tende_giornaliera_camping: {
      it: `Ricavo medio unità Tende/Glamping per singola Fascia di prezzo granularità mensile`,
      en: ``,
      de: ``
    },
    permanenza_media_tende_giornaliera: {
      it: `Durata Media Soggiorno Tende/Glamping granularità giornaliera`,
      en: ``,
      de: ``
    },
    focus_nazionalità_camping: {
      it: `Focus Nazionalità`,
      en: ``,
      de: ``
    },
    descrizione_focus_nazionalità_camping: {
      it: `Analisi dettagliata sulle Nazionalità per Piazzole, Bungalow/Villette, Caravan/Casette mobili e Tende/Glamping`,
      en: ``,
      de: ``
    },
    occupazione_nazionalità_prodotto_camping: {
      it: `Occupazione Nazionalità per singolo Prodotto granularità mensile`,
      en: ``,
      de: ``
    },
    ricavo_medio_unità_piazzole_nazionalità_giornaliera_camping: {
      it: `Ricavo medio unità Piazzole per singola Nazionalità granularità giornaliera`,
      en: ``,
      de: ``
    },
    occupazione_nazionalità_mensile_camping: {
      it: `Occupazione dettagliato per Nazionalità granularità mensile`,
      en: ``,
      de: ``
    },
    ricavo_medio_unità_nazionalità_camping: {
      it: `Ricavo medio unità dettagliato per Nazionalità granularità settimanale`,
      en: ``,
      de: ``
    },
    descrizione_focus_canali_camping: {
      it: `Analisi dettagliata sui Canali di Vendita per Piazzole, Bungalow/Villette,
          Caravan/Casette mobili e Tende/Glamping`,
      en: ``,
      de: ``
    },
    occupazione_canali_prodotto_camping: {
      it: `Occupazione Canali di vendita per singolo Prodotto granularità mensile`,
      en: ``,
      de: ``
    },
    ricavo_medio_unità_canali_prodotto_camping: {
      it: `Ricavo medio unità Canali di vendita per singolo Prodotto`,
      en: ``,
      de: ``
    },
    occupazione_canale_camping: {
      it: `Occupazione dettagliato per Canale`,
      en: ``,
      de: ``
    },
    focus_ricavo_complessivo: {
      it: `Focus Ricavo complessivo`,
      en: ``,
      de: ``
    },
    descrizione_focus_ricavo_complessivo_camping: {
      it: `Analisi dettagliata sul ricavo complessivo per Prodotti, Canali di vendita e Nazionalità`,
      en: ``,
      de: ``
    },
    ricavo_complessivo_mensile_camping: {
      it: `Ricavo complessivo granularità mensile`,
      en: ``,
      de: ``
    },
    ricavo_complessivo_canale_mensile_camping: {
      it: `Ricavo complessivo dettagliato per Canale di vendita granularità mensile`,
      en: ``,
      de: ``
    },
    ricavo_complessivo_nazionalità_mensile_camping: {
      it: `Ricavo complessivo dettagliato per Nazionalità granularità mensile`,
      en: ``,
      de: ``
    },
    focus_trend_storici_camping: {
      it: `Focus trend storici`,
      en: ``,
      de: ``
    },
    descrizione_focus_trend_storici_camping: {
      it: `Analisi dettagliata indicatori principali Anno Corrente vs. Anno Precedente vs. Due Anni Precedenti`,
      en: ``,
      de: ``
    },
    principali_indicatori_storici_mensile_camping: {
      it: `Indicatori principali granularità mensile`,
      en: ``,
      de: ``
    },
    principali_indicatori_piazzole_storici_mensile_camping: {
      it: `Indicatori principali Piazzole granularità mensile`,
      en: ``,
      de: ``
    },
    principali_indicatori_bungalow_storici_mensile_camping: {
      it: `Indicatori principali Bungalow/Villette granularità mensile`,
      en: ``,
      de: ``
    },
    principali_indicatori_caravan_storici_mensile_camping: {
      it: `Indicatori principali Caravan/Casette mobili granularità mensile`,
      en: ``,
      de: ``
    },
    focus_permanenza_media_camping: {
      it: `Focus Durata media del Soggiorno`,
      en: ``,
      de: ``
    },
    descrizione_focus_permanenza_media_camping: {
      it: `Analisi delle durate medie di soggiorno delle prenotazioni nei diversi segmenti`,
      en: ``,
      de: ``
    },
    permanenza_media_prodotto_giornaliera_camping: {
      it: `Durata media del soggiorno dettagliato per Prodotto granularità mensile`,
      en: ``,
      de: ``
    },
    permanenza_media_nazionalità_giornaliera_camping: {
      it: `Durata media del soggiorno per Nazionalità granularità mensile`,
      en: ``,
      de: ``
    },
    permanenza_media_piazzole_nazionalità_giornaliera_camping: {
      it: `Durata media del soggiorno Piazzole dettagliato per Nazionalità granularità settimanale`,
      en: ``,
      de: ``
    },
    permanenza_media_bungalow_nazionalità_giornaliera_camping: {
      it: `Durata media del soggiorno Bungalow/Villette dettagliato per Nazionalità granularità settimanale`,
      en: ``,
      de: ``
    },
    descrizione_focus_cancellazioni_camping: {
      it: `Analisi dell'Occupazione cancellazione e del Ricavo cancellato dettagliato per Prodotto e Nazionalità`,
      en: ``,
      de: ``
    },
    principali_indicatori_cancellazioni_giornaliera_camping: {
      it: `Occupazione cancellata e Ricavo cancellato granularità giornaliera`,
      en: ``,
      de: ``
    },
    principali_indicatori_cancellazioni_prodotto_mensile_camping: {
      it: `Occupazione cancellata e Ricavo cancellato per Prodotto granularità mensile`,
      en: ``,
      de: ``
    },
    principali_indicatori_cancellazioni_nazionalità_mensile_camping: {
      it: `Occupazione cancellata e Ricavo cancellato per Nazionalità granularità mensile`,
      en: ``,
      de: ``
    },
    principali_indicatori_cancellazioni_piazzole_mensile_camping: {
      it: `Occupazione cancellata e Ricavo cancellato Piazzole granularità mensile`,
      en: ``,
      de: ``
    },
    focus_booking_window_camping: {
      it: `Booking Window`,
      en: ``,
      de: ``
    },
    descrizione_focus_booking_window_camping: {
      it: `Analisi dettagliata sulla pressione della domanda per le date future`,
      en: ``,
      de: ``
    },
    focus_spit: {
      it: `SPIT (Same Point In Time)`,
      en: ``,
      de: ``
    },
    descrizione_focus_spit_camping: {
      it: `Analisi sulle performance di Occupazione e Ricavi sulla stessa data dell'anno scorso`,
      en: ``,
      de: ``
    },
    descrizione_focus_spit: {
      it: `Analisi sulle performance di Occupazione, Ricavi e Cancellazioni alla stessa data dell'anno scorso`,
      en: ``,
      de: ``
    },
    indicatori_principali_spit_camping: {
      it: `Occupazione granularità giornaliera`,
      en: ``,
      de: ``
    },
    occupazione_prodotto_spit_camping: {
      it: `Occupazione dettagliata per Prodotto granularità mensile`,
      en: ``,
      de: ``
    },
    ricavo_medio_unità_prodotto_spit_camping: {
      it: `Ricavo medio unità dettagliato per Prodotto granularità mensile`,
      en: ``,
      de: ``
    },
    ricavo_complessivo_prodotto_spit_camping: {
      it: `Ricavo complessivo dettagliato per Prodotto granularità mensile`,
      en: ``,
      de: ``
    },
    occupazione_giornaliera_booking_window_camping: {
      it: `Occupazione granularità giornaliera`,
      en: ``,
      de: ``
    },
    occupazione_prodotto_booking_window_camping: {
      it: `Occupazione dettagliata per Prodotto granularità mensile`,
      en: ``,
      de: ``
    },
    ricavo_medio_unità_prodotto_booking_window_camping: {
      it: `Ricavo medio unità dettagliato per Prodotto granularità mensile`,
      en: ``,
      de: ``
    },
    ricavo_complessivo_prodotto_booking_window_camping: {
      it: `Ricavo complessivo dettagliato per Prodotto granularità mensile`,
      en: ``,
      de: ``
    },
    durata_media_soggiorno_nazionalità: {
      it: `Durata Media di Soggiorno - Italia, Germania, Austria e Svizzera`,
      en: ``,
      de: ``
    },
    focus_generale: {
      it: `Analisi generale di destinazione`,
      en: ``,
      de: ``
    },
    descrizione_focus_generale: {
      it: `Analisi generale sull'andamento della destinazione: Occupazione, Ricavi e Durata media soggiorno`,
      en: ``,
      de: ``
    },
    occupazione_adr_revpar: {
      it: `Occupazione, ADR e RevPAR`,
      en: ``,
      de: ``
    },
    permanenza_media: {
      it: `Durata Media di Soggiorno`,
      en: ``,
      de: ``
    },
    principali_indicatori_spit: {
      it: `Occupazione, ADR, Ricavo complessivo granularità giornaliera`,
      en: ``,
      de: ``
    },
    cancellazioni_spit: {
      it: `Camere cancellate, Occupazione cancellata, Ricavo cancellato granularità giornaliera`,
      en: ``,
      de: ``
    },
    focus_booking_window: {
      it: `BOOKING WINDOW`,
      en: ``,
      de: ``
    },
    descrizione_focus_booking_window: {
      it: `Occupazione, Camere vendute, Ricavo complessivo e Cancellazioni in entrata negli ultimi 30 giorni per i prossimi 90`,
      en: ``,
      de: ``
    },
    indicatori_principali_booking_window: {
      it: `Occupazione, Camere vendute, Ricavo complessivo in entrata negli ultimi 30 giorni per i prossimi 90`,
      en: ``,
      de: ``
    },
    cancellazioni_booking_window: {
      it: `Occupazione cancellata, Camere cancellate, Ricavo complessivo cancellato negli ultimi 30 giorni per i prossimi 90`,
      en: ``,
      de: ``
    },
    focus_trend_storici: {
      it: `Analisi generale - Sequenza storica`,
      en: ``,
      de: ``
    },
    focus_trend_storici: {
      it: `Analisi generale - Sequenza storica`,
      en: ``,
      de: ``
    },
    descrizione_focus_trend_storici: {
      it: `Analisi Generale di Destinazione - Dati a Consuntivo vs. Ultimi Due Anni`,
      en: ``,
      de: ``
    },
    principali_indicatori_trend_storici: {
      it: `Occupazione, Camere Vendute, Ricavi e REVPAR consuntivi da inizio anno vs. i due anni precedenti`,
      en: ``,
      de: ``
    },
  }
</script>