<template>
  <div class="schedario">
    <div class="errore">
      {#if abbonamento_scaduto}
        <div>{@html t.abbonamento_scaduto[$localizzazione]}</div>
      {:else}
        <img src={immagine_errore} alt=""/>
        <div>{@html t.errori.spiacenti[$localizzazione]}.</div>
        <div>{@html t.errori.contatta_supporto[$localizzazione]}.</div>
      {/if}
    </div>
  </div>
</template>

<style>
  :global(div.statistica div.errore) {
    display:        flex;
    flex-direction: column;
    align-items:    center;
    margin:         28px;
  }
  :global(div.statistica div.errore > img) {
    height:         500px;
  }
</style>

<script>
  import immagine_errore from "@/base/immagini/errore.svg"
  import { localizzazione } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  export let abbonamento_scaduto = false

  const t = avvia_localizzazione(dizionario_errore)
</script>

<script context="module">
  export const dizionario_errore = {
    abbonamento_scaduto: {
      it: `Il tuo abbonamento è scaduto. Per rinnovarlo contattaci a <a href="mailto:supporto@hbenchmark.it">supporto@hbenchmark.it</a>.`,
      en: `Your subscription has expired. To renew it contact us at <a href="mailto:supporto@hbenchmark.it">supporto@hbenchmark.it</a>.`,
      de: `Ihr Abonnement ist abgelaufen. Um Ihr Abonnement zu erneuern, kontaktieren Sie uns bitte unter <a href="mailto:supporto@hbenchmark.it">supporto@hbenchmark.it</a>.`
    }
  }
</script>