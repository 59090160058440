// Svuby
import "@/base/sorgenti/svuby"

// Sessioni Portale.
import "@/portale/sorgenti/sessione"

// Dominio
import "@/portale/sorgenti/dominio"

// App
import App from "@/portale/sorgenti/App.svelte"

const app = new App({
	target: document.body
})

export default app