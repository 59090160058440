<template>
  <GestioneStruttura>
    {#if in_caricamento}
      <Caricatore/>
    {:else}
      
      <p class="margini-positivi">
        {@html t.informazioni_generiche[$l]}
        <a href="/"
           on:click|preventDefault={apri_dettagli_regole_concorrenti}>
          <b>{t.clicca_qui[$l]}</b>.
        </a>
      </p>

      {#each concorrenti as concorrente, indice}
        <Concorrente
            {concorrente}
            on:change={aggiorna_gruppi_comparabili}/>
      {/each}

      {#each concorrenti_vuoti as concorrente, indice}
        <Concorrente
            {concorrente}
            on:change={aggiorna_gruppi_comparabili}/>
      {/each}
    {/if}

    <Modale
        espansa={true}
        richiudibile={true}
        titolo={t.modale.titolo[$l]}
        bind:aperta={modale_regole_concorrenti_aperta}>
      <div class="modale-contenuto">
        <ul class="regole-concorrenti">
          <li>{@html t.modale.regole.regola_1[$l]}</li>
          <li>{@html t.modale.regole.regola_2[$l]}</li>
          <li>{@html t.modale.regole.regola_3[$l]}</li>
          <li>{@html t.modale.regole.regola_4[$l]}</li>
          <li>{@html t.modale.regole.regola_5[$l]}</li>
        </ul>
      </div>
    </Modale>
  </GestioneStruttura>
</template>

<style>

</style>


<script>
  import GestioneStruttura from "@/portale/componenti/GestioneStruttura.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Concorrente from "@/base/componenti/Concorrente.svelte"
  import Modale from "@/base/componenti/Modale.svelte"
  import { onDestroy } from "svelte"
  import { sessione_corrente } from "@/portale/sorgenti/sessione"
  import { gruppi_comparabili } from "@/portale/sorgenti/sessione"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  const t = avvia_localizzazione(dizionario_concorrenti)

  let in_caricamento = true
  let concorrenti = []
  let concorrenti_vuoti = []
  let modale_regole_concorrenti_aperta = false

  ////
  // Richiede i concorrenti.
  async function richiedi_concorrenti() {
    in_caricamento = true

    concorrenti = []
    concorrenti_vuoti = []

    let risposta = await retro.chiama(
      "GET",
      retro.estremi.portale.concorrenti,
      { id_struttura: $sessione_corrente._id })

    concorrenti = risposta.contenuto.concorrenti

    if (concorrenti.length < 3) {
      (3 - concorrenti.length).times(i => {
        concorrenti_vuoti.push(concorrente_vuoto())
      })
    }

    in_caricamento = false
  }

  ////
  // Crea un concorrente vuoto.
  function concorrente_vuoto() {
    return {
      nome: t.nuovo_nome[$l],
      id_struttura: $sessione_corrente._id,
      id_strutture: []
    }
  }

  ////
  // Apre la modale con i dettagli sulle regole concorrenti.
  function apri_dettagli_regole_concorrenti() {
    modale_regole_concorrenti_aperta = true
  }


  ////
  // Aggiorna i gruppi comparabili.
  async function aggiorna_gruppi_comparabili() {
    let risposta = await retro.chiama(
      "GET",
      retro.estremi.portale.gruppi)

    gruppi_comparabili.cambia_in(risposta.contenuto.gruppi_comparabili)
  }

  // Al cambio sessione, richiede i concorrenti.
  const scollega_cambio_sessione = sessione_corrente.su_cambio(richiedi_concorrenti)
  // Quando distrutto, stacca il collegamento del cambio sessione.
  onDestroy(scollega_cambio_sessione)
</script>

<script context="module">
  export const dizionario_concorrenti = {
    titolo_concorrenti: {
      it: `Competitors`,
      en: `Competitors`,
      de: `Wettbewerber`
    },
    nuovo_nome: {
      it: `Nuovo Competitor`,
      en: `New Competitor`,
      de: `Neuer Wettbewerber`
    },
    informazioni_generiche: {
      it: `In questa pagina puoi creare o modificare i 3 gruppi che identificano i tuoi competitor.
            Ogni gruppo deve contenere <b>almeno 5 strutture</b>, fino ad un <b>massimo di 10</b>. È possibile effettuare
            un <b>massimo di 5 modifiche</b> ad ogni gruppo. L'azione di sola rinomina di un competitor non incrementa il contatore modifiche.
            Per ulteriori dettagli sulle regole dei gruppi di competitor`,
      en: `In this page you can create or edit the 3 sets of properties that represent your competitors.
            Every set must have <b>at least 5 properties</b>, up to a <b>maximum of 10</b>. You can edit
            the set a <b>maximum of 5 times</b>. If you just rename the set, it will not increase the edit counter.
            For more details on the competitive set rules`,
      de: `Auf dieser Seite können Sie die 3 Gruppen erstellen oder ändern, die Ihre Wettbewerber repräsentieren.
            Jede Gruppe muss <b> mindestens 5 Betriebe </b> enthalten, bis zu <b> maximal 10 </b>. Du kannst
            a <b> maximal 5 Änderungen </b> für jede Gruppe bearbeiten. Die Umbenennung eines Wettbewerbers allein erhöht den Änderungszähler nicht.
            Weitere Details zu den Regeln der Teilnehmergruppen`
    },
    modale: {
      titolo: {
        it: `Regole Gruppo di Competitor`,
        en: `Compset Rules`,
        de: `Regeln für Wettbewerber-Gruppen`
      },
      regole: {
        regola_1: {
          it: `Si possono creare fino a 3 gruppi di competitor composti da un minimo di <b>5</b> e un massimo di <b>10</b> strutture`,
          en: `You can create up to 3 competitor groups, with a minimum of <b>5</b> properties and a maximum of <b>10</b>`,
          de: `Sie können bis zu 3 Wettbewerber-Gruppen erstellen mit mindestens <b>5</b> und maximal <b>10</b> Betrieben`
        },
        regola_2: {
          it: `Ogni gruppo non può essere modificato più di 5 volte`,
          en: `Every group cannot be modified more than 5 times`,
          de: `Jede Gruppe kann nicht mehr als 5 Mal geändert werden`
        },
        regola_3: {
          it: `Ogni gruppo deve contenere almeno 3 strutture diverse da tutti gli altri gruppi`,
          en: `Every group must contain at least 3 different properties from other groups' properties`,
          de: `Jede Gruppe muss mindestens 3 Eigenschaften enthalten, die sich von den anderen Gruppen unterscheiden`
        },
        regola_4: {
          it: `Ogni modifica di un gruppo deve contenere almeno 2 variazioni di strutture`,
          en: `Every edit of a group must contain at least 2 variations of properties`,
          de: `Jede Bearbeitung einer Gruppe muss mindestens 2 Variationen von Betrieben enthalten`
        },
        regola_5: {
          it: `Ogni gruppo non può contenere due o più strutture appartenenti alla stessa catena/proprietà`,
          en: `Every group cannot contain two or more properties of the same chain/holding`,
          de: `Jede Gruppe kann nicht zwei oder mehr Betriebe derselben Kette / Holding enthalten`
        },
      }
    }
  }
</script>