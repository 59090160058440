<template>
  <Messaggio>
    <div slot="testi">
      <h1>
        {t.titolo[$l]}
      </h1>
  
      <p>{t.testo_1[$l]}</p>
  
      <Lista lista={lista_testi}/>

      <div class="descrizione-veneto">
        HBenchmark tra le sue diverse funzionalità permette alle strutture ricettive (Hotel e Camping) di confrontare
        le proprie performance di OCCUPAZIONE, ADR, e REVPAR con le performance di altri hotel e della destinazione.
        Permette inoltre di creare informazioni aggregate utilizzabili dalla destinazione in merito alle performance
        di OCCUPAZIONE, ADR, e REVPAR delle strutture presenti. Si dichiara pertanto che HBenchmark è una soluzione
        con requisiti e caratteristiche idonee alla descrizione richiesta come intervento obbligatorio nel Bando della
        Regione Veneto (DGR n. 1390 del 16 settembre 2020).

        Al termine della registrazione riceverai la fattura riportante in oggetto la seguente dicitura:<br>
        <b>«Spesa agevolata a valere sul POR FESR Veneto 2014-2020, Azione 3.3.4 sub C DGR1390/2020»</b>
      </div>
    </div>
  </Messaggio>
</template>

<style>
  :global(div div.descrizione-veneto){
    width: 135%;
    transform: translateX(-10%);
    font-size: 9px;
    margin: 14px 0 50px;
    line-height: 17px;
  }
</style>

<script>
  import Lista from "@/base/componenti/Lista.svelte"
  import Messaggio from "@/base/componenti/accesso/Messaggio.svelte"
  import { dizionario_messaggio } from "@/base/componenti/accesso/Messaggio.svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  const t = avvia_localizzazione(dizionario_messaggio)

  $: lista_testi = [
    {
      testo: t.testo_lista_1[$l]
    },{
      testo: t.testo_lista_2[$l]
    },{
      testo: t.testo_lista_3[$l]
    }
  ]
</script>
