<template>
  <div class="elenco-serie standard">
    {#each gruppi as gruppo, indice_gruppo}
      {#if indice_gruppo < numero_serie_mostrate}
        <div
            class="gruppo-serie"
            class:esteso={gruppi.length == 1}>
          <div class="gruppo">
            {gruppo.nome}
            {#if gruppo.categorie != null && gruppo.categorie.length > 0}
              -
              {gruppo.categorie.join(" - ")}
            {/if}
          </div>
          {#each periodi as periodo, indice_periodo}
            {#if indice_gruppo == 0 && indice_periodo == 0}
              <Serie
                  {statistica}
                  serie={serie_per_gruppo_e_periodo[indice_gruppo][indice_periodo][0]}
                  on:evidenzia
                  on:mostra
                  on:nascondi/>
            {:else}
              <Serie
                  {statistica}
                  serie={serie_per_gruppo_e_periodo[indice_gruppo][indice_periodo][0]}
                  serie_comparata={serie_per_gruppo_e_periodo[0][0][0]}
                  on:evidenzia
                  on:mostra
                  on:nascondi/>
            {/if}
          {/each}
        </div>
      {/if}
    {/each}
  </div>
</template>

<style>
  :global(div.legenda-serie div.elenco-serie) {
    padding-top:  0 !important;
  }
  :global(div.legenda-serie div.elenco-serie.standard) {
    flex-direction: row;
    width:          100%;
  }
  :global(div.legenda-serie div.elenco-serie.standard div.gruppo-serie) {
    margin-right:   14px;
    width:          calc((100% / 3) - 14px);
  }
  :global(div.legenda-serie div.elenco-serie.standard div.gruppo-serie.esteso) {
    width:          auto;
    min-width:      200px;
  }
</style>

<script>
  import Serie from "@/base/componenti/statistiche/legenda_serie/Serie.svelte"
  import { createEventDispatcher } from "svelte"

  export let statistica = {}
  export let serie_per_gruppo_e_periodo = {}
  export let numero_serie_mostrate = 99
  export let numero_limite_serie = 99

  const propaga = createEventDispatcher()

  let gruppi = []
  let periodi = []

  ////
  // Determina gruppi e periodi.
  function determina_gruppi_e_periodi() {
    gruppi = statistica.gruppi
    numero_limite_serie = gruppi.length
    periodi = statistica.cornice_temporale.periodi
  }

  $: if (serie_per_gruppo_e_periodo != null) determina_gruppi_e_periodi()
</script>
