<template>
  {conteggio_corrente.format_digits(decimali)}
</template>

<style>
</style>

<script>
  export let numero = 0
  export let decimali = null
  export let durata = 1000

  let frequenza_aggiornamento = 1000 / 60
  let passi_totali = Math.round(durata / frequenza_aggiornamento)
  let addolcisci = t => t * ( 2 - t )
  let conteggio_corrente = 0

  // Determina quanti decimali ha il numero.
  function determina_decimali() {
    if (decimali == null) {
      let numero_in_stringa = numero.toString()
      let posizione_virgola = numero_in_stringa.indexOf(".")
      decimali = numero_in_stringa.length - posizione_virgola - 1
      if (posizione_virgola < 0) decimali = 0
    }
  }
  ////
  // Avvia il conteggio.
  function avvia_conteggio() {
    determina_decimali()
    conteggio_corrente = 0

	  let passo = 0

	  const counter = setInterval(() => {
	  	passo++

	  	let progresso = addolcisci(passo / passi_totali)
	  	conteggio_corrente = (numero * progresso).round(decimali)

	  	if (passo === passi_totali) clearInterval(counter)
	  }, frequenza_aggiornamento)
  }

  // Quando cambia il numero, avvia il conteggio.
  $: if (numero != null) avvia_conteggio()
</script>
