<template>
  <RottaNuovoAbbonamento titolo={t.titolo[$l]}>
    <div class="passo-progresso-abbonamento schermo-intero">
      {#if in_caricamento}
        <Caricatore/>
      {:else}
        <Modulo
            gestore={continua}
            validatore={validatore_modulo}
            bind:errori={errori}>
          {#if !DOMINI_SENZA_COUPON.include($dominio)}
            <TitoloSecondario>{t.hai_un_buono_sconto[$l]}</TitoloSecondario>
            <div class="buono-sconto">
              <Inserimento 
                  cancellabile={true}
                  errore={errori.codice_buono_sconto}
                  segnaposto={t.inserisci_buono_sconto[$l]}
                  bind:valore={$abbonamento.codice_buono_sconto}
                  on:input={ripristina_buono_sconto}/>

              <div class="azioni">
                <Bottone 
                    variante="testo-leggero-invertito-con-bordo"
                    on:click={valida_buono_sconto}>
                  {t.verifica[$l]}
                </Bottone>
              </div>
              {#if $abbonamento.buono_sconto != null && $abbonamento.buono_sconto.valido}
                <div class="successo">
                  <span class="blocco-sconti" class:intera={piano_scontato && integrazione_scontata}>
                    {#if $abbonamento.buono_sconto.con_periodo_di_prova}
                      {t.periodo_di_prova_attivato[$l]}: {$abbonamento.buono_sconto.termine_periodo_di_prova.to_date.format("%d %B %Y")}
                    {:else}
                      {#if $abbonamento.buono_sconto.sconto_in_euro > 0}
                        {t.sconto[$l]}: {$abbonamento.buono_sconto.sconto_in_euro} {@html $valuta_per_dominio}
                      {:else if $abbonamento.buono_sconto.sconto_in_percentuale > 0}
                        {t.sconto[$l]}: {$abbonamento.buono_sconto.sconto_in_percentuale} %
                      {/if}
                      {#if piano_scontato && integrazione_scontata}
                        <br/>
                      {/if}
                      {#if $abbonamento.buono_sconto.sconto_in_euro_integrazione > 0}
                        {t.sconto_integrazione[$l]}: {$abbonamento.buono_sconto.sconto_in_euro_integrazione} {@html $valuta_per_dominio}
                      {:else if $abbonamento.buono_sconto.sconto_in_percentuale_integrazione > 0}
                        {t.sconto_integrazione[$l]}: {$abbonamento.buono_sconto.sconto_in_percentuale_integrazione} %
                      {/if}
                    {/if}
                  </span>
                </div>
              {/if}
            </div>
          {/if}
          <div class="piani">
            {#if $dominio == "veneto" || $dominio == "bccveneto"}
              <div
                  class="testo-piani-personalizzato"
                  class:giustificato={piani_calcolati.length == 1}>
                {#if $dominio == "veneto" || $dominio == "bccveneto"}
                  <p>{@html t.testi_personalizzati.per_dominio.veneto_1[$l]} <b>{Date.today.add(1, "year").format("%d %B %Y")}</b>{@html t.testi_personalizzati.per_dominio.veneto_2[$l]}.</p>
                {/if}
              </div>
            {/if}
            <div class="piani-abbonamento">
              <table>
                <thead>
                  <th>{t.confronta[$l]}</th>
                  {#each piani_calcolati as piano}
                    <th
                        class="nome-piano"
                        class:selezionato={$abbonamento.nome_piano == piano.nome}>
                      {piano.nome}
                    </th>
                  {/each}
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    {#each piani_calcolati as piano}
                      <td>
                        {#if $abbonamento.nome_piano == piano.nome}
                          <Bottone
                              variante="primario">
                            {t.selezionato[$l]}
                          </Bottone>
                        {:else}
                          <Bottone
                              variante="testo-leggero-invertito-con-bordo"
                              on:click={seleziona_piano(piano)}>
                            {t.seleziona[$l]}
                          </Bottone>
                        {/if}
                      </td>
                    {/each}
                  </tr>
                  <tr>
                    <td>{t.caratteristiche.base[$l]}</td>
                    {#each piani_calcolati as piano, indice}
                      <td><Icona tipo="fatto"/></td>
                    {/each}
                  </tr>
                  <tr>
                    <td>{t.caratteristiche.permanenza_media[$l]}</td>
                    {#each piani_calcolati as piano, indice}
                      <td><Icona tipo="fatto"/></td>
                    {/each}
                  </tr>
                  <tr>
                    <td>{t.caratteristiche.segmentazioni[$l]}</td>
                    {#each piani_calcolati as piano, indice}
                      <td><Icona tipo="fatto"/></td>
                    {/each}
                  </tr>
                  <tr>
                    <td>{t.caratteristiche.disdette[$l]}</td>
                    {#each piani_calcolati as piano, indice}
                      <td><Icona tipo="fatto"/></td>
                    {/each}
                  </tr>
                  <tr>
                    <td>{t.caratteristiche.spit[$l]}</td>
                    {#each piani_calcolati as piano, indice}
                      <td class:senza-caratteristica={piano.nome != "pro"}>
                        {#if piano.nome == "pro"}
                          <Icona tipo="fatto"/>
                        {/if}
                      </td>
                    {/each}
                  </tr>
                  <tr>
                    <td>{t.prezzo[$l]}</td>
                    {#each piani_calcolati as piano, indice}
                      <td class:selezionato={$abbonamento.nome_piano == piano.nome}>
                        {#if piano.in_prova}
                          <span class="prezzo">{@html $valuta_per_dominio} {piano.prezzo}</span>{t.cadenza_fatturazione[$l]}
                          <p>
                            {t.periodo_di_prova[$l]}
                            {piano.termine_prova}
                          </p>
                        {:else if piano.scontato}
                          {#if piano.mostra_scontistica}
                            <span class="scontato">
                              <span class="prezzo">{@html $valuta_per_dominio}&nbsp;{piano.prezzo}</span>{t.cadenza_fatturazione[$l]}
                            </span>
                          {/if}
                          <span class="prezzo">{@html $valuta_per_dominio}&nbsp;{piano.prezzo_scontato}</span>{t.cadenza_fatturazione[$l]}
                        {:else}
                          <span class="prezzo">{@html $valuta_per_dominio}&nbsp;{piano.prezzo}</span>{t.cadenza_fatturazione[$l]}
                        {/if}
                      </td>
                    {/each}
                  </tr>
                </tbody>
              </table>
            </div>
            <div 
                class="info-costo-integrazione">
              {#if $abbonamento.piano != null && $abbonamento.piano.costo_integrazione_scontato}
                {@html t.info_costo_integrazione[$l]}
                <span class="scontato">{COSTO_INTEGRAZIONE}</span>
                <b>{$abbonamento.piano.costo_integrazione.toFixed(2)}</b> {@html $valuta_per_dominio}.
              {:else if $abbonamento.piano != null && $abbonamento.piano.costo_integrazione > 0}
                {@html t.info_costo_integrazione[$l]}
                <b>{$abbonamento.piano.costo_integrazione}</b> {@html $valuta_per_dominio}.
              {:else}
                {@html t.nessun_costo_integrazione[$l]}.<br/>
              {/if}
            </div>
          </div>

          <div class="azioni">
            <Bottone
                variante="primario"
                on:click={apri_modale_contratto}>
              {t.continua[$l]}
            </Bottone>
            <Bottone 
                variante="testo-leggero-invertito"
                on:click={evento => $abbonamento.passo_corrente -= 1}>
              {t.torna_indietro[$l]}
            </Bottone>
          </div>

          <Modale
              richiudibile={true}
              scorribile={false}
              mostra_eccedenze={true}
              titolo={t.contratto[$l]}
              bind:aperta={modale_contratto_aperta}>
            <div class="modale-contenuto modale-contratto">
              <BarraMessaggio>{t.scorri_contratto[$l]}</BarraMessaggio>
              <div
                  class="contratto"
                  on:scroll={controlla_contratto_scrollato}
                  bind:this={elemento_contratto}>
                {#if $l == "it"}
                  <ContrattoItaliano tipo_struttura={$abbonamento.struttura.tipo}/>
                {:else if $l == "de"}
                  <ContrattoTedesco tipo_struttura={$abbonamento.struttura.tipo}/>
                {:else}
                  <ContrattoInglese tipo_struttura={$abbonamento.struttura.tipo}/>
                {/if}
              </div>
      
              <Bottone
                  tipo="invio"
                  variante="primario"
                  disabilitato={!contratto_scrollato}
                  on:click={evento => $abbonamento.termini_accettati = true}>
                {t.accetta_contratto[$l]} {t.contratto[$l]}
              </Bottone>
            </div>
          </Modale>
        </Modulo>
      {/if}
    </div>
  </RottaNuovoAbbonamento>
</template>

<style>
  :global(div.buono-sconto){
    display: flex;
  }
  :global(div.buono-sconto div.inserimento){
    margin-right: 15px;
    width:        30%;
  }
  :global(div.buono-sconto div.successo){
    margin:       0 auto;
    display:      flex;
    align-items:  center;
  }
  :global(div.buono-sconto div.successo span.blocco-sconti){
    height: 42px;
  }
  :global(div.buono-sconto div.successo span.blocco-sconti.intera){
    height: 100%;
  }
  :global(div.info-costo-integrazione),
  :global(div.testo-piani-personalizzato) {
    margin:     14px 0;
    text-align: justify;
  }
  :global(div.info-costo-integrazione span.scontato) {
    text-decoration:  line-through;
  }
  /* Tabella */
  :global(div.piani-abbonamento table) {
    width:            100%;
    border-spacing:   0;
    border-color:     transparent;
    border-collapse:  collapse;
    position:         relative;
  }
  :global(div.piani-abbonamento table thead),
  :global(div.piani-abbonamento table tbody),
  :global(div.piani-abbonamento table tbody tr) {
    width:    100%;
  }
  :global(div.piani-abbonamento table tbody tr td) {
    font-size:        14px;
    padding:          14px 16px;
    border-top:       none;
    background-color: inherit;
    line-height:      24px;
  }
  :global(div.piani-abbonamento table tbody tr td button) {
    display: inline-block;
  }
  :global(div.piani-abbonamento table thead) {
    margin:         0;
    font-size:      16px;
    line-height:    30px;
    color:          var(--colore-titolo);
    border-bottom:  1px solid var(--colore-linea);
  }
  :global(div.piani-abbonamento table tbody tr:nth-child(2n+3)) {
    background-color: var(--colore-bianco);
  }
  :global(div.piani-abbonamento table thead th:first-child),
  :global(div.piani-abbonamento table tbody tr td:first-child) {
    text-align: start;
    width: calc(100% / 3);
  }
  :global(div.piani-abbonamento table thead th:not(:first-child)),
  :global(div.piani-abbonamento table tbody tr td:not(:first-child)) {
    text-align: center;
    width: calc(100% / 6);
  }
  :global(div.piani-abbonamento table thead th.nome-piano){
    font-weight:    normal;
    text-transform: capitalize;
  }
  :global(div.piani-abbonamento table thead th.nome-piano.selezionato){
    font-weight:    bold;
  }
  :global(div.piani-abbonamento table tbody tr td) {
    color: var(--colore-testo-leggero);
  }
  :global(div.piani-abbonamento table tbody tr td.selezionato) {
    font-weight: bold;
  }
  :global(div.piani-abbonamento table tbody tr td.senza-caratteristica::after) {
    display:          block;
    content:          "";
    height:           2px;
    width:            15px;
    background-color: var(--colore-linea);
    margin:           0 auto;
  }
  :global(div.piani-abbonamento table tbody tr td i.icona) {
    color: var(--colore-verde);
  }
  :global(div.piani-abbonamento table tbody tr:last-child td:not(:first-child)) {
    font-size: 12px;
  }
  :global(div.piani-abbonamento table tbody tr:last-child td span.scontato) {
    text-decoration:  line-through;
    font-size:        11px;
  }
  :global(div.piani-abbonamento table tbody tr:last-child td span.scontato span.prezzo) {
    font-size: 14px;
  }
  :global(div.piani-abbonamento table tbody tr td span.prezzo) {
    font-size: 20px;
  }
  :global(div.modale-contenitore div.modale-contenuto.modale-contratto) {
    height: 65vh;
  }
  :global(div.modale-contenuto.modale-contratto div.contratto) {
    margin-bottom: 15px;
    height: calc(100% - 93px);
    overflow-y: scroll;
    background-color: var(--colore-sfondo-cruscotto);
  }
  :global(div.modale-contenuto.modale-contratto div.contratto + button) {
    margin: 0 auto;
  }
</style>

<script>
  import BarraMessaggio from "@/base/componenti/BarraMessaggio.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Icona from "@/base/componenti/Icona.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import Modale from "@/base/componenti/Modale.svelte"
  import Modulo from "@/base/componenti/Modulo.svelte"
  import TitoloSecondario from "@/base/componenti/TitoloSecondario.svelte"
  import ContrattoItaliano from "@/portale/componenti/abbonamento/contratti/Italiano.svelte"
  import ContrattoTedesco from "@/portale/componenti/abbonamento/contratti/Tedesco.svelte"
  import ContrattoInglese from "@/portale/componenti/abbonamento/contratti/Inglese.svelte"
  import RottaNuovoAbbonamento from "@/portale/componenti/abbonamento/RottaNuovoAbbonamento.svelte";
  import { onMount } from "svelte"
  import { abbonamento } from "@/portale/sorgenti/abbonamento"
  import { dominio } from "@/portale/sorgenti/dominio"
  import { valuta_per_dominio } from "@/portale/sorgenti/valuta_per_dominio"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  const t = avvia_localizzazione(dizionario_piano)
  const DOMINI_SENZA_COUPON = [ "trentino", "bergamo", "padova", "livigno", "genova", "confcommercio-verona", "veneto" ]
  const DOMINI_SENZA_DATI_FATTURAZIONE = [ "trentino", "bergamo", "padova", "livigno", "genova", "ticino" ]
  const DOMINI_CON_BUONOSCONTO_OBBLIGATORIO = [ "ticino" ]
  const COSTO_INTEGRAZIONE = 350.0
  const COSTO_INTEGRAZIONE_TICINO = 385.0
  const PIANI = [
    { nome: "plus",   prezzo: 1000.0 },
    { nome: "pro",    prezzo: 2500.0 } ]

  let in_caricamento = true
  let modale_contratto_aperta = false
  let errori = { codice_buono_sconto: null }
  let contratto_scrollato = false
  let elemento_contratto

  $: piani_calcolati  = PIANI.map(calcola_scontistica).filter(filtra_su_flusso_personalizzato)
  $: piano_scontato = $abbonamento.buono_sconto.sconto_in_euro > 0 || $abbonamento.buono_sconto.sconto_in_percentuale > 0
  $: integrazione_scontata =  $abbonamento.buono_sconto.sconto_in_euro_integrazione > 0 || $abbonamento.buono_sconto.sconto_in_percentuale_integrazione > 0

  // Ripristina i dati di buono sconto.
  function ripristina_buono_sconto() {
    buono_sconto_per_flusso_personalizzato()
    $abbonamento.piano          = piani_calcolati[0]
    $abbonamento.nome_piano     = piani_calcolati[0].nome
    errori.codice_buono_sconto  = null
  }

  ////
  // Validazione del modulo.
  function validatore_modulo() {
    if (!DOMINI_CON_BUONOSCONTO_OBBLIGATORIO.include($dominio)) return

    if ($abbonamento.buono_sconto == null || Object.keys($abbonamento.buono_sconto).length == 0) {
      errori.codice_buono_sconto = t.validazioni.richiesto[$l]
      modale_contratto_aperta = false
    }
  }

  // Ottiene il buono sconto del flusso personalizzato.
  function buono_sconto_per_flusso_personalizzato() {
    switch ($abbonamento.flusso_personalizzato) {
      case "trentino":
      case "bergamo":
        $abbonamento.buono_sconto = { sconto_in_euro: 2500.0, sconto_in_euro_integrazione: 350.0 }
        return
      case "veneto":
      case "bccveneto":
        $abbonamento.buono_sconto = { sconto_in_euro: 1800.0 }
        return
      case "confcommercio-verona":
        $abbonamento.buono_sconto = { sconto_in_euro: 1800.0, sconto_in_euro_integrazione: 350.0 }
        return
      case "padova":
      case "livigno":
      case "genova":
        $abbonamento.buono_sconto = { sconto_in_euro: 1000.0, sconto_in_euro_integrazione: 350.0 }
        return
      case "firenze":
        $abbonamento.buono_sconto = { sconto_in_percentuale: 100.0 }
        return
      default:
        $abbonamento.buono_sconto = {}
        return
    }
  }

  // Valida i dati buono sconto.
  async function valida_buono_sconto() {
    if (!$abbonamento.codice_buono_sconto) return

    in_caricamento = true

    let risposta = await retro.chiama("GET",
      retro.estremi.licenze.buoni_sconto,
      { codice: $abbonamento.codice_buono_sconto })

    if (risposta.stato != 200) {
      ripristina_buono_sconto()
      errori.codice_buono_sconto  = t.non_valido[$l]
    } else {
      errori.codice_buono_sconto  = null
      $abbonamento.buono_sconto   = risposta.contenuto.buono_sconto
    }

    piani_calcolati = PIANI.map(calcola_scontistica).filter(filtra_su_flusso_personalizzato)

    $abbonamento.piano = PIANI.find(piano => piano.nome == $abbonamento.nome_piano)

    in_caricamento = false
  }

  // Filtra piano su flusso personalizzato.
  function filtra_su_flusso_personalizzato(piano) {
    if ($abbonamento.struttura.tipo == "campeggio" && piano.nome != "pro") return false

    switch ($abbonamento.flusso_personalizzato) {
      case "confcommercio-verona":
      case "veneto":
      case "bccveneto":
      case "trentino":
      case "firenze":
      case "bergamo":
      case "ticino":
        if (piano.nome != "pro") return false
        break
      case "padova":
      case "livigno":
      case "genova":
        if (piano.nome != "plus") return false
        break
    }
    return true
  }

  // Valida i dati piano.
  function continua() {
    $abbonamento.passo_corrente = 3
    if (DOMINI_SENZA_DATI_FATTURAZIONE.include($abbonamento.flusso_personalizzato)) {
      if ($abbonamento.passo_raggiunto < 5) $abbonamento.passo_raggiunto = 5
      abbonamento.sovrascrivi($abbonamento)
      navigatore.verso("/nuovo_abbonamento/pagamento")
    } else {
      if ($abbonamento.passo_raggiunto < 3) $abbonamento.passo_raggiunto = 3
      abbonamento.sovrascrivi($abbonamento)
      navigatore.verso("/nuovo_abbonamento/fatturazione")
    }
  }

  // Calcola la scontistica sul piano.
  function calcola_scontistica(piano) {
    if ($abbonamento.buono_sconto.con_periodo_di_prova) {
      piano.in_prova        = true
      piano.scontato        = false
      piano.termine_prova   = $abbonamento.buono_sconto.termine_periodo_di_prova.to_date.format("%d %B %Y")
      piano.prezzo_finale   = 0
    } else if ($abbonamento.buono_sconto.sconto_in_euro > 0) {
      piano.in_prova        = false
      piano.scontato        = true
      piano.prezzo_scontato = piano.prezzo -
        $abbonamento.buono_sconto.sconto_in_euro
      if (piano.prezzo_scontato < 0) piano.prezzo_scontato = 0
      piano.prezzo_finale   = piano.prezzo_scontato
    } else if ($abbonamento.buono_sconto.sconto_in_percentuale > 0) {
      piano.in_prova        = false
      piano.scontato        = true
      piano.prezzo_scontato = piano.prezzo -
        (piano.prezzo * $abbonamento.buono_sconto.sconto_in_percentuale / 100.0)
      if (piano.prezzo_scontato < 0) piano.prezzo_scontato = 0
      piano.prezzo_finale   = piano.prezzo_scontato
    } else {
      piano.in_prova        = false
      piano.scontato        = false
      piano.prezzo_finale   = piano.prezzo
    }

    if ($abbonamento.buono_sconto.sconto_in_euro_integrazione >= COSTO_INTEGRAZIONE) {
      piano.costo_integrazione_scontato = true
      piano.costo_integrazione          = 0
    } else if ($abbonamento.buono_sconto.sconto_in_euro_integrazione > 0) {
      piano.costo_integrazione_scontato = true
      piano.costo_integrazione          = COSTO_INTEGRAZIONE -
        $abbonamento.buono_sconto.sconto_in_euro_integrazione
    } else if ($abbonamento.buono_sconto.sconto_in_percentuale_integrazione > 0) {
      piano.costo_integrazione_scontato = true
      piano.costo_integrazione          = COSTO_INTEGRAZIONE -
        (COSTO_INTEGRAZIONE * $abbonamento.buono_sconto.sconto_in_percentuale_integrazione / 100.0)
    } else {
      piano.costo_integrazione_scontato = false
      if ($dominio == "ticino")
        piano.costo_integrazione          = COSTO_INTEGRAZIONE_TICINO
      else
        piano.costo_integrazione          = COSTO_INTEGRAZIONE
    }

    switch ($abbonamento.flusso_personalizzato) {
      case "veneto":
      case "bccveneto":
        piano.mostra_scontistica = false
        break
      default:
        piano.mostra_scontistica = true
        break
    }

    piano.prezzo_finale = piano.prezzo_finale + piano.costo_integrazione
    return piano
  }

  // Preseleziona piano.
  function preseleziona_piano() {
    if ($abbonamento.piano != null) return
    if ($abbonamento.struttura.tipo == "campeggio") {

      $abbonamento.piano       = PIANI[1]
      $abbonamento.nome_piano  = PIANI[1].nome
      return
    }

    switch ($abbonamento.flusso_personalizzato) {
      case "veneto":
      case "confcommercio-verona":
      case "bccveneto":
      case "trentino":
      case "firenze":
      case "bergamo":
      case "ticino":
        $abbonamento.piano       = PIANI[1]
        $abbonamento.nome_piano  = PIANI[1].nome
        break
      default:
        $abbonamento.piano       = PIANI[0]
        $abbonamento.nome_piano  = PIANI[0].nome
        break
    }
  }

  // Seleziona il piano.
  function seleziona_piano(piano) {
    $abbonamento.nome_piano = piano.nome
    $abbonamento.piano      = piano
  }

  ////
  // Va alla pagina di recupero della password.
  function apri_modale_contratto(evento) {
    modale_contratto_aperta = true
  }

  ////
  // Controlla se il contratto è stato scrollato fino in fondo.
  function controlla_contratto_scrollato() {
    if (elemento_contratto != null &&
      elemento_contratto.scrollTop >= (elemento_contratto.scrollHeight - elemento_contratto.offsetHeight - 10)) {
      contratto_scrollato = true
    }
  }

  // Quando pronto, Imposta piano preselezionato e calcola scontistiche.
  buono_sconto_per_flusso_personalizzato()
  onMount(evento => {
    PIANI.forEach(piano => calcola_scontistica(piano))
    preseleziona_piano()
    in_caricamento = false
  })
</script>

<script context="module">
  export const dizionario_piano = {
    titolo: {
      it: `Piano abbonamento`,
      en: `Subscription plan`,
      de: `Abonnement-Plan`
    },
    inserisci_buono_sconto: {
      it: `Inserisci qui il tuo coupon`,
      en: `Enter your coupon here`,
      de: `Geben Sie hier Ihren Gutschein ein`
    },
    hai_un_buono_sconto: {
      it: `Hai un codice sconto?`,
      en: `Do you have a coupon?`,
      de: `Haben Sie einen Rabatt-Code?`
    },
    verifica: {
      it: `Applica codice sconto`,
      en: `Apply coupon`,
      de: `Rabatt-Code anwenden`
    },
    confronta: {
      it: `Confronta i piani`,
      en: `Compare plans`,
      de: `Tarife vergleichen`
    },
    seleziona: {
      it: `Seleziona`,
      en: `Select`,
      de: `Auswählen`
    },
    selezionato: {
      it: `Selezionato`,
      en: `Selected`,
      de: `Ausgewählt`
    },
    prezzo: {
      it: `Prezzo`,
      en: `Price`,
      de: `Preis`
    },
    accetta_contratto: {
      it: `Accetto i termini e le condizioni del`,
      en: `I accept the terms and conditions of the`,
      de: `Ich akzeptiere die Bedingungen der`
    },
    contratto: {
      it: `Contratto`,
      en: `Contract`,
      de: `Vertrag`
    },
    non_valido: {
      it: `Spiacenti, il buono sconto fornito non è valido`,
      en: `Sorry, the provided coupon is not valid`,
      de: `Entschuldigung, der angegebene Rabattcode ist ungültig`
    },
    periodo_di_prova: {
      it: `In prova fino al`,
      en: `In try & buy until`,
      de: `In Testphase bis`
    },
    cadenza_fatturazione: {
      it: `/anno`,
      en: `/year`,
      de: `/jahr`
    },
    info_costo_integrazione: {
      it: `Questo piano richiede un costo <em>una tantum</em> di integrazione con il tuo PMS di`,
      en: `This plan requires a <em>one-time only</em> integration cost with your PMS of`,
      de: `Dieser Plan erfordert <em>einmalige</em> Integrationskosten für Ihr PMS von`
    },
    nessun_costo_integrazione: {
      it: `L'abbonamento non richiede alcun costo <em>una tantum</em> di integrazione`,
      en: `This plan does not require any integration cost`,
      de: `Dieser Plan erfordert keine Integrationskosten`
    },
    periodo_di_prova_attivato: {
      it: `Applicato periodo di prova fino a`,
      en: `Activated try & buy period until`,
      de: `Aktivierte Testphase bis`
    },
    sconto: {
      it: `Applicato sconto sul canone di`,
      en: `Applied discount on subscription of`,
      de: `Angewandter Rabatt auf das Abonnement von`
    },
    sconto_integrazione: {
      it: `Applicato sconto sul costo di integrazione di`,
      en: `Applied discount on integration costs of`,
      de: `Angewandter Rabatt auf die Integrationskosten von`
    },
    scorri_contratto: {
      it: `Scorri e leggi tutto il contratto prima di accettarlo.`,
      en: `Read the entire contract before accepting it.`,
      de: `Scrollen Sie nach unten und lesen Sie den gesamten Vertrag, bevor Sie ihn akzeptieren.`
    },
    caratteristiche: {
      base: {
        it: `Occupazione, prezzi e relativi indicatori internazionali, posizionamento sul mercato`,
        en: `Occupation, Prices, International KPIs`,
        de: `Beschäftigung, Preise, internationale KPIs`
      },
      permanenza_media: {
        it: `Durata media prenotazione`,
        en: `Average stay`,
        de: `Durchschnittlicher Aufenthalt`
      },
      segmentazioni: {
        it: `Analisi per Trattamento, Canale di Vendita, Segmento di Mercato, Nazionalità degli ospiti`,
        en: `Analysis on Arrangement, Sales Channel, Market Segment, Nationality`,
        de: `Analyse für Arrangements, Vertriebskanal, Marktsegment, Nationalität`
      },
      disdette: {
        it: `KPI disdette`,
        en: `Cancellation KPI`,
        de: `KPI stornieren`
      },
      spit: {
        it: `Statistiche Same Point In Time`,
        en: `SPIT Analysis (Same Point in Time)`,
        de: `SPIT Analyse (Same Point in Time)`
      }
    },
    testi_personalizzati: {
      per_dominio: {
        veneto_1: {
          it: `Il servizio sarà attivo fino al`,
          en: `The service will be available until`,
          de: ``
        },
        veneto_2: {
          it: `, con possibilità di rinnovo`,
          en: `, with the chance to be renewed`,
          de: ``
        },
      }
    }
  }
</script>