<template>
  <div 
      class="legenda-indicatori-multipli"
      class:di-un-singolo-gruppo={statistica.gruppi.length == 1}>
    {#each statistica.indicatori as indicatore, indice}
      {#if indice < numero_indicatori_mostrati}
        <div 
            class="legenda-singolo-indicatore"
            class:dettagliato={indicatore.dettagliato}>
          <div class="titolo-indicatore">
            <Indicatore
                {indicatore}/>
          </div>
    
          <LegendaSerie
              {indicatore}
              {statistica}
              serie={ricava_serie_per_indicatore(indice)}
              on:evidenzia={attiva_disattiva_evidenziazione}
              on:mostra={mostra_serie}
              on:nascondi={nascondi_serie}/>
        </div>
      {/if}
    {/each}
  </div>

  {#if (statistica.indicatori.length > ricava_minimo_indicatori_mostrati())}
    <Bottone
        variante="primario-invertito"
        piccolo={true}
        on:click={mostra_nascondi_indicatori}>
      {#if numero_indicatori_mostrati == ricava_minimo_indicatori_mostrati()}
        {t.mostra_tutte[$localizzazione]}
      {:else}
        {t.nascondi[$localizzazione]}
      {/if}
    </Bottone>
  {/if}

  <Misto
      bind:pronto={pronto}
      bind:grafico={grafico}
      bind:opzioni_highcharts={opzioni_highcharts}/>

  <div
    id={id_univoco}
    class="grafico"
    bind:this={elemento_grafico}/>

  <StruttureContribuenti
      {statistica}
      {serie}/>
</template>

<style>
  :global(div.statistica div.legenda-indicatori-multipli){
    display:    flex;
    flex-flow:  wrap column;
  }
  :global(div.statistica div.legenda-indicatori-multipli.di-un-singolo-gruppo){
    flex-direction: row;
  }
  :global(div.statistica  div.legenda-indicatori-multipli div.legenda-singolo-indicatore){
    border-bottom: 1px solid var(--colore-bordo);
    margin-bottom: 14px;
  }
  :global(div.statistica  div.legenda-indicatori-multipli.di-un-singolo-gruppo div.legenda-singolo-indicatore.dettagliato){
    border-top:     1px solid var(--colore-bordo);
    border-bottom:  1px solid var(--colore-bordo);
    padding-top:    14px;
    margin-bottom:  14px;
  }
  :global(div.statistica  div.legenda-indicatori-multipli div.legenda-singolo-indicatore.dettagliato,
  div.statistica  div.legenda-indicatori-multipli.di-un-singolo-gruppo div.legenda-singolo-indicatore.dettagliato){
    width: 100%;
  }
  :global(div.statistica  div.legenda-indicatori-multipli.di-un-singolo-gruppo div.legenda-singolo-indicatore){
    width:  calc(100% / 3);
    border: none;
  }
  :global(div.statistica  div.legenda-indicatori-multipli div.legenda-singolo-indicatore div.titolo-indicatore){
    text-align:     start;
    font-weight:    500;
    font-size:      15px;
    margin-bottom:  7px;
    padding-left:   14px;
  }
  :global(div.statistica  div.legenda-indicatori-multipli div.legenda-singolo-indicatore div.titolo-indicatore div.indicatore){
    border:   none;
    padding:  0;
    cursor:   default;
  }
  :global(div.statistica  div.legenda-indicatori-multipli div.legenda-singolo-indicatore div.titolo-indicatore div.indicatore:hover){
    background-color: transparent;
  }
  :global(div.grafico) {
    height:      427px;
  }
  :global(.highcharts-credits) {
    display:     none;
  }
  :global(div.grafico img.meteo-grafico){
    height:      1.5rem;
    width:       1.5rem;
  }
</style>

<script>
  import Highcharts from "highcharts"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import LegendaSerie from "@/base/componenti/statistiche/LegendaSerie.svelte"
  import StruttureContribuenti from "@/base/componenti/statistiche/StruttureContribuenti.svelte"
  import Misto from "@/base/componenti/statistiche/grafici/Misto.svelte"
  import Indicatore from "@/base/componenti/statistiche/Indicatore.svelte"
  import { localizzazione } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { sessione_corrente } from "@/portale/sorgenti/sessione"
  import { tick } from "svelte"
  import { onMount } from "svelte"
  import { onDestroy } from "svelte"

  export let statistica = {}
  export let serie = []
  export let anteprima = false

  let atto = anteprima ? "anteprima" : "finale"
  let id_univoco = `${statistica._id}_${serie[0].indice_indicatore}_${atto}`
  let grafico = null
  let grafico_highcharts = null
  let opzioni_highcharts = {}
  let pronto = false
  let elemento_grafico = null
  let numero_indicatori_mostrati = ricava_minimo_indicatori_mostrati()

  const scollega = localizzazione.su_cambio(disegna_grafico)
  const t = avvia_localizzazione(dizionario_grafico_misto)

  ////
  // Determina il tipo di visualizzazione del grafico.
  async function avvia_grafico() {
    if (elemento_grafico == null) return

    console.log("Grafico".colore("verde"), `: nuovo grafico <Misto>.`)

    pronto = false
    await tick()

    grafico = {
      statistica: statistica,
      indicatori: statistica.indicatori,
      serie: serie }
  }

  ////
  // Disegna il grafico.
  function disegna_grafico(arg) {
    if (grafico == null) return
    if (elemento_grafico == null) return
    if (elemento_grafico != null) distruggi_grafico()

    console.log("Grafico".colore("verde"), ": disegno grafico.")

    imposta_colori_etichette()
    opzioni_highcharts.legend = { enabled: false }
    grafico_highcharts = Highcharts.chart(id_univoco, opzioni_highcharts)
  }

  ////
  // Distrugge il grafico.
  function distruggi_grafico(arg) {
    if (grafico == null || grafico_highcharts == null) return
    grafico_highcharts.destroy()
  }

  ////
  // Scollega cambio localizzazione e distrugge grafico.
  function scollega_e_distruggi() {
    scollega()
    distruggi_grafico()
  }

  ////
  // Imposta i colori delle etichette.
  function imposta_colori_etichette() {
    if (grafico == null) return
    if (opzioni_highcharts.xAxis != null && opzioni_highcharts.xAxis.labels != null)
      opzioni_highcharts.xAxis.labels.style = { color: "var(--colore-testo-leggero)" }
    if (opzioni_highcharts.yAxis != null && opzioni_highcharts.yAxis.labels != null)
      opzioni_highcharts.yAxis.labels.style = { color: "var(--colore-testo-leggero)" }
  }

  ////
  // Ricava le serie collegate ad un indice indicatore
  function ricava_serie_per_indicatore(indice) {
    let serie_raggruppate = serie.filter(serie => serie.indice_indicatore == indice)
    
    return serie_raggruppate
  }

  ////
  // Ricava il numero minimo di indicatori che devono essere mostrati
  function ricava_minimo_indicatori_mostrati() {
    let primi_tre_dettagliati = statistica.indicatori.some((indicatore, indice) => {
      return indice < 3 && indicatore.dettagliato == true
    })
    if (primi_tre_dettagliati) return 1
    if (statistica.gruppi.length == 1) return 3
    return 1
  }

  ////
  // Mostra o nasconde gli indicatori a seconda del loro numero e di quello dei gruppi
  function mostra_nascondi_indicatori() {
    switch (numero_indicatori_mostrati) {
      case ricava_minimo_indicatori_mostrati():
        numero_indicatori_mostrati = statistica.indicatori.length
        break;
      
      case statistica.indicatori.length:
        numero_indicatori_mostrati = ricava_minimo_indicatori_mostrati()
        break;
      default:
        numero_indicatori_mostrati = statistica.indicatori.length
        break;
    }
  }

  ////
  // Mostra la serie sul grafico
  function mostra_serie(evento) {
    grafico_highcharts.series[evento.detail.indice].show()
  }

  ////
  // Mostra la serie sul grafico
  function nascondi_serie(evento) {
    grafico_highcharts.series[evento.detail.indice].hide()
  }

  ////
  // Mostra la serie sul grafico
  function attiva_disattiva_evidenziazione(evento) {
    if (evento.detail) {
      grafico_highcharts.series.forEach((serie, indice_serie) => {
        if (indice_serie != evento.detail.indice)
          grafico_highcharts.series[indice_serie].setState("inactive")
      })
    } else {
      grafico_highcharts.series.forEach((serie, indice_serie) => {
        grafico_highcharts.series[indice_serie].setState("normal")
      })
    }
  }

  // Al cambio dei parametri, ridetermina il tipo visualizzazione.
  $: if (serie != null) avvia_grafico()

  // Quando pronto, disegna il grafico.
  $: if (pronto == true) disegna_grafico()

  // Quando pronto, determina il tipo visualizzazione.
  onMount(avvia_grafico)
  // Quando distrutto, elimina il grafico.
  onDestroy(scollega_e_distruggi)
</script>

<script context="module">
  export const dizionario_grafico_misto = {
    mostra_tutte: {
      it: `Mostra tutti gli indicatori`,
      en: `Show all indicators`,
      de: `Alle Indikatoren anzeigen`
    }
  }
</script>