<template>
  <Autenticatore
    {rotte}>
    {#if pagina_pronta}
      <div class="contenuto-barra-laterale">
        {#if !è_nuovo_abbonato || è_rotta_abbonamenti}
          {#if è_rotta_abbonamenti}
            <ProgressoAbbonamento/>
          {:else}          
            <div class="sessione-corrente">
              <Selezione
                  piana={true}
                  ricercabile={true}
                  etichetta={t.la_mia_sessione[$utente_corrente.ruolo][$l]}
                  opzioni={opzioni_sessione}
                  valore={$sessione_corrente._id}
                  on:change={cambia_sessione}/>
            </div>

            <div class="rotte">
              <Collegamento nome_rotta="/">
                {t.nome_rotta_cruscotto[$l]}
              </Collegamento>
              <Collegamento nome_rotta="/rapporti">
                {t.nome_rotta_rapporti[$l]}
              </Collegamento>
              <Collegamento nome_rotta="/eventi">
                {t.nome_rotta_eventi[$l]}
              </Collegamento>
              <Collegamento nome_rotta="/analisi">
                {t.nome_rotta_analisi[$l]}
              </Collegamento>
            </div>
          {/if}
        {/if}

        <div
            class="impostazioni"
            class:margine-nuovo-abbonato={è_nuovo_abbonato}>
          {#if $utente_corrente.ruolo == "gestore_struttura" || è_nuovo_abbonato}
            <div class="impostazione accademia">
              <Collegamento nome_rotta="/accademia">
                {t.nome_rotta_accademia[$l]}
              </Collegamento>
            </div>

            <div class="impostazione gestione-strutture">
              <Collegamento
                  determinatore_rotta_corrente={è_rotta_gestione_strutture}
                  on:click={vai_a_gestione_strutture}>
                {t.nome_rotta_gestione_strutture[$l]}
              </Collegamento>
            </div>
          {/if}
  
          <div class="impostazione account">
            <Collegamento nome_rotta="/profilo/anagrafica">
              {t.nome_rotta_account[$l]}
            </Collegamento>
          </div>
        </div>
      </div>
    {/if}
  </Autenticatore>

  <Modale
      richiudibile={true}
      espansa={true}
      titolo={t.benvenuto[$l]}
      bind:aperta={modale_primo_accesso_aperta}
      on:chiusa={conferma_primo_accesso}>
    <div class="modale-contenuto">
      {@html t.testo_modale[$l]}
      <div class="azioni">
        <Bottone
            variante="primario"
            on:click={evento => window.open("https://preventivi.hbenchmark.it/HBenchmark_Comunicazionenuovapiattaforma.pdf")}>
          {t.scopri_novità[$l]}
        </Bottone>

        <Bottone
            variante="primario"
            on:click={evento => window.open("https://youtu.be/6_oTbT5o_Nc")}>
          {t.video_tutorial[$l]}
        </Bottone>
      </div>
      <div class="flessibile giustificati-centro"><small><em>{@html t.sottotesto_modale[$l]}</em></small></div>
    </div>
  </Modale>

  <svelte:window on:logout={pulisci_sessioni}/>
</template>

<style>
  /* Barra laterale. */
  :global(div.contenuto-barra-laterale) {
    height:           100%;
    display:          flex;
    flex-direction:   column;
  }

  /* Sessione corrente. */
  :global(div.contenuto-barra-laterale > div.localizzatore),
  :global(div.contenuto-barra-laterale > div.sessione-corrente) {
    padding-left:     7px;
  }
  :global(div.contenuto-barra-laterale > div.sessione-corrente label) {
    font-weight:      800;
    font-size:        16px;
    line-height:      30px;
  }
  :global(div.contenuto-barra-laterale > div.sessione-corrente div.contenitore-valore) {
    height:           40px;
    font-size:        16px;
    position:         relative;
  }
  :global(div.contenuto-barra-laterale > div.sessione-corrente div.contenitore-valore div.valore-selezionato) {
    margin-right: 35px;
  }
  :global(div.contenuto-barra-laterale > div.sessione-corrente .contenitore-valore .contenitore-icona) {
    height:           40px;
    top:              0!important;
    right:            var(--padding-base);
  }

  /* Rotte. */
  :global(div.contenuto-barra-laterale > div.rotte),
  :global(div.contenuto-barra-laterale > div.impostazioni) {
    position:         relative;
  }
  :global(div.contenuto-barra-laterale > div.impostazioni.margine-nuovo-abbonato) {
    margin-top: auto;
  }
  :global(div.contenuto-barra-laterale > div.rotte) {
    flex-grow:        1;
  }
  :global(div.contenuto-barra-laterale > div.impostazioni::before) {
    content: "";
    background-color: var(--colore-linea);
    display: block;
    margin: 0 10px;
    height: 1px;
  }
  :global(div.contenuto-barra-laterale > div.impostazioni > div.impostazione) {
    height:           40px;
  }
  :global(div.contenuto-barra-laterale > div.impostazioni > div.impostazione:first-child) {
    margin-top: 10px;
  }
  :global(div.modale-contenuto div.azioni){
    display: flex;
    justify-content: space-around;
    margin: 45px 0 25px;
  }
</style>

<script>
  import Accesso from "@/base/rotte/Accesso.svelte"
  import Autenticatore from "@/base/rotte/Autenticatore.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Collegamento from "@/base/componenti/barra_laterale/Collegamento.svelte"
  import Localizzatore from "@/base/componenti/Localizzatore.svelte"
  import Modale from "@/base/componenti/Modale.svelte"
  import Selezione from "@/base/componenti/Selezione.svelte"
  import ProgressoAbbonamento from "@/portale/componenti/abbonamento/Progresso.svelte"
  import Abbonamenti from "@/portale/rotte/gestione_struttura/Abbonamenti.svelte"
  import Accademia from "@/portale/rotte/Accademia.svelte"
  import Anagrafica from "@/portale/rotte/profilo/Anagrafica.svelte"
  import Analisi from "@/portale/rotte/Analisi.svelte"
  import Codifiche from "@/portale/rotte/gestione_struttura/Codifiche.svelte"
  import Concorrenti from "@/portale/rotte/gestione_struttura/Concorrenti.svelte"
  import ControlloDati from "@/portale/rotte/gestione_struttura/ControlloDati.svelte"
  import Cruscotto from "@/portale/rotte/Cruscotto.svelte"
  import DatiFatturazione from "@/portale/rotte/profilo/DatiFatturazione.svelte"
  import Eventi from "@/portale/rotte/Eventi.svelte"
  import GestioneStrutture from "@/portale/rotte/GestioneStrutture.svelte"
  import NuovoAbbonamentoFatturazione from "@/portale/rotte/abbonamenti/Fatturazione.svelte"
  import NuovoAbbonamentoGestionale from "@/portale/rotte/abbonamenti/Gestionale.svelte"
  import NuovoAbbonamentoPagamento from "@/portale/rotte/abbonamenti/Pagamento.svelte"
  import NuovoAbbonamentoPiano from "@/portale/rotte/abbonamenti/Piano.svelte"
  import NuovoAbbonamentoRiprendi from "@/portale/rotte/abbonamenti/Riprendi.svelte"
  import NuovoAbbonamentoStruttura from "@/portale/rotte/abbonamenti/Struttura.svelte"
  import Pagamento from "@/portale/rotte/gestione_struttura/Pagamento.svelte"
  import PagamentoAnnullato from "@/portale/rotte/abbonamenti/pagamento/Annullato.svelte"
  import PagamentoCompletato from "@/portale/rotte/abbonamenti/pagamento/Completato.svelte"
  import Palette from "@/portale/rotte/Palette.svelte"
  import Prodotti from "@/portale/rotte/gestione_struttura/Prodotti.svelte"
  import Rapporti from "@/portale/rotte/Rapporti.svelte"
  import RecuperoPassword from "@/base/rotte/RecuperoPassword.svelte"
  import Registrazione from "@/base/rotte/Registrazione.svelte"
  import Struttura from "@/portale/rotte/gestione_struttura/Struttura.svelte"
  import Trasmissioni from "@/portale/rotte/gestione_struttura/Trasmissioni.svelte"
  import { utente_corrente } from "@/base/sorgenti/svuby"
  import { sessione_corrente } from "@/portale/sorgenti/sessione"
  import { sessioni_selezionabili } from "@/portale/sorgenti/sessione"
  import { gruppi_comparabili } from "@/portale/sorgenti/sessione"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { fade } from "svelte/transition"
  import { onDestroy } from "svelte"
  import { onMount } from "svelte"
  import { tick } from "svelte"
  import { querystring } from "svelte-spa-router"
  import { location } from "svelte-spa-router"

  const t = avvia_localizzazione(dizionario_componente_principale)
  const LOCALIZZAZIONI = [ "it", "en", "de" ]
  const rotte_utente_abbonato = {
    "/": Cruscotto,
    "/eventi": Eventi,
    "/rapporti": Rapporti,
    "/analisi": Analisi,
    "/profilo/anagrafica": Anagrafica,
    "/profilo/dati_fatturazione": DatiFatturazione,
    "/gestione_strutture": GestioneStrutture,
    "/gestione_strutture/struttura": Struttura,
    "/gestione_strutture/concorrenti": Concorrenti,
    "/gestione_strutture/prodotti": Prodotti,
    "/gestione_strutture/codifiche": Codifiche,
    "/gestione_strutture/controllo_dati": ControlloDati,
    "/gestione_strutture/trasmissioni": Trasmissioni,
    "/gestione_strutture/abbonamenti": Abbonamenti,
    "/gestione_strutture/pagamento": Pagamento,
    "/nuovo_abbonamento/riprendi": NuovoAbbonamentoRiprendi,
    "/nuovo_abbonamento/struttura": NuovoAbbonamentoStruttura,
    "/nuovo_abbonamento/gestionale": NuovoAbbonamentoGestionale,
    "/nuovo_abbonamento/piano": NuovoAbbonamentoPiano,
    "/nuovo_abbonamento/fatturazione": NuovoAbbonamentoFatturazione,
    "/nuovo_abbonamento/pagamento": NuovoAbbonamentoPagamento,
    "/nuovo_abbonamento/pagamento/completato": PagamentoCompletato,
    "/nuovo_abbonamento/pagamento/annullato": PagamentoAnnullato,
    "/accademia": Accademia,
    "/palette": Palette,
    "/accesso": Accesso,
    "/registrazione": Registrazione,
    "/recupero": RecuperoPassword }
  const rotte_utente_non_abbonato = {
    "/nuovo_abbonamento/riprendi": NuovoAbbonamentoRiprendi,
    "/nuovo_abbonamento/struttura": NuovoAbbonamentoStruttura,
    "/nuovo_abbonamento/gestionale": NuovoAbbonamentoGestionale,
    "/nuovo_abbonamento/piano": NuovoAbbonamentoPiano,
    "/nuovo_abbonamento/fatturazione": NuovoAbbonamentoFatturazione,
    "/nuovo_abbonamento/pagamento": NuovoAbbonamentoPagamento,
    "/nuovo_abbonamento/pagamento/completato": PagamentoCompletato,
    "/nuovo_abbonamento/pagamento/annullato": PagamentoAnnullato,
    "/profilo/anagrafica": Anagrafica,
    "/profilo/dati_fatturazione": DatiFatturazione,
    "/accademia": Accademia,
    "/accesso": Accesso,
    "/registrazione": Registrazione,
    "/recupero": RecuperoPassword,
    "/*": GestioneStrutture }
  const rotte_solo_account = {
    "/nuovo_abbonamento/riprendi": NuovoAbbonamentoRiprendi,
    "/nuovo_abbonamento/struttura": NuovoAbbonamentoStruttura,
    "/nuovo_abbonamento/gestionale": NuovoAbbonamentoGestionale,
    "/nuovo_abbonamento/piano": NuovoAbbonamentoPiano,
    "/nuovo_abbonamento/fatturazione": NuovoAbbonamentoFatturazione,
    "/nuovo_abbonamento/pagamento": NuovoAbbonamentoPagamento,
    "/nuovo_abbonamento/pagamento/completato": PagamentoCompletato,
    "/nuovo_abbonamento/pagamento/annullato": PagamentoAnnullato,
    "/gestione_strutture": GestioneStrutture,
    "/gestione_strutture/struttura": Struttura,
    "/gestione_strutture/concorrenti": Concorrenti,
    "/gestione_strutture/prodotti": Prodotti,
    "/gestione_strutture/codifiche": Codifiche,
    "/gestione_strutture/controllo_dati": ControlloDati,
    "/gestione_strutture/trasmissioni": Trasmissioni,
    "/gestione_strutture/abbonamenti": Abbonamenti,
    "/gestione_strutture/pagamento": Pagamento,
    "/profilo/anagrafica": Anagrafica,
    "/profilo/dati_fatturazione": DatiFatturazione,
    "/accademia": Accademia,
    "/accesso": Accesso,
    "/registrazione": Registrazione,
    "/recupero": RecuperoPassword,
    "/*": GestioneStrutture }

  let pagina_pronta = false
  let modale_primo_accesso_aperta = false
  let primo_accesso = false
  let è_rotta_abbonamenti = false
  let opzioni_sessione = []
  let rotte = {} // rotta default 404?

  let osservatore = new MutationObserver((mutazioni, osservatore) => {
    if (document.getElementById("tidio-chat") == null) return

    mostra_nascondi_tidio_chat()
    osservatore.disconnect()
  })

  $: è_gestore_struttura = $utente_corrente != null && $utente_corrente.ruolo == "gestore_struttura" &&
    $utente_corrente.id_strutture.length == 0
  $: è_ospite = $utente_corrente != null && $utente_corrente.ruolo == "ospite"
  // $: è_nuovo_abbonato = è_gestore_struttura || è_ospite
  // Modifiche per rendere la piattaforma solo account
  let è_nuovo_abbonato = true

  $: if (è_nuovo_abbonato) {
    // rotte = rotte_utente_non_abbonato
    // Modifiche per rendere la piattaforma solo account
    rotte = rotte_solo_account
  }else {
    rotte = rotte_utente_abbonato
  }

  ////
  // Richiede i gruppi comparabili.
  async function richiedi_gruppi_comparabili() {
    if ($utente_corrente == null) return
    await tick()
    // if (è_nuovo_abbonato) return pagina_pronta = true
    // Modifiche per rendere la piattaforma solo account
    if (è_gestore_struttura || è_ospite) return pagina_pronta = true

    pagina_pronta = false

    let risposta_gruppi = await retro.chiama(
      "GET",
      retro.estremi.portale.gruppi)
    
    let gruppi = risposta_gruppi.contenuto.gruppi_comparabili
    let sessioni = ottieni_sessioni_selezionabili_da(gruppi)

    if ($utente_corrente.ruolo == "gestore_struttura") {
      let risposta_abbonamenti = await retro.chiama(
        "GET",
        retro.estremi.licenze.abbonamenti)
  
      let abbonamenti = risposta_abbonamenti.contenuto.abbonamenti
  
      let risposta_clienti = await retro.chiama(
        "GET",
        retro.estremi.licenze.clienti)
  
      let clienti = risposta_clienti.contenuto.clienti

      sessioni = sessioni.map(sessione => {
        let abbonamenti_sessione = abbonamenti.filter(abbonamento => abbonamento.id_struttura == sessione._id)
        if (abbonamenti_sessione.length > 0 ) return { ...sessione, abbonamenti: abbonamenti_sessione }
        return { ...sessione }
      })
  
      sessioni = sessioni.map(sessione => {
        if (sessione.abbonamenti == null) return { ...sessione }
        let clienti_sessione = []
        sessione.abbonamenti.forEach(abbonamento => {
          let cliente_abbonamento = clienti.filter(cliente => cliente._id == abbonamento.id_cliente)
          clienti_sessione = [ ...clienti_sessione, ...cliente_abbonamento]
        })
        if (clienti_sessione.length > 0 ) return { ...sessione, clienti: clienti_sessione }
        return { ...sessione }
      })
    }

    let prima_sessione = sessioni[0]

    if ($sessione_corrente == null || $sessione_corrente._id == null)
      sessione_corrente.cambia_in(prima_sessione)

    sessioni_selezionabili.cambia_in(sessioni)
    gruppi_comparabili.cambia_in(gruppi)

    opzioni_sessione = sessioni.map(sessione => ({
      valore: sessione._id,
      testo: sessione.nome
    }))

    console.log(`Sessione corrente: ${$sessione_corrente.nome.colore("magenta")}`)
    pagina_pronta = true
  }

  ////
  // Pulisce le sessioni.
  function pulisci_sessioni() {
    sessione_corrente.cambia_in({})
    gruppi_comparabili.cambia_in([])
  }

  ////
  // Ottiene le sessioni selezionabili dall'utente corrente.
  function ottieni_sessioni_selezionabili_da(gruppi) {
    switch ($utente_corrente.ruolo) {
    case "gestore_struttura":
      return gruppi.filter(gruppo => gruppo.collezione == "strutture")
    case "operatore_del_territorio":
      return gruppi.filter(gruppo => gruppo.collezione == "territori")
    case "operatore_della_zona":
      return gruppi.filter(gruppo => gruppo.collezione == "zone" && $utente_corrente.id_zone.includes(gruppo._id))
    default:
      console.warn("// DA IMPLEMENTARE!")
    }
  }

  ////
  // Cambia la sessione corrente.
  function cambia_sessione(evento) {
    let id_sessione_selezionata = evento.detail.valore
    if (id_sessione_selezionata == $sessione_corrente._id) return

    let sessione_selezionata = $sessioni_selezionabili.find(sessione => (
      sessione._id == id_sessione_selezionata
    ))

    if (sessione_selezionata == null) return
    sessione_corrente.cambia_in(sessione_selezionata)
    console.log(`Sessione corrente: ${sessione_selezionata.nome.colore("magenta")}`)
  }

  ////
  // Va alle impostazioni.
  function vai_a_gestione_strutture() {
    navigatore.verso("/gestione_strutture")
  }

  ////
  // Determina se è una delle rotte impostazioni.
  function è_rotta_gestione_strutture(nuova_rotta) {
    return nuova_rotta.include("/gestione_strutture")
  }

  ////
  // Torna al cruscotto.
  function torna_a_cruscotto() {
    navigatore.verso("/")
  }

  ////
  // Deposita variabile primo accesso.
  function conferma_primo_accesso() {
    deposito_locale.deposita("primo_accesso", true)
  }

  ////
  // Mostra o nasconde la chat Tidio.
  function mostra_nascondi_tidio_chat() {
    let elemento_chat = document.getElementById("tidio-chat")

    if (elemento_chat == null) return

    if ($location.includes("nuovo_abbonamento")) {
      elemento_chat.classList.add("nascosta")
    } else {
      elemento_chat.classList.remove("nascosta")
    }
  }

  onMount(() => {
    // Di default a true in modo da disattivare la modale  --V
    primo_accesso = deposito_locale.prendi("primo_accesso", true)

    if (!primo_accesso && $utente_corrente != null && !è_nuovo_abbonato) modale_primo_accesso_aperta = true

    osservatore.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false
    })
  })

  // Al cambio di utente, richiede i gruppi comparabili.
  const scollega_utente = utente_corrente.su_cambio(richiedi_gruppi_comparabili)
  // Al cambio di rotta, verifica se la rotta corrente è abbonamenti
  const scollega_navigatore = navigatore.rotta_corrente.su_cambio(nuova_rotta => {
    è_rotta_abbonamenti = nuova_rotta.include("/nuovo_abbonamento/")
    mostra_nascondi_tidio_chat()
  })
  // Quando distrutto, scollega i cambi.
  onDestroy(scollega_utente, scollega_navigatore)
</script>

<script context="module">
  export const dizionario_componente_principale = {
    la_mia_sessione: {
      gestore_struttura: {
        it: `La Mia Struttura`,
        en: `My Property`,
        de: `Mein Betrieb`
      },
      operatore_del_territorio: {
        it: `Il Mio Territorio`,
        en: `My Territory`,
        de: `Mein Territorium`
      },
      operatore_della_zona: {
        it: `La Mia Zona`,
        en: `My Zone`,
        de: `Meine Zone`
      },
    },
    benvenuto: {
      it: `Benvenuto nella nuova versione di HBenchmark`,
      en: `Welcome to the new version of HBenchmark`,
      de: `Willkommen bei der neuen Version von HBenchmark`
    },
    testo_modale: {
      it: `<p>Ti diamo il benvenuto in questa nuova versione della piattaforma grazie alla quale potrai ottenere
        delle analisi ancora più dettagliate sulla tua struttura e sul tuo mercato di riferimento.</p>
        <p>Abbiamo preparato dei contenuti che ti guideranno alla scoperta di tutte le novità e un video tutorial
        che ti spiegherà nel dettaglio come utilizzare il nuovo configuratore, ovvero il cuore pulsante di questa nuova
        versione di HBenchmark.</p>`,
      en: `<p>We welcome you to this new version of the platform thanks to which you will be able to obtain even more
        detailed analyzes on your structure and on your target market.</p>
        <p>We have prepared content that will guide you to discover all the news and a video tutorial that will explain
        in detail how to use the new configurator, which is the beating heart of this new version of HBenchmark.</p>`,
      de: `<p>Wir begrüßen Sie zu dieser neuen Version der Plattform, dank der Sie noch detailliertere Analysen zu
        Ihrem Betrieb und Ihrem Zielmarkt erhalten.</p>
        <p>Wir haben Inhalte vorbereitet, die Sie durch alle Neuigkeiten führen, und ein Video-Tutorial, das die
        Verwendung des neuen Konfigurators detailliert erklärt, welcher das Herz dieser neuen Version von HBenchmark
        ist.</p>`
    },
    scopri_novità: {
      it: `Scopri tutte le novità`,
      en: `Discover what's new`,
      de: `Entdecken Sie, was es Neues gibt`
    },
    video_tutorial: {
      it: `Video tutorial configuratore`,
      en: `Video tutorial configurator`,
      de: `Video-Tutorial Konfigurator`
    },
    sottotesto_modale: {
      it: `Per qualsiasi richiesta non esitare a scriverci a
        <a href="mailto:supporto@hbenchmark.it">supporto@hbenchmark.it</a>`,
      en: `For any request do not hesitate to write to us at
        <a href="mailto:supporto@hbenchmark.it"> supporto@hbenchmark.it </a>`,
      de: `Für jede Anfrage zögern Sie nicht, uns an
        <a href="mailto:supporto@hbenchmark.it"> supporto@hbenchmark.it </a> zu schreiben`
    },
    nome_rotta_cruscotto: {
      it: `Dashboard`,
      en: `Dashboard`,
      de: `Dashboard`
    },
    nome_rotta_rapporti: {
      it: `Report`,
      en: `Reports`,
      de: `Berichte`
    },
    nome_rotta_eventi: {
      it: `Eventi`,
      en: `Events`,
      de: `Veranstaltungen`
    },
    nome_rotta_account: {
      it: `Il mio Account`,
      en: `My account`,
      de: `Mein Konto`
    },
    nome_rotta_accademia: {
      it: `Academy`,
      en: `Academy`,
      de: `Academy`
    },
    nome_rotta_gestione_strutture: {
      it: `Gestione Strutture`,
      en: `Facilities Management`,
      de: `Gebäudemanagement`
    },
    nome_rotta_analisi: {
      it: `Intelligence`,
      en: `Intelligence`,
      de: `Intelligenz`
    }
  }
</script>
