<template>
  {#if sottoscrivente}
    <BarraMessaggio>
      Contratto sottoscritto da <b>{sottoscrivente.nome}</b> il giorno <b>{sottoscrivente.sottoscritto_il.format("%d %B %Y")}</b>
    </BarraMessaggio>
  {/if}
  
  <p class="c0"><span class="c1">Sehr geehrte Kundinnen und Kunden, herzlich willkommen auf der
    HBenchmark-Plattform.</span></p>
  <p class="c0"><span class="c1">HBenchmark srl mit Rechtssitz in Italien, Altavilla Vicentina, via Retrone n. 16, (VI)
      - USt-IdNr. und Steuernummer: 03998320240 - Tel n. 044427620, E-Mail </span><span class="c7"><a class="c5"
        href="mailto:info@hbenchmark.it">info@hbenchmark.it</a></span><span class="c1">&nbsp;Legal Mail </span><span
      class="c7"><a class="c5" href="mailto:hbenchmark@legalmail.it">hbenchmark@legalmail.it</a></span><span
      class="c1">, hat gemeinsam mit anderen Unternehmen der Develon-Gruppe diese Plattform erstellt und entwickelt. Sie
      wird mit einer propriet&auml;ren Software verwaltet und ist professionellen Anbietern gastgewerblicher Leistungen,
      wie Hoteliers und Campingplatzbetreibern, vorbehalten.</span></p>
  <p class="c0"><span class="c1">Wie aus der &bdquo;Brosch&uuml;re HB&ldquo; hervorgeht, die Sie mit der entsprechenden
      Taste angeklickt haben [falls noch nicht geschehen, bitten wir Sie, dies vor dem Weiterlesen zu tun], handelt es
      sich bei der Plattform HBenchmark um eine webbasierte kollaborative Plattform, welche aggregierte Informationen
      normalisiert und organisiert. Aus diesen erschlie&szlig;en ihre Algorithmen spezifische Leistungskennzahlen (KPI
      oder Key Performance Indicators) f&uuml;r das Gastgewerbe und vergleichen diese Indikatoren, um die
      Preisoptimierung zu erleichtern, dynamische Preisstrategien zu unterst&uuml;tzen, dem Unternehmensrating
      Glaubw&uuml;rdigkeit zu verleihen und jede Investitionshypothese mit dem n&ouml;tigen Hintergrundwissen zu
      bearbeiten.</span></p>
  <p class="c0"><span class="c1">Dieser Abonnementvertrag (nachfolgend &bdquo;</span><span
      class="c3">Vertrag</span><span class="c1">&ldquo;), in Verbindung mit der </span><span class="c7"><a class="c5"
        href="https://www.iubenda.com/privacy-policy/7839661">Datenschutzerkl&auml;rung</a></span><span
      class="c1">&nbsp;und der </span><span class="c7"><a class="c5"
        href="https://preventivi.hbenchmark.it/{tipo_struttura == "hotel" ? "brochure-hotel_DE.pdf" : "flyer-camping_DE.pdf"}" target="_blank">Brosch&uuml;re
        HBenchmark</a></span><span class="c1">&nbsp;als dessen weitere Bestandteile, enth&auml;lt die Bedingungen,
      welche Ihren Zugriff auf die Plattform und Ihre Nutzung der geforderten Dienstleistungen regulieren und bildet in
      Verbindung mit dem Antrags- und Zahlungsformular die rechtliche Vereinbarung, anhand derer die Beziehung zwischen
      HBenchmark und Ihrer Person (wenn Sie Einzelunternehmer sind) oder dem von Ihnen vertretenen Rechtssubjekt
      geregelt wird. </span></p>
  <p class="c0"><span class="c1">Der Vertrag zwischen HBenchmark und Ihrer Person gilt als abgeschlossen und wirksam,
      sobald Sie das Verfahren zum Erhalt des Abonnements erfolgreich fertiggestellt und die entsprechende Geb&uuml;hr
      entrichtet haben.</span></p>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c3">Art. 1. &nbsp;Begriffsbestimmungen</span><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c1">Die untenstehend angegebenen Begriffe haben im Verlauf des Vertrages die nachfolgend
      zugewiesene Bedeutung</span></p>
  <p class="c0"><span class="c3">Aktivit&auml;tsdaten</span><span class="c1">: Daten Ihrer Aktivit&auml;t,
      einschlie&szlig;lich Firmenname, USt-IdNr., Firmensitz, E-Mail-Adresse, PEC (zertifizierte E-Mail-Adresse),
      Telefonnummer, gesetzlicher Vertreter usw., welche Sie im Formular f&uuml;r das Abonnement eintragen m&uuml;ssen.
    </span></p>
  <p class="c0"><span class="c3">Reservierungsinformationen</span><span class="c1">: Informationen im Zusammenhang mit
      den Reservierungen, die im Verwaltungsprogramm des Abonnenten hinterlegt und an HB &uuml;bermittelt werden, wie z.
      B. folgende: Name der Einrichtung (Ihr Hotel oder Ihre Aktivit&auml;t), Reservierung (ohne personenbezogene
      Daten), Aufenthaltsdatum, Reservierungsdatum, Anreisedatum, Abreisedatum, Anzahl der reservierten Zimmer, Anzahl
      der erwachsenen G&auml;ste, Anzahl der Kinder, Zimmertyp, Beschreibung der Serviceleistung, Wert der Reservierung
      (Zimmer + Serviceleistung), Status der Reservierung, Stornierungsdatum (bei stornierte Reservierung),
      Nationalit&auml;t der Reservierung, Code des Reservierungskanals (direkt, Internet, TO usw.) sowie Code des
      Marktsektors der Reservierung. Wir erhalten diese Informationen ausschlie&szlig;lich in anonymisierter Form, d. h.
      ohne, dass es uns m&ouml;glich ist, mit diesen Informationen Daten zur Identifizierung von Personen zu
      verkn&uuml;pfen. </span></p>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c3">Aggregierte Informationen</span><span class="c1">: Reservierungsinformationen mehrerer
      Abonnenten, die in anonymisierter Form normalisiert und aggregiert werden. </span></p>
  <p class="c0"><span class="c3">Pers&ouml;nliche KPI</span><span class="c1">: Leistungskennzahlen, Statistiken und
      Berichte, welche die Algorithmen von HBenchmark aus den Reservierungsinformationen jedes einzelnen Abonnenten
      verarbeiten/extrahieren; dieser Vorgang wird in der </span><span class="c7"><a class="c5"
        href="https://preventivi.hbenchmark.it/{tipo_struttura == "hotel" ? "brochure-hotel_DE.pdf" : "flyer-camping_DE.pdf"}" target="_blank">Brosch&uuml;re</a></span><span
      class="c1">&nbsp;detaillierter beschrieben. </span></p>
  <p class="c0"><span class="c3">Aggregierte KPI</span><span class="c1">: Leistungskennzahlen, Statistiken, Vergleiche
      und Berichte, welche die Algorithmen von HBenchmark aus den </span><span class="c1">Aggregierten
      Daten/Informationen </span><sup><a href="#cmnt1" id="cmnt_ref1">[a]</a></sup><span
      class="c1">verarbeiten/extrahieren; dieser Vorgang wird in der Brosch&uuml;re () detaillierter beschrieben. Einige
      aggregierte KPIs k&ouml;nnen den Entscheidungstr&auml;gern, mit denen HB zusammenarbeitet, zug&auml;nglich gemacht
      werden. </span></p>
  <p class="c0"><span class="c3">HB:</span><span class="c1">&nbsp;Benchmark-Plattform f&uuml;r das Gastgewerbe, die in
      Anhang B) detaillierter beschrieben wird. </span></p>
  <p class="c0"><span class="c3">Abonnement:</span><span class="c1">&nbsp;Online- oder Offline-Verfahren, welches im
      Anhang B beschrieben wird und mit dem der Kunde f&uuml;r einen mit der gezahlten Geb&uuml;hr
      &uuml;bereinstimmenden Zeitraum auf die HB-Plattform zugreifen kann. </span></p>
  <p class="c0"><span class="c3">Abonnent:</span><span class="c1">&nbsp;Im Tourismus t&auml;tiges Rechtssubjekt, bei dem
      es sich sowohl um eine nat&uuml;rliche als auch juristische Person handeln kann, die das Verfahren zum Erhalt des
      Abonnements abgeschlossen hat und f&uuml;r die Dauer des Abonnements Zugriff auf die HB-Plattform besitzt. </span>
  </p>
  <p class="c0"><span class="c3">API-Schnittstelle:</span><span class="c1">&nbsp;Diese werden auch als API REST
      (Representational State Transfer) bezeichnet und sind eine Gesamtheit von Schnittstellen und Verfahren, die HB
      bereitstellt, damit das Hotel-Verwaltungssystem (PMS) &uuml;ber einen verschl&uuml;sselten und
      passwortgesch&uuml;tzten Kanal eine Abfrage und Kollaboration mit der Plattform erm&ouml;glicht. </span></p>
  <p class="c0"><span class="c3">Hotel-Verwaltungssystem:</span><span class="c1">&nbsp;Auch als PMS bezeichnetes System,
      mithilfe dessen das Hotel die Planung der Zimmer, die Check-in- und Check-out-Aktivit&auml;ten, die Zimmerpreise
      sowie die mit der Reservierung und Rechnungsstellung verkn&uuml;pften Serviceleistungen verwaltet. </span></p>
  <p class="c0"><span class="c3">Brosch&uuml;re:</span><span class="c1">&nbsp;Brosch&uuml;re, welche die von HB
      bereitgestellten Dienstleistungen erl&auml;utert und Ihnen &uuml;ber den folgenden </span><span class="c7"><a
        class="c5"
        href="https://preventivi.hbenchmark.it/{tipo_struttura == "hotel" ? "brochure-hotel_DE.pdf" : "flyer-camping_DE.pdf"}" target="_blank">Link</a></span><span
      class="c11">&nbsp;zur Verf&uuml;gung steht.</span><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c3">Competitive Set:</span><span class="c1">&nbsp;IT-Tool, das es Ihnen erm&ouml;glicht,
      die KPIs Ihrer Einrichtung mit den aggregierten einer Gruppe anderer Einrichtungen &ndash; nicht weniger als 5
      &ndash; zu vergleichen, die Sie selbst aus der Liste der Abonnenten ausw&auml;hlen k&ouml;nnen. Die Tatsache, dass
      Sie wie jeder andere Benutzer die Einrichtungen, die zur Gruppe geh&ouml;ren werden, mit dem entsprechenden Namen
      ausw&auml;hlen k&ouml;nnen, bedeutet nicht, dass jemand aus den KPIs der Gruppe die Leistung einer oder jeder der
      Komponenten entnehmen oder extrahieren kann. Die Gesetze der Statistik setzen voraus, dass wenn eine Gruppe aus
      mindestens 4 Einrichtungen besteht, die Leistung einer einzelnen Einrichtung nicht extrapoliert werden kann. Als
      Vorsichtsma&szlig;nahme haben wir beschlossen, diese Mindestanzahl auf 5 zu erh&ouml;hen. </span></p>
  <p class="c0"><span class="c3">&nbsp;</span><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c3">Art. 2. Abonnement und Zugriffsmodalit&auml;ten</span><span class="c1">&nbsp;</span>
  </p>
  <p class="c0"><span class="c1">F&uuml;r den Zugriff auf die Plattform und die Pers&ouml;nlichen und Aggregierten KPIs
      m&uuml;ssen Sie ein Abonnement f&uuml;r die HB-Plattform abschlie&szlig;en. Klicken Sie dazu auf den Link
    </span><span class="c7"><a class="c5"
        href="http://portal.hbenchmark.it" target="_blank">http://portal.hbenchmark.it</a></span><span
      class="c1">&nbsp;und befolgen Sie das gef&uuml;hrte Verfahren. Bitte beachten Sie, dass das Verfahren so lange
      blockiert ist, bis Sie die Vertragsunterlagen gelesen, durchgesehen und akzeptiert haben. Wir m&ouml;chten Sie
      bitten, die Klauseln 4 (Verarbeitung der Reservierungsinformationen und Vertraulichkeitsverpflichtungen) und 6
      (Datenschutz und Einwilligung) besonders aufmerksam zu lesen. </span></p>
  <p class="c0"><span class="c1">2.1. In dieser Phase werden Sie aufgefordert, sowohl einige personenbezogene als auch
      Ihre Aktivit&auml;t betreffende Daten einzugeben, die dazu dienen, Sie in die Abonnentenliste aufzunehmen. Die
      Richtigkeit, Wahrhaftigkeit und Vollst&auml;ndigkeit der Aktivit&auml;tsdaten, um deren Bereitstellung wir Sie
      bitten, ist f&uuml;r die Erbringung unserer Dienstleistungen von wesentlicher Bedeutung.</span></p>
  <p class="c0"><span class="c1">Sollten wir feststellen, dass die von Ihnen eingegebenen Aktivit&auml;tsdaten nicht der
      Wahrheit entsprechen und nicht korrekt sind, was auch im Zuge einer sp&auml;teren &Uuml;berpr&uuml;fung geschehen
      kann, k&ouml;nnen wir Ihren Zugriff auf die HB-Plattform fristlos sperren.</span></p>
  <p class="c0"><span class="c1">2.2. An einem bestimmten Punkt werden Sie aufgefordert, ein Fenster zu &ouml;ffnen, in
      dem die Verwaltungsprogramme gelistet sind, mit denen HBenchmark eine API-Schnittstelle erstellt hat. Hier
      w&auml;hlen Sie das von Ihnen verwendete Hotel-Verwaltungssystem aus.</span></p>
  <p class="c0"><span class="c1">Durch die Auswahl Ihres Hotel-Verwaltungssystems beauftragen Sie Ihr Verwaltungssystem,
      sich am Ende des Antragsverfahrens mit der API von HBenchmark &uuml;ber eine gef&uuml;hrte Prozedur zu verbinden,
      um Folgendes zu &uuml;bermitteln: (i) t&auml;glich die aktuellen Reservierungsinformationen und (ii) beim ersten
      Zugriff die Reservierungsinformationen der letzten zwei Jahre, sofern vorhanden.</span></p>
  <p class="c0"><span class="c1">Hierdurch garantieren Sie, die Reservierungsinformationen rechtm&auml;&szlig;ig erhoben
      und an uns gesendet zu haben.</span></p>
  <p class="c0"><span class="c1">2.3. Weitere Modalit&auml;ten</span></p>
  <p class="c0" id="h.gjdgxs"><span class="c1">Sollte das von Ihnen verwendete Hotel-Verwaltungssystem sich nicht in der
      entsprechenden Liste befinden, bitten wir Sie, uns dies unverz&uuml;glich mitzuteilen, da wir auf andere, in jedem
      Fall gef&uuml;hrte Verbindungsmodalit&auml;ten zugreifen m&uuml;ssen. Sollte es nicht m&ouml;glich sein, eine
      Verbindung mit Ihrem Hotel-Verwaltungssystem aufzubauen, k&ouml;nnen wir unsere Dienstleistungen nicht anbieten
      und es ist Ihnen nicht m&ouml;glich, ein Abonnement f&uuml;r die HB-Plattform abzuschlie&szlig;en.</span></p>
  <p class="c0"><span class="c1">Des Weiteren werden Sie aufgefordert, einen Benutzernamen einzugeben, und es wird Ihnen
      ein Passwort mitgeteilt; Sie sind verpflichtet, Ihr Konto und Ihr Passwort vertraulich zu behandeln sowie den
      Zugriff auf Ihren Computer und Ihre Ger&auml;te zu kontrollieren; ferner akzeptieren Sie im Rahmen der geltenden
      gesetzlichen Bestimmungen, f&uuml;r alle Aktivit&auml;ten, die von Ihrem Konto und mit Ihrem Passwort vorgenommen
      werden, verantwortlich zu sein. Sie verpflichten sich, alle notwendigen Vorsichtsma&szlig;nahmen zu treffen, um zu
      gew&auml;hrleisten, dass Ihr Passwort sicher und vertraulich bleibt, und sie verpflichten sich, uns umgehend zu
      informieren, sollten Sie Grund zu der Annahme haben, dass Dritte Ihr Passwort kennen oder wenn Ihr Passwort in
      unbefugterweise verwendet wird bzw. evtl. verwendet werden k&ouml;nnte</span></p>
  <p class="c0"><span class="c1">Das Verfahren zum Erhalt des Abonnements wird von einem Assistenten unterst&uuml;tzt,
      wobei Sie stets die M&ouml;glichkeit haben, per Chat oder Telefon von unserem Kundendienst kontaktiert zu
      werden.</span></p>
  <p class="c0"><span class="c1">Bitte beachten Sie, dass zwischen dem Abschluss des Verfahrens zum Erhalt des
      Abonnements und dem Senden und Laden der Daten in unsere Systeme technische Verz&ouml;gerungen unvermeidbar sind.
      Unser Kundendienst steht Ihnen zur Verf&uuml;gung.</span></p>
  <p class="c0"><span class="c3">Art. 3 - Angebotene Dienstleistungen</span><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c1">Die von HBenchmark angebotenen Dienstleistungen sind die Pers&ouml;nlichen und
      Aggregierten KPIs sowie die Competitive Sets, welche in der </span><span class="c7"><a class="c5"
        href="https://preventivi.hbenchmark.it/{tipo_struttura == "hotel" ? "brochure-hotel_DE.pdf" : "flyer-camping_DE.pdf"}" target="_blank">Brosch&uuml;re</a></span><span
      class="c1">&nbsp;detaillierter beschrieben werden. Sie k&ouml;nnen darauf f&uuml;r die Dauer Ihres Abonnements
      zugreifen. </span></p>
  <p class="c0"><span class="c1">HBenchmark beh&auml;lt sich das Recht vor, Ihnen weitere Dienstleistungen anzubieten,
      &uuml;ber die Sie zeitnah informiert werden.</span></p>
  <ol class="c2 lst-kix_list_1-0 start" start="4">
    <li class="c8 li-bullet-0"><span class="c6">- Verarbeitung der Reservierungsinformationen und
        Vertraulichkeitsverpflichtungen von HB</span><span class="c1">&nbsp;</span></li>
  </ol>
  <p class="c0"><span class="c1">4.1. Die Reservierungsinformationen, welche von uns kontinuierlich normalisiert,
      analysiert und verarbeitet werden, um die Pers&ouml;nlichen KPIs zu erhalten und daraufhin, nach der Aggregation,
      die Aggregierten KPIs zu erhalten, lassen keine Identifizierung des Rechtssubjekts, auf welche sie sich beziehen,
      zu. Sie sind somit anonym und unterliegen nicht der Regelung der Rechtsvorschriften zum Datenschutz.</span></p>
  <p class="c0"><span class="c1">4.2. Solche betrieblichen Informationen k&ouml;nnen hingegen unter den Schutz des
      Gesetzbuchs &uuml;ber gewerbliches Eigentum in der Fassung des D. Lgs. [ital. gesetzesvertretendes Dekret] 63 von
      2018 fallen.</span></p>
  <p class="c0"><span class="c1">Durch Ihr Abonnement bei HB geben Sie Ihre Einwilligung und Genehmigung, auch
      gem&auml;&szlig; Art. 99 des Gesetzbuchs &uuml;ber gewerbliches Eigentum, dass:</span></p>
  <ul class="c2 lst-kix_list_2-0 start">
    <li class="c12 c10 li-bullet-0"><span class="c1">Ihre Reservierungsinformationen von Ihrem Hotel-Verwaltungssystem
        an HB gesendet bzw. von diesem erfasst werden.</span></li>
    <li class="c0 c10 li-bullet-0"><span class="c1">Ihre Reservierungsinformationen von HB verarbeitet werden, um die
        Pers&ouml;nlichen KPIs zu extrapolieren.</span></li>
    <li class="c0 c10 li-bullet-0"><span class="c1">Ihre Reservierungsinformationen in anonymisierter Form mit jenen der
        anderen Abonnenten aggregiert werden, um Cluster aus Ort, Zone, Gebiet, Typologie, Region und Nation zu bilden,
        aus denen daraufhin die HB-Algorithmen die auf HB sichtbaren Aggregierten KPIs verarbeiten und
        extrapolieren.</span></li>
    <li class="c9 li-bullet-0"><span class="c1">Ihre Reservierungsinformationen von HB verarbeitet werden, um
        Aggregierte KPIs in ein Competitive Set zu extrapolieren.</span></li>
  </ul>
  <p class="c0"><span class="c3">Bitte beachten Sie, dass ohne diese Genehmigungen und Einwilligungen die Dienstleistung
      f&uuml;r Sie nicht erbracht werden kann</span><span class="c1">. </span></p>
  <p class="c0"><span class="c1">4.3. Wir verpflichten uns und garantieren:</span></p>
  <ul class="c2 lst-kix_list_3-0 start">
    <li class="c8 li-bullet-0"><span class="c1">Ihre Reservierungsinformationen, die in IT-Systemen gespeichert werden
        und vor unrechtm&auml;&szlig;igem oder unbefugtem Zugriff gesch&uuml;tzt sind, sowie Ihre Pers&ouml;nlichen KPIs
        ausschlie&szlig;lich Ihnen selbst zug&auml;nglich zu machen. Sie allein k&ouml;nnen uns entsprechende
        Anweisungen geben, wenn Sie m&ouml;chten, dass Ihre Reservierungsinformationen und Pers&ouml;nlichen KPIs
        sichtbar gemacht oder an Dritte weitergegeben werden.</span></li>
  </ul>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <ul class="c2 lst-kix_list_4-0 start">
    <li class="c8 li-bullet-0"><span class="c1">Bei Aufl&ouml;sung des Abonnementverh&auml;ltnisses aus einem beliebigen
        Grund, einschlie&szlig;lich der Aufl&ouml;sung des Vertrages, wird die Erfassung der Reservierungsinformationen
        automatisch beendet, und Ihre Aktivit&auml;t ist auf HB nicht weiter sichtbar und zug&auml;nglich; die
        Reservierungsinformationen, welche uns w&auml;hrend des Abonnementzeitraums zugesendet wurden und in den
        historischen aggregierten Informationen verbleiben, k&ouml;nnen nicht zu Ihnen zur&uuml;ckverfolgt, erkannt,
        identifiziert oder extrahiert werden.</span></li>
  </ul>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c3">Art. 5. - Verpflichtung des Abonnenten, Aggregierte KPIs nicht zu verbreiten,
      offenzulegen und zu vermarkten </span></p>
  <p class="c0"><span class="c1">Die von HBenchmark verarbeiteten Aggregierten KPIs und Competitive Sets k&ouml;nnen
      von Ihnen nur innerhalb Ihres Unternehmens und mit Ihren Partnern und Mitarbeitern ausschlie&szlig;lich zum Zweck
      der Marktanalyse und zur Verbesserung der Leistung und der Strategien Ihrer Aktivit&auml;t oder zur Planung von
      Initiativen oder Investitionen verwendet werden. Mit diesem Vertrag verpflichten Sie sich, diese nicht zu
      verbreiten oder Dritten und/oder der &Ouml;ffentlichkeit zug&auml;nglich zu machen und sie nicht f&uuml;r direkte
      oder indirekte gewerbliche Zwecke zu &uuml;bermitteln oder abzutreten. Sollten diese Hypothesen eintreten,
      k&ouml;nnen wir die Dienstleistungen fristlos sperren, den Vertrag aufl&ouml;sen und die Wiedergutmachung von
      Sch&auml;den verlangen.&nbsp;</span></p>
  <p class="c0"><span class="c3">Art. 6. - Datenschutz und Einwilligung</span><span class="c1">. </span></p>
  <p class="c0"><span class="c3">6.1.</span><span class="c1">&nbsp;W&auml;hrend des Antragsverfahrens stellen Sie uns
      einige Informationen zur Verf&uuml;gung, die f&uuml;r die Registrierung und die Bereitstellung der Dienste
      erforderlich sind. Diese k&ouml;nnen sensible Informationen und Daten enthalten, wie Ihren Namen, Ihr Geburtsdatum
      und/oder die Steuernummer, Ihre E-Mail-Adresse oder die Telefonnummer, den Aussteller Ihrer Kreditkarte sowie
      einige Nummern und Codes. </span></p>
  <p class="c0"><span class="c1">Der Name, der Ort und die Kategorie Ihrer Aktivit&auml;t werden in die Liste der
      Abonnenten auf HB eingegeben und sind f&uuml;r andere Abonnenten sichtbar.</span></p>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c1">Wir verarbeiten Ihre personenbezogenen Daten mit der gr&ouml;&szlig;ten Sorgfalt und
      Vorsicht und verwenden diese nur, um Ihnen die Dienstleistungen zu erbringen, Sie um Ihre Meinung zu bitten und um
      Sie &uuml;ber die neuen Initiativen und neuen Dienstleistungen unseres Unternehmens auf dem Laufenden zu halten;
      einige Ihrer Daten k&ouml;nnen von den Unternehmen unserer Develon-Gruppe verarbeitet werden, welche &uuml;ber
      Produkte und Dienstleistungen verf&uuml;gen, von denen wir annehmen, dass sie f&uuml;r Sie interessant sein
      k&ouml;nnen. Dies geschieht stets unter Einhaltung unserer </span><span class="c7"><a class="c5"
        href="https://www.iubenda.com/privacy-policy/7839661">Datenschutzerkl&auml;rung</a></span><span
      class="c1">. </span></p>
  <p class="c0"><span class="c1">Ihre Kreditkartendaten durchlaufen hingegen unser System nicht, sondern werden von
      Anfang an ausschlie&szlig;lich von den Zahlungskreisl&auml;ufen verarbeitet und gespeichert, deren Liste Sie auf
      dem Antragsformular f&uuml;r das Abonnement finden.</span></p>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c3">6.2. Bitte beachten Sie au&szlig;erdem Folgendes</span><span class="c1">: </span></p>
  <ol class="c2 lst-kix_list_5-0 start" start="1">
    <li class="c12 c10 li-bullet-0"><span class="c6">a) Transaktionen, an denen Dritte beteiligt sind:</span><span
        class="c1">&nbsp;Wir k&ouml;nnen Ihnen Dienstleistungen, Anwendungen, Kompetenzen oder Produkte von Dritten zur
        Verf&uuml;gung stellen. Wenn Sie diese Dienstleistungen akzeptieren, m&uuml;ssen wir die Daten mit diesen
        Dritten teilen. </span></li>
    <li class="c9 li-bullet-0"><span class="c6">b) Drittdienstleister:</span><span class="c1">&nbsp;Wir beauftragen
        andere Unternehmen und Personen mit der Durchf&uuml;hrung bestimmter T&auml;tigkeiten in unserem Namen. Z. B.
        der Versand von Post in Papierform und E-Mails, Entfernen der wiederkehrenden Informationen aus Kundenlisten,
        Abwicklung von Zahlungen. </span></li>
  </ol>
  <p class="c0"><span class="c1">Diese Drittdienstleister haben lediglich Zugriff auf die personenbezogenen Daten, die
      f&uuml;r die Aus&uuml;bung ihrer T&auml;tigkeit erforderlich sind; sie k&ouml;nnen Daten, &uuml;ber welche sie
      Kenntnis erlangen, jedoch nicht f&uuml;r andere Zwecke verarbeiten. Dar&uuml;ber hinaus sind diese Dritten
      verpflichtet, die personenbezogenen Daten, &uuml;ber welche sie Kenntnis erlangen, in &Uuml;bereinstimmung mit
      dieser Datenschutzerkl&auml;rung und den anwendbaren Datenschutzbestimmungen zu verarbeiten. Ferner arbeiten wir
      mit technischen Serviceanbietern, welche die technische Infrastruktur verwalten k&ouml;nnten, die zur
      Bereitstellung unserer Dienstleistung erforderlich ist, insbesondere Anbieter, welche die HB-Anwendung, deren
      Inhalt und die von uns verarbeiteten Daten hosten, speichern, verwalten und pflegen.&nbsp;</span></p>
  <ol class="c2 lst-kix_list_6-0 start" start="1">
    <li class="c10 c12 li-bullet-0"><span class="c6">c) Unternehmens&uuml;bertragungen:</span><span class="c1">&nbsp;Da
        unsere T&auml;tigkeit sich in einer st&auml;ndigen Weiterentwicklung befindet, k&ouml;nnten wir andere
        Unternehmen oder Dienstleistungen verkaufen oder erwerben. Im Allgemeinen geh&ouml;ren bei solchen Transaktionen
        die Kundendaten zum &uuml;bertragenen Unternehmensverm&ouml;gen, unterliegen jedoch dem Inhalt der ggf.
        anwendbaren vorherigen Datenschutzerkl&auml;rung (es sei denn, der Kunde gibt eine anderslautende Einwilligung).
        Sollte die HBenchmark S.r.l. oder im Wesentlichen ihr Unternehmensverm&ouml;gen von Dritten erworben werden,
        w&uuml;rden auch die personenbezogenen Kundendaten nat&uuml;rlicherweise in das &uuml;bertragene Verm&ouml;gen
        einflie&szlig;en. </span></li>
    <li class="c9 li-bullet-0"><span class="c6">d) Schutz von HBenchmark:</span><span class="c1">&nbsp;Wir geben
        Kontodaten und andere personenbezogene Daten weiter, wenn wir der Ansicht sind, dass diese Weitergabe mit den
        gesetzlichen Verpflichtungen &uuml;bereinstimmt; um unsere Nutzungsbedingungen und sonstige Vereinbarungen
        einzuhalten oder anzuwenden; oder um die Rechte, das Eigentum sowie die Sicherheit der HBenchmark S.r.l.,
        unserer Benutzer und sonstiger Rechtssubjekte zu sch&uuml;tzen. </span></li>
  </ol>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c3">6.3. Einwilligung </span></p>
  <p class="c0"><span class="c1">Bei Fertigstellung des Verfahrens zum Erhalt des Abonnements bitten wir Sie, uns Ihre
      Einwilligung zur Verwendung Ihrer Daten entsprechend den vorherigen Abs&auml;tzen mit den Schutzvorkehrungen
      gem&auml;&szlig; unserer Datenschutzerkl&auml;rung und der geltenden Gesetzgebung zu geben. Ohne diese
      Einwilligung k&ouml;nnen wir Ihnen die Dienstleistung nicht erbringen.</span></p>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c3">Art. 7 - Abschluss, Dauer und Verl&auml;ngerung</span><span class="c1">&nbsp;</span>
  </p>
  <p class="c0"><span class="c1">Der Vertrag zwischen HBenchmark und Ihrer Person gilt als abgeschlossen und wirksam,
      sobald Sie das Verfahren zum Erhalt des Abonnements erfolgreich fertiggestellt und die entsprechende Geb&uuml;hr
      entrichtet haben. Sofern keine anderslautenden Einzelvereinbarungen vorliegen, betr&auml;gt die Laufzeit des
      Abonnements bei HB ein Jahr ab dem Zahlungsdatum der Geb&uuml;hr und verl&auml;ngert sich automatisch, wenn nicht
      mindestens 1 Tag vor dem Ablauf eine K&uuml;ndigung, die auch per E-Mail erfolgen kann, stattfindet. Sollte
      Ihrerseits keine K&uuml;ndigung erfolgen, akzeptieren und genehmigen Sie, dass die Geb&uuml;hr f&uuml;r die
      j&auml;hrliche Verl&auml;ngerung automatisch abgehoben wird.</span></p>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c3">Art. 8 - Geb&uuml;hr </span></p>
  <p class="c0"><span class="c1">8.1. Die Geb&uuml;hr f&uuml;r das Jahresabonnement entspricht der Summe, die jeweils
      zum Zeitpunkt des Abonnements auf der Website ver&ouml;ffentlicht wird, zzgl. gesetzlicher Mehrwertsteuer, und
      ist, mit Ausnahme von Rabatten und Sonderbedingungen, die auf unserer Website ver&ouml;ffentlicht und
      vorgeschlagen werden, zu entrichten. HB beh&auml;lt sich das Recht vor, die Geb&uuml;hr zu &auml;ndern und eine
      entsprechende Mitteilung auf der Website zu erlassen.</span></p>
  <p class="c0"><span class="c1">8.2. F&uuml;r Benutzer, die ein vorausbezahltes oder herabgesetztes Angebot von einem
      Dritten, einer Organisation oder einem Verband erhalten haben, dem ein Code beiliegt, k&ouml;nnen separate
      Bedingungen angewendet werden, die zusammen mit dem Code anzugeben sind und die der Abonnent durch die Annahme des
      Angebots akzeptiert.</span></p>
  <p class="c0"><span class="c1">8.3. Sollte es aus technischen Gr&uuml;nden nicht m&ouml;glich sein, das Verfahren
      f&uuml;r das Abonnement abzuschlie&szlig;en, erstatten wir Ihnen die ggf. bereits bezahlte Geb&uuml;hr umgehend
      zur&uuml;ck.</span></p>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c3">Art. 9 - Haftungsbeschr&auml;nkungen</span><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c1">HB bietet zudem prognostische Auswertungen, die unter Verwendung von Algorithmen
      erstellt werden.</span></p>
  <p class="c0"><span class="c1">So sehr diese Algorithmen auch konstant &uuml;berwacht wurden und werden, wird
      hierdurch die nat&uuml;rliche Ungewissheit der Prognosen sowohl in Bezug auf unber&uuml;cksichtigte Faktoren als
      auch in Bezug auf das Auftreten unvorhergesehener Faktoren weder eliminiert, noch k&ouml;nnte sie eliminiert
      werden. HBenchmark garantiert keinerlei Eintreten der Ergebnisse der prognostischen Analysen und ist bzw. wird
      nicht verantwortlich f&uuml;r das unerwartete Ergebnis einer beliebigen Entscheidung oder Wahl, welche Sie
      aufgrund solcher prognostischen Analysen getroffen haben k&ouml;nnten.</span></p>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c3">Art. 10 &ndash; &Auml;nderungen an diesem Vertrag </span></p>
  <p class="c0"><span class="c3">&nbsp;</span><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c1">HBenchmark beh&auml;lt sich das Recht vor, &Auml;nderungen an diesem Abonnementvertrag
      vorzuschlagen, welche erst dann in Kraft treten, nachdem sie von Ihnen per E-Mail oder direkt auf der Plattform
      akzeptiert wurden. Im Falle der Verl&auml;ngerung des Abonnements nach der Mitteilung der &Auml;nderungen gelten
      diese automatisch als angenommen.</span></p>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c3">Art. 11 &ndash; Aufl&ouml;sung oder K&uuml;ndigung und Fortbestand der
      Vertraulichkeitspflichten </span></p>
  <p class="c0"><span class="c1">Im Falle der Aufl&ouml;sung und/oder K&uuml;ndigung dieses Vertrags bleiben unbeschadet
      der gesetzlich vorgesehenen Rechtsmittel die Klauseln gem&auml;&szlig; Art. 5 f&uuml;r einen Zeitraum von 5 Jahren
      wirksam.</span></p>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c3">Art. 12 &ndash; Geltendes Recht </span></p>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c1">Dieser Abonnementvertrag, bestehend aus dem Antragsformular, den Bedingungen, der
      Datenschutzerkl&auml;rung und der Brosch&uuml;re, unterliegt dem italienischen Recht. Jede Streitigkeit, die nicht
      im gegenseitigen Einvernehmen beigelegt werden kann, ist vor einem Prozess vor dem zust&auml;ndigen Gericht
      Verhandlungssache eines Schlichtungsversuchs gem&auml;&szlig; D. Lgs [ital. gesetzesvertretendes Dekret] 28 von
      2010 und nachfolgenden &Auml;nderungen und Erg&auml;nzungen.</span></p>
  <p class="c0"><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c3">Art. 13 &ndash; Kontakte</span><span class="c1">&nbsp;</span></p>
  <p class="c0"><span class="c1">F&uuml;r Benachrichtigungen, Informationen oder Anfragen jeder Art kann der Abonnent
      HBenchmark folgenderma&szlig;en kontaktieren:</span></p>
  <ul class="c2 lst-kix_list_7-0 start">
    <li class="c12 c10 li-bullet-0"><span class="c14"><a class="c5"
          href="mailto:supporto@hbenchmark.it">supporto@hbenchmark.it</a></span><span class="c1">&nbsp;</span></li>
    <li class="c0 c10 li-bullet-0"><span class="c1">+39 (0)444 276202</span></li>
  </ul>
</template>

<style>
  .c7 {
    color: #0000ff;
    text-decoration: underline;
  }

  .c9 {
    margin-left: 36px;
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 14px;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c8 {
    margin-left: 36px;
    padding-top: 14px;
    padding-left: 0px;
    padding-bottom: 14px;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c3 {
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal
  }

  .c11 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal
  }

  .c12 {
    padding-top: 14px;
    padding-bottom: 0px;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c14 {
    -webkit-text-decoration-skip: none;
    color: #0000ff;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }

  .c6 {
    font-weight: 700;
  }

  .c2 {
    padding: 0;
    margin: 0
  }

  .c10 {
    margin-left: 36px;
    padding-left: 0px;
  }

  .c5 {
    color: inherit;
    text-decoration: inherit
  }
</style>

<script>
  import BarraMessaggio from "@/base/componenti/BarraMessaggio.svelte"

  export let sottoscrivente = null
  export let tipo_struttura = "hotel"
</script>