<template>
  <div class="tabella-soggiorni">
    <div class="riepilogo">
      <div class="data">
        {t.data[$l]}:&nbsp;
        <b>{data.format("%d %B %Y")}</b>
      </div>
      <div class="soggiorni-trovati">
        {t.soggiorni_trovati[$l]}:&nbsp;
        <b>{soggiorni.length}</b>
      </div>
    </div>

    <Tabella
        {colonne}
        {valore_cella}
        esportabile={true}
        righe={soggiorni}
        dizionario={t}
        intestazione_fissa={true}
        let:riga={riga}
        let:colonna={colonna}
        let:valore={valore}>
      {#if colonna == "accettato"}
        <div class="accettato">
          {#if riga.accettato && stati_confermati.include(riga.stato)}
            <Fanalino stato="ok"/>
            {t.si[$l]}
          {:else}
            <Fanalino stato="errore"/>
            {t.no[$l]}
          {/if}
        </div>
      {:else}
        {valore_cella(riga, colonna, valore)}
      {/if}
    </Tabella>
  </div>
</template>

<style>
  :global(div.tabella-soggiorni) {
    flex-grow:        1;
    display:          flex;
    flex-direction:   column;
    overflow:         hidden;
  }
  :global(div.tabella-soggiorni div.riepilogo) {
    display:          flex;
    align-items:      flex-end;
    justify-content:  space-between;
    margin-bottom:    14px;
  }
  :global(div.tabella-soggiorni div.accettato) {
    display:          flex;
    align-items:      center;
  }
  :global(div.tabella-soggiorni div.accettato span.fanalino) {
    margin-right:     7px;
  }
</style>

<script>
  import Tabella from "@/base/componenti/Tabella.svelte"
  import Fanalino from "@/base/componenti/Fanalino.svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  export let data = Date.today
  export let soggiorni = []
  export let struttura = {}
  export let colonne = [
    "accettato", "id_esterno", "trasmesso_il", "data_arrivo", "data_partenza",
    "data_creazione", "data_cancellazione", "stato", "motivi_rifiuto",
    "unità_vendute", "ospiti", "ospiti_adulti", "ospiti_bambini", "ricavo", "ricavo_unità",
    "nazionalità", "trattamento", "canale", "mercato", "prodotto",
    "codice_stato", "codice_nazionalità", "codice_trattamento", "codice_canale", "codice_mercato", "codice_prodotto" ]

  const t = avvia_localizzazione(dizionario_soggiorni)
  const stati_confermati = [ "check_in", "check_out", "inserito" ]

  ////
  // Determina il valore di una cella.
  function valore_cella(riga, colonna, valore, indice_riga, indice_colonna) {
    if (valore == null) return "-"

    switch (colonna) {
      case "id_esterno":
        if (struttura.tipo == "camping")
          return (riga.informazioni_aggiuntive || {}).pass || valore
        else
          return valore || "-"
      case "trasmesso_il":
        return valore.to_date.format("%d %B %Y %H:%M")
      case "data_arrivo":
      case "data_partenza":
      case "data_creazione":
      case "data_cancellazione":
        return valore.to_date.format("%d %B %Y")
      case "unità_vendute":
      case "ospiti":
      case "ospiti_adulti":
      case "ospiti_bambini":
      case "ricavo":
      case "ricavo_unità":
        if (valore < 1) return valore
        return valore.format({ reduce: false, show_zero: true })
      case "stato":
      case "trattamento":
      case "canale":
      case "mercato":
      case "nazionalità":
      case "prodotto":
        if (t[valore])
          return t[valore][$l]
        else
          return "-"
      case "motivi_rifiuto":
        let motivi = valore.map(motivo => {
          if (t.stati[motivo])
            return t.stati[motivo][$l]
          else
            return motivo
        })
        return motivi.join(", ")
      default:
        return valore || "-"
    }
  }
</script>

<script context="module">
  export const dizionario_soggiorni = {
    data: {
      it: `Data`,
      en: `Date`,
      de: `Datum`
    },
    soggiorni_trovati: {
      it: `Numero prenotazioni trovate`,
      en: `Number of reservations`,
      de: `Anzahl der Reservierungen`
    },
    stati:{      
      stato_da_escludere: {
        it: `Stato da escludere`,
        en: ``,
        de: ``
      }, 
      canale_da_escludere: {
        it: `Canale da escludere`,
        en: ``,
        de: ``
      }, 
      mercato_da_escludere: {
        it: `Mercato da escludere`,
        en: ``,
        de: ``
      },    
      prodotto_da_escludere: {
        it: `Prodotto da escludere`,
        en: ``,
        de: ``
      }, 
      trattamento_da_escludere: {
        it: `Trattamento da escludere`,
        en: ``,
        de: ``
      }, 
      nazionalità_da_escludere: {
        it: `Nazionalità da escludere`,
        en: ``,
        de: ``
      },    
      rifiutato_da_operatore: {
        it: `Rifiutato da operatore`,
        en: ``,
        de: ``
      }, 
      ricavo_a_zero: {
        it: `Ricavo a zero`,
        en: ``,
        de: ``
      }, 
      unità_vendute_a_zero: {
        it: `Unità vendute a zero`,
        en: ``,
        de: ``
      }, 
      ospiti_a_zero: {
        it: `Ospiti a zero`,
        en: ``,
        de: ``
      },
      prodotto_extra: {
        it: `Prodotto extra`,
        en: ``,
        de: ``
      }, 
      non_consolidata: {
        it: `Non consolidata`,
        en: ``,
        de: ``
      }, 
      non_aggiornata: {
        it: `Non aggiornata`,
        en: ``,
        de: ``
      }, 
      interfaccia_disattiva: {
        it: `Interfaccia disattiva`,
        en: ``,
        de: ``
      }
    },
    colonne: {
      accettato: {
        it: `Accettata`,
        en: `Accepted`,
        de: `Akzeptiert`
      },
      id_esterno: {
        it: `ID PMS`,
        en: `PMS ID`,
        de: `PMS-ID`
      },
      trasmesso_il: {
        it: `Trasm. il`,
        en: `Transm. at`,
        de: `Übertrag. am`
      },
      data_arrivo: {
        it: `Arrivo`,
        en: `Arrival`,
        de: `Ankunft`
      },
      data_partenza: {
        it: `Partenza`,
        en: `Departure`,
        de: `Abfahrt`
      },
      data_creazione: {
        it: `Creata il`,
        en: `Created at`,
        de: `Erstellt am`
      },
      data_cancellazione: {
        it: `Cancellata il`,
        en: `Cancelled at`,
        de: `Abgesagt am`
      },
      stato: {
        it: `Stato`,
        en: `Status`,
        de: `Status`
      },
      motivi_rifiuto: {
        it: `Motivi rifiuto`,
        en: ``,
        de: ``
      },
      unità_vendute: {
        it: `Vendute`,
        en: `Sold`,
        de: `Verkauft`
      },
      ospiti: {
        it: `Ospiti`,
        en: `Guests`,
        de: `Gäste`
      },
      ospiti_adulti: {
        it: `Adulti`,
        en: `Adults`,
        de: `Erwachsene`
      },
      ospiti_bambini: {
        it: `Bambini`,
        en: `Children`,
        de: `Kinder`
      },
      ricavo: {
        it: `Ricavo`,
        en: `Revenue`,
        de: `Umsatz`
      },
      ricavo_unità: {
        it: `Ricavo Unità`,
        en: `Unit Revenue`,
        de: `Einheitenumsatz`
      },
      nazionalità: {
        it: `Nazione`,
        en: `Nationality`,
        de: `Staatsangehörigkeit`
      },
      trattamento: {
        it: `Trattamento`,
        en: `Rate`,
        de: `Bewertung`
      },
      canale: {
        it: `Canale`,
        en: `Channel`,
        de: `Kanal`
      },
      mercato: {
        it: `Mercato`,
        en: `Market`,
        de: `Markt`
      },
      prodotto: {
        it: `Prodotto`,
        en: `Product`,
        de: `Produkt`
      },
      codice_stato: {
        it: `Cod. Stato PMS`,
        en: `PMS Status Code`,
        de: `PMS-Statuscode`
      },
      codice_nazionalità: {
        it: `Cod. Nazione PMS`,
        en: `PMS Nationality Code`,
        de: `PMS-Nationalitätscode`
      },
      codice_trattamento: {
        it: `Cod. Trattamento PMS`,
        en: `PMS Rate Code`,
        de: `PMS-Preiscode`
      },
      codice_canale: {
        it: `Cod. Canale PMS`,
        en: `PMS Channel Code`,
        de: `PMS-Kanalcode`
      },
      codice_mercato: {
        it: `Cod. Mercato PMS`,
        en: `PMS Market Code`,
        de: `PMS-Marktcode`
      },
      codice_prodotto: {
        it: `Cod. Prodotto PMS`,
        en: `PMS Product Code`,
        de: `PMS-Produktcode`
      }
    }
  }
</script>
