<template>
  {segno}
  {#if numero == null || isNaN(numero)}
    -
  {:else if numero == 0 && mostra_zero == false}
    -
  {:else}
    {#if animato}
      <Contatore
          numero={numero_ridotto}
          decimali={decimali}/>
    {:else}
      {numero_ridotto.format_digits(decimali)}
    {/if}
  {/if}
  {suffisso_contrazione}
  {suffisso}
</template>

<style>
</style>

<script>
  import Contatore from "@/base/componenti/Contatore.svelte"

  export let numero = 0
  export let decimali = 0
  export let unità = null
  export let valuta = null
  export let riduci = false
  export let animato = true
  export let mostra_zero = false

  let segno = numero < 0 ? "-" : ""
  let numero_ridotto = null
  let suffisso_contrazione = ""
  let suffisso = ""

  ////
  // Determina proprietà del numero.
  function determina_proprietà() {
    // Riduzione in milioni o migliaia.
    if (riduci) {
      numero_ridotto = Math.abs(numero)
      suffisso_contrazione = ""
      suffisso = ""

      if (numero_ridotto >= 1000000) {
        numero_ridotto = numero_ridotto / 1000000
        suffisso_contrazione = "M"
        decimali = 1
      } else if (numero_ridotto >= 10000) {
        numero_ridotto = numero_ridotto / 1000
        suffisso_contrazione = "K"
        decimali = 1
      }
    }

    // Decimali.
    numero_ridotto = numero_ridotto.round(decimali)

    // Suffisso finale unità o valuta.
    switch (unità) {
      case "valuta":
        suffisso = valuta || "€"
        break
      case "percentuale":
        suffisso = "%"
        break
      case "punti_percentuali":
        suffisso = "pp"
        break
      case "pura":
      default:
        suffisso = ""
    }
  }

  $: if (numero != null) determina_proprietà()
</script>
