<template>
  <div class="statistica">
    {#if in_caricamento}
      <Caricatore/>
    {:else}
      <div class="intestazione">
        <h3>{titolo || ""}</h3>
        <div class="controlli">
          {#if configurabile}
            <Bottone
                piccolo={true}
                variante="secondario-invertito"
                on:click={apri_configuratore}>
              <Icona tipo="modifica"/>
            </Bottone>
          {/if}
          {#if esportabile}
            <Bottone
                piccolo={true}
                variante="secondario-invertito"
                on:click={window.print}>
              <Icona tipo="esporta"/>
            </Bottone>
          {/if}
          {#if rimuovibile}
            <Bottone
                piccolo={true}
                variante="secondario-invertito"
                on:click={evento => propaga("delete")}>
              <Icona tipo="cestino"/>
            </Bottone>
          {/if}
        </div>
      </div>

      {#if in_errore}
        <ErroreStatistica {abbonamento_scaduto}/>
      {:else}
        <SchedarioStatistica
            {anteprima}
            {statistica}
            {modalità_scura}/>
      {/if}
    {/if}
  </div>
</template>

<style>
  :global(div.statistica > div.caricatore-contenitore) {
    min-height: 598px;
  }
  :global(rect.highcharts-background) {
    fill:       var(--colore-bianco) !important;
  }

  :global(div.statistica > div.intestazione) {
    height:           54px;
    display:          flex;
    align-items:      center;
    justify-content:  space-between;
  }
  :global(div.statistica > div.intestazione > h3) {
    margin:           0;
    padding-left:     7px;
    white-space:      nowrap;
  }
  :global(div.statistica > div.intestazione > div.controlli) {
    display:          flex;
    justify-content:  flex-end;
    margin-top:       14px;
    margin-bottom:    7px;
  }

  /* Schedario con gli Indicatori. */
  :global(div.statistica > div.schedario) {
    background: var(--colore-bianco);
    box-shadow: 0 5px 10px 0 var(--colore-ombreggiatura);
    overflow-x: auto;
    overflow-y: hidden;
  }
  :global(div.statistica > div.schedario > div.etichette) {
    min-width:  1024px;
  }
  :global(div.statistica > div.schedario > div.schede) {
    min-width:  1024px;
  }
</style>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import ErroreStatistica from "@/base/componenti/statistiche/Errore.svelte"
  import SchedarioStatistica from "@/base/componenti/statistiche/Schedario.svelte"
  import { createEventDispatcher } from "svelte"

  export let configurabile = true
  export let esportabile = true
  export let rimuovibile = false
  export let richiedi_dati = false
  export let in_errore = false
  export let anteprima = false
  export let sessione = null
  export let statistica = {}
  export let rapporto = {}
  export let modalità_scura = false

  const propaga = createEventDispatcher()

  let in_caricamento = true
  let abbonamento_scaduto = false

  $: titolo = statistica.titolo || ""
  $: gruppi = statistica.gruppi || []
  $: indicatori = statistica.indicatori || []
  $: cornice_temporale = rapporto.cornice_temporale || statistica.cornice_temporale || {}
  $: granularità = statistica.granularità || "giornaliera"
  $: mostra_etichette_valori = statistica.mostra_etichette_valori == false ? false : true
  $: mostra_meteo = statistica.mostra_meteo == true ? true : false
  $: mostra_eventi = statistica.mostra_eventi == true ? true : false
  $: mostra_prezzi_con_iva = statistica.mostra_prezzi_con_iva == true ? true : false
  $: tipo_grafico = statistica.tipo_grafico

  ////
  // Richiede i dati della statistica.
  async function richiedi_statistica() {
    if (sessione == null || sessione._id == null) return
    if (gruppi == null || gruppi.length == 0) return
    if (indicatori == null || indicatori.length == 0) return

    let indicatori_da_visualizzare = []

    indicatori_da_visualizzare = verifica_indicatore()

    console.log("Statistica".colore("verde"), ": richiesta dati.")

    in_caricamento = true
    in_errore = false

    let parametri = {
      titolo,
      sessione,
      gruppi,
      indicatori: indicatori_da_visualizzare,
      cornice_temporale,
      granularità,
      mostra_etichette_valori,
      mostra_meteo,
      mostra_eventi,
      mostra_prezzi_con_iva,
      tipo_grafico }

    if (parametri.cornice_temporale.da && parametri.cornice_temporale.a) {
      delete parametri.cornice_temporale.codice
      delete parametri.cornice_temporale.id_evento
    }
    if (parametri.cornice_temporale.codice) {
      delete parametri.cornice_temporale.da
      delete parametri.cornice_temporale.a
      delete parametri.cornice_temporale.id_evento
    }
    if (parametri.cornice_temporale.id_evento) {
      delete parametri.cornice_temporale.da
      delete parametri.cornice_temporale.a
      delete parametri.cornice_temporale.codice
    }

    let risposta = await retro.chiama(
      "POST",
      retro.estremi.portale.statistiche,
      parametri)

    if (risposta.status == 402) abbonamento_scaduto = true
    else abbonamento_scaduto = false

    if (risposta.fallita) {
      in_errore = true
      in_caricamento = false
      richiedi_dati = false
      return null
    }

    statistica = risposta.contenuto.statistica

    in_caricamento = false
    richiedi_dati = false
  }

  ////
  // Verifica se l'indicatore può essere comparato con gruppi esterni
  function verifica_indicatore() {
    return indicatori // funzione rimossa su richiesta di FT
    if (gruppi.length <= 1) return indicatori
    if (gruppi.find(gruppo => gruppo.collezione == "territori") == null &&
      gruppi.find(gruppo => gruppo.collezione == "località") == null &&
      gruppi.find(gruppo => gruppo.collezione == "zone") == null &&
      gruppi.find(gruppo => gruppo.collezione == "concorrenti") == null)
      return indicatori

    let nuovi_indicatori = indicatori.map(indicatore => {
      if (indicatore.codice == "camere_vendute" || indicatore.codice == "unità_vendute")
        return indicatore = { ...indicatore, codice: "mpi" }
      if (indicatore.codice == "ricavo_camere" || indicatore.codice == "ricavo_unità")
        return indicatore = { ...indicatore, codice: "ari" }
      if (indicatore.codice == "ricavo_complessivo")
        return indicatore = { ...indicatore, codice: "rgi" }
      return indicatore
    })

    return nuovi_indicatori
  }

  ////
  // Apre configuratore.
  function apri_configuratore() {
    propaga("change", { statistica })
  }

  ////
  // Rimuove il caricamento.
  function rimuovi_caricamento() {
    if (richiedi_dati) return
    in_caricamento = false
  }

  // Quando richiesto, richiede la statistica.
  $: if (richiedi_dati == true) richiedi_statistica()
  // Quando arrivano dati, rimuove caricamento.
  $: if (statistica.serie != null) rimuovi_caricamento()
</script>
