<template>
  <RottaNuovoAbbonamento titolo={t.titolo[$l]}>
    <div class="passo-progresso-abbonamento fatturazione">
      {#if in_invio}
        <Caricatore/>
      {:else}
        <TitoloSecondario>
          {t.titolo_secondario[$l]}
          <div slot="azioni">
            {#if $abbonamento.crea_nuovo_cliente && clienti.length > 0}
              <Bottone
                  piccolo={true}
                  variante="primario-invertito"
                  on:click={evento => $abbonamento.crea_nuovo_cliente = false}>
                {t.inserisci_cliente_precedente[$l]}
              </Bottone>
            {/if}
          </div>
        </TitoloSecondario>
        {#if $abbonamento.crea_nuovo_cliente}
          <Modulo 
              validatore={valida_fatturazione}
              gestore={continua}
              bind:errori={errori}>

            {#if errore_creazione_cliente}
              <p class="errore">
                {t.errori.spiacenti[$l]}.
              </p>
              <p>
                {@html t.errori.contatta_supporto[$l]}.
              </p>
            {:else}
              <Inserimento 
                  etichetta={`${t.ragione_sociale[$l]}*`}
                  errore={errori.ragione_sociale}
                  bind:valore={$abbonamento.cliente.ragione_sociale}/>
              <Inserimento 
                  etichetta={`${t.email_fatturazione[$l]}*`}
                  errore={errori.email_fatturazione}
                  bind:valore={$abbonamento.cliente.email_fatturazione}/>
              <Inserimento 
                  etichetta={`${t.indirizzo[$l]}*`}
                  attributi={{ etichetta: "Address" }}
                  errore={errori.indirizzo}
                  bind:valore={$abbonamento.cliente.indirizzo}/>
              <div class="blocco-inserimento-campi">
                <Inserimento 
                    etichetta={`${t.città[$l]}*`}
                    attributi={{ etichetta: "City" }}
                    errore={errori.città}
                    bind:valore={$abbonamento.cliente.città}/>
                {#if $abbonamento.cliente.nazione == "IT"}
                  <Selezione 
                      etichetta={`${t.provincia[$l]}*`}
                      opzioni={opzioni_province_italiane}
                      ricercabile_senza_icona={true}
                      errore={errori.provincia}
                      bind:valore={$abbonamento.cliente.provincia}/>
                {:else}
                  <Inserimento 
                      etichetta={`${t.provincia[$l]}*`}
                      attributi={{ etichetta: "Province" }}
                      errore={errori.provincia}
                      bind:valore={$abbonamento.cliente.provincia}/>
                {/if}
                <Inserimento 
                    etichetta={`${t.cap[$l]}*`}
                    attributi={{ etichetta: "ZIP" }}
                    errore={errori.cap}
                    bind:valore={$abbonamento.cliente.cap}/>
              </div>
              <div class="blocco-inserimento-campi">
                <Inserimento 
                    etichetta={`${t.telefono[$l]}*`}
                    attributi={{ etichetta: "Phone" }}
                    errore={errori.telefono}
                    bind:valore={$abbonamento.cliente.telefono}/>
                <Selezione 
                    etichetta={t.nazione[$l]}
                    opzioni={opzioni_nazioni}
                    ricercabile_senza_icona={true}
                    bind:valore={$abbonamento.cliente.nazione}/>
              </div>
              {#if $abbonamento.cliente != null && $abbonamento.cliente.nazione == "IT"}
                <div class="blocco-inserimento-campi">
                  <Inserimento
                      etichetta={`${t.partita_iva[$l]}**`}
                      attributi={{ etichetta: "VAT Number" }}
                      errore={errori.partita_iva}
                      bind:valore={$abbonamento.cliente.partita_iva}/>
                  <Inserimento
                      etichetta={`${t.codice_fiscale[$l]}**`}
                      attributi={{ etichetta: "Fiscal Code" }}
                      errore={errori.codice_fiscale}
                      bind:valore={$abbonamento.cliente.codice_fiscale}/>
                </div>
                <div class="blocco-inserimento-campi">
                  <Inserimento 
                      etichetta={`${t.codice_sdi[$l]}***`}
                      errore={errori.codice_sdi}
                      bind:valore={$abbonamento.cliente.codice_sdi}/>
                  <Inserimento 
                      etichetta={`${t.email_pec[$l]}***`}
                      errore={errori.email_pec}
                      bind:valore={$abbonamento.cliente.email_pec}/>
                </div>
              {/if}
              <p>
                <small>** {t.inserire_piva_o_cf[$l]}</small> <br>
                <small>*** {t.inserire_sdi_o_pec[$l]}</small>
              </p>
            {/if}
            <div class="azioni">
              {#if errore_creazione_cliente}
                <Bottone 
                    variante="primario"
                    on:click={evento => errore_creazione_cliente = false}>
                  {t.riprova[$l]}
                </Bottone>
              {:else}
                <Bottone 
                    tipo="invio"
                    variante="primario">
                  {t.continua[$l]}
                </Bottone>
              {/if}
              <Bottone 
                  variante="testo-leggero-invertito"
                  on:click={torna_indietro}>
                {t.torna_indietro[$l]}
              </Bottone>
            </div>
          </Modulo>
        {:else}
          <Selezione 
              etichetta={t.vuoi_riutilizzare_clienti_già_inseriti[$l]}
              opzioni={opzioni_clienti}
              bind:valore={id_cliente_selezionato}>
            <div slot="azioni">
              <Bottone
                  piccolo={true}
                  variante="primario-invertito"
                  on:click={crea_nuovo_cliente}>
                {t.inserisci_nuovo_cliente[$l]}
              </Bottone>
            </div>
          </Selezione>
          {#if cliente_selezionato != null}
            <div class="informazioni-fatturazione">
              <TitoloSecondario>
                {t.ragione_sociale[$l]}
              </TitoloSecondario>
              <div class="informazione-fatturazione">
                {cliente_selezionato.ragione_sociale}
              </div>

              <TitoloSecondario>
                {t.email_fatturazione[$l]}
              </TitoloSecondario>
              <div class="informazione-fatturazione">
                {cliente_selezionato.email_fatturazione}
              </div>

              <TitoloSecondario>
                {t.indirizzo[$l]}
              </TitoloSecondario>
              <div class="informazione-fatturazione">
                {cliente_selezionato.indirizzo}
              </div>
              
              <TitoloSecondario>
                {t.partita_iva[$l]}/{t.codice_fiscale[$l]}
              </TitoloSecondario>
              <div class="informazione-fatturazione">
                {cliente_selezionato.partita_iva || "-"} <br/>
                {cliente_selezionato.codice_fiscale || "-"}
              </div>
            </div>
          {/if}
          <div class="azioni">
            <Bottone
                variante="primario"
                on:click={riutilizza_cliente_selezionato}>
              {t.continua[$l]}
            </Bottone>
            <Bottone 
                variante="testo-leggero-invertito"
                on:click={torna_indietro}>
              {t.torna_indietro[$l]}
            </Bottone>
          </div>
        {/if}
      {/if}
    </div>
  </RottaNuovoAbbonamento>
</template>

<style>
  :global(div.passo-progresso-abbonamento.fatturazione div.blocco-inserimento-campi > div.inserimento),
  :global(div.passo-progresso-abbonamento.fatturazione div.blocco-inserimento-campi div.selezione) {
    width: 49%;
  }
  :global(div.passo-progresso-abbonamento.fatturazione form div.blocco-inserimento-campi:nth-child(4) div.inserimento:not(:first-child)),
  :global(div.passo-progresso-abbonamento.fatturazione form div.blocco-inserimento-campi:nth-child(4) div.selezione) {
    width: 24%;
  }
  :global(div.passo-progresso-abbonamento.fatturazione form div.selezione div.contenitore-opzioni) {
    height: 200px;
  }
  :global(div.passo-progresso-abbonamento.fatturazione div.informazioni-fatturazione div.titolo-secondario) {
    margin-bottom: 0;
  }
  :global(div.passo-progresso-abbonamento.fatturazione div.informazioni-fatturazione div.informazione-fatturazione) {
    margin-bottom: 15px;
  }
  :global(div.passo-progresso-abbonamento.fatturazione div.azioni) {
    display: flex;
  }
</style>

<script>
  import RottaNuovoAbbonamento from "@/portale/componenti/abbonamento/RottaNuovoAbbonamento.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Modulo from "@/base/componenti/Modulo.svelte"
  import Selezione from "@/base/componenti/Selezione.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import TitoloSecondario from "@/base/componenti/TitoloSecondario.svelte"
  import { onMount } from "svelte"
  import { abbonamento } from "@//portale/sorgenti/abbonamento"
  import { nazionalità } from "@/base/sorgenti/svuby/nazionalità"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"

  const t = avvia_localizzazione(dizionario_fatturazione)
  const province_italiane = {
    AG: "Agrigento", AL: "Alessandria", AN: "Ancona", AO: "Aosta", AQ: "L'Aquila", AR: "Arezzo", AP: "Ascoli Piceno", AT: "Asti", AV: "Avellino",
    BA: "Bari", BT: "Barletta Andria Trani", BL: "Belluno", BN: "Benevento", BG: "Bergamo", BI: "Biella", BO: "Bologna", BZ: "Bolzano", BS: "Brescia",
    BR: "Brindisi", CA: "Cagliari", CL: "Caltanissetta", CB: "Campobasso", CI: "Carbonia Iglesias", CE: "Caserta", CT: "Catania", CZ: "Catanzaro",
    CH: "Chieti", CO: "Como", CS: "Cosenza", CR: "Cremona", KR: "Crotone", CN: "Cuneo", EN: "Enna", FM: "Fermo", FE: "Ferrara", FI: "Firenze",
    FG: "Foggia", FC: "Forlì Cesena", FR: "Frosinone", GE: "Genova", GO: "Gorizia", GR: "Grosseto", IM: "Imperia", IS: "Isernia", SP: "La Spezia",
    LT: "Latina", LE: "Lecce", LC: "Lecco", LI: "Livorno", LO: "Lodi", LU: "Lucca", MC: "Macerata", MN: "Mantova", MS: "Massa Carrara", MT: "Matera",
    VS: "Medio Campidano", ME: "Messina", MI: "Milano", MO: "Modena", MB: "Monza Brianza", NA: "Napoli", NO: "Novara", NU: "Nuoro", OG: "Ogliastra",
    OT: "Olbia Tempio", OR: "Oristano", PD: "Padova", PA: "Palermo", PR: "Parma", PV: "Pavia", PG: "Perugia", PU: "Pesaro Urbino", PE: "Pescara",
    PC: "Piacenza", PI: "Pisa", PT: "Pistoia", PN: "Pordenone", PZ: "Potenza", PO: "Prato", RG: "Ragusa", RA: "Ravenna", RC: "Reggio Calabria",
    RE: "Reggio Emilia", RI: "Rieti", RN: "Rimini", RM: "Roma", RO: "Rovigo", SA: "Salerno", SS: "Sassari", SV: "Savona", SI: "Siena", SR: "Siracusa",
    SO: "Sondrio", TA: "Taranto", TE: "Teramo", TR: "Terni", TO: "Torino", TP: "Trapani", TN: "Trento", TV: "Treviso", TS: "Trieste", UD: "Udine",
    VA: "Varese", VE: "Venezia", VB: "Verbania", VC: "Vercelli", VR: "Verona", VV: "Vibo Valentia", VI: "Vicenza", VT: "Viterbo" }
  const espressione_email_valida           = /^\S+@\S+\.\S+$/
  const espressione_partita_iva_valida     = /^\d{11}$/
  const espressione_codice_fiscale_valido_16  = /^[A-Z\d]{16}$/
  const espressione_codice_fiscale_valido_11  = /^[A-Z\d]{11}$/

  let in_invio                  = false
  let errore_creazione_cliente  = false
  let id_cliente_selezionato    = null
  let clienti                   = []
  let errori                    = {
    ragione_sociale:    null,
    email_fatturazione: null,
    indirizzo:          null,
    città:              null,
    provincia:          null,
    cap:                null,
    telefono:           null,
    partita_iva:        null,
    codice_fiscale:     null,
    codice_sdi:         null,
    email_pec:          null }

  $: opzioni_clienti = clienti.map(cliente => {
    return { valore: cliente._id, testo: cliente.ragione_sociale }
  })
  $: opzioni_nazioni = nazionalità.map((sigla, nome) => {
    return { valore: sigla, testo: nome[$l] }
  })
  $: opzioni_province_italiane = province_italiane.map((sigla, nome) => {
    return { valore: sigla, testo: `${nome} (${sigla})` }
  })
  $: cliente_selezionato = clienti.find(cliente => cliente._id == id_cliente_selezionato)

  // Considerazioni per flussi personalizzati.
  switch ($abbonamento.flusso_personalizzato) {
    case "padova":
    case "livigno":
    case "trentino":
      $abbonamento.id_cliente = "5e15f2b42ea50b21e1190e93"
      continua()
      break
  }

  // Valida i dati fatturazione.
  function valida_fatturazione() {
    if (!$abbonamento.cliente.ragione_sociale)    errori.ragione_sociale    = t.validazioni.richiesto[$l]
    if (!$abbonamento.cliente.email_fatturazione) errori.email_fatturazione = t.validazioni.richiesto[$l]
    if (!$abbonamento.cliente.indirizzo)          errori.indirizzo          = t.validazioni.richiesto[$l]
    if (!$abbonamento.cliente.città)              errori.città              = t.validazioni.richiesto[$l]
    if (!$abbonamento.cliente.provincia)          errori.provincia          = t.validazioni.richiesto[$l]
    if (!$abbonamento.cliente.cap)                errori.cap                = t.validazioni.richiesto[$l]
    if (!$abbonamento.cliente.telefono)           errori.telefono           = t.validazioni.richiesto[$l]

    if (!$abbonamento.cliente.email_fatturazione.match(espressione_email_valida))
      errori.email_fatturazione = t.validazioni.email[$l]

    if ($abbonamento.cliente != null && $abbonamento.cliente.nazione == "IT") {
      if (!$abbonamento.cliente.partita_iva && !$abbonamento.cliente.codice_fiscale) {
        errori.partita_iva    = t.validazioni_cliente.iva_o_fiscale[$l]
        errori.codice_fiscale = t.validazioni_cliente.iva_o_fiscale[$l]
      }

      if ($abbonamento.cliente.partita_iva && !$abbonamento.cliente.partita_iva.match(espressione_partita_iva_valida))
        errori.partita_iva = t.validazioni_cliente.partita_iva[$l]
      if ($abbonamento.cliente.codice_fiscale && (!$abbonamento.cliente.codice_fiscale.match(espressione_codice_fiscale_valido_16) && !$abbonamento.cliente.codice_fiscale.match(espressione_codice_fiscale_valido_11)))
        errori.codice_fiscale = t.validazioni_cliente.codice_fiscale[$l]

      if (!$abbonamento.cliente.codice_sdi && !$abbonamento.cliente.email_pec) {
        errori.codice_sdi = t.validazioni_cliente.sdi_o_pec[$l]
        errori.email_pec  = t.validazioni_cliente.sdi_o_pec[$l]
      }

      if ($abbonamento.cliente.email_pec && !$abbonamento.cliente.email_pec.match(espressione_email_valida))
        errori.email_pec = t.validazioni_cliente.email_pec[$l]
    }
  }

  // Crea il cliente.
  async function crea_cliente() {
    in_invio = true
    errore_creazione_cliente = false

    if (!$abbonamento.cliente.partita_iva)    delete $abbonamento.cliente.partita_iva
    if (!$abbonamento.cliente.codice_fiscale) delete $abbonamento.cliente.codice_fiscale

    let risposta = await retro.chiama("POST",
      retro.estremi.licenze.clienti,
      { cliente: $abbonamento.cliente })

    if (risposta.stato != 201) errore_creazione_cliente = true
    if (risposta.stato == 201) $abbonamento.id_cliente  = risposta.contenuto.cliente._id

    in_invio = false

    continua()
  }

  // Richiedi clienti già creati.
  async function richiedi_clienti() {
    in_invio = true

    let risposta = await retro.chiama("GET", retro.estremi.licenze.clienti)
    clienti = risposta.contenuto.clienti

    if (clienti.length > 0)   id_cliente_selezionato = clienti[0]._id
    if (clienti.length == 0)  $abbonamento.crea_nuovo_cliente = true

    in_invio = false
  }

  // Riutilizza cliente selezionato.
  function riutilizza_cliente_selezionato() {
    delete $abbonamento.cliente
    $abbonamento.id_cliente = id_cliente_selezionato
    continua()
  }

  // Crea nuovo cliente.
  function crea_nuovo_cliente() {
    if ($abbonamento.cliente == null) $abbonamento.cliente = {}
    delete $abbonamento.id_cliente
    $abbonamento.crea_nuovo_cliente = true
  }

  ////
  // Torna al passo precedente
  function torna_indietro() {
    $abbonamento.passo_corrente -= 1
    navigatore.verso("/nuovo_abbonamento/piano")
  }

  // Continua al prossimo passo.
  function continua() {
    $abbonamento.passo_corrente = 4
    if ($abbonamento.passo_raggiunto < 4) $abbonamento.passo_raggiunto = 4
    abbonamento.sovrascrivi($abbonamento)
    navigatore.verso("/nuovo_abbonamento/pagamento")
  }

  // Quando pronto, richiede i clienti.
  onMount(richiedi_clienti)
</script>

<script context="module">
  export const dizionario_fatturazione = {
    titolo: {
      it: `Fatturazione`,
      en: `Billing`,
      de: `Abrechnung`
    },
    titolo_secondario: {
      it: `Inserisci i dati di fatturazione`,
      en: `Enter your billing details`,
      de: `Geben Sie Ihre Rechnungsdaten ein`
    },
    ragione_sociale: {
      it: `Ragione Sociale`,
      en: `Business Name`,
      de: `Firmenname`
    },
    email_fatturazione: {
      it: `Email Fatturazione`,
      en: `Billing Email`,
      de: `Rechnungs-E-Mail`
    },
    indirizzo: {
      it: `Indirizzo`,
      en: `Address`,
      de: `Adresse`
    },
    città: {
      it: `Città`,
      en: `City`,
      de: `Stadt`
    },
    provincia: {
      it: `Provincia`,
      en: `Province`,
      de: `Bundesland`
    },
    cap: {
      it: `CAP`,
      en: `Zip Code`,
      de: `Postleitzahl`
    },
    nazione: {
      it: `Nazione`,
      en: `Country`,
      de: `Land`
    },
    telefono: {
      it: `Telefono`,
      en: `Phone`,
      de: `Telefonnummer`
    },
    partita_iva: {
      it: `Partita IVA`,
      en: `VAT Number`,
      de: `Umsatzsteuer-Identifikationsnummer`
    },
    codice_fiscale: {
      it: `Cod. Fiscale`,
      en: `Fiscal Code`,
      de: `Steuernummer`
    },
    codice_sdi: {
      it: `Codice SDI`,
      en: `SDI Code`,
      de: `SDI-Code`
    },
    email_pec: {
      it: `Email PEC`,
      en: `Email PEC`,
      de: `E-Mail PEC`
    },
    vuoi_riutilizzare_clienti_già_inseriti: {
      it: `Utilizza dati già inseriti per`,
      en: `Use data already entered for`,
      de: `Bereits eingegebene Daten verwenden für`
    },
    inserisci_nuovo_cliente: {
      it: `+ Aggiungi nuova ragione sociale`,
      en: `+ Add new company name`,
      de: `+ Neuen Firmennamen hinzufügen`
    },
    inserisci_cliente_precedente: {
      it: `Utilizza dati già inseriti`,
      en: `Use data already entered`,
      de: `Bereits eingegebene Daten verwenden`
    },
    inserire_piva_o_cf: {
      it: `Inserire Partita IVA e/o Cod. Fiscale`,
      en: `Enter VAT number and/or Fiscal Code`,
      de: `Geben Sie die Umsatzsteuer-Identifikationsnummer und / oder die Steuergesetzbuch ein`
    },
    inserire_sdi_o_pec: {
      it: `Inserire il Codice SDI e/o Email PEC`,
      en: `Enter SDI Code and/or Email PEC`,
      de: `Geben Sie die SDI-Code und / oder die E-Mail PEC ein`
    },
    validazioni_cliente: {
      iva_o_fiscale: {
        it: `Inserire almeno uno fra Partita IVA o Cod. Fiscale`,
        en: `Insert at least one of VAT Number or Fiscal Code`,
        de: `Geben Sie mindestens eine Umsatzsteuer-Identifikationsnummer oder eine Steuernummer ein`
      },
      partita_iva: {
        it: `Inserire 11 cifre, senza il prefisso 'IT'`,
        en: `Insert 11 digits, without 'IT' prefix`,
        de: `11 Ziffern ohne IT-Präfix einfügen`
      },
      codice_fiscale: {
        it: `Inserire 11 o 16 caratteri fra lettere MAIUSCOLE e cifre`,
        en: `Insert 11 or 16 between UPPER CASE characters and digits`,
        de: `Fügen Sie 11 oder 16 zwischen GROSSBUCHSTABEN und Ziffern ein`
      },
      sdi_o_pec: {
        it: `Inserire almeno uno fra Codice SDI o Email PEC`,
        en: `Insert at least one of SDI Code or Email PEC`,
        de: `Geben Sie mindestens einen SDI-Code oder eine E-Mail PEC ein`
      },
      email_pec: {
        it: `Inserire una Email PEC valida`,
        en: `Insert a valid PEC Email`,
        de: `Geben Sie eine gültige PEC-E-Mail ein`
      }
    }
  }
</script>
